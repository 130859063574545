import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import throttle from "lodash/throttle";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisible = useMemo(
    () =>
      throttle(() => {
        const scrollAreaElement =
          document.getElementsByClassName("scrollarea")[0];
        const scrolled = scrollAreaElement.scrollTop;
        setIsVisible(scrolled >= scrollAreaElement.clientHeight);
      }, 200),
    []
  );

  const handleClick = useCallback(() => {
    document.getElementsByClassName("scrollarea")[0]?.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  useEffect(() => {
    const listener = document
      .getElementsByClassName("scrollarea")[0]
      ?.addEventListener("scroll", toggleVisible);

    return () => {
      document
        .getElementsByClassName("scrollarea")[0]
        ?.removeEventListener("scroll", listener);
    };
  }, [toggleVisible]);

  const theme = useTheme();

  return isVisible ? (
    <Paper>
      <Button
        disableRipple
        // olor="white"
        startIcon={<ArrowUpwardIcon />}
        style={{
          backgroundColor: "white",
          bottom: 30,
          color: theme.palette.primary.main,
          position: "fixed",
          right: 30,
          zIndex: Number.MAX_SAFE_INTEGER,
        }}
        variant="contained"
        onClick={handleClick}
      >
        To top
      </Button>
    </Paper>
  ) : null;
};

export default ScrollToTop;
