/* eslint-disable prettier/prettier */
import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import useConfirmLeave from "./../hooks/useConfirmLeave";
import useModal from "./../hooks/useModal";
import { getFieldProps, inputProps } from "./utils";

const editSchema = yup.object().shape({
  name: yup.string().required(),
});

const EditTipGroupModal = ({ item, onClose, onConfirm, isNew = false }) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });
  const formik = useFormik({
    initialValues: isNew
      ? {
          name: "",
        }
      : item,
    onSubmit: handleConfirm,
    validationSchema: editSchema,
    validator: () => ({}),
  });

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isNew ? "Add tip group" : "Edit tip group"}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Name"
              margin="none"
              placeholder="Tip Group name..."
              variant="outlined"
              {...getFieldProps(formik, { name: "name" })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTipGroupModal;
