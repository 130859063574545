import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sticky from "react-sticky-el";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import times from "lodash/times";
import countBy from "lodash/countBy";
import keyBy from "lodash/keyBy";
import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useModals } from "../../hooks/useModals";

import { MenuItem, TextField, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiChip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import MuiList from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import deepPurple from "@material-ui/core/colors/deepPurple";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FileCopy from "@material-ui/icons/FileCopy";

import { useAppContext } from "../../api/AppContext";
import TitleTypography from "../TitleTypography";
import Loader from "../shared/Loader";
import OutlinedButton from "../OutlinedButton";
import useLocalStorage from "../../hooks/useLocalStorage";
import Preview from "../Preview";
import AsyncButton from "../shared/AsyncButton";
import useStickyHeight from "../../hooks/useStickyHeight";
import MessagesRules from "./MessagesRules";
import { getIntersectedIntervals } from "../utils";

const renderers = {
  paragraph: "span",
};

const DragHandleContainer = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const Chip = withStyles({
  label: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
})(MuiChip);

const AccordionSummary = withStyles({
  content: {
    "&.Mui-expanded": {
      margin: 0,
    },
    wordBreak: "break-all",
  },
})(MuiAccordionSummary);

const List = withStyles({
  root: {
    width: "100%",
  },
})(MuiList);

const ListItem = withStyles({
  root: {
    paddingRight: 160,
  },
})(MuiListItem);

// the same is copied in Tips.js; TODO move it to an utility file
const maxSemanticTextPreviewLength = 80;
const getSemanticTextPreview = function ({ title, text }) {
  const tmpTextPreview = (title || text) ?? "";
  const textPreview =
    tmpTextPreview.length > maxSemanticTextPreviewLength
      ? `${tmpTextPreview.slice(0, maxSemanticTextPreviewLength)}...`
      : tmpTextPreview;
  return textPreview;
};

const Group = ({
  journeyId,
  i,
  group,
  groups,
  isDisabled,
  onAddGroup,
  onDeleteGroup,
  onChangeGroup,
  nudgesByFlowId,
  nudgesCountByFlowId,
  selected,
  onNudgeEdit,
  onNudgeDelete,
  onNudgePublish,
  onListItemClick,
}) => {
  const { order, id, isTakeAll, inPastDaysNumber, flows, stimulusId } = group;

  const handleIsTakeAllChange = useCallback(
    (e) =>
      onChangeGroup({
        id,
        isTakeAll: e.target.value === "true",
      }),
    [id, onChangeGroup]
  );

  const handleInPastDaysNumberChange = useCallback(
    (e) =>
      onChangeGroup({
        id,
        inPastDaysNumber: parseInt(e.target.value),
      }),
    [id, onChangeGroup]
  );

  const modals = useModals();

  const openCopyFlow = useCallback(
    ({ journeyId, flowId }) => {
      modals.openModal(modals.modalTypes.CopyFlow, {
        flowId,
        journeyId,
      });
    },
    [modals]
  );

  const handleCopyFlow = useCallback(
    ({ journeyId, flowId }) =>
      () => {
        if (!flowId || !journeyId) {
          return;
        }
        openCopyFlow({ flowId, journeyId });
      },
    [openCopyFlow]
  );

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">
            Priority group {i + 1}
            {i === 0 && <> (highest)</>}
            {i !== 0 && i === groups.length - 1 && <> (lowest)</>}
          </Typography>
        </Grid>
        <Grid item>
          <AsyncButton
            color="primary"
            disabled={isDisabled}
            endIcon={<AddIcon />}
            size="small"
            variant="contained"
            onClick={onAddGroup({
              order: (order + (groups[i - 1]?.order || 0)) / 2,
              stimulusId,
            })}
          >
            Add a higher priority group
          </AsyncButton>
        </Grid>
        {groups.length > 1 && (
          <Grid item>
            <AsyncButton
              color="primary"
              disabled={isDisabled || flows?.length > 0}
              endIcon={<DeleteIcon />}
              size="small"
              variant="contained"
              onClick={onDeleteGroup(id)}
            >
              Remove priority group
            </AsyncButton>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            style={{
              lineHeight: "32px",
              whiteSpace: "pre-wrap",
            }}
            variant="body2"
          >
            {i === groups.length - 1
              ? "Don't show nudges from this priority group if "
              : "Skip to the next priority group if "}
            <TextField
              select
              disabled={isDisabled}
              value={isTakeAll}
              onChange={handleIsTakeAllChange}
            >
              <MenuItem value="false">at least one</MenuItem>
              <MenuItem value="true">all</MenuItem>
            </TextField>
            {"  "}
            nudges of this priority group has been seen by the user within the
            last{"  "}
            <TextField
              select
              disabled={isDisabled}
              value={inPastDaysNumber}
              onChange={handleInPastDaysNumberChange}
            >
              {times(30, (i) => (
                <MenuItem value={i + 1}>{i + 1}</MenuItem>
              ))}
            </TextField>
            {"  "}
            days or there are no nudges of this priority group valid right now.
          </Typography>
        </Grid>
      </Grid>
      <Droppable droppableId={group.id}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List dense>
              {!flows?.length && (
                <Box p={2}>
                  <Typography style={{ textAlign: "center" }} variant="body2">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    The aren't any nudges in this group. Drag and drop first
                  </Typography>
                </Box>
              )}
              {flows?.map(({ id: flowId, isPublished }, index) => {
                if (!nudgesByFlowId[flowId]) {
                  return null;
                }

                const {
                  id,
                  text,
                  title,
                  flowId: nudgeFlowId,
                } = nudgesByFlowId[flowId];
                return (
                  <Draggable
                    key={id}
                    draggableId={`${stimulusId}+${nudgeFlowId}`}
                    index={index}
                  >
                    {(provided) => (
                      <div {...provided.draggableProps} ref={provided.innerRef}>
                        <ListItem
                          key={id}
                          button
                          selected={id === selected}
                          style={{
                            ...(!isPublished && {
                              backgroundColor: deepPurple[50],
                            }),
                          }}
                          onClick={onListItemClick(id)}
                        >
                          <ListItemAvatar>
                            <div {...(!isDisabled && provided.dragHandleProps)}>
                              <DragHandleContainer isDisabled={isDisabled}>
                                <DragHandleIcon
                                  color={isDisabled ? "disabled" : "action"}
                                />
                              </DragHandleContainer>
                            </div>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <ReactMarkdown renderers={renderers}>
                                {getSemanticTextPreview({ text, title })}
                              </ReactMarkdown>
                            }
                            primaryTypographyProps={{
                              color: "textPrimary",
                              // paddingRight: 50, // space for the new button; this for some reason is not working
                            }}
                            secondary={`${
                              (flowId ? nudgesCountByFlowId[flowId] : 1) || 1
                            } steps`}
                            style={{ paddingRight: 50 }}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip placement="top" title="Copy">
                              <IconButton
                                onClick={handleCopyFlow({
                                  flowId,
                                  journeyId,
                                })}
                              >
                                <FileCopy />
                              </IconButton>
                            </Tooltip>
                            <IconButton onClick={onNudgeEdit(id)}>
                              <EditIcon />
                            </IconButton>
                            <Tooltip
                              title={
                                isPublished
                                  ? "Published (click to unpublish)"
                                  : "Unpublished (click to publish)"
                              }
                            >
                              <span>
                                <IconButton
                                  disabled={isDisabled}
                                  onClick={onNudgePublish(flowId)}
                                >
                                  {isPublished ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                            <IconButton onClick={onNudgeDelete(flowId)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </List>
          </div>
        )}
      </Droppable>
    </>
  );
};

const triggerConfigValidationSchema = ({ days }) =>
  yup.object({
    tiers: yup.array().of(
      yup.object({
        from: yup
          .number()
          .integer()
          .min(1, "From must be greater than or equal to ${min}")
          .required()
          .when("to", {
            is: (to) => to !== undefined,
            then: yup
              .number()
              .max(yup.ref("to"), "From must be less than or equal to ${max}"),
          }),
        to: yup
          .number()
          .integer()
          .min(yup.ref("from"), "To must be greater than or equal to ${min}")
          .max(days, "To must be less than or equal to ${max}"),
        maxDailyOccurrences: yup.number().integer().min(1).max(100).required(),
        minOccurrencesInterval: yup
          .number()
          .integer()
          .min(0)
          .max(1440)
          .required(),
      })
    ),
  });

const TriggerConfig = ({ config, isDisabled, onSave, days, triggers }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: config,
    onSubmit: async (values) => {
      const intersectedIntervals = getIntersectedIntervals(
        (values.tiers || []).map(({ from, to }) => [from, to])
      );
      if (intersectedIntervals.length) {
        enqueueSnackbar(
          `Tiers have intersected interval for triggers ${triggers
            .map(({ name }) => name)
            .join(", ")}`,
          {
            variant: "error",
          }
        );
        return;
      }

      return onSave(values);
    },
    validationSchema: triggerConfigValidationSchema({ days }),
    validator: () => ({}),
  });

  const handleCancel = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Nudges rules for this trigger
            </Typography>
            <Typography variant="body2">
              The following rules are valid for this trigger in this Journey
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MessagesRules
              days={days}
              defaultMaxDailyOccurrences={1}
              defaultMinOccurrencesInterval={30}
              formik={formik}
              isDisabled={isDisabled}
              isGeneralInfoMessages={false}
              maxDailyOccurrencesLabel="Maximum daily occurrences"
              maxOccurrencesHelperText="If a user has already received ${value} nudges related to this trigger, that user cannot receive any more nudges related to this trigger"
              minOccurrencesIntervalHelperText="If a user received a nudge related to this trigger, that user cannot receive another nudge related to this trigger for ${value} minutes"
              minOccurrencesIntervalLabel="Timer across nudges"
              size="small"
            />
          </Grid>
          {formik.dirty && (
            <>
              <Grid item>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  endIcon={
                    formik.isSubmitting && <CircularProgress size={25} />
                  }
                  size="small"
                  variant="contained"
                  onClick={formik.submitForm}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  size="small"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

const Contextual = ({ journeyId, locale, isDisabled }) => {
  const {
    // fetchTriggersAppLink,
    // triggersAppLink,
    fetchTriggers,
    isTriggersLoading,
    triggers,
    fetchSemantics,
    isSemanticsLoading,
    isGroupsLoading,
    fetchGroups,
    groups,
    semantics,
    user,
    updateTrigger,
    deleteTrigger,
    // updateJourneySemantic,
    exportSemantics,
    updateJourneyGroup,
    updateMultipleJourneyGroups,
    deleteJourneyGroup,
    fetchTriggerConfigs,
    isTriggerConfigsLoading,
    triggerConfigs,
    updateTriggerConfig,
    journeys,
    deleteJourneySemanticsByFlow,
  } = useAppContext();
  const modals = useModals();

  // useEffect(() => {
  //   if (triggersAppLink) {
  //     return;
  //   }

  //   fetchTriggersAppLink();
  // }, [triggersAppLink]);

  useEffect(() => {
    fetchTriggers({ journeyId });
    fetchTriggerConfigs({ journeyId });
  }, [journeyId]);

  useEffect(() => {
    fetchSemantics({ journeyId, locale });
  }, [journeyId, locale]);

  useEffect(() => {
    fetchGroups({ journeyId, locale });
  }, [journeyId, locale]);

  const journey = useMemo(
    () =>
      journeys.find(
        (journey) => journey.id === journeyId && journey.locale === locale
      ),
    [journeyId, locale]
  );

  const nudges = useMemo(
    () => (semantics || []).filter(({ type }) => type === "NUDGE"),
    [semantics]
  );

  const nudgesCountByFlowId = useMemo(
    () => countBy(nudges, "flowId"),
    [nudges]
  );

  const nudgesByFlowId = useMemo(
    () =>
      keyBy(
        nudges.filter(({ isFirstInFlow }) => isFirstInFlow),
        "flowId"
      ),
    [nudges]
  );

  // const handleDownloadTriggersApp = useCallback(() => {
  //   window.open(triggersAppLink);
  // }, [triggersAppLink]);

  const history = useHistory();
  const handleNudgePublish = useCallback(
    (group) => (flowId) => () => {
      const currentFlow = group.flows.filter(({ id }) => id === flowId)[0];
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: currentFlow.isPublished ? "Unpublish" : "Publish",
        onConfirm: async () => {
          await updateJourneyGroup({
            flows: group.flows.map((flowObj) =>
              flowObj.id === flowId
                ? { ...flowObj, isPublished: !flowObj.isPublished }
                : flowObj
            ),
            id: group.id,
          });
        },
        text: `Nudge flow will be ${
          currentFlow.isPublished ? "hidden" : "visible"
        } for users`,
        title: `${
          currentFlow.isPublished ? "Unpublish" : "Publish"
        } the nudge?`,
      });
    },
    [modals, updateJourneyGroup]
  );
  const handleNudgeEdit = useCallback(
    (id) => () => {
      history.push(`/journeys/${journeyId}/${locale}/nudges/${id}`);
    },
    [history, journeyId, locale]
  );
  const handleNudgeDelete = useCallback(
    (group) => (flowId) => () =>
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: "Delete",
        onConfirm: async () => {
          await updateJourneyGroup({
            id: group.id,
            flows: group.flows.filter(({ id }) => id !== flowId),
          });
          await deleteJourneySemanticsByFlow({ flowId, journeyId });
        },
        text: `You're about to permanently delete a nudges.`,
        title: "Delete the nudge?",
      }),
    [modals, updateJourneyGroup, deleteJourneySemanticsByFlow, journeyId]
  );

  // const handleAddNewContent = useCallback(() => {
  //   modals.openModal(modals.modalTypes.CreateContextualContent, {
  //     onConfirm: ({ contentType }) => {
  //       modals.openModal(modals.modalTypes.CreateContextualTrigger, {
  //         contentType,
  //         onConfirm: async (trigger) => {
  //           const createdTrigger = await updateTrigger(journeyId, {
  //             ...trigger,
  //             contentType,
  //             id: uuidv4(),
  //           });

  //           if (!createdTrigger) {
  //             return;
  //           }

  //           const nudge = {
  //             flowId: `flow-${uuidv4()}`,
  //             isNew: true,
  //             journeyId,
  //             stimulusId: createdTrigger.stimulusId,
  //             type: "NUDGE",
  //           };

  //           let semantics;

  //           if (contentType === "onboarding" || contentType === "step_guide") {
  //             semantics = times(4, () => `sem-${uuidv4()}`).map(
  //               (id, i, array) => ({
  //                 ...nudge,
  //                 ...(i === 0 && {
  //                   cta1Target: array[1],
  //                   cta1Text: `Start ${
  //                     contentType === "onboarding" ? "onboarding" : "the guide"
  //                   }`,
  //                   cta1Type: "Semantic",
  //                 }),
  //                 ...(i === 0 &&
  //                   contentType === "step_guide" && {
  //                     cta2Text: "Skip",
  //                     cta2Type: "Close",
  //                   }),
  //                 ...(i === array.length - 1 && {
  //                   cta1Target: array[i - 1],
  //                   cta1Text: "⬅ Back",
  //                   cta1Type: "Semantic",
  //                   cta2Text: "Finish",
  //                   cta2Type: "Close",
  //                 }),
  //                 ...(i !== 0 &&
  //                   i !== array.length - 1 && {
  //                     cta1Target: array[i - 1],
  //                     cta1Text: "⬅ Back",
  //                     cta1Type: "Semantic",
  //                     cta2Target: array[i + 1],
  //                     cta2Text: "Next ➡",
  //                     cta2Type: "Semantic",
  //                   }),
  //                 ctaType:
  //                   i === 0 && contentType === "onboarding"
  //                     ? "CTA"
  //                     : "DOUBLE_CTA",
  //                 id,
  //                 index: i,
  //                 isFirstInFlow: i === 0,
  //                 semanticId: id,
  //                 text: `Deep dive into the tip's topic and showcase **the value ${
  //                   i + 1
  //                 } for the user.**`,
  //               })
  //             );
  //           }

  //           if (
  //             contentType === "custom" ||
  //             contentType === "feature_promotion"
  //           ) {
  //             const id = `sem-${uuidv4()}`;
  //             semantics = [
  //               {
  //                 ...nudge,
  //                 cta1Text: "Ok, got it",
  //                 cta1Type: "Close",
  //                 cta2Text: "",
  //                 cta2Type: "Close",
  //                 ctaType: "CTA",
  //                 id,
  //                 index: 0,
  //                 isFirstInFlow: true,
  //                 semanticId: id,
  //                 text:
  //                   "Deep dive into the tip's topic and showcase **the value for the user.**",
  //               },
  //             ];
  //           }

  //           if (!semantics?.length) {
  //             return;
  //           }

  //           for (const semantic of semantics) {
  //             await updateJourneySemantic(semantic);
  //           }

  //           handleNudgeEdit(semantics[0].id)();
  //         },
  //       });
  //     },
  //   });
  // }, [
  //   handleNudgeEdit,
  //   journeyId,
  //   modals,
  //   updateJourneySemantic,
  //   updateTrigger,
  // ]);

  const handleDeleteTrigger = useCallback(
    ({ id, name }) =>
      (e) => {
        e.stopPropagation();
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Delete",
          onConfirm: () => deleteTrigger(journeyId, id),
          text: `You're about to permanently delete a trigger "${name}" with interactions.`,
          title: "Delete the trigger?",
        });
      },
    [deleteTrigger, journeyId, modals]
  );

  const handleAddLegacyNudge = useCallback(
    (stimulusId) => async () => {
      history.push(`/journeys/${journeyId}/${locale}/nudges/sem-${uuidv4()}`, {
        isNew: true,
        stimulusId,
        type: "NUDGE",
      });
    },
    [history, journeyId, locale]
  );

  const handleEditTrigger = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      modals.openModal(modals.modalTypes.CreateContextualTrigger, {
        onConfirm: async (trigger) => {
          await updateTrigger(journeyId, trigger);
        },
        trigger: triggers.find((trigger) => trigger.id === id),
      });
    },
    [journeyId, modals, triggers, updateTrigger]
  );

  const [expanded, setExpanded] = useLocalStorage(
    `${user.sub}.Journeys.${journeyId}-${locale}.Contextual.ExpandedTriggers`,
    {}
  );

  const handleExpandedChange = useCallback(
    (id) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [id]: !expanded[id],
      }));
    },
    [setExpanded]
  );

  const [selected, setSelected] = useState(null);
  const handleListItemClick = useCallback(
    (id) => () => {
      setSelected(id);
    },
    []
  );

  const handleExport = useCallback(
    () => exportSemantics({ journeyId, locale }),
    [exportSemantics, journeyId, locale]
  );

  const handleAddGroup = useCallback(
    ({ order, stimulusId }) =>
      async () => {
        const group = {
          flows: [],
          isNew: true,
          inPastDaysNumber: 1,
          isTakeAll: true,
          journeyId,
          order,
          stimulusId,
        };

        await updateJourneyGroup(group);
      },
    [journeyId, updateJourneyGroup]
  );

  const handleDeleteGroup = useCallback(
    (id) => () => deleteJourneyGroup({ id }),
    [deleteJourneyGroup]
  );

  const handleOnDragEnd = useCallback(
    async (result) => {
      if (!result.destination) {
        return;
      }

      const { droppableId: sourceGroupId, index: sourceIndex } = result.source;
      const { droppableId: destinationGroupId, index: destinationIndex } =
        result.destination;

      const [stimulusId, flowId] = result.draggableId.split("+");

      const flow = { id: flowId };
      const sourceGroup = groups[stimulusId].find(
        ({ id }) => sourceGroupId === id
      );

      if (sourceGroupId !== destinationGroupId) {
        const destinationGroup = groups[stimulusId].find(
          ({ id }) => destinationGroupId === id
        );
        const sourceFlows = [...sourceGroup.flows];
        sourceFlows.splice(sourceIndex, 1);

        const destinationFlows = [...destinationGroup.flows];
        destinationFlows.splice(destinationIndex, 0, flow);
        await updateMultipleJourneyGroups([
          {
            flows: sourceFlows,
            id: sourceGroupId,
          },
          {
            flows: destinationFlows,
            id: destinationGroupId,
          },
        ]);

        return;
      }

      const flows = [...sourceGroup.flows];
      flows.splice(sourceIndex, 1);
      flows.splice(destinationIndex, 0, flow);

      await updateJourneyGroup({
        flows,
        id: sourceGroupId,
      });
    },
    [groups, updateJourneyGroup, updateMultipleJourneyGroups]
  );

  const handleTriggerConfigSave = useCallback(
    (values) => updateTriggerConfig(values),
    []
  );

  const triggersByStimulusIdMap = useMemo(
    () => groupBy(triggers, "stimulusId"),
    [triggers]
  );
  const triggersByStimulusId = useMemo(
    () => Object.values(triggersByStimulusIdMap),
    [triggers]
  );

  const triggerConfigByStimulusId = useMemo(
    () => keyBy(triggerConfigs, "stimulusId"),
    [triggerConfigs]
  );
  const theme = useTheme();

  const { containerRef: previewContainerRef, height } = useStickyHeight();

  if (
    isTriggersLoading ||
    isSemanticsLoading ||
    isGroupsLoading ||
    isTriggerConfigsLoading
  ) {
    return <Loader inline />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="flex-end" spacing={4}>
          <Grid item>
            <AsyncButton
              color="primary"
              variant="contained"
              onClick={handleExport}
              {...{ "hi-web-id": "semantics-export-button" }}
            >
              Export all tips and nudges
            </AsyncButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <TitleTypography variant="h6">Nudges</TitleTypography>
                  </Grid>
                  {/* {triggersAppLink && (
                    <Grid item>
                      <Button
                        color="primary"
                        endIcon={<GetAppIcon />}
                        onClick={handleDownloadTriggersApp}
                      >
                        Triggers creation tool
                      </Button>
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">
                  Support employees in the processes that are complex and
                  require some additional assistance. Add triggers (you can
                  download our trigger app below) for the beginning of the
                  process and create flow of nudges to guide employees though
                  them.
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>

          {/* 
          Commented in the context of sc32538
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={isDisabled}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddNewContent}
            >
              Add new content
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            {triggersByStimulusId.map(
              ([
                {
                  type,
                  name,
                  id,
                  windowClassName,
                  stimulusId,
                  journeyId: triggerJourneyId,
                },
                ...triggers
              ]) => (
                <Accordion
                  key={id}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={Boolean(expanded[id])}
                  onChange={handleExpandedChange(id)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      style={{ flexWrap: "nowrap", width: "100%" }}
                    >
                      <Grid item style={{ flexGrow: 1 }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Typography variant="h6">{name}</Typography>
                              </Grid>

                              {!triggerJourneyId && (
                                <Grid item>
                                  <Chip
                                    color="secondary"
                                    label="Legacy"
                                    size="small"
                                  />
                                </Grid>
                              )}

                              {triggers.map(({ id, name }) => (
                                <Grid key={id} item xs={12}>
                                  <Typography variant="subtitle2">
                                    {name}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                          {type === "browser" ||
                            (!type &&
                              windowClassName?.match(/^https?:\/\/.+/) && (
                                <Grid item xs={12}>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {windowClassName}
                                  </Typography>
                                </Grid>
                              ))}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                          style={{ flexWrap: "nowrap" }}
                        >
                          <Grid item style={{ whiteSpace: "nowrap" }}>
                            <Typography color="textSecondary" variant="body2">
                              {sumBy(
                                groups[stimulusId],
                                ({ flows }) => flows.length
                              )}{" "}
                              nudges under this trigger
                            </Typography>
                          </Grid>
                          {triggerJourneyId && expanded[id] && (
                            <>
                              <Grid item>
                                <OutlinedButton
                                  disabled={isDisabled}
                                  size="small"
                                  onClick={handleEditTrigger(id)}
                                >
                                  Edit
                                </OutlinedButton>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  disabled={isDisabled}
                                  onClick={handleDeleteTrigger({ id, name })}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </>
                          )}
                          {!triggerJourneyId && (
                            <Grid item>
                              <IconButton
                                disabled={isDisabled}
                                onClick={handleAddLegacyNudge(stimulusId)}
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box pl={4} width="100%">
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Grid container spacing={2}>
                          {!triggerJourneyId &&
                            triggerConfigByStimulusId[stimulusId] && (
                              <Grid item xs={12}>
                                <TriggerConfig
                                  config={triggerConfigByStimulusId[stimulusId]}
                                  days={journey.days.length}
                                  isDisabled={isDisabled}
                                  triggers={triggersByStimulusIdMap[stimulusId]}
                                  onSave={handleTriggerConfigSave}
                                />
                              </Grid>
                            )}
                          {groups[stimulusId]?.map(
                            (group, i, stimulusGroups) => (
                              <Grid key={group.id} item style={{ flexGrow: 1 }}>
                                <Paper variant="outlined">
                                  <Box p={2}>
                                    <Group
                                      group={group}
                                      groups={stimulusGroups}
                                      i={i}
                                      journeyId={journeyId}
                                      nudgesByFlowId={nudgesByFlowId}
                                      nudgesCountByFlowId={nudgesCountByFlowId}
                                      selected={selected}
                                      onAddGroup={handleAddGroup}
                                      onChangeGroup={updateJourneyGroup}
                                      onDeleteGroup={handleDeleteGroup}
                                      onListItemClick={handleListItemClick}
                                      onNudgeDelete={handleNudgeDelete(group)}
                                      onNudgeEdit={handleNudgeEdit}
                                      onNudgePublish={handleNudgePublish(group)}
                                    />
                                  </Box>
                                </Paper>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </DragDropContext>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2} xs={12} />
      <Grid item lg={4} xs={12}>
        <Sticky
          scrollElement=".scrollarea"
          stickyStyle={{ top: theme.spacing(4) + 92 }}
        >
          <div ref={previewContainerRef}>
            <Grid
              container
              spacing={4}
              style={{ flexDirection: "column", height, flexWrap: "nowrap" }}
            >
              <Grid item>
                <TitleTypography variant="h6">Preview</TitleTypography>
                <Typography color="textSecondary" variant="body2">
                  {selected ? "" : "Please select an interaction to preview"}
                </Typography>
              </Grid>
              <Grid item style={{ minHeight: 0 }}>
                {selected && (
                  <Box
                    alignItems="flex-start"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Preview
                      semanticId={selected}
                      src={`${
                        process.env
                          .REACT_APP_account_content_dashboard_render_url
                      }?silent=true&preview=true&props=${btoa(
                        JSON.stringify({
                          did: `preview_${locale}`,
                          semanticId: selected,
                        })
                      )}`}
                      userId={`preview_${locale}`}
                      viewType="client"
                    >
                      {({ iframe, reload, width, height }) => (
                        <>
                          <Box
                            height={height}
                            style={{ overflowY: "auto" }}
                            width={width + 15}
                          >
                            {iframe}
                          </Box>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="center"
                            mt={3}
                            width={width + 15}
                          >
                            <Box mb={2} mr={2}>
                              <OutlinedButton
                                endIcon={<EditIcon />}
                                size="small"
                                onClick={handleNudgeEdit(selected)}
                              >
                                Edit
                              </OutlinedButton>
                            </Box>
                            {/* <Box mb={1} mr={1}>
                              <OutlinedButton
                                endIcon={<GetAppIcon />}
                                size="small"
                                onClick={openExportImage}
                              >
                                Export
                              </OutlinedButton>
                            </Box> */}

                            <Box>
                              <OutlinedButton
                                endIcon={<AutorenewIcon />}
                                size="small"
                                onClick={reload}
                              >
                                Reset
                              </OutlinedButton>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Preview>
                  </Box>
                )}
              </Grid>
            </Grid>
          </div>
        </Sticky>
      </Grid>
    </Grid>
  );
};

export default Contextual;
