import React from "react";
import styled, { css } from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  ${({ inline }) =>
    inline
      ? css`
          height: 100%;
          width: 100%;
        `
      : ""}
`;

const Loader = (props) => (
  <LoaderContainer {...props}>
    <CircularProgress />
  </LoaderContainer>
);

export default Loader;
