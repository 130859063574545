import format from "date-fns/format";

const interactionPeriodOptions = {
  ANYTIME: { isDefault: true, label: "anytime" },
  SPECIFIC_INTERVAL: { label: "Specific interval" },
  USER_PREFERRED: { label: "User preferred" },
};

const getScheduledSlotSpecificIntervalString = ({
  datePeriod,
  specificInterval,
}) =>
  datePeriod === "SPECIFIC_INTERVAL" && specificInterval
    ? `${("0" + specificInterval?.from?.hours).slice(-2)}:${(
        "0" + specificInterval?.from?.minutes
      ).slice(-2)}-${("0" + specificInterval?.to?.hours).slice(-2)}:${(
        "0" + specificInterval?.to?.minutes
      ).slice(-2)}`
    : interactionPeriodOptions[datePeriod].label;
const getScheduledSlotDatetimeIntervalString = ({
  datetimeEnd,
  datetimeStart,
}) =>
  `${format(new Date(datetimeStart), "dd MMMM yyyy HH:mm")} - ${format(
    new Date(datetimeEnd),
    "dd MMMM yyyy HH:mm"
  )}`;

const getScheduledSlotName = ({
  datePeriod,
  datetimeEnd,
  datetimeStart,
  specificInterval,
}) =>
  `${getScheduledSlotDatetimeIntervalString({
    datetimeEnd,
    datetimeStart,
  })}, at ${getScheduledSlotSpecificIntervalString({
    datePeriod,
    specificInterval,
  })}`;
export {
  getScheduledSlotName,
  getScheduledSlotDatetimeIntervalString,
  getScheduledSlotSpecificIntervalString,
  interactionPeriodOptions,
};
