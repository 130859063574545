import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const TemplateFamilySelect = (props) => (
  <TextField
    fullWidth
    select
    InputLabelProps={{ shrink: true }}
    label={props.label || "Template family select"}
    variant="outlined"
    {...props}
  >
    {props.templatefamilies.map((obj) => {
      return (
        <MenuItem key={obj.tFamilyId} value={obj.tFamilyId}>
          {obj.tFamilyName}
        </MenuItem>
      );
    })}
  </TextField>
);

export default TemplateFamilySelect;
