import React, { memo, useCallback, useRef, useState } from "react";
import bytes from "bytes";

const FileUpload = ({
  children,
  onChange,
  disabled,
  accept,
  maxSize,
  onError,
  multiple = false,
  readAs = "ArrayBuffer",
}) => {
  const [value, setValue] = useState();
  const [error, setError] = useState();
  const inputRef = useRef(null);
  const upload = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRef?.current?.click();
  }, [inputRef]);

  const handleUpload = useCallback(
    (files) => {
      const result = [];

      for (const file of files) {
        if (Number.isFinite(maxSize) && file.size > maxSize) {
          const error = `Your file (${bytes(
            file.size
          )}) exceeds the maximum size limit (${bytes(maxSize, {
            unit: "MB",
          })}). Please upload a lighter file.`;
          setError(error);

          if (onError) {
            onError(error);
          }
          return;
        }

        // example: .png, .jpg, .jpeg
        const extension = file.name.split(".").pop().toLowerCase();
        if (
          !accept
            .replace(/\./g, "") // remove .
            .split(",")
            .map((s) => s.trim().toLowerCase())
            .includes(extension)
        ) {
          const error = `The selected file type is not of the right kind (${accept}). Please select the proper files.`;
          setError(error);

          if (onError) {
            onError(error);
          }
          return;
        }

        setError(null);

        const reader = new FileReader();
        reader.onload = async () => {
          if (
            onChange &&
            ((readAs === "ArrayBuffer" &&
              typeof reader.result === "object" &&
              reader.result.constructor.name === "ArrayBuffer") ||
              (readAs === "DataURL" && typeof reader.result === "string"))
          ) {
            let type = file.type;

            result.push({
              body: reader.result,
              name: file.name,
              size: file.size,
              type,
            });

            if (result.length === files.length) {
              onChange(multiple ? result : result[0]);
              setValue(multiple ? result : result[0]);
              inputRef.current.value = "";
            }
          }
        };

        if (readAs === "DataURL") {
          reader.readAsDataURL(file);
          return;
        }

        reader.readAsArrayBuffer(file);
      }
    },
    [maxSize, multiple, onChange, onError, readAs]
  );

  const handleChange = useCallback(
    (e) => {
      setValue(null);
      const files = e.target.files;
      handleUpload(files);
    },
    [handleUpload]
  );

  return (
    <>
      <input
        ref={inputRef}
        hidden
        accept={accept}
        disabled={disabled}
        multiple={multiple}
        type="file"
        onChange={handleChange}
      />
      {children({ error, onUpload: handleUpload, upload, value })}
    </>
  );
};

export default memo(FileUpload);
