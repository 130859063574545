import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import set from "lodash/set";
import times from "lodash/times";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import sumBy from "lodash/sumBy";
import compose from "lodash/fp/compose";
import fpOrderBy from "lodash/fp/orderBy";
import fpFilter from "lodash/fp/filter";
import fpUniqBy from "lodash/fp/uniqBy";
import cloneDeep from "lodash/cloneDeep";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";

import deepPurple from "@material-ui/core/colors/deepPurple";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import Loader from "../shared/Loader";
import OutlinedButton from "../OutlinedButton";
import TitleTypography from "../TitleTypography";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";
import ConfirmableInput from "../shared/ConfirmableInput";
import Sticky from "react-sticky-el";
import getData from "../../lib/getData";
import { useHistory } from "react-router-dom";
import FlowPreview from "../FlowPreview";
import { useTheme } from "@material-ui/core";
import AsyncButton from "../shared/AsyncButton";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const DragHandleContainer = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
`;

const StructureAndInteractions = ({
  journeyId,
  locale,
  steps,
  days,
  tiers,
  isDisabled,
}) => {
  const {
    fetchSemantics,
    fetchFlowRelations,
    semantics,
    isSemanticsLoading,
    isMaterialsLoading,
    fetchMaterials,
    materials,
    updateJourney,
    deleteJourneyStep,
    user,
    isTriggerConfigsLoading,
    isTriggersLoading,
    fetchTriggerConfigs,
    fetchTriggers,
    triggers,
    triggerConfigs,
    createFlowRelation,
    deleteFlowRelation,
    flowRelations,
    isFlowRelationsLoading,
    exportSemantics,
  } = useAppContext();

  useEffect(() => {
    fetchSemantics({ journeyId, locale });
    fetchFlowRelations({ journeyId, locale });
    fetchMaterials({ journeyId, locale });
    fetchTriggerConfigs({ journeyId });
    fetchTriggers({ journeyId });
  }, [journeyId, locale]);

  const modals = useModals();

  const handleExport = useCallback(
    () => exportSemantics({ journeyId, locale }),
    [exportSemantics, journeyId, locale]
  );

  const handleCreateStep = useCallback(() => {
    modals.openModal(modals.modalTypes.EditStep, {
      onConfirm: (value) =>
        updateJourney({
          days: [
            ...(days || []),
            ...times(value.days, (i) => ({
              day: (days?.length ?? 0) + i + 1,
              id: `day-${uuidv4()}`,
              name: `Untitled day`,
            })),
          ],
          id: journeyId,
          steps: [
            ...steps,
            {
              days: value.days,
              description: value.description,
              id: `step-${uuidv4()}`,
              isPublished: value.isPublished,
              name: value.name,
            },
          ],
        }),
      step: {
        days: 5,
        description: "",
        isNew: true,
        isPublished: steps.slice(-1)[0].isPublished,
        name: "",
      },
      isNew: true,
    });
  }, [days, journeyId, modals, steps, updateJourney]);

  const { enqueueSnackbar } = useSnackbar();

  const handleOnDragEnd = useCallback(
    async (result) => {
      if (!result.destination) {
        return;
      }

      const from = result.source.index;
      let to = result.destination.index;

      if (result.type === "STEP") {
        if (from === to) {
          return;
        }

        if (steps[from].isPublished !== steps[to].isPublished) {
          enqueueSnackbar(
            `Can't drag and drop ${
              steps[from].isPublished ? "published" : "unpublished"
            } step ${from < to ? "after" : "before"} ${
              steps[to].isPublished ? "published" : "unpublished"
            } step`,
            { variant: "error" }
          );
          return;
        }

        let newSteps = [...steps];
        newSteps.splice(to, 0, newSteps.splice(from, 1)[0]);

        let newDays = [...days];
        newDays.splice(
          sumBy(steps.slice(0, to), "days"),
          0,
          ...newDays.splice(
            sumBy(steps.slice(0, from), "days"),
            steps[from].days
          )
        );

        newDays = newDays.map((day, i) => ({
          ...day,
          day: i + 1,
        }));

        return updateJourney({
          days: newDays,
          id: journeyId,
          steps: newSteps,
        });
      }

      if (result.type === "DAY") {
        const stepRegexp = /^step-(\d+)/;
        const [, sourceStepIndex] = result.source.droppableId.match(stepRegexp);
        const [, destinationStepIndex] =
          result.destination.droppableId.match(stepRegexp);

        const newSteps = [...steps];

        if (sourceStepIndex !== destinationStepIndex) {
          if (+destinationStepIndex > 0 && to === 0) {
            // currently 'to' is 0 when you DragEnd towards an empty destinationStep
            to = steps
              .slice(0, +destinationStepIndex + 1)
              .reduce((total, step) => total + step.days, 0);
          }
          newSteps[+sourceStepIndex].days -= 1;
          newSteps[+destinationStepIndex].days += 1;
          if (+destinationStepIndex > +sourceStepIndex && to > 0) {
            // this is to fix a weird behaviour
            to--;
          }
        }

        let newDays = [...days];
        newDays.splice(to, 0, newDays.splice(from, 1)[0]);

        newDays = newDays.map((day, i) => ({
          ...day,
          day: i + 1,
        }));

        return updateJourney({
          days: newDays,
          id: journeyId,
          steps: newSteps,
        });
      }
    },
    [steps, days, updateJourney, journeyId, enqueueSnackbar]
  );

  const [activeSteps, setActiveSteps] = useLocalStorage(
    `${user.sub}.Journeys.${journeyId}-${locale}.Structure.ActiveSteps`,
    {}
  );

  const handleStepClick = useCallback(
    (step) => () => {
      setActiveSteps((activeSteps) => ({
        ...activeSteps,
        [step.id]: !activeSteps[step.id],
      }));
    },
    [setActiveSteps]
  );

  const handleEditClick = useCallback(
    (step) => (e) => {
      e.stopPropagation();

      if (!isFinite(step) || !steps[step]) {
        return;
      }
      modals.openModal(modals.modalTypes.EditStep, {
        onConfirm: (value) =>
          updateJourney({
            id: journeyId,
            steps: set([...steps], step, value),
          }),
        step: steps[step],
        stepIndex: step,
      });
    },
    [journeyId, modals, steps, updateJourney]
  );

  const handleDeleteStep = useCallback(
    (stepId) => (e) => {
      e.stopPropagation();

      const semanticsWithCTAToStep = semantics.filter(
        ({ cta1Type, cta2Type, cta1LinkId, cta2LinkId }) =>
          (cta1Type === "Dashboard Step" && cta1LinkId === stepId) ||
          (cta2Type === "Dashboard Step" && cta2LinkId === stepId)
      );

      if (semanticsWithCTAToStep.length > 0) {
        modals.openModal(modals.modalTypes.SemanticsWithEmptyCTALink, {
          items: semanticsWithCTAToStep,
          title:
            "Can't delete this step. It is linked to CTA actions in the following semantics",
          locale,
          journeyId,
          color: "error",
        });
        return;
      }
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: "Delete",
        onConfirm: async () => {
          await deleteJourneyStep({ journeyId, stepId });

          if (tiers.some(({ from, to }) => from >= (to || days.length - 5))) {
            enqueueSnackbar(
              "Now the tiers of rules for Journey are corrupted. Please fix them.",
              {
                variant: "error",
              }
            );
          }

          const corruptedStimulusIds = triggerConfigs
            .filter(({ tiers }) =>
              tiers.some(({ from, to }) => from >= (to || days.length - 5))
            )
            .map(({ stimulusId }) => stimulusId);

          const corruptedTriggers = triggers.filter(({ stimulusId }) =>
            corruptedStimulusIds.includes(stimulusId)
          );

          if (corruptedTriggers.length) {
            enqueueSnackbar(
              `Now the tiers of rules for nudges (${corruptedTriggers
                .map(({ name }) => name)
                .join(", ")}) are corrupted. Please fix them.`,
              {
                variant: "error",
              }
            );
          }
        },
        text: `You're about to permanently delete the "${
          steps.find((step) => stepId === step.id).name
        }" and Learning Materials associated with this step.`,
        title: "Delete the step?",
      });
    },
    [
      days.length,
      deleteJourneyStep,
      enqueueSnackbar,
      journeyId,
      locale,
      modals,
      semantics,
      steps,
      tiers,
      triggerConfigs,
      triggers,
    ]
  );

  const handlePublishStep = useCallback(
    (step, value) => (e) => {
      e.stopPropagation();

      if (!value) {
        const semanticsWithCTAToStep = semantics.filter(
          ({ cta1Type, cta2Type, cta1LinkId, cta2LinkId }) =>
            (cta1Type === "Dashboard Step" && cta1LinkId === steps[step].id) ||
            (cta2Type === "Dashboard Step" && cta2LinkId === steps[step].id)
        );

        if (semanticsWithCTAToStep.length > 0) {
          modals.openModal(modals.modalTypes.SemanticsWithEmptyCTALink, {
            items: semanticsWithCTAToStep,
            title:
              "Can't unpublish this step. It is linked to CTA actions in the following semantics",
            locale,
            journeyId,
            color: "error",
          });
          return;
        }
      }

      modals.openConfirmation({
        confirmCTATitle: steps[step].isPublished ? "unpublish" : "publish",
        text: `You want to ${
          steps[step].isPublished ? "unpublish" : "publish"
        } step ${step + 1}. All step's materials and tips will be ${
          steps[step].isPublished ? "hidden" : "visible"
        } for users`,
        onConfirm: () =>
          updateJourney({
            id: journeyId,
            steps: set([...steps], `${step}.isPublished`, value),
          }),
      });
    },
    [journeyId, locale, modals, semantics, steps, updateJourney]
  );

  const materialsByStepId = useMemo(
    () => groupBy(materials, "stepId"),
    [materials]
  );

  const {
    primes,
    primesByDays,
    flowRelationsByFlowId,
    flowRelationsByRelationId,
    primesBySteps,
  } = useMemo(
    () => getData({ semantics, days, flowRelations, steps }),
    [days, flowRelations, semantics]
  );

  const daysByNumber = useMemo(() => keyBy(days, "day"), [days]);

  const resetEditWrapper = useCallback(
    (action) =>
      (...args) => {
        return action(...args);
      },
    []
  );

  const [rename, setRename] = useState({});

  const handleRename = useCallback(
    (day) => () => {
      if (isDisabled) {
        return;
      }
      setImmediate(() =>
        setRename((rename) => ({
          ...rename,
          [day]: true,
        }))
      );
    },
    [isDisabled]
  );

  const handleRenameSave = useCallback(
    (day) => async (name) => {
      const newDays = [...days];
      const index = newDays.findIndex((i) => i.day === day);
      newDays.splice(index, 1, {
        ...newDays[index],
        name,
      });
      await updateJourney({
        days: newDays,
        id: journeyId,
      });
      setRename((rename) => {
        const newRename = { ...rename };
        delete newRename[day];
        return newRename;
      });
    },
    [days, journeyId, updateJourney]
  );

  const handleRenameCancel = useCallback(
    (day) => () => {
      setRename((rename) => {
        const newRename = { ...rename };
        delete newRename[day];
        return newRename;
      });
    },
    []
  );

  const [selectedDay, setSelectedDay] = useState(null);

  const handleListItemClick = useCallback(
    (day) => () => {
      setSelectedDay(day);
    },
    [setSelectedDay]
  );

  const handleAddDay = useCallback(
    (stepNumber) => async () => {
      const newSteps = [...steps];
      newSteps[stepNumber].days = newSteps[stepNumber].days + 1;

      let newDays = [...days];
      const daysBefore = sumBy(steps.slice(0, stepNumber + 1), "days");
      newDays.splice(daysBefore - 1, 0, {
        id: `day-${uuidv4()}`,
        name: `Untitled day`,
      });

      newDays = newDays.map((day, i) => ({
        ...day,
        day: i + 1,
      }));

      await updateJourney({
        days: newDays,
        id: journeyId,
        steps: newSteps,
      });
    },
    [days, journeyId, steps, updateJourney]
  );

  const handleDeleteDay = useCallback(
    (stepNumber, day) => () =>
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: "Delete",
        onConfirm: async () => {
          let newDays = cloneDeep(days);
          newDays.splice(day - 1, 1);
          newDays = newDays.map((day, i) => ({
            ...day,
            day: i + 1,
          }));

          const newSteps = cloneDeep(steps);
          newSteps[stepNumber].days -= 1;

          if (flowRelationsByRelationId[days[day - 1].id]?.length) {
            await Promise.all(
              flowRelationsByRelationId[days[day - 1].id].map(
                async ({ flowId, relationId }) =>
                  deleteFlowRelation({
                    flowId,
                    relationId,
                    journeyId,
                  })
              )
            );
          }

          await updateJourney({
            days: newDays,
            id: journeyId,
            steps: newSteps,
          });
        },
        text: `You're about to permanently delete this day ${day} day "${daysByNumber[day].name}". Its associated message will not be deleted, but will become associated to no day.`,
        title: "Delete the day?",
      }),
    [
      days,
      daysByNumber,
      deleteFlowRelation,
      flowRelationsByRelationId,
      journeyId,
      modals,
      steps,
      updateJourney,
    ]
  );

  const handleDisassociateTip = useCallback(
    (day) => () =>
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: "Disassociate",
        onConfirm: async () =>
          deleteFlowRelation({
            flowId: primesByDays[day][0].flowId,
            relationId: daysByNumber[day].id,
            journeyId,
          }),
        text: `You're about to disassociate the tip from this day ${day} day "${daysByNumber[day].name}". This day will be without any planned tip.`,
        title: "Disassociate this tip?",
      }),
    [daysByNumber, deleteFlowRelation, journeyId, modals, primesByDays]
  );

  const handleAssociateTip = useCallback(
    (day) => () =>
      modals.openModal(modals.modalTypes.AssociateTip, {
        day,
        name: daysByNumber[day].name,
        onConfirm: async (flowId) =>
          createFlowRelation({
            flowId,
            relationId: daysByNumber[day].id,
            type: "day",
            journeyId,
          }),
        tips: compose(
          fpFilter(({ flowId }) => !flowRelationsByFlowId[flowId]),
          fpUniqBy("flowId"),
          fpOrderBy(["isFirstInFlow"], ["asc"])
        )(primes),
      }),
    [
      createFlowRelation,
      daysByNumber,
      flowRelationsByFlowId,
      journeyId,
      modals,
      primes,
    ]
  );

  const history = useHistory();
  const openEdit = useCallback(
    ({ journeyId, locale, interaction }) => {
      history.push(
        `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${
          interaction.id
        }`
      );
    },
    [history]
  );

  const theme = useTheme();

  const handleCopyStepLink = useCallback(
    (step) => (e) => {
      e.stopPropagation();
      const qs = new URLSearchParams();
      qs.append("step", btoa(step.id));
      const link = `https://deeplink.${
        process.env[
          `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
        ]
      }/dashboard?${qs.toString()}`;

      navigator.clipboard.writeText(link);
      enqueueSnackbar("Copied", { variant: "success" });
    },
    [enqueueSnackbar]
  );

  if (
    isSemanticsLoading ||
    isMaterialsLoading ||
    isTriggersLoading ||
    isTriggerConfigsLoading ||
    isFlowRelationsLoading
  ) {
    return <Loader inline />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="flex-end" spacing={4}>
          <Grid item>
            <AsyncButton
              color="primary"
              variant="contained"
              onClick={handleExport}
              {...{ "hi-web-id": "semantics-export-button" }}
            >
              Export all tips and nudges
            </AsyncButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TitleTypography variant="h6">Structure</TitleTypography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              Steps are used to structure Tips and Learning Materials. Use this
              section to create, rename, delete, sort steps. Remember that Tips
              and Learning Materials depend on steps: if a step is deleted, they
              are deleted too; if a step is re-sorted, they are re-sorted too.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          disabled={isDisabled}
          endIcon={<AddIcon />}
          variant="contained"
          onClick={handleCreateStep}
        >
          Add new step
        </Button>
      </Grid>
      <Grid item lg={6} xs={12}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="steps" type="STEP">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {steps.map((step, i) => {
                  const primes = primesBySteps[i];

                  const primesByFlowId = groupBy(primes, "flowId");

                  const totalPrimes = Object.keys(primesByFlowId).length;
                  const totalFlowsPrimes = Object.values(primesByFlowId).filter(
                    (primes) => primes.length > 1
                  ).length;
                  const totalSinglePrimes = Object.values(
                    primesByFlowId
                  ).filter((primes) => primes.length === 1).length;

                  return (
                    <Draggable key={i} draggableId={`step-${i}`} index={i}>
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <Accordion
                            key={step}
                            TransitionProps={{ unmountOnExit: false }}
                            expanded={Boolean(activeSteps[step.id])}
                            style={{
                              ...(!step.isPublished && {
                                backgroundColor: deepPurple[50],
                              }),
                            }}
                            onChange={handleStepClick(step)}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Grid
                                container
                                alignItems="center"
                                justifycontent="space-between"
                                spacing={1}
                                style={{ width: "100%", flexWrap: "nowrap" }}
                              >
                                <Grid item style={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    style={{ flexWrap: "nowrap" }}
                                  >
                                    <Grid item>
                                      <div
                                        {...(!isDisabled &&
                                          provided.dragHandleProps)}
                                      >
                                        <DragHandleContainer
                                          isDisabled={isDisabled}
                                        >
                                          <DragHandleIcon
                                            color={
                                              isDisabled ? "disabled" : "action"
                                            }
                                          />
                                        </DragHandleContainer>
                                        {provided.placeholder}
                                      </div>
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      direction="column"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        <Typography variant="h6">
                                          {step.name}
                                        </Typography>
                                      </Grid>
                                      {step.description && (
                                        <Grid item>
                                          <Typography color="textSecondary">
                                            {step.description}
                                          </Typography>
                                        </Grid>
                                      )}
                                      <Grid item>
                                        <Typography
                                          color="textSecondary"
                                          variant="caption"
                                        >
                                          {step.days} Days, {totalPrimes} Tips (
                                          {totalSinglePrimes} single,{" "}
                                          {totalFlowsPrimes} flows),{" "}
                                          {materialsByStepId[step.id]?.length ||
                                            0}{" "}
                                          Learning Materials
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                    style={{ flexWrap: "nowrap" }}
                                  >
                                    <Grid item>
                                      {step.isPublished &&
                                        (i === steps.length - 1 ||
                                          !steps[i + 1].isPublished) && (
                                          <OutlinedButton
                                            disabled={isDisabled}
                                            endIcon={<VisibilityOffIcon />}
                                            size="small"
                                            onClick={handlePublishStep(
                                              i,
                                              false
                                            )}
                                          >
                                            Unpublish
                                          </OutlinedButton>
                                        )}

                                      {!step.isPublished &&
                                        (i === 0 ||
                                          steps[i - 1].isPublished) && (
                                          <OutlinedButton
                                            disabled={isDisabled}
                                            endIcon={<VisibilityIcon />}
                                            size="small"
                                            onClick={handlePublishStep(i, true)}
                                          >
                                            Publish
                                          </OutlinedButton>
                                        )}
                                    </Grid>
                                    <Grid item>
                                      <OutlinedButton
                                        disabled={isDisabled}
                                        size="small"
                                        onClick={handleEditClick(i)}
                                      >
                                        Edit
                                      </OutlinedButton>
                                    </Grid>
                                    <Grid item>
                                      <OutlinedButton
                                        size="small"
                                        onClick={handleCopyStepLink(step)}
                                      >
                                        Copy link
                                      </OutlinedButton>
                                    </Grid>
                                    <Grid item>
                                      <Tooltip title="Delete step">
                                        <span>
                                          <IconButton
                                            disabled={
                                              isDisabled || steps.length <= 1
                                            }
                                            onClick={handleDeleteStep(step.id)}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Divider />
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box pl={5} width="100%">
                                <Paper variant="outlined">
                                  <Box p={2}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Typography
                                          color="textSecondary"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Days Details
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Droppable
                                          droppableId={`step-${i}`}
                                          isDropDisabled={!activeSteps[step.id]} // disable drop if accordion closed
                                          type="DAY"
                                        >
                                          {(provided) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                            >
                                              <List dense>
                                                {times(
                                                  step.days,
                                                  (dayIndex) => {
                                                    const daysBefore = sumBy(
                                                      steps.slice(0, i),
                                                      "days"
                                                    );
                                                    const day =
                                                      daysBefore + dayIndex + 1;

                                                    return (
                                                      <Draggable
                                                        key={day - 1}
                                                        draggableId={`step-${i}-day-${
                                                          day - 1
                                                        }`}
                                                        index={day - 1}
                                                      >
                                                        {(provided) => (
                                                          <div
                                                            {...provided.draggableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                          >
                                                            <ListItem
                                                              button
                                                              selected={
                                                                day ===
                                                                selectedDay
                                                              }
                                                              onClick={handleListItemClick(
                                                                day
                                                              )}
                                                            >
                                                              <ListItemAvatar>
                                                                <div
                                                                  {...(!isDisabled &&
                                                                    provided.dragHandleProps)}
                                                                >
                                                                  <DragHandleContainer
                                                                    isDisabled={
                                                                      isDisabled
                                                                    }
                                                                  >
                                                                    <DragHandleIcon
                                                                      color={
                                                                        isDisabled
                                                                          ? "disabled"
                                                                          : "action"
                                                                      }
                                                                    />
                                                                  </DragHandleContainer>
                                                                </div>
                                                              </ListItemAvatar>
                                                              <ListItemText
                                                                primary={
                                                                  rename[
                                                                    day
                                                                  ] ? (
                                                                    <Box mr={8}>
                                                                      <ConfirmableInput
                                                                        autoFocus
                                                                        value={
                                                                          daysByNumber[
                                                                            day
                                                                          ]
                                                                            ?.name ||
                                                                          ""
                                                                        }
                                                                        variant="standard"
                                                                        onCancel={resetEditWrapper(
                                                                          handleRenameCancel(
                                                                            day
                                                                          )
                                                                        )}
                                                                        onChange={handleRenameSave(
                                                                          day
                                                                        )}
                                                                      />
                                                                    </Box>
                                                                  ) : (
                                                                    <span
                                                                      onClick={handleRename(
                                                                        day
                                                                      )}
                                                                    >
                                                                      {daysByNumber[
                                                                        day
                                                                      ]?.name ||
                                                                        "Unnamed Day"}
                                                                    </span>
                                                                  )
                                                                }
                                                                secondary={`${day} Day - ${
                                                                  primesByDays[
                                                                    day
                                                                  ]?.length ?? 0
                                                                } Tips in flow`}
                                                              />
                                                              <ListItemSecondaryAction>
                                                                <Tooltip title="Delete day">
                                                                  <IconButton
                                                                    disabled={
                                                                      isDisabled
                                                                    }
                                                                    onClick={handleDeleteDay(
                                                                      i,
                                                                      day
                                                                    )}
                                                                  >
                                                                    <DeleteIcon />
                                                                  </IconButton>
                                                                </Tooltip>
                                                                {primesByDays[
                                                                  day
                                                                ]?.length ? (
                                                                  <Tooltip title="Disassociate tip">
                                                                    <IconButton
                                                                      disabled={
                                                                        isDisabled
                                                                      }
                                                                      onClick={handleDisassociateTip(
                                                                        day
                                                                      )}
                                                                    >
                                                                      <LinkOffIcon />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                ) : (
                                                                  <Tooltip title="Associate tip">
                                                                    <IconButton
                                                                      disabled={
                                                                        isDisabled
                                                                      }
                                                                      onClick={handleAssociateTip(
                                                                        day
                                                                      )}
                                                                    >
                                                                      <LinkIcon />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                )}
                                                              </ListItemSecondaryAction>
                                                            </ListItem>
                                                            <Divider />
                                                            {
                                                              provided.placeholder
                                                            }
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    );
                                                  }
                                                )}
                                                {provided.placeholder}
                                              </List>
                                            </div>
                                          )}
                                        </Droppable>
                                        <Box pt={2}>
                                          <Button
                                            color="primary"
                                            disabled={isDisabled}
                                            endIcon={<AddIcon />}
                                            variant="contained"
                                            onClick={handleAddDay(i)}
                                          >
                                            Add Day
                                          </Button>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Paper>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                          {i !== steps.length - 1 && (
                            <Box pb={2} pt={2}>
                              <Divider />
                            </Box>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item lg={2} xs={12} />
      <Grid item lg={4} xs={12}>
        <Sticky
          scrollElement=".scrollarea"
          stickyStyle={{ top: theme.spacing(4) + 92 }}
        >
          <FlowPreview
            interactions={primesByDays[selectedDay] ?? []}
            journeyId={journeyId}
            locale={locale}
            name="day"
            onEdit={(interaction) =>
              openEdit({ journeyId, locale, interaction })
            }
          />
        </Sticky>
      </Grid>
    </Grid>
  );
};

export default StructureAndInteractions;
