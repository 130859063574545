import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import useModal from "../hooks/useModal";
import { CircularProgress } from "@material-ui/core";

const Confirm = ({
  onClose,
  onConfirm,
  title,
  text,
  confirmCTATitle,
  closeCTATitle,
  reverted = true,
}) => {
  const { isLoading, handleConfirm } = useModal({ onClose, onConfirm });
  return (
    <Dialog fullWidth open maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {title}
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {reverted ? (
          <>
            <Button
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={25} />}
              variant="contained"
              onClick={handleConfirm}
            >
              {confirmCTATitle}
            </Button>
            <OutlinedButton disabled={isLoading} onClick={onClose}>
              {closeCTATitle}
            </OutlinedButton>
          </>
        ) : (
          <>
            <Button
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={25} />}
              variant="contained"
              onClick={handleConfirm}
            >
              {confirmCTATitle}
            </Button>
            <OutlinedButton disabled={isLoading} onClick={onClose}>
              {closeCTATitle}
            </OutlinedButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
