const contentTypes = {
  FeaturePromotion: "feature_promotion",
  Onboarding: "onboarding",
  StepGuide: "step_guide",
};

const contentTypesConfig = {
  [contentTypes.Onboarding]: {
    cta1Types: ["Semantic"],
    cta2Types: ["Semantic"],
    ctaTypes: ["CTA"],
    defaultInteractionCount: 3,
    uiConfig: {
      anchor: "screen_center",
    },
  },
  [contentTypes.FeaturePromotion]: {
    cta1Types: ["Dashboard Material", "External Link", "Close"],
    cta2Types: ["Dashboard Material", "External Link", "Close"],
    ctaTypes: ["CTA", "DOUBLE_CTA"],
    defaultInteractionCount: 1,
    uiConfig: {
      anchor: "bottom-right",
    },
  },
  [contentTypes.StepGuide]: {
    cta1Types: ["Semantic"],
    cta2Types: ["Semantic"],
    ctaTypes: ["CTA"],
    defaultInteractionCount: 3,
    uiConfig: {
      anchor: "bottom-right",
    },
  },
};

export { contentTypes, contentTypesConfig };
