import { useParams } from "react-router-dom";

import SpecialLinks from "./SpecialLinks";
import UserMetadata from "./UserMetadata";
import Configuration from "./Configuration";
import Integrations from "./Integrations";
import CallDetection from "./CallDetection";
import CompanyUser from "./CompanyUser";
import TipGroups from "./TipGroups";

const Settings = () => {
  const { tab } = useParams();

  if (!tab || tab === "special-links") {
    return <SpecialLinks />;
  }
  if (tab === "user-metadata") {
    return <UserMetadata />;
  }

  if (tab === "cid-configurations") {
    return <Configuration />;
  }

  if (tab === "integrations") {
    return <Integrations />;
  }

  if (tab === "calldetection-configuration") {
    return <CallDetection />;
  }

  if (tab === "companyuser-configuration") {
    return <CompanyUser />;
  }
  if (tab === "tip-groups-for-analytics") {
    return <TipGroups />;
  }

  return null;
};

export default Settings;
