import React from "react";
import { useFormikContext } from "formik";
import { Checkbox, FormControlLabel } from "@material-ui/core/";

const Emoji = () => {
  const formik = useFormikContext();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={formik.values.useEmojis}
          color="primary"
          onChange={(event) =>
            formik.setFieldValue("useEmojis", event.target.checked)
          }
        />
      }
      label="Should we use emojis?"
      style={{ margin: 0 }}
    />
  );
};

export default Emoji;
