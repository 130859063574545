import { useCallback, useState } from "react";

const useModal = ({ onClose, onConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = useCallback(
    async (...args) => {
      if (typeof onConfirm !== "function") {
        return;
      }

      setIsLoading(true);
      try {
        await onConfirm(...args);

        if (typeof onClose !== "function") {
          return;
        }
        onClose(...args);
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, onConfirm]
  );

  return {
    handleConfirm,
    isLoading,
  };
};

export default useModal;
