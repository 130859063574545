import React, { useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import Highlighter from "react-highlight-words";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import useModal from "../hooks/useModal";
import CircularProgress from "@material-ui/core/CircularProgress";

const filterOptions = createFilterOptions({
  stringify: (option) =>
    `${option.id} ${option.title} ${option.text} ${option.name} ${option.description}`,
});

const renderers = {
  paragraph: "span",
};

const AssociateTipModal = ({ day, name, onClose, onConfirm, tips } = {}) => {
  const [selectedFlowId, setSelectedFlowId] = useState();

  const handleChange = useCallback((e, value, reason) => {
    if (reason !== "select-option") {
      return;
    }

    setSelectedFlowId(value.flowId);
  }, []);

  const { isLoading, handleConfirm } = useModal({ onClose, onConfirm });

  const handleAssociateConfirm = useCallback(
    () => handleConfirm(selectedFlowId),
    [handleConfirm, selectedFlowId]
  );

  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Associate a tip
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Associate a tip for${day ? ` ${day} day` : ""} "${name}".`}
        </DialogContentText>
        <Autocomplete
          fullWidth
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.title}
          options={tips}
          renderInput={(params) => <TextField {...params} size="small" />}
          renderOption={(option, { inputValue }) => (
            <ListItemText
              primary={
                <Highlighter
                  searchWords={[inputValue || ""]}
                  textToHighlight={option.title}
                >
                  <ReactMarkdown renderers={renderers}>
                    {option.title}
                  </ReactMarkdown>
                </Highlighter>
              }
              secondary={
                <Highlighter
                  searchWords={[inputValue || ""]}
                  textToHighlight={option.text || ""}
                >
                  <ReactMarkdown renderers={renderers}>
                    {option.text}
                  </ReactMarkdown>
                </Highlighter>
              }
            />
          )}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isLoading || !selectedFlowId}
          endIcon={isLoading && <CircularProgress size={25} />}
          variant="contained"
          onClick={handleAssociateConfirm}
        >
          Associate
        </Button>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssociateTipModal;
