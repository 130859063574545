import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Highlighter from "react-highlight-words";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../api/AppContext";

const TypeformsSelect = ({
  integrationId,
  onChange,
  value,
  disabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { fetchTypeforms, isTypeformsLoading, typeforms } = useAppContext();

  const options = typeforms;

  useEffect(() => {
    if (!isOpen || options.length !== 0) {
      return;
    }

    fetchTypeforms({ integrationId });
  }, [integrationId, isOpen, options.length]);

  const handleChange = useCallback(
    (e, value, reason) => {
      setIsOpen(false);
      if (!["select-option"].includes(reason)) {
        return;
      }

      onChange?.(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableClearable
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => option.title}
      groupBy={(option) => option.workspaceName}
      loading={isTypeformsLoading}
      open={isOpen}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            startAdornment: isTypeformsLoading ? (
              <InputAdornment position="end">
                {isTypeformsLoading && <CircularProgress size={25} />}
              </InputAdornment>
            ) : (
              props.InputProps?.startAdornment ??
              params.InputProps?.startAdornment
            ),
          }}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.title}
            >
              {option.title}
            </Highlighter>
          }
        />
      )}
      value={options.find((option) => option.id === value) || null}
      onChange={handleChange}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    />
  );
};

export default TypeformsSelect;
