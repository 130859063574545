import MuiButton from "@material-ui/core/Button";

const { withStyles } = require("@material-ui/core");

const Button = withStyles({
  outlined: {
    borderColor: "#e0e0e0",
  },
})(MuiButton);

const OutlinedButton = (props) => (
  <Button {...props} color="primary" variant="outlined" />
);

export default OutlinedButton;
