import keyBy from "lodash/keyBy";
import compose from "lodash/fp/compose";
import groupBy from "lodash/groupBy";
import fpMapValues from "lodash/fp/mapValues";
import fpFilter from "lodash/fp/filter";
import fpGroupBy from "lodash/fp/groupBy";

const getData = ({ days, semantics, flowRelations, scheduledSlots, steps }) => {
  const daysById = keyBy(days, "id");
  const primes = semantics?.filter(({ type }) => type === "PRIME");
  const firstInFlowPrimes = primes?.filter(
    ({ isFirstInFlow }) => isFirstInFlow
  );
  const primesByFlowId = groupBy(primes, "flowId");
  const primesByDays = compose(
    fpGroupBy("day"),
    (o) => o.flat(2),
    (o) => Object.values(o),
    fpMapValues((relations) =>
      relations.map(({ flowId, relationId }) =>
        (primesByFlowId[flowId] || []).map((prime) => ({
          ...prime,
          day: daysById[relationId]?.day,
          isFirstInFlow: Boolean(prime.isFirstInFlow),
          dayId: relationId,
        }))
      )
    ),
    fpGroupBy("relationId"),
    fpFilter(["type", "day"])
  )(flowRelations);

  const primesBySteps = Object.fromEntries(
    (steps || []).reduce(
      (total, step, i) => {
        const primes = days
          .slice(total.day, total.day + step.days)
          .map(({ day }) => primesByDays[day] || [])
          .flat(2);

        return {
          day: total.day + step.days,
          result: [...total.result, [i, primes]],
        };
      },
      { day: 0, result: [] }
    ).result
  );

  const scheduledSlotsById = keyBy(scheduledSlots, "id");

  const primesByScheduledSlotId = compose(
    fpGroupBy("scheduledSlotId"),
    (o) => o.flat(2),
    (o) => Object.values(o),
    fpMapValues((relations) =>
      relations.map(({ flowId, relationId }) =>
        (primesByFlowId[flowId] || []).map((prime) => ({
          ...prime,
          scheduledSlotId: relationId,
          date: scheduledSlotsById[relationId]?.date,
          isFirstInFlow: Boolean(prime.isFirstInFlow),
        }))
      )
    ),
    fpGroupBy("relationId"),
    fpFilter(["type", "scheduledSlot"])
  )(flowRelations);

  const flowRelationsByFlowId = groupBy(flowRelations, "flowId");
  const flowRelationsByRelationId = groupBy(flowRelations, "relationId");

  return {
    primesBySteps,
    primesByDays,
    primes,
    primesByFlowId,
    firstInFlowPrimes,
    flowRelationsByFlowId,
    flowRelationsByRelationId,
    primesByScheduledSlotId,
    scheduledSlotsById,
  };
};

export default getData;
