import React, { useCallback, useMemo, useState } from "react";
import keyBy from "lodash/keyBy";
import { Link } from "react-router-dom";
import uniq from "lodash/uniq";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import ImageUpload from "../ImageUpload";
import InfoTooltip from "../InfoTooltip";
import LocaleSelect from "../shared/LocaleSelect";
import CallBehaviourSelect from "../shared/CallBehaviourSelect";
import TitleTypography from "../TitleTypography";
import { getFieldProps, inputProps, multilineInputProps } from "../utils";
import Timestamps from "../Timestamps";
import MessagesRules from "./MessagesRules";
import { useModals } from "../../hooks/useModals";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppContext } from "../../api/AppContext";
import SaveButtons from "../SaveButtons";

const SelectorAlignedTypography = withStyles({
  root: {
    lineHeight: "3",
  },
})(Typography);

const Subtitle = withStyles({
  root: {
    maxWidth: "722px",
  },
})(Typography);

const SecondsSelector = withStyles({
  root: {
    paddingRight: 8,
    width: 120,
  },
})(TextField);

const SecondsSelectorWithinTypography = withStyles({
  root: {
    paddingLeft: 8,
  },
})(SecondsSelector);

// const TimesSelector = withStyles({
//   root: {
//     paddingRight: 8,
//     width: 120,
//   },
// })(TextField);

const Stats = ({ stats, semanticsWithEmptyCTALink, journeyId, locale }) => {
  const modals = useModals();
  const { user } = useAppContext();

  const [isIncludeUnpublished, setIsIncludeUnpublished] = useLocalStorage(
    `${user.sub}.Journeys.${journeyId}-${locale}.GeneralInfo.IsIncludeUnpublished`,
    {}
  );

  const handleIsIncludeUnpublishedChange = useCallback(
    (e) => {
      setIsIncludeUnpublished(e.target.checked);
    },
    [setIsIncludeUnpublished]
  );

  const handleShowSemanticsWithEmptyCTALink = useCallback(
    (e) => {
      modals.openModal(modals.modalTypes.SemanticsWithEmptyCTALink, {
        items: semanticsWithEmptyCTALink,
        journeyId,
        locale,
      });
      e.preventDefault();
    },
    [journeyId, locale, modals, semanticsWithEmptyCTALink]
  );

  const filteredStats = useMemo(
    () => stats[isIncludeUnpublished ? "all" : "published"] ?? stats,
    [isIncludeUnpublished, stats]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isIncludeUnpublished}
              color="primary"
              onChange={handleIsIncludeUnpublishedChange}
            />
          }
          label="Include unpublished items"
        />
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalDays}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          Days
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalPrimes}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          ({filteredStats.totalSinglePrimes} single,{" "}
          {filteredStats.totalFlowsPrimes} flows,{" "}
          {filteredStats.totalUnassignedPrimes} unassigned) Tips
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalScheduledSlots}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          Scheduled Slots (
          {filteredStats.totalScheduledSlots -
            filteredStats.totalScheduledPrimes}{" "}
          with unassigned message)
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalNudges}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          Nudges
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalTriggers}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          Triggers in use
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalMaterials}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          Learning materials
        </Subtitle>
      </Grid>
      <Grid item lg={2} md={3} xs={6}>
        <TitleTypography textAlign="center" variant="h6">
          {filteredStats.totalExternalLinks}
        </TitleTypography>
        <Subtitle color="textSecondary" variant="body2">
          External links
        </Subtitle>
      </Grid>
      {Array.isArray(semanticsWithEmptyCTALink) && (
        <Grid item lg={2} md={3} xs={6}>
          <TitleTypography
            color={semanticsWithEmptyCTALink.length ? "error" : "textPrimary"}
            textAlign="center"
            variant="h6"
          >
            {semanticsWithEmptyCTALink.length}
          </TitleTypography>
          <Subtitle
            color={semanticsWithEmptyCTALink.length ? "error" : "textSecondary"}
            variant="body2"
          >
            Messages with missing CTA link
            {semanticsWithEmptyCTALink.length > 0 && (
              <>
                {" "}
                (
                <Link onClick={handleShowSemanticsWithEmptyCTALink}>
                  see them
                </Link>
                )
              </>
            )}
          </Subtitle>
        </Grid>
      )}
    </Grid>
  );
};

const GeneralInfo = ({
  formik,
  isDisabled,
  journeys,
  semanticsWithEmptyCTALink,
}) => {
  const { user } = useAppContext();
  const handleUpload = useCallback(
    (field) =>
      ({ url }) => {
        formik.setFieldValue(field, url);
      },
    [formik]
  );

  const [journeyIdToLink, setJourneyIdToLink] = useState(null);
  const handleJourneyLink = useCallback(() => {
    if (!journeyIdToLink) {
      return;
    }

    setJourneyIdToLink(null);

    formik.setFieldValue(
      `linkedJourneys.${
        formik.values.linkedJourneys ? formik.values.linkedJourneys.length : 0
      }`,
      {
        journeyAId: formik.values.id,
        journeyBId: journeyIdToLink,
      }
    );
  }, [formik, journeyIdToLink]);

  const handleLinkedJourneyDelete = useCallback(
    (id) => () => {
      formik.setFieldValue(
        "linkedJourneys",
        formik.values.linkedJourneys.filter(
          ({ journeyAId, journeyBId }) => ![journeyAId, journeyBId].includes(id)
        )
      );
    },
    [formik]
  );

  const handleJourneyIdToLinkChange = useCallback((e, journey) => {
    if (!journey?.value) {
      return;
    }

    setJourneyIdToLink(journey.value);
  }, []);

  const journeysById = useMemo(() => keyBy(journeys, "id"), [journeys]);
  const linkedJourneysLocales = useMemo(
    () =>
      uniq(
        (formik.values.linkedJourneys || [])
          .map(({ journeyAId, journeyBId }) => [
            journeysById[journeyAId]?.locale,
            journeysById[journeyBId]?.locale,
          ])
          .flat()
      ),
    [formik.values.linkedJourneys, journeysById]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Timestamps {...formik.values} journeys={journeys} />
          <Grid item xs={12}>
            <TitleTypography variant="h6">General information</TitleTypography>
          </Grid>
          <Grid item xs={12}>
            <Subtitle color="textSecondary" variant="body2">
              Upload an icon and image that will appear as the cover for your
              Journey inside user dashboard. Manage journeys name, language and
              category.
            </Subtitle>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>ㅤ</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Name"
              margin="none"
              variant="outlined"
              {...getFieldProps(formik, { disabled: isDisabled, name: "name" })}
            />
          </Grid>
          <Grid item xs={12}>
            <LocaleSelect
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "locale",
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Internal plan name"
              margin="none"
              placeholder="Internal name for the Journey"
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "internalName",
              })}
            />
            <FormHelperText>
              {`This field is for internal purposes only and won't be visible to
              users.`}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <List subheader={<ListSubheader>Linked journeys</ListSubheader>}>
              {(formik.values.linkedJourneys || []).map(
                ({ journeyAId, journeyBId }) => {
                  const journey =
                    journeysById[
                      journeyAId === formik.values.id ? journeyBId : journeyAId
                    ];
                  return (
                    <ListItem key={`${journeyAId}-${journeyBId}`} button>
                      <ListItemText
                        primary={
                          <Link
                            to={`/journeys/${journey.id}/${journey.locale}`}
                          >
                            {journey.internalName || journey.name}
                          </Link>
                        }
                        secondary={journey.locale}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={handleLinkedJourneyDelete(journey.id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item style={{ flex: 1 }}>
                <Autocomplete
                  fullWidth
                  getOptionLabel={({ name }) => name}
                  options={journeys
                    .filter(
                      ({ locale, createdAt }) =>
                        locale !== formik.values.locale &&
                        !linkedJourneysLocales.includes(locale) &&
                        createdAt
                    )
                    .map(({ name, internalName, id, locale }) => ({
                      locale,
                      name: internalName || name,
                      value: id,
                    }))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      label="Add new linked Journey"
                      margin="none"
                      placeholder="Choose from the list"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <span>{option.name}</span>
                      <Box ml={2}>
                        <Chip
                          color="primary"
                          label={option.locale}
                          size="small"
                        />
                      </Box>
                    </Box>
                  )}
                  value={
                    journeys.find(({ id }) => id === journeyIdToLink) || null
                  }
                  onChange={handleJourneyIdToLinkChange}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disabled={!journeyIdToLink}
                  onClick={handleJourneyLink}
                >
                  Link Journey
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography style={{ fontWeight: "bolder" }}>Image</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="Upload .jpg or .png files. The uploaded picture will be displayed with 960px width and 320px height.
                  In case of size mismatch, the picture will be scaled horizontally to 960px and cropped vertically (a central stripe of 320px height will be taken)"
                  title="Image Dimensions"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography style={{ fontWeight: "bolder" }}>Icon</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="Upload .jpg or .png files. For better result, the image should
                  have 32x32(px) dimensions."
                  title="Icon Dimensions"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <ImageUpload
              caption={
                <Typography
                  color="textSecondary"
                  style={{ color: "#BFBFBF" }}
                  variant="body2"
                >
                  Upload .jpg or .png files. The uploaded picture will be
                  displayed with 960px width and 320px height. In case of size
                  mismatch, the picture will be scaled horizontally to 960px and
                  cropped vertically (a central stripe of 320px height will be
                  taken).
                </Typography>
              }
              containerStyles={{ height: 150 }}
              customPathInfo={{ journeyId: formik.values.id }}
              disabled={isDisabled || formik.isSubmitting}
              errorMessage={getFieldProps(formik, { name: "image" }).helperText}
              styles={{ maxHeight: 150, width: "unset" }}
              value={formik.values.image}
              onChange={handleUpload("image")}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageUpload
              caption={
                <Typography
                  color="textSecondary"
                  style={{ color: "#BFBFBF" }}
                  variant="body2"
                >
                  Upload .jpg or .png files. For better result, the image should
                  have 32x32(px) dimensions.
                </Typography>
              }
              containerStyles={{ height: 150 }}
              customPathInfo={{ journeyId: formik.values.id }}
              disabled={isDisabled || formik.isSubmitting}
              errorMessage={getFieldProps(formik, { name: "icon" }).helperText}
              styles={{ maxHeight: 150, width: "unset" }}
              value={formik.values.icon}
              onChange={handleUpload("icon")}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TitleTypography variant="h6">Plan description</TitleTypography>
        {/* <Subtitle color="textSecondary" variant="body2">
          {`Describe your plan in a couple of sentences maximum. The description
          will appear in the user dashboard below the plan's title. What else to
          include? Focus on the objective part and keep it skills-based. Then,
          switch to the plan's duration and "who is suggested for" section.`}
        </Subtitle> */}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="About this Journey"
              margin="none"
              placeholder="What is your Journey about?"
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "description",
              })}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="What you will gain"
              margin="none"
              placeholder="What are the values that your Journey brings to the users?"
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "benefits",
              })}
            />
          </Grid> */}
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="Your commitment"
              margin="none"
              placeholder="How long is your Journey?"
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "durationInfo",
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="We suggest this Journey to"
              margin="none"
              placeholder="For whom is your Journey recommended?"
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "suggestions",
              })}
            />
          </Grid>
        </Grid>
      </Grid> */}
      {formik.values.stats && (
        <>
          <Grid item xs={12}>
            <TitleTypography variant="h6">Content overview</TitleTypography>
          </Grid>
          <Grid item xs={12}>
            <Stats
              journeyId={formik.values.id}
              locale={formik.values.locale}
              semanticsWithEmptyCTALink={semanticsWithEmptyCTALink}
              stats={formik.values.stats}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TitleTypography variant="h6">Configuration</TitleTypography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Messages global rules</Typography>
        <Typography variant="body2">
          The following rules are valid for this Journey
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <MessagesRules
          days={formik.values.days.length}
          expandedKey={`${user.sub}.Journeys.${formik.values.id}-${formik.values.locale}.GeneralInfo.Messages.ExpandedTiers`}
          formik={formik}
          globalCheckboxLabel="Extend to tips and scheduled tips"
          isDisabled={isDisabled}
          isGeneralInfoMessages={true}
          maxDailyOccurrencesLabel="Per day"
          maxMonthlyOccurrencesLabel="Per month"
          maxOccurrencesHelperText="If a user has already received the maximum allowed number of messages for day/week/month, that user cannot receive any more messages"
          maxWeeklyOccurrencesLabel="Per week"
          minOccurrencesIntervalHelperText="If a user received a message (either a minitip or nudge), that user cannot receive another message for ${value} hours"
          minOccurrencesIntervalLabel="hours"
          nudgeCheckboxLabel="In this tier, avoid sending nudges"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={getFieldProps(formik, { name: "isSoundEnabled" }).value}
              color="primary"
              disabled={formik.isSubmitting || isDisabled}
              name="isSoundEnabled"
              onChange={
                getFieldProps(formik, { name: "isSoundEnabled" }).onChange
              }
            />
          }
          label="Sound for tips and nudges"
        />
        <FormHelperText>Available since version 24.0.86.0</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={
                getFieldProps(formik, { name: "isAnimationEnabled" }).value
              }
              color="primary"
              disabled={formik.isSubmitting || isDisabled}
              name="isAnimationEnabled"
              onChange={
                getFieldProps(formik, { name: "isAnimationEnabled" }).onChange
              }
            />
          }
          label="Shaking of minitips/nudges"
        />
      </Grid>

      {/* Feature removed in task Simplify UI of tip creation in content portal (25-01-2024)
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              {...getFieldProps(formik, {
                defaultValue: false,
                name: "miniTipAutoProgress",
              })}
              checked={
                getFieldProps(formik, {
                  name: "miniTipAutoProgress",
                }).value
              }
            />
          }
          label={`After the card-preview is received, automatically open it into card after (in seconds): `}
        />
        <SecondsSelector
          InputProps={{
            inputProps: {
              min: 0,
              step: 1,
            },
          }}
          {...getFieldProps(formik, {
            disabled: !getFieldProps(formik, {
              name: "miniTipAutoProgress",
            }).value,
            name: "miniTipAutoProgressDelay",
          })}
          placeholder="number"
          type="number"
        ></SecondsSelector>
      </Grid> 

      {getFieldProps(formik, { name: "miniTipAutoProgress" }).value ===
        true && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                {...getFieldProps(formik, {
                  defaultValue: false,
                  name: "miniTipAutoProgressOnlyIfIgnored",
                })}
                checked={
                  getFieldProps(formik, {
                    name: "miniTipAutoProgressOnlyIfIgnored",
                  }).value
                }
              />
            }
            label={`Apply the logic above only if the user has already received, but not opened, that card-preview at least this many times: `}
          />
          <TimesSelector
            InputProps={{
              inputProps: {
                min: 1,
                step: 1,
              },
            }}
            {...getFieldProps(formik, {
              defaultValue: 1,
              disabled: !getFieldProps(formik, {
                name: "miniTipAutoProgressOnlyIfIgnored",
              }).value,
              name: "miniTipAutoProgressOnlyIfIgnoredTimes",
            })}
            placeholder="number"
            type="number"
          ></TimesSelector>
        </Grid>
      )}*/}

      {["Tips", "Nudges"].map((type) => (
        <>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <CallBehaviourSelect
                  required
                  label={
                    type === "Tips"
                      ? "If user is in a call, tips or scheduled tips:"
                      : "If user is in a call, nudges:"
                  }
                  {...getFieldProps(formik, {
                    defaultValue:
                      formik.values["callBehaviour" + type] || "sendAnyway",
                    disabled: isDisabled,
                    name: "callBehaviour" + type,
                  })}
                ></CallBehaviourSelect>
              </Grid>
              {getFieldProps(formik, { name: "callBehaviour" + type }).value ===
                "delay" && (
                <Grid item md={9} xs={12}>
                  <SelectorAlignedTypography>
                    Wait{"  "}
                    <SecondsSelectorWithinTypography
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 1,
                        },
                      }}
                      {...getFieldProps(formik, {
                        disabled:
                          !getFieldProps(formik, {
                            name: "callBehaviour" + type,
                          }).value === "delay",
                        name: "afterCallDelay" + type,
                      })}
                      placeholder="number"
                      type="number"
                    ></SecondsSelectorWithinTypography>{" "}
                    {type === "Tips"
                      ? "seconds after the call is finished before showing tips or scheduled tips."
                      : "seconds after the call is finished before showing nudges."}
                  </SelectorAlignedTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ))}

      {!isDisabled && (
        <Grid item xs={12}>
          <SaveButtons formik={formik} />
        </Grid>
      )}
    </Grid>
  );
};

export default GeneralInfo;
