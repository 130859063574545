import SvgIcon from "@material-ui/core/SvgIcon";

const SignOutIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M3 3h8V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8v-2H3z" />
    <path d="M19 10l-6-5v4H5v2h8v4l6-5z" />
  </SvgIcon>
);

export default SignOutIcon;
