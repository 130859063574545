import React from "react";
import { Grid, Typography } from "@material-ui/core";

const Start = ({ textPrimary, textSecondary, textInfo, textConditions }) => {
  return (
    <>
      <Grid item xs={12}>
        <Grid item>
          <Typography variant="h6">{textPrimary}</Typography>
        </Grid>
        {textSecondary && (
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              {textSecondary}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {textInfo && (
          <>
            <Grid item>
              <Typography variant="h6">{textInfo}</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                <ul>
                  {textConditions &&
                    textConditions.map((textCondition, idx) => {
                      return <li key={idx}> {textCondition}</li>;
                    })}
                </ul>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Start;
