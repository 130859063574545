import React from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "../shared/Dialog";
import DialogActions from "../DialogActions";
import useConfirmLeave from "../../hooks/useConfirmLeave";
import useModal from "../../hooks/useModal";
import useEditMaterial from "../Material/useEditMaterial";

const EditMaterialModal = ({
  locale,
  material,
  journeys,
  journey,
  onClose,
  onConfirm,
}) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });
  const { isNew } = material;
  const { ui, formik } = useEditMaterial({
    material,
    journeys,
    journey,
    locale,
    onUpdate: handleConfirm,
  });

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isNew ? "Add Learning Material" : "Edit Learning Material"}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{ui}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMaterialModal;
