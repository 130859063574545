import React, { useCallback, useEffect, useMemo, useRef } from "react";
import keyBy from "lodash/keyBy";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";

import Table from "../shared/Table";
import { useAppContext } from "../../api/AppContext";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
// import {
//   CHANNEL_BROWSERPLUGIN,
//   CHANNEL_CLIENT,
//   CHANNEL_EMBEDDED,
//   CHANNEL_TEAMSAPP,
//   DASHBOARD_ACCESS_ICON,
// } from "./consts";

const columns = ({ journeys, journeysById }) => [
  {
    field: "email",
    title: "Email",
    type: "string",
  },
  {
    field: "firstname",
    title: "First Name",
    type: "string",
  },
  {
    field: "lastname",
    title: "Last Name",
    type: "string",
  },
  {
    field: "notes",
    title: "Notes",
    type: "string",
  },
  {
    field: "companiesassociated",
    initialEditValue: [],
    title: "Companies Associated",
    type: "string",
  },
  {
    field: "isEnabled",
    title: "is Enabled",
    type: "boolean",
    render: (props) =>
      props.isEnabled ? (
        <Typography color="textPrimary" variant="body2">
          Yes (activated on{" "}
          {format(props.enabledAt * 1000, "dd MMMM yyyy HH:mm:ss")})
        </Typography>
      ) : (
        "No"
      ),
  },
  {
    defaultSort: "desc",
    field: "createdAt",
    // hidden: true,
  },
];

const User = () => {
  const ref = useRef();

  const {
    fetchJourneys,
    fetchUsers,
    isJourneysLoading,
    journeys,
    fetchConfigurations,
    isConfigurationsLoading,
    // configurations,
    users,
    // deleteConfiguration,
    deleteUser,
  } = useAppContext();

  useEffect(() => {
    fetchJourneys();
    fetchConfigurations();
    fetchUsers();
  }, []);

  const onRowDelete = useCallback(
    async (value) => deleteUser(value),
    [deleteUser]
  );

  const editable = useMemo(
    () => ({
      onRowDelete,
    }),
    [onRowDelete]
  );

  const history = useHistory();

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Table
        actions={[
          {
            icon: (props) => <EditIcon {...props} color="action" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              history.push(
                `/settings/companyuser-configuration/user/${rowData.id}`
              );
            },
          },
        ]}
        columns={columns({ journeys, journeysById: keyBy(journeys, "id") })}
        data={users}
        editable={editable}
        isLoading={isJourneysLoading || isConfigurationsLoading}
        options={{
          filtering: true,
        }}
        refetch={fetchConfigurations}
        tableActions={[
          <Button
            key="add"
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push("/settings/companyuser-configuration/user/new", {
                isNew: true,
              });
            }}
          >
            Create new User
          </Button>,
        ]}
        tableRef={ref}
        title="Users"
      />
    </Box>
  );
};

export default User;
