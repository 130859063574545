import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

// import GetAppIcon from "@material-ui/icons/Edit";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import Timestamps from "../Timestamps";

import { getFieldProps, inputProps, multilineInputProps } from "../utils";
import JourneysSelect from "../shared/JourneysSelect";
import SaveButtons from "../SaveButtons";

const CompanyPage = ({ journeys, formik }) => {
  // const handleAvailableJourneysChange = useCallback(
  //   (value) => {
  //     formik.setFieldValue("availableJourneys", value);
  //     formik.setFieldTouched("availableJourneys", true);
  //   },
  //   [formik]
  // );

  // const id = useMemo(() => uuidv4().slice(0, 8), []);

  // const handleNameChange = useCallback(
  //   (e) => {
  //     formik.setFieldValue("cname", e.target.value);
  //     formik.setFieldTouched("cname", true);

  //     const cid = `${slugify(e.target.value, {
  //       lower: true,
  //       replacement: "",
  //     })}${id}`;

  //     if (
  //       !formik.values.isNew ||
  //       (formik.touched.cid && formik.values.cid !== cid)
  //     ) {
  //       return;
  //     }

  //     formik.setFieldValue("cid", cid);
  //   },
  //   [formik, id]
  // );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Timestamps
            createdAt={formik.values.createdAt}
            createdBy={formik.values.createdBy}
            updatedAt={formik.values.updatedAt}
            updatedBy={formik.values.updatedBy}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">
            Company for access to content dashboard
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Company name"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "cname" })}
                // onChange={handleNameChange}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography variant="h6">Notes</Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    InputProps={multilineInputProps}
                    label="Notes"
                    margin="none"
                    minRows={5}
                    placeholder="ex.: n. of seats; planned start date"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "notes",
                    })}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <JourneysSelect
                    multiple
                    {...getFieldProps(formik, {
                      name: "dash_availableJourneys",
                    })}
                    journeys={journeys}
                    label="Journeys"
                    // onChange={handleAvailableJourneysChange}
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <JourneysSelect
                    multiple
                    {...getFieldProps(formik, {
                      name: "dash_enabledUsers",
                    })}
                    journeys={journeys}
                    label="Users"
                    // onChange={handleAvailableJourneysChange}
                  />
                </Grid>
                {/* <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="What you will gain"
              margin="none"
              placeholder="What are the values that your Journey brings to the users?"
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, {
                disabled: isDisabled,
                name: "benefits",
              })}
            />
          </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SaveButtons formik={formik} />
        </Grid>
      </Grid>
    </Box>
  );
};

const editSchema = yup.object({
  cname: yup.string().required().max(50),
  notes: yup.string().max(500),
});

const CompanyPageContainer = () => {
  const {
    fetchCompany,
    company,
    isCompanyLoading,
    updateCompany,
    // fetchConfiguration,
    // configuration,
    // isCompanyLoading,
    // updateCompany,
    isJourneysLoading,
    fetchJourneys,
    journeys,
  } = useAppContext();

  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { state } = useLocation();

  const companyEntity = state?.isNew
    ? {
        cname: "",
        isNew: true,
        journeys: [],
        notes: "",
        users: [],
      }
    : company;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: companyEntity && {
      ...companyEntity,
    },
    onSubmit: async (values) => {
      const newOrUpdatedCompany = await updateCompany({
        ...values,
      });

      if (!values.isNew) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace("new", newOrUpdatedCompany.id),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  useEffect(() => {
    if (!state?.isNew) {
      fetchCompany({ id });
    }
    fetchJourneys();
  }, [fetchCompany, fetchJourneys, id, state?.isNew]);

  if (isCompanyLoading || isJourneysLoading) {
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Company not found 😢</Typography>
      </Box>
    );
  }

  return <CompanyPage formik={formik} journeys={journeys} />;
};

export default CompanyPageContainer;
