/* eslint-disable prettier/prettier */
import {
  Grid,
  TextField,
  Box,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useAppContext } from "../../api/AppContext";

const Prompt = ({ formik, tags, tools }) => {
  const handleInputChange = (name, value, lang) => {
    if (lang) {
      formik.setFieldValue(name, {
        ...formik.values[name],
        [lang]: value,
      });
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Box
      boxSizing="border-box"
      component="form"
      display="flex"
      flexDirection="column"
      p={4}
      width="100%"
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={formik.touched.title?.en && Boolean(formik.errors.title?.en)}
              helperText={formik.touched.title?.en && formik.errors.title?.en}
              label="Title (EN)"
              margin="none"
              value={formik.values.title?.en || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("title", event.target.value, "en")
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={formik.touched.title?.it && Boolean(formik.errors.title?.it)}
              helperText={formik.touched.title?.it && formik.errors.title?.it}
              label="Title (IT)"
              margin="none"
              value={formik.values.title?.it || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("title", event.target.value, "it")
              }
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.description?.en &&
                Boolean(formik.errors.description?.en)
              }
              helperText={
                formik.touched.description?.en && formik.errors.description?.en
              }
              label="Description (EN)"
              margin="none"
              value={formik.values.description?.en || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("description", event.target.value, "en")
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.description?.it &&
                Boolean(formik.errors.description?.it)
              }
              helperText={
                formik.touched.description?.it && formik.errors.description?.it
              }
              label="Description (IT)"
              margin="none"
              value={formik.values.description?.it || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("description", event.target.value, "it")
              }
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.prompttext?.en &&
                Boolean(formik.errors.prompttext?.en)
              }
              helperText={
                formik.touched.prompttext?.en && formik.errors.prompttext?.en
              }
              label="Prompt (EN)"
              margin="none"
              rows={4}
              value={formik.values.prompttext?.en || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("prompttext", event.target.value, "en")
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.prompttext?.it &&
                Boolean(formik.errors.prompttext?.it)
              }
              helperText={
                formik.touched.prompttext?.it && formik.errors.prompttext?.it
              }
              label="Prompt (IT)"
              margin="none"
              rows={4}
              value={formik.values.prompttext?.it || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("prompttext", event.target.value, "it")
              }
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              error={formik.touched.tool_id && Boolean(formik.errors.tool_id)}
              helperText={formik.touched.tool_id && formik.errors.tool_id}
              label="Tool"
              margin="none"
              value={formik.values.tool_id || ""}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("tool_id", event.target.value)
              }
            >
              {tools.map((tool) => (
                <MenuItem key={tool.id} value={tool.id}>
                  {tool.toolname}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                multiple: true,
                onChange: (event) =>
                  handleInputChange("tagids", event.target.value),
                value: formik.values.tagids || [],
              }}
              error={formik.touched.tagids && Boolean(formik.errors.tagids)}
              helperText={formik.touched.tagids && formik.errors.tagids}
              label="Tags"
              margin="none"
              variant="outlined"
            >
               {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tagname?.en}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container item justifyContent="center" mt={2} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isfromtypeform || false}
                  color="primary"
                  name="isfromtypeform"
                  onChange={(event) =>
                    handleInputChange("isfromtypeform", event.target.checked)
                  }
                />
              }
              label="isFromTypeform"
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};


const PromptContainer = () => {
  const { fetchTools, fetchTags, updatePrompt, tags, tools } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [configurationEntity, setConfigurationEntity] = useState(null);
  const { rowData } = location.state || {};
  const basePath = useMemo(() => location.pathname.split('/')[1], [location.pathname]);

  useEffect(() => {
    fetchTools(basePath);
    fetchTags(basePath);
  }, [fetchTags, fetchTools, basePath]);

  const getValidationSchema = (basePath) => Yup.object({
    description: Yup.object({
      en: Yup.string().required("English description is required"),
      it: Yup.string().required("Italian description is required")
    }),
    isfromtypeform: Yup.boolean(),
    prompttext: Yup.object({
      en: Yup.string().required("English prompt is required"),
      it: Yup.string().required("Italian prompt is required")
    }),
    tagids: basePath === 'knowledge-hub' 
      ? Yup.array().of(Yup.string())
      : Yup.array()
          .of(Yup.string())
          .required("At least one tag is required")
          .min(1, "At least one tag is required"),
    title: Yup.object({
      en: Yup.string().required("English title is required"),
      it: Yup.string().required("Italian title is required")
    }),
    tool_id: Yup.string().required("Tool is required"),
  });
  
  useEffect(() => {
    if (rowData && tags.length > 0) {
      const tagIds = rowData.tags
      .map((tagObj) => tags.find((tag) => tag.tagname?.en === tagObj?.en)?.id)
      .filter(tagId => tagId);
  
      const formatMultilingualField = (value) => {
        if (typeof value === 'string') {
          return { en: '', it: value };
        }
        return value || { en: '', it: '' };
      };
  
      setConfigurationEntity({
        ...rowData,
        description: formatMultilingualField(rowData.description),
        id,
        prompttext: formatMultilingualField(rowData.prompttext),
        tagids: tagIds,
        title: formatMultilingualField(rowData.title),
      });
    }
  }, [id, location.state, rowData, tags]);
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity || {
      description: { en: '', it: '' },
      isNew: true,
      isfromtypeform: false,
      prompttext: { en: '', it: '' },
      tagids: [],
      title: { en: '', it: '' },
      tool_id: '',
    },
    onSubmit: async (values) => {
      const selectedTags = tags
        .filter(tag => values.tagids.includes(tag.id))
        .map(tag => tag.tagname);
  
      const cleanedValues = {
        description: values.description,
        isfromtypeform: values.isfromtypeform,
        prompttext: values.prompttext,
        tagids: values.tagids,
        tags: selectedTags,
        title: values.title,
        toolid: values.tool_id,
        toolname: tools.find(tool => tool.id === values.tool_id)?.toolname || '',
      };
  
      if (values.isNew) {
        cleanedValues.isNew = values.isNew;
      } else {
        cleanedValues.id = values.id;
      }
  
      await updatePrompt({ isNew: values.isNew, ...cleanedValues }, basePath);
      history.push(`/${basePath}/prompt`);
    },
    validationSchema: getValidationSchema(basePath),
  });
  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Prompt is not found 😢</Typography>
      </Box>
    );
  }

  return <Prompt formik={formik} tags={tags} tools={tools} />;
};

export default PromptContainer;
