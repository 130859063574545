import React from "react";

import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

const Version = () => (
  <Box p={1} textAlign="center">
    <Typography color="textSecondary" variant="caption">
      Version {process.env.REACT_APP_VERSION}
    </Typography>
  </Box>
);

export default Version;
