import React, { useCallback, useEffect, useMemo } from "react";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import ordinal from "ordinal";
import Highlighter from "react-highlight-words";
import removeMarkdown from "remove-markdown";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import SearchIcon from "@material-ui/icons/Search";

import { useAppContext } from "../../api/AppContext";
import getData from "../../lib/getData";

const filterOptions = createFilterOptions({
  stringify: (option) =>
    `${option.id} ${removeMarkdown(option.title ?? "")} ${removeMarkdown(
      option.text ?? ""
    )} ${removeMarkdown(option.name ?? "")} ${removeMarkdown(
      option.description ?? ""
    )}`,
});

const SemanticsSearch = ({ journeyId, locale }) => {
  const {
    semantics,
    triggers,
    fetchTriggers,
    journeys,
    isJourneysLoading,
    isSemanticsLoading,
    isTriggersLoading,
    materials,
    fetchSemantics,
    fetchMaterials,
    isMaterialsLoading,
    fetchFlowRelations,
    fetchScheduledSlots,
    isFlowRelationsLoading,
    isScheduledSlotsLoading,
    flowRelations,
    scheduledSlots,
  } = useAppContext();

  const triggersByStimulusId = useMemo(
    () => groupBy(triggers, "stimulusId"),
    [triggers]
  );

  const journey = useMemo(
    () =>
      journeys.find(
        (journey) => journey.id === journeyId && journey.locale === locale
      ),
    [journeyId, journeys, locale]
  );

  const { flowRelationsByFlowId, scheduledSlotsById } = useMemo(
    () => getData({ semantics, flowRelations, scheduledSlots }),
    [scheduledSlots, flowRelations, semantics]
  );

  const daysByDayId = useMemo(() => keyBy(journey?.days, "id"), [journey]);

  useEffect(() => {
    if (!triggers.length) {
      fetchTriggers({ journeyId });
    }

    if (!semantics.length) {
      fetchSemantics({ journeyId, locale });
    }

    if (!materials.length) {
      fetchMaterials({ journeyId, locale });
    }

    if (!flowRelations) {
      fetchFlowRelations({ journeyId, locale });
    }

    if (!scheduledSlots) {
      fetchScheduledSlots({ journeyId });
    }
  }, [journeyId, locale]);

  const sortedSemantics = useMemo(
    () => [
      ...orderBy(
        semantics.map((semantic) => ({
          ...semantic,
          date: scheduledSlotsById[
            flowRelationsByFlowId[semantic.flowId]?.[0]?.relationId
          ]?.date,
          day: daysByDayId[
            flowRelationsByFlowId[semantic.flowId]?.[0]?.relationId
          ]?.day,
          groupColumn:
            semantic.type === "PRIME"
              ? flowRelationsByFlowId[semantic.flowId]?.[0]?.type ===
                "scheduledSlot"
                ? "Scheduled Tips"
                : "Tips"
              : "Nudges",
        })),
        ["type", "day"],
        ["desc", "asc"]
      ),
      ...(materials?.length > 0
        ? materials.map((material) => ({
            ...material,
            groupColumn: "Materials",
            type: "LEARNING-MATERIAL",
          }))
        : []),
    ],
    [
      daysByDayId,
      flowRelationsByFlowId,
      materials,
      scheduledSlotsById,
      semantics,
    ]
  );

  const handleChange = useCallback(
    (e, value, reason) => {
      if (reason !== "select-option") {
        return;
      }

      window.open(
        `/journeys/${journeyId}/${locale}/${value.type.toLowerCase()}s/${
          value.id
        }`,
        "_blank"
      );
    },
    [journeyId, locale]
  );

  return (
    <Autocomplete
      fullWidth
      filterOptions={filterOptions}
      getOptionLabel={(option) =>
        option.type === "PRIME" ? option.title : option.text
      }
      groupBy={(option) => option.groupColumn}
      loading={
        isJourneysLoading ||
        isSemanticsLoading ||
        isTriggersLoading ||
        isMaterialsLoading ||
        isScheduledSlotsLoading ||
        isFlowRelationsLoading
      }
      options={sortedSemantics}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            inputProps: {
              ...params.inputProps,
              "hi-web-id": "semantics-search",
            },
          }}
          size="small"
          style={{ maxWidth: 500 }}
        />
      )}
      renderOption={(option, { inputValue }) => (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2">
              <Highlighter
                hi
                searchWords={[inputValue || ""]}
                textToHighlight={removeMarkdown(
                  option[
                    option.type === "LEARNING-MATERIAL" ? "name" : "title"
                  ] ?? ""
                )}
              />
            </Typography>
            <Typography variant="body2">
              <Highlighter
                searchWords={[inputValue || ""]}
                textToHighlight={removeMarkdown(
                  option[
                    option.type === "LEARNING-MATERIAL" ? "description" : "text"
                  ] ?? ""
                )}
              />
            </Typography>
          </Grid>
          {option.type === "PRIME" && (
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right" }} variant="body2">
                {option.date || (option.day && `${ordinal(option.day)} day`)}
              </Typography>
            </Grid>
          )}
          {option.type === "NUDGE" && (
            <Grid item xs={4}>
              <Grid container direction="column" spacing={1}>
                {triggersByStimulusId[option.stimulusId]?.map(
                  ({ id, name }) => (
                    <Grid key={id} item>
                      <Typography
                        style={{ textAlign: "right", wordBreak: "break-all" }}
                        variant="subtitle2"
                      >
                        {name}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          )}
          {option.type === "LEARNING-MATERIAL" && (
            <Grid item xs={4}>
              <Typography style={{ textAlign: "right" }} variant="body2">
                Step{" "}
                {journey?.steps?.findIndex(({ id }) => id === option.stepId) +
                  1}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      style={{ textAlign: "right" }}
      onChange={handleChange}
    />
  );
};

export default SemanticsSearch;
