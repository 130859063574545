import React, { useCallback, useEffect, useMemo, useRef } from "react";
import keyBy from "lodash/keyBy";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";

import Table from "../shared/Table";
import { useAppContext } from "../../api/AppContext";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

const columns = ({ journeys, journeysById }) => [
  {
    field: "cname",
    title: "Company Name",
    type: "string",
  },
  {
    field: "notes",
    title: "Notes",
  },
  {
    defaultSort: "desc",
    field: "createdAt",
    hidden: true,
  },
];

const Company = () => {
  const ref = useRef();

  const {
    fetchJourneys,
    isJourneysLoading,
    journeys,
    fetchCompanies,
    isCompaniesLoading,
    companies,
    deleteCompany,
  } = useAppContext();

  useEffect(() => {
    fetchJourneys();
    fetchCompanies();
  }, []);

  const onRowDelete = useCallback(
    async (value) => deleteCompany(value),
    [deleteCompany]
  );

  const editable = useMemo(
    () => ({
      onRowDelete,
    }),
    [onRowDelete]
  );

  const history = useHistory();

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Table
        actions={[
          {
            icon: (props) => <EditIcon {...props} color="action" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              history.push(
                `/settings/companyuser-configuration/company/${rowData.id}`
              );
            },
          },
        ]}
        columns={columns({ journeys, journeysById: keyBy(journeys, "id") })}
        data={companies}
        editable={editable}
        isLoading={isJourneysLoading || isCompaniesLoading}
        options={{
          filtering: true,
        }}
        refetch={fetchCompanies}
        tableActions={[
          <Button
            key="add"
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push("/settings/companyuser-configuration/company/new", {
                isNew: true,
              });
            }}
          >
            Create new Company
          </Button>,
        ]}
        tableRef={ref}
        title="Companies"
      />
    </Box>
  );
};

export default Company;
