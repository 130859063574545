export const tmpTemplateFamilies = [
  {
    semanticTemplateCatalogue: [
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-2e4c95b5-dd68-41b4-9c31-33e3703ea439",
        templateName: "Card 1A",
        templatePreviewUrl: "templates/card_01A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-0dd751ee-53f3-4445-beba-acb2d359c360",
        templateName: "Card 1B",
        templatePreviewUrl: "templates/card_01B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-3bcac35d-b562-4fec-be51-75ea3393fa1a",
        templateName: "Card 1C",
        templatePreviewUrl: "templates/card_01C.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-a9cf769f-bacf-4d52-9240-07c672bb0563",
        templateName: "Card 2A",
        templatePreviewUrl: "templates/card_02A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-382a1382-4c0a-4c39-8b87-05601dcdcec2",
        templateName: "Card 3A",
        templatePreviewUrl: "templates/card_03A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-3f1ce8ce-5599-4180-8fae-e49ecb6e43b6",
        templateName: "Card 4A",
        templatePreviewUrl: "templates/card_04A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: true,
        modelSelectorColumns: 6,
        templateId: "crd-730ce5bd-fb82-4c5b-9498-2535a64326f9",
        templateName: "Card 5A",
        templatePreviewUrl: "templates/card_05A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: true,
        modelSelectorColumns: 6,
        templateId: "crd-3ad44bbb-ccf0-4739-b1cc-93de7aad54a2",
        templateName: "Card 5B",
        templatePreviewUrl: "templates/card_05B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: true,
        modelSelectorColumns: 6,
        templateId: "crd-dde54333-c2e5-4c79-9e04-ba0fc998e592",
        templateName: "Card 6A",
        templatePreviewUrl: "templates/card_06A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: true,
        modelSelectorColumns: 6,
        templateId: "crd-5aefe275-cdb1-4966-aaa4-656cee917e0f",
        templateName: "Card 6B",
        templatePreviewUrl: "templates/card_06B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-692b782d-858f-4838-bd41-c9ac37420201",
        templateName: "Card 7A",
        templatePreviewUrl: "templates/card_07A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-6d50a381-8527-4e77-affc-d1c6a97b2f3a",
        templateName: "Card 7B",
        templatePreviewUrl: "templates/card_07B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-d9ec1b3a-af13-490e-addf-ac1f832591b1",
        templateName: "Card 7C",
        templatePreviewUrl: "templates/card_07C.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-f5a20b7c-73b9-478f-a1b9-8595e45dd969",
        templateName: "Card 8A",
        templatePreviewUrl: "templates/card_08A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-09501028-80e3-4e24-8b83-15957648463e",
        templateName: "Card 9A",
        templatePreviewUrl: "templates/card_09A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 4,
        templateId: "crd-63a9d59c-3a08-477c-b673-ebd583c72331",
        templateName: "Card 9B",
        templatePreviewUrl: "templates/card_09B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-cc8ac643-1753-475a-b693-165fae4e6a40",
        templateName: "Card 9C",
        templatePreviewUrl: "templates/card_09C.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-112300ab-39c9-41f3-a51b-90955d675988",
        templateName: "Card 9D",
        templatePreviewUrl: "templates/card_09D.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-71b8a8f9-b9a7-497e-b6f5-836614b4890c",
        templateName: "Card 10A",
        templatePreviewUrl: "templates/card_10A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-120752be-6a00-4c6f-8560-482ecf7cdc07",
        templateName: "Card 10B",
        templatePreviewUrl: "templates/card_10B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-703add2c-d20e-4b5d-886e-9c2d9a518c99",
        templateName: "Card 11A",
        templatePreviewUrl: "templates/card_11A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-644b5940-804b-406a-b0ba-6995b58e8e34",
        templateName: "Card 11B",
        templatePreviewUrl: "templates/card_11B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-780fa506-9814-469e-8758-32ec684c96bb",
        templateName: "Card 12A",
        templatePreviewUrl: "templates/card_12A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-7e999e37-9233-43ee-ae82-5f23772442f7",
        templateName: "Card 13A",
        templatePreviewUrl: "templates/card_13A.svg",
      },
    ],
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    tFamilyName: "Family1",
  },
  {
    semanticTemplateCatalogue: [
      {
        isSmall: false,
        isActive: true,
        templateId: "crd-50ad24d4-c833-4aa9-9958-3a0cfc59c54f",
        templateName: "Card Legacy",
      },
    ],
    tFamilyId: "fam-6fae4cea-82c4-4f2f-89c8-a04973c7fbfa",
    tFamilyName: "Legacy",
  },
];
