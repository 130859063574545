import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import TextField from "@material-ui/core/TextField";
import useModal from "../hooks/useModal";
import { getFieldProps, inputProps } from "./utils";

const validationSchema = yup.object().shape({
  deployment: yup.string(),
  name: yup.string().required(),
});

const CopyActivationLinkModal = ({ onConfirm, onClose } = {}) => {
  const { handleConfirm, isLoading } = useModal({
    onClose,
    onConfirm,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deployment: "",
      isNew: true,
      name: "",
    },
    onSubmit: async (values) => {
      await handleConfirm(values);
    },
    validationSchema,
    validator: () => ({}),
  });

  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {formik.values.isNew ? "Create new" : "Edit"} client
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Name"
                margin="none"
                placeholder="What is the client's name?"
                variant="outlined"
                {...getFieldProps(formik, { name: "name" })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Deployment"
                margin="none"
                placeholder="Create ID for the wave..."
                variant="outlined"
                {...getFieldProps(formik, { name: "deployment" })}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyActivationLinkModal;
