import React from "react";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "./shared/Dialog";
import { getFieldProps, inputProps } from "./utils";
import * as yup from "yup";

const validationSchema = yup.object({
  password: yup.string().min(10).required(),
});

const DeleteJourneyModal = ({ journey, onConfirm, onClose }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    onSubmit: async (values) => {
      await onConfirm(values);
      await onClose();
    },
    validationSchema,
    validator: () => ({}),
  });

  return (
    <Dialog fullWidth open maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            {`Delete "${journey.internalName || journey.name}" Journey?`}
            <div>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All materials and interactions will be deleted
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                label="Password"
                {...getFieldProps(formik, {
                  name: "password",
                })}
                InputProps={inputProps}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting && <CircularProgress size={25} />}
            type="submit"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteJourneyModal;
