import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import Sticky from "react-sticky-el";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import format from "date-fns/format";
// import addDays from "date-fns/addDays";
import compose from "lodash/fp/compose";
import fpFilter from "lodash/fp/filter";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ListItemAvatar, useTheme } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import Loader from "../shared/Loader";
import TitleTypography from "../TitleTypography";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";
import Tooltip from "@material-ui/core/Tooltip";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkIcon from "@material-ui/icons/Link";
import fpUniqBy from "lodash/fp/uniqBy";
import fpOrderBy from "lodash/fp/orderBy";
import FlowPreview from "../FlowPreview";
import getData from "../../lib/getData";
import { getScheduledSlotName } from "../../lib/scheduledSlotUtilities";

const ListItem = withStyles({
  root: {
    paddingRight: 160,
  },
})(MuiListItem);

const ScheduledSlots = ({ journeyId, locale, isDisabled, days }) => {
  const {
    fetchSemantics,
    isSemanticsLoading,
    isUIConfigsLoading,
    deleteScheduledSlot,
    updateScheduledSlot,
    scheduledSlots,
    isScheduledSlotsLoading,
    fetchScheduledSlots,
    fetchFlowRelations,
    isFlowRelationsLoading,
    flowRelations,
    deleteFlowRelation,
    createFlowRelation,
    semantics,
    fetchUserStatePaths,
    userStatePaths,
    isUserStatePathsLoading,
    surveys,
    fetchAllSurveysForUser: fetchSurveys,
    isSurveysLoading,
  } = useAppContext();

  const { primesByScheduledSlotId, primes, flowRelationsByFlowId } = useMemo(
    () => getData({ flowRelations, semantics }),
    [flowRelations, semantics]
  );

  useEffect(() => {
    fetchSemantics({ journeyId, locale });
    fetchScheduledSlots({ journeyId });
    fetchFlowRelations({ journeyId, locale });
    fetchUserStatePaths();
    fetchSurveys({ journeyId });
  }, [journeyId, locale]);

  const history = useHistory();
  const modals = useModals();

  const handleEditScheduledSlot = useCallback(
    (scheduledSlot) => () => {
      modals.openModal(modals.modalTypes.EditScheduledSlot, {
        // interactionPeriods,
        scheduledSlot,
        isDisabled,
        days,
        onConfirm: (scheduledSlot) =>
          updateScheduledSlot({ journeyId, scheduledSlot }),
        scheduledSlots,
        userStatePaths,
        surveys,
        journeyId,
      });
    },
    [
      days,
      isDisabled,
      journeyId,
      modals,
      scheduledSlots,
      updateScheduledSlot,
      userStatePaths,
      surveys,
    ]
  );

  const handleDelete = useCallback(
    ({ id, scheduledSlotName }) =>
      () => {
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Delete",
          onConfirm: () => deleteScheduledSlot({ id }),
          text: `You're about to permanently delete this scheduled lot ${scheduledSlotName}. It's associated message will not be deleted, but will become associated to no scheduled slot.`,
          title: "Delete the scheduled slot?",
        });
      },
    [history, journeyId, locale]
  );

  const handleAddScheduledSlot = useCallback(() => {
    const id = `scheduled-slot-${uuidv4()}`;

    modals.openModal(modals.modalTypes.EditScheduledSlot, {
      // interactionPeriods,
      scheduledSlot: { id },
      isNew: true,
      isDisabled,
      days,
      onConfirm: (scheduledSlot) =>
        updateScheduledSlot({ isNew: true, journeyId, scheduledSlot }),
      scheduledSlots,
      userStatePaths,
      surveys,
      journeyId,
    });
  }, [
    modals,
    isDisabled,
    days,
    scheduledSlots,
    updateScheduledSlot,
    journeyId,
    userStatePaths,
    surveys,
  ]);

  const [selectedId, setSelectedId] = useState(null);

  const handleListItemClick = useCallback(
    (id) => () => {
      setSelectedId(id);
    },
    []
  );

  const handleDisassociateTip = useCallback(
    ({ id, scheduledSlotName }) =>
      () =>
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Disassociate",
          onConfirm: async () =>
            deleteFlowRelation({
              flowId: primesByScheduledSlotId[id][0].flowId,
              journeyId,
              relationId: id,
            }),
          text: `You're about to disassociate the tip from this scheduled slot ${scheduledSlotName}. This scheduled slot will be without any planned tip.`,
          title: "Disassociate this tip?",
        }),
    [deleteFlowRelation, journeyId, modals, primesByScheduledSlotId]
  );

  const handleAssociateTip = useCallback(
    ({ id, scheduledSlotName }) =>
      () =>
        modals.openModal(modals.modalTypes.AssociateTip, {
          name: scheduledSlotName,
          onConfirm: async (flowId) =>
            createFlowRelation({
              flowId,
              journeyId,
              relationId: id,
              type: "scheduledSlot",
            }),
          tips: compose(
            fpFilter(({ flowId }) => !flowRelationsByFlowId[flowId]),
            fpUniqBy("flowId"),
            fpOrderBy(["isFirstInFlow"], ["asc"])
          )(primes),
        }),
    [createFlowRelation, flowRelationsByFlowId, journeyId, modals, primes]
  );

  const openEdit = useCallback(
    ({ journeyId, locale, interaction }) => {
      history.push(
        `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${
          interaction.id
        }`
      );
    },
    [history]
  );

  const theme = useTheme();

  if (
    isSemanticsLoading ||
    isUIConfigsLoading ||
    isScheduledSlotsLoading ||
    isFlowRelationsLoading ||
    isUserStatePathsLoading ||
    isSurveysLoading
  ) {
    return <Loader inline />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TitleTypography variant="h6">Scheduled Slots</TitleTypography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Scheduled tips are like tips, but they do not follow the{" "}
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  progress of user's Journey. They will appear on the datetime
                  selected in this section. After they are opened they will not
                  make the Journey progress. On the day when a scheduled tip is
                  planned, other tips will not appear but nudges could. In a day
                  maximum 1 scheduled tip can be sent. If more than one
                  scheduled tips are eligible, the one of these with older Start
                  datetime will be picked.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={isDisabled}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddScheduledSlot}
            >
              Add new scheduled slot
            </Button>
          </Grid>
          <Grid item xs={12}>
            <List dense>
              {scheduledSlots
                .sort((x, y) => x.datetimeStart - y.datetimeStart)
                .map(
                  ({
                    conditions,
                    datePeriod,
                    datetimeEnd,
                    datetimeStart,
                    id,
                    specificInterval,
                    isAlwaysVisibleInUDashboard,
                  }) => {
                    const scheduledSlotName = getScheduledSlotName({
                      datePeriod,
                      datetimeEnd,
                      datetimeStart,
                      specificInterval,
                    });
                    return (
                      <Fragment key={id}>
                        <ListItem
                          button
                          selected={id === selectedId}
                          onClick={handleListItemClick(id)}
                        >
                          <ListItemAvatar>
                            <CalendarTodayIcon color="action" />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`Scheduled on ${scheduledSlotName}`}
                            secondary={`${
                              primesByScheduledSlotId[id]?.length ?? 0
                            } Tips in flow`}
                          />
                          <ListItemSecondaryAction>
                            {!isDisabled && (
                              <>
                                <Tooltip title="Edit scheduled slot">
                                  <IconButton
                                    disabled={isDisabled}
                                    onClick={handleEditScheduledSlot({
                                      conditions,
                                      datePeriod,
                                      datetimeEnd,
                                      datetimeStart,
                                      id,
                                      specificInterval,
                                      isAlwaysVisibleInUDashboard,
                                    })}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete scheduled slot">
                                  <IconButton
                                    disabled={isDisabled}
                                    onClick={handleDelete({
                                      id,
                                      scheduledSlotName,
                                    })}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                                {primesByScheduledSlotId[id]?.length ? (
                                  <Tooltip title="Disassociate tip">
                                    <IconButton
                                      disabled={isDisabled}
                                      onClick={handleDisassociateTip({
                                        id,
                                        scheduledSlotName,
                                      })}
                                    >
                                      <LinkOffIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Associate tip">
                                    <IconButton
                                      disabled={isDisabled}
                                      onClick={handleAssociateTip({
                                        id,
                                        scheduledSlotName,
                                      })}
                                    >
                                      <LinkIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    );
                  }
                )}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2} xs={12} />
      <Grid item lg={4} xs={12}>
        <Sticky
          scrollElement=".scrollarea"
          stickyStyle={{ top: theme.spacing(4) + 92 }}
        >
          <FlowPreview
            interactions={primesByScheduledSlotId[selectedId] ?? []}
            journeyId={journeyId}
            locale={locale}
            name="scheduled slot"
            onEdit={(interaction) =>
              openEdit({ journeyId, locale, interaction })
            }
          />
        </Sticky>
      </Grid>
    </Grid>
  );
};

export default ScheduledSlots;
