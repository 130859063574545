import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { getScheduledSlotName } from "../../lib/scheduledSlotUtilities";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import { useModals } from "../../hooks/useModals";
import useLocalStorage from "../../hooks/useLocalStorage";
import Timestamps from "../Timestamps";
import MuiChip from "@material-ui/core/Chip";
const uniqBy = require("lodash/uniqBy");

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const A = styled.a`
  text-decoration: none;
`;

const Chip = withStyles({
  label: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  root: { cursor: "pointer", marginLeft: "0.25rem", marginRight: "0.5rem" },
})(MuiChip);

const UserMetadata = () => {
  const {
    fetchUserStatePaths,
    userStatePaths,
    isUserStatePathsLoading,
    isJourneysLoading,
    updateUserStatePath,
    deleteUserStatePath,
    fetchJourneys,
    user,
    journeys,
    fetchAllTriggers,
    triggers,
  } = useAppContext();

  const modals = useModals();
  const [modifiedTriggers, setModifiedTriggers] = useState([]);

  useEffect(() => {
    setModifiedTriggers(
      uniqBy(triggers, "stimulusId").map((trigger) => {
        return { id: trigger.stimulusId, name: trigger.stimulusId };
      })
    );
  }, [triggers]);

  const [expanded, setExpanded] = useLocalStorage(
    `${user.sub}.UserStatePaths.Expanded`,
    {}
  );

  const handleExpandClick = useCallback(
    (step) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [step]: !expanded[step],
      }));
    },
    [setExpanded]
  );

  useEffect(() => {
    if (!journeys.length) {
      fetchJourneys();
    }
    fetchUserStatePaths({ withusageinfo: true });
    fetchAllTriggers();
  }, [fetchAllTriggers, fetchJourneys, fetchUserStatePaths, journeys.length]);

  const handleCreate = useCallback(() => {
    modals.openModal(modals.modalTypes.EditUserMeta, {
      isNew: true,
      onConfirm: (userMeta) =>
        updateUserStatePath({
          isNew: true,
          ...userMeta,
        }),
      triggers: modifiedTriggers,
    });
  }, [modals, updateUserStatePath, modifiedTriggers]);

  const handleEdit = useCallback(
    (userMeta) => (e) => {
      e.stopPropagation();
      modals.openModal(modals.modalTypes.EditUserMeta, {
        item: userMeta,
        onConfirm: (userMeta) => updateUserStatePath(userMeta),
        triggers: modifiedTriggers,
      });
    },
    [modals, updateUserStatePath, modifiedTriggers]
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleEditSurvey = useCallback(
    ({ id, journeyId }) =>
      () => {
        const journey = journeys.find(({ id }) => id === journeyId);

        if (!journey) {
          enqueueSnackbar("Journey doesn't exist", { variant: "error" });
          return;
        }
        window.open(
          `/journeys/${journey.id}/${journey.locale}/surveys/${id}/outcomes`,
          "_blank"
        );
      },
    [enqueueSnackbar, journeys]
  );

  const handleEditScheduled = useCallback(
    ({ journeyId }) =>
      () => {
        const journey = journeys.find(({ id }) => id === journeyId);

        if (!journey) {
          enqueueSnackbar("Journey doesn't exist", { variant: "error" });
          return;
        }
        window.open(
          `/journeys/${journey.id}/${journey.locale}/scheduled-slots`,
          "_blank"
        );
      },
    [enqueueSnackbar, journeys]
  );

  const handleEditSemantic = useCallback(
    ({ journeyId, id, type }) =>
      () => {
        const journey = journeys.find(({ id }) => id === journeyId);

        if (!journey) {
          enqueueSnackbar("Journey doesn't exist", { variant: "error" });
          return;
        }
        window.open(
          `/journeys/${journeyId}/${
            journey.locale
          }/${type.toLowerCase()}s/${id}`,
          "_blank"
        );
      },
    [enqueueSnackbar, journeys]
  );

  const handleDelete = useCallback(
    ({ name, displayName, populating, condition, surveys }) =>
      (e) => {
        e.stopPropagation();

        if (
          populating.nudges?.length ||
          populating.primes?.length ||
          condition.nudges?.length ||
          condition.scheduledSlots?.length ||
          surveys.outcome?.length ||
          surveys.typeform?.length
        ) {
          enqueueSnackbar(
            "This User's Metadata field is used in some journeys; clear its usage and then you can delete this record.",
            {
              variant: "error",
            }
          );
          setExpanded((expanded) => ({
            ...expanded,
            [name]: true,
          }));
          return;
        }

        modals.openConfirmation({
          onConfirm: () => deleteUserStatePath({ id: name }),
          text: `You want to delete User's Metadata "${displayName}"`,
        });
      },
    [deleteUserStatePath, enqueueSnackbar, modals, setExpanded]
  );

  if (isUserStatePathsLoading || isJourneysLoading) {
    return <Loader />;
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">User&#39;s Metadata</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                Use this section to edit User&#39;s Metadata fields that will be
                available across journeys.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={handleCreate}
          >
            Add new User&#39;s metadata
          </Button>
        </Grid>
        <Grid item>
          {userStatePaths &&
            userStatePaths.map((userStatePath, i) => (
              <Accordion
                key={i}
                expanded={Boolean(expanded[i])}
                onChange={handleExpandClick(i)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    <Grid item style={{ flexGrow: 1 }}>
                      <Grid container direction="column" spacing={0.5}>
                        <Grid item>
                          <Typography variant="h6">
                            {userStatePath.displayName || userStatePath.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography color="textSecondary" variant="body2">
                            key: {userStatePath.name}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            description: {userStatePath.description || ""}
                          </Typography>
                          {!!userStatePath?.selectedTriggersForMetadata
                            ?.length && (
                            <Typography color="textSecondary" variant="body2">
                              This field will be set to 1 automatically whenever
                              any of the following trigger is triggered:
                              {userStatePath.selectedTriggersForMetadata.map(
                                (selectedTrigger, idx) => {
                                  return (
                                    <Chip
                                      key={idx}
                                      label={
                                        modifiedTriggers.find(
                                          (trigger) =>
                                            trigger.id === selectedTrigger
                                        )?.id
                                      }
                                      size="small"
                                    />
                                  );
                                }
                              )}
                            </Typography>
                          )}
                          <Typography color="textSecondary" variant="body2">
                            Usage:{" "}
                            {userStatePath.populating?.primes?.length || 0} tips
                            and {userStatePath.populating?.nudges?.length || 0}{" "}
                            nudges;{" "}
                            {(userStatePath.condition?.nudges?.length || 0) +
                              (userStatePath.condition?.scheduledSlots
                                ?.length || 0)}{" "}
                            special conditions;{" "}
                            {(userStatePath.surveys?.typeform?.length || 0) +
                              (userStatePath.surveys?.outcome?.length ||
                                0)}{" "}
                            survey settings
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Tooltip title="Edit">
                            <IconButton onClick={handleEdit(userStatePath)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Delete">
                            <IconButton onClick={handleDelete(userStatePath)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper style={{ width: "100%" }} variant="outlined">
                    <Box p={2}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Grid item>
                            <Timestamps
                              createdAt={userStatePath.createdAt}
                              createdBy={userStatePath.createdBy}
                              updatedAt={userStatePath.updatedAt}
                              updatedBy={userStatePath.updatedBy}
                            />
                          </Grid>
                          <br></br>
                          <Grid item>
                            <Typography color="textSecondary" variant="body2">
                              Populated in the following messages:
                            </Typography>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Tips</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.populating?.primes?.map(
                                ({
                                  id,
                                  title,
                                  text,
                                  journeyId,
                                  journey,
                                  type,
                                }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {title}
                                        </>
                                      }
                                      secondary={text}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditSemantic({
                                            id,
                                            journeyId,
                                            type,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Nudges</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.populating?.nudges?.map(
                                ({
                                  id,
                                  title,
                                  text,
                                  journeyId,
                                  type,
                                  journey,
                                }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {title}
                                        </>
                                      }
                                      secondary={text}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditSemantic({
                                            id,
                                            journeyId,
                                            type,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Grid>
                          <Grid item>
                            <Typography color="textSecondary" variant="body2">
                              Special conditions:
                            </Typography>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Scheduled slots</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.condition?.scheduledSlots?.map(
                                ({
                                  id,
                                  datePeriod,
                                  datetimeStart,
                                  datetimeEnd,
                                  journeyId,
                                  journey,
                                  specificInterval,
                                }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}/scheduled-slots`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {getScheduledSlotName({
                                            datePeriod,
                                            datetimeEnd,
                                            datetimeStart,
                                            specificInterval,
                                          })}
                                        </>
                                      }
                                      // secondary={text}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditScheduled({
                                            journeyId,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Nudges</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.condition?.nudges?.map(
                                ({
                                  id,
                                  title,
                                  text,
                                  journeyId,
                                  type,
                                  journey,
                                }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {title}
                                        </>
                                      }
                                      secondary={text}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditSemantic({
                                            id,
                                            journeyId,
                                            type,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Grid>
                          <Grid item>
                            <Typography color="textSecondary" variant="body2">
                              Survey settings:
                            </Typography>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Outcomes hi</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.surveys?.outcome?.map(
                                ({ id, journeyId, journey, name, type }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}/surveys`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {name}
                                        </>
                                      }
                                      secondary={`type: ${type}`}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditSurvey({
                                            id,
                                            journeyId,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                            <List
                              subheader={
                                <ListSubheader>
                                  <b>Typeform fields</b>
                                </ListSubheader>
                              }
                            >
                              {userStatePath.surveys?.typeform?.map(
                                ({ id, journeyId, journey, name, type }) => (
                                  <ListItem key={id}>
                                    <ListItemText
                                      primary={
                                        <>
                                          {journey && (
                                            <>
                                              <A
                                                href={`/journeys/${journey.id}/${journey.locale}/surveys`}
                                                rel="noreferrer noopener"
                                                target="_blank"
                                              >
                                                (
                                                {journey.internalName ||
                                                  journey.name}
                                                )
                                              </A>{" "}
                                            </>
                                          )}
                                          {name}
                                        </>
                                      }
                                      secondary={`type: ${type}`}
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={handleEditSurvey({
                                            id,
                                            journeyId,
                                          })}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Grid>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserMetadata;
