import * as yup from "yup";
import React, { useCallback, Fragment, useEffect, useMemo } from "react";
import { useFormik } from "formik";

import groupBy from "lodash/groupBy";

import JourneysSelect from "../shared/JourneysSelect";
import StimuliSelect from "../shared/StimuliSelect";
import { useAppContext } from "../../api/AppContext";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import styled from "styled-components/macro";

import { FormControlLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "../shared/Dialog";
import DialogActions from "../DialogActions";
import useConfirmLeave from "../../hooks/useConfirmLeave";
import useModal from "../../hooks/useModal";
import { getFieldProps } from "../utils";
import * as Yup from "yup";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

const validationSchema = () =>
  yup.object().shape({
    nudgeStimulusId: Yup.string().when("targetFlowId", {
      is: (val) => val === "Nudge",
      otherwise: (schema) => schema.nullable(),
      then: (schema) => schema.required(),
    }),
    targetFlowType: yup.mixed().oneOf(["Tip", "Nudge"]).required(),
    targetJourneyId: Yup.string().required(),
  });

const CopyFlowModal = ({ journeyId, flowId, onClose }) => {
  const {
    journeys,
    otherJourneyTriggers,
    fetchTriggers,
    isTriggersLoading,
    isJourneysLoading,
    copySemanticFlow,
  } = useAppContext(); // fetchJourneys, fetchTriggers

  const triggersByStimulusId = useMemo(
    () =>
      otherJourneyTriggers
        ? Object.values(groupBy(otherJourneyTriggers, "stimulusId"))
        : [],
    [otherJourneyTriggers]
  );

  const stimulusOptions = useMemo(
    // there are multiple triggers with same stimulusId; take the first one to display a name
    // Note: trigger id is usually equal to its name; stimulusId is in stimulusId field
    () =>
      triggersByStimulusId
        ? triggersByStimulusId.map(([{ name, stimulusId }, ...triggers]) => {
            return { id: stimulusId, name };
          })
        : [],
    [triggersByStimulusId]
  );

  const onConfirm = useCallback(
    async (data) => {
      const discardedCtaInfo = await copySemanticFlow({
        flowId,
        formData: data,
        journeyId,
      });
      console.log("discardedCtaInfo => ", JSON.stringify(discardedCtaInfo));
      onClose();
    },
    [flowId, journeyId, onClose]
  );

  const { handleConfirm } = useModal({ onClose, onConfirm });

  const formik = useFormik({
    initialValues: {
      nudgeStimulusId: null,
      targetFlowType: "Tip",
      targetJourneyId: null,
    },
    onSubmit: (values) => {
      handleConfirm({
        ...values,
      });
    },
    validationSchema: validationSchema(),
    validator: () => ({}),
  });

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  useEffect(() => {
    if (
      formik.values.targetFlowType === "Nudge" &&
      formik.values.targetJourneyId
    ) {
      fetchTriggers({ otherJourneyId: formik.values?.targetJourneyId });
    }
  }, [
    fetchTriggers,
    formik.values.targetJourneyId,
    formik.values.targetFlowType,
  ]);

  const handleNudgeStimulusIdChange = useCallback(
    (value) => {
      formik.setFieldValue("nudgeStimulusId", value);
    },
    [formik]
  );

  const handleTargetJourneyIdChange = useCallback(
    (value) => {
      formik.setFieldValue("targetJourneyId", value);
      formik.setFieldValue("nudgeStimulusId", null); // reset stimulus selection
    },
    [formik]
  );

  // TODO remember last selections and reapply here..
  // useEffect(() => {
  //   formik.values.targetJourneyId =
  // }, [formik]);

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Copy a flow
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {isJourneysLoading ? (
              <LoaderContainer>
                <CircularProgress size={30} />
              </LoaderContainer>
            ) : (
              <JourneysSelect
                {...getFieldProps(formik, {
                  name: "targetJourneyId",
                })}
                journeys={journeys}
                label="Target Journey"
                onChange={handleTargetJourneyIdChange}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="flowtypelabel">Destination flow type:</FormLabel>
              <RadioGroup
                aria-labelledby="flowtypelabel"
                name="targetFlowType"
                value={formik.values.targetFlowType}
                onChange={formik.handleChange}
              >
                {[
                  { description: "Tip", value: "Tip" },
                  { description: "Nudge", value: "Nudge" },
                ].map(({ description, value }) => (
                  <Fragment key={value}>
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label={
                        <Typography color="textSecondary" variant="body2">
                          {description}
                        </Typography>
                      }
                      value={value}
                    />
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {formik.values.targetJourneyId &&
            formik.values.targetFlowType === "Nudge" &&
            stimulusOptions.length && (
              <Grid item xs={12}>
                {isTriggersLoading ? (
                  <LoaderContainer>
                    <CircularProgress size={30} />
                  </LoaderContainer>
                ) : (
                  <StimuliSelect
                    {...getFieldProps(formik, {
                      name: "nudgeStimulusId",
                    })}
                    label="Stimulus"
                    stimuli={stimulusOptions}
                    onChange={handleNudgeStimulusIdChange}
                  />
                )}
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={
            !formik.dirty ||
            formik.isSubmitting ||
            (formik.values.targetFlowType === "Nudge" &&
              !formik.values?.nudgeStimulusId)
          }
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyFlowModal;
