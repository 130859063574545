import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import TitleTypography from "../../TitleTypography";
import { useAppContext } from "../../../api/AppContext";
import Loader from "../../shared/Loader";
import OutlinedButton from "../../OutlinedButton";
import { useModals } from "../../../hooks/useModals";
import useLocalStorage from "../../../hooks/useLocalStorage";
import Timestamps from "../../Timestamps";
import { surveyOutcomeTypeTitles } from "./consts";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const SurveyItem = ({
  id,
  name,
  type,
  outcome,
  description,
  journeyId,
  locale,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
}) => {
  const history = useHistory();
  const { deleteSurvey, user } = useAppContext();
  const modals = useModals();

  const [activeSurveys, setActiveSurveys] = useLocalStorage(
    `${user.sub}.Journeys.${journeyId}-${locale}.Surveys.ActiveSurveys`,
    {}
  );

  const handleSurveyExpand = useCallback(() => {
    setActiveSurveys((activeSurveys) => ({
      ...activeSurveys,
      [id]: !activeSurveys[id],
    }));
  }, [id, setActiveSurveys]);

  const handleEdit = useCallback(() => {
    history.push(`/journeys/${journeyId}/${locale}/surveys/${id}`);
  }, [history, id, journeyId, locale]);

  const handleDelete = useCallback(() => {
    modals.openConfirmation({
      closeCTATitle: "Go back",
      confirmCTATitle: "Delete",
      onConfirm: async () => deleteSurvey({ id }),
      text: `You're about to permanently delete this survey "${name}".`,
      title: "Delete the survey?",
    });
  }, [deleteSurvey, id, modals, name]);

  return (
    <Accordion expanded={activeSurveys[id]} onChange={handleSurveyExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          style={{ width: "100%", flexWrap: "nowrap" }}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ flexWrap: "nowrap" }}
            >
              <Grid container item direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h6">{name}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary">{description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ flexWrap: "nowrap" }}
            >
              <Grid item>
                <OutlinedButton size="small" onClick={handleEdit}>
                  Edit
                </OutlinedButton>
              </Grid>
              <Grid item>
                <Tooltip title="Delete step">
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Paper variant="outlined">
            <Box p={2}>
              <Grid container spacing={1} style={{ flexDirection: "column" }}>
                <Grid item>
                  {" "}
                  <Timestamps
                    createdAt={createdAt}
                    createdBy={createdBy}
                    updatedAt={updatedAt}
                    updatedBy={updatedBy}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="body2">Tool:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{type}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="body2">Outcome:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {surveyOutcomeTypeTitles[outcome.type]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Surveys = ({ journeyId, surveys, locale }) => {
  const history = useHistory();
  const handleAddSurvey = useCallback(() => {
    history.push(`/journeys/${journeyId}/${locale}/surveys/new`, {
      isNew: true,
    });
  }, [history, journeyId, locale]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TitleTypography variant="h6">Tests/Surveys</TitleTypography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              Use this section to create new tests or surveys. You will be able
              to connect to multiple Survey tools, determine the outcomes of the
              survey and setup the right channel to communicate the survey.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          endIcon={<AddIcon />}
          variant="contained"
          onClick={handleAddSurvey}
        >
          Create new survey
        </Button>
      </Grid>
      <Grid item xs={12}>
        {surveys.map((survey) => (
          <SurveyItem key={survey.id} {...survey} locale={locale} />
        ))}
      </Grid>
    </Grid>
  );
};

const SurveysContainer = ({ journeyId, locale }) => {
  const { fetchSurveys, isSurveysLoading, surveys } = useAppContext();

  useEffect(() => {
    fetchSurveys({ journeyId });
  }, [journeyId]);

  if (isSurveysLoading) {
    return <Loader />;
  }

  return <Surveys journeyId={journeyId} locale={locale} surveys={surveys} />;
};

export default SurveysContainer;
