import React, { useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import Highlighter from "react-highlight-words";

import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import keyBy from "lodash/keyBy";

const renderers = {
  paragraph: "span",
};

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.id} ${option.name} ${option.internalName}`,
});

const MultiSelect = ({
  items,
  value,
  onChange,
  helperText,
  error,
  disableClearable,
  ...props
}) => {
  const itemsById = useMemo(() => keyBy(items, "id"), [items]);
  const handleChange = useCallback(
    (e, value, reason) => {
      if (!["select-option", "remove-option", "clear"].includes(reason)) {
        return;
      }

      onChange?.(value.map(({ id }) => id));
    },
    [onChange]
  );

  return (
    <Autocomplete
      fullWidth
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.internalName || option.name}
      multiple={true}
      options={items}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          error={error}
          helperText={helperText}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.internalName || option.name}
            >
              <ReactMarkdown renderers={renderers}>
                {option.internalName || option.name}
              </ReactMarkdown>
            </Highlighter>
          }
        />
      )}
      value={(value || []).map((id) => itemsById[id]).filter(Boolean)}
      onChange={handleChange}
    />
  );
};

export default MultiSelect;
