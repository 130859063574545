import Box from "@material-ui/core/Box";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import IconButton from "@material-ui/core/IconButton";
import React, { useEffect, useState } from "react";
import ReactCopyToClipboard from "react-copy-to-clipboard";
import styled, { css } from "styled-components";
import { Tooltip, Typography } from "@material-ui/core";

const Container = styled.div`
  align-items: center;
  display: flex;

  ${(props) =>
    props.hidden &&
    css`
      opacity: 0;
    `}
`;

const CopiedContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const CopyToClipboard = ({ text, children }) => {
  const [isCopied, setIsCopied] = useState(false);
  let timer = null;

  useEffect(() => () => timer && clearTimeout(timer), [timer]);

  const onCopy = () => {
    setIsCopied(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      <Tooltip aria-label="Copy to clipboard" title="Copy to clipboard">
        <Box position="relative">
          {isCopied && (
            <CopiedContainer>
              <Typography variant="subtitle2">copied!</Typography>
            </CopiedContainer>
          )}
          <Container hidden={isCopied}>
            {children}
            <IconButton color="inherit" size="small">
              <FileCopyOutlinedIcon fontSize="small" />
            </IconButton>
          </Container>
        </Box>
      </Tooltip>
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboard;
