import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageExport = () => {
  const ref = useRef();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [screenshotUrl, setScreenshotUrl] = useState();
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const history = useHistory();

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  useEffect(() => {
    const listener = (e) => {
      try {
        const { data } = e;
        if (data.type !== "rendered") {
          return;
        }
        setHeight(data.height);
        setWidth(data.width);

        // eslint-disable-next-line no-empty
      } catch {}
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);

  const handleScreenshot = useCallback(async () => {
    if (!!width && !!height) {
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          preferCurrentTab: true,
        });
        const video = document.createElement("video");

        video.addEventListener("loadedmetadata", () => {
          const canvas = document.createElement("canvas");
          canvas.style.display = "none";
          video.style.display = "none";
          const ctx = canvas.getContext("2d");

          canvas.width = width;
          canvas.height = height;

          video.play();
          stream.getVideoTracks()[0].stop();

          const iframePosition = ref.current.getBoundingClientRect();
          const x = iframePosition.left + window.scrollX;
          const y = iframePosition.top + window.scrollY + 1;

          ctx.drawImage(video, x, y, width, height, 0, 0, width, height);
          const canvasUrl = canvas.toDataURL("image/png");
          setScreenshotUrl(canvasUrl);
          openImgEditor();
        });
        video.srcObject = stream;
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [height, width]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedParams = searchParams.get("url");

    const imageUrl = encodedParams
      ? JSON.parse(window.atob(encodedParams))
      : history.push("/");
    setImgUrl(imageUrl);

    !screenshotUrl && handleScreenshot();
  }, [screenshotUrl, handleScreenshot, history]);

  const saveImage = (editedImageObject) => {
    const link = document.createElement("a");
    link.download = editedImageObject.fullName;
    link.href = editedImageObject.imageBase64;
    link.click();
    closeImgEditor();
  };

  return (
    <Container>
      {!isImgEditorShown && !!imgUrl && (
        <iframe
          ref={ref}
          allow="display-capture"
          frameBorder="0"
          height={height}
          id="iframe"
          name="_blank"
          src={imgUrl}
          title={"title"}
          width={width}
          onError={() => console.log("error")}
        />
      )}

      {isImgEditorShown && (
        <FilerobotImageEditor
          Rotate={{ angle: 90, componentType: "slider" }}
          Text={{ text: "" }}
          annotationsCommon={{
            fill: "#000000",
          }}
          closeAfterSave={true}
          defaultSavedImageName={"export"}
          defaultSavedImageQuality={1}
          defaultTabId={TABS.RESIZE}
          defaultToolId={TOOLS.CROP}
          source={screenshotUrl}
          onClose={closeImgEditor}
          onSave={(editedImageObject, _designState) =>
            saveImage(editedImageObject)
          }
        />
      )}
    </Container>
  );
};

export default ImageExport;
