/* eslint-disable prettier/prettier */
import { useParams } from "react-router-dom";
import PromptTable from "./CopilotTable";
/* import Tool from "./Tools";
import Tags from "./Tags";
 */
const Copilot = () => {
  const { tab } = useParams();

  if (!tab || tab === "prompt") {
    return <PromptTable />;
  }
  if (tab === "tools") {
    return <PromptTable />;
  }
  if (tab === "tags") {
    return <PromptTable />;
  }

  return null;
};

export default Copilot;