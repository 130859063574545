import MUIRichTextEditor from "mui-rte";
import Picker from "emoji-picker-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";

import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const EmojiPicker = ({ onMouseDown, id, disabled }) => {
  const [anchorEl, setAnchorEl] = useState();
  const handleEmojiClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEmojiOpen = useCallback((e) => {
    setAnchorEl(e.target);
  }, []);

  const handleEmojiClick = useCallback(
    (e, emojiObject) => {
      document.getElementById(id).value = emojiObject.emoji;
      onMouseDown(e);
      setAnchorEl(null);
    },
    [id, onMouseDown]
  );

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={Boolean(anchorEl)}
        onClose={handleEmojiClose}
      >
        <Picker onEmojiClick={handleEmojiClick} />
      </Popover>
      <IconButton disabled={disabled} id={id} onClick={handleEmojiOpen}>
        <InsertEmoticonIcon />
      </IconButton>
    </>
  );
};

const MarkdownEditor = ({
  value,
  name,
  label,
  helperText,
  error,
  placeholder,
  maxLength,
  onChange,
  disabled,
  secondary = false,
}) => {
  const [state, setState] = useState(JSON.stringify(markdownToDraft(value)));

  useEffect(() => {
    setState(JSON.stringify(markdownToDraft(value)));
  }, [value]);

  const handleChangeDebounced = useMemo(
    () =>
      debounce((editorState) => {
        if (!onChange) {
          return;
        }

        if (!editorState) {
          return;
        }

        const content = editorState.getCurrentContent();
        const rawObject = convertToRaw(content);
        onChange({
          target: {
            name,
            value: draftToMarkdown(rawObject, {}),
          },
        });
      }, 500),
    [name, onChange]
  );

  return (
    <FormControl fullWidth component="fieldset" error={error}>
      <Typography
        color={error ? "error" : secondary ? "textSecondary" : "textPrimary"}
        variant="subtitle2"
      >
        {label}
      </Typography>
      <FormGroup>
        <Box minHeight={93}>
          <MUIRichTextEditor
            inlineToolbar
            controls={[
              "bold",
              "italic",
              "undo",
              "redo",
              "clear",
              "emoji-select",
              "underline",
              "strikethrough",
              "highlight",
              "link",
              "numberList",
              "bulletList",
              "quote",
              "code",
            ]}
            customControls={[
              {
                component: EmojiPicker,
                name: "emoji-select",
                onClick: (editorState, _name, element) => {
                  const selection = editorState.getSelection();
                  const newContentState = Modifier.insertText(
                    editorState.getCurrentContent(),
                    selection,
                    element.value
                  );

                  return EditorState.push(
                    editorState,
                    newContentState,
                    "insert-characters"
                  );
                },
                type: "callback",
              },
            ]}
            defaultValue={state}
            draftEditorProps={{
              spellCheck: true,
            }}
            error={error}
            inlineToolbarControls={[
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "link",
              "clear",
            ]}
            label={placeholder}
            maxLength={maxLength}
            readOnly={disabled}
            onChange={handleChangeDebounced}
          />
        </Box>
      </FormGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MarkdownEditor;
