import ContentLoader from "react-content-loader";
import React from "react";

const CardLoader = () => (
  <ContentLoader
    height={465}
    style={{ width: "100%" }}
    viewBox="0 0 306.5 465"
    width={306.5}
  >
    <rect height={164} width={306.5} />
    <circle cx={24 + 12} cy={164 + 16 + 12} r={12} />
    <rect height={24} rx={12} ry={12} width={76} x={24 + 24 + 8} y={164 + 16} />
    <rect
      height={6}
      rx={3}
      ry={3}
      width={306.5 - 24 - 24}
      x={24}
      y={164 + 16 + 24 + 6 + 12}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={306.5 - 24 - 24}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={306.5 - 24 - 24}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12 + 20}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={306.5 - 24 - 24}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12 + 20 + 20}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={(306.5 - 24 - 24) / 2}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12 + 20 + 20 + 10 + 12 + 5 + 5}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={(306.5 - 24 - 24) / 2}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12 + 20 + 20 + 10 + 12 + 6 + 16 + 5}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={(306.5 - 24 - 24) / 2}
      x={24}
      y={164 + 16 + 24 + 6 + 12 + 24 + 12 + 20 + 20 + 10 + 12 + 6 + 16 + 16 + 5}
    />
    <rect
      height={4}
      rx={2}
      ry={2}
      width={(306.5 - 24 - 24) / 2}
      x={24}
      y={
        164 +
        16 +
        24 +
        6 +
        12 +
        24 +
        12 +
        20 +
        20 +
        10 +
        12 +
        6 +
        16 +
        16 +
        16 +
        5
      }
    />
    <rect
      height={36}
      rx={4}
      ry={4}
      width={79.5}
      x={24}
      y={
        164 +
        16 +
        24 +
        6 +
        12 +
        24 +
        12 +
        20 +
        20 +
        10 +
        12 +
        6 +
        16 +
        16 +
        16 +
        10 +
        8 +
        10 +
        5
      }
    />
    <rect
      height={36}
      rx={4}
      ry={4}
      width={79.5}
      x={24 + 79.5 + 10}
      y={
        164 +
        16 +
        24 +
        6 +
        12 +
        24 +
        12 +
        20 +
        20 +
        10 +
        12 +
        6 +
        16 +
        16 +
        16 +
        10 +
        8 +
        10 +
        5
      }
    />
    <rect
      height={36}
      rx={4}
      ry={4}
      width={79.5}
      x={24 + 79.5 + 10 + 79.5 + 10}
      y={
        164 +
        16 +
        24 +
        6 +
        12 +
        24 +
        12 +
        20 +
        20 +
        10 +
        12 +
        6 +
        16 +
        16 +
        16 +
        10 +
        8 +
        10 +
        5
      }
    />
  </ContentLoader>
);

export default CardLoader;
