import React, { useCallback, Fragment, useEffect } from "react";
import times from "lodash/times";
import { TimePicker } from "@material-ui/pickers";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useAppContext } from "../../api/AppContext";

import { getFieldProps, inputProps } from "../utils";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

let alreadyFetchedJourneyBenefitsForJourneyId = "";

const getConditionOptions = (type) => {
  if (type === "weekday") {
    return [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ].map((name, i) => ({
      name,
      value: i + 1,
    }));
  }

  if (type === "month") {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((name, i) => ({
      name,
      value: i + 1,
    }));
  }

  if (type === "day") {
    return times(31, (i) => ({
      name: i + 1,
      value: i + 1,
    }));
  }
};

const valueToEvent = (name) => (func) => (value) =>
  func({
    target: {
      name,
      value: {
        hours: value.getHours(),
        minutes: value.getMinutes(),
      },
    },
  });

const typeLabels = {
  hour: "Hour",
  weekday: "Weekday",
  day: "Day of month",
  month: "Month",
  activeDays: "Journey progress day",
  userStatePath: "User's metadata",
  survey: "Survey",
  subscribedToWelfare: "Subscribed to welfare",
};

const SpecialConditions = ({
  isDisabled,
  formik,
  days,
  defaultType = null,
  types = Object.keys(typeLabels),
  userStatePaths = [],
  surveys = [],
  journeyId,
}) => {
  const {
    fetchJourneyBenefits,
    isListJourneyBenefitsLoading,
    journeyBenefits,
  } = useAppContext();
  useEffect(() => {
    if (
      journeyId &&
      !isListJourneyBenefitsLoading &&
      alreadyFetchedJourneyBenefitsForJourneyId !== journeyId
    ) {
      fetchJourneyBenefits({ journey_id: journeyId });
      alreadyFetchedJourneyBenefitsForJourneyId = journeyId;
    }
  }, [
    journeyId,
    fetchJourneyBenefits,
    journeyBenefits,
    isListJourneyBenefitsLoading,
  ]);

  const handleTypeChange = useCallback(
    (i) => (e) => {
      const newValue = [...(formik.values.conditions || [])];
      const { condition } = formik.values.conditions?.[i] || {};
      newValue.splice(i, 1, {
        condition,
        type: e.target.value,
        ...(e.target.value === "hour"
          ? { from: null, to: null }
          : {
              values: ["hour", "activeDays", "userStatePath"].includes(
                e.target.value
              )
                ? null
                : [],
            }),
        ...(e.target.value === "survey" && { status: "completed" }),
      });
      formik.setFieldValue("conditions", newValue);
    },
    [formik]
  );

  const handleAdd = useCallback(() => {
    formik.setFieldValue("conditions", [
      ...(formik.values.conditions || []),
      {
        condition: "and",
        type: defaultType,
        values: [],
      },
    ]);
  }, [defaultType, formik]);

  const handleDelete = useCallback(
    (i) => () => {
      const newValue = [...(formik.values.conditions || [])];
      newValue.splice(i, 1);
      formik.setFieldValue("conditions", newValue);
    },
    [formik]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Special conditions</Typography>
      </Grid>
      {(formik.values.conditions || []).length === 0 && (
        <Grid item xs={12}>
          <Typography variant="body2">No special condition is set.</Typography>
        </Grid>
      )}
      {(formik.values.conditions || []).map(
        ({ type, values, condition }, i) => {
          const valuesProps = getFieldProps(formik, {
            disabled: isDisabled,
            name: `conditions.[${i}].values`,
          });

          let fromValue = getFieldProps(formik, {
            disabled: isDisabled,
            name: `conditions.[${i}].from`,
          }).value;

          const now = new Date();

          if (fromValue?.hours) {
            fromValue = new Date(
              `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()} ${
                fromValue.hours
              }:${fromValue.minutes}`
            );
          }

          let toValue = getFieldProps(formik, {
            disabled: isDisabled,
            name: `conditions.[${i}].to`,
          }).value;

          if (toValue?.hours) {
            toValue = new Date(
              `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()} ${
                toValue.hours
              }:${toValue.minutes}`
            );
          }

          return (
            <Grid key={i} item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body1">{i + 1}.</Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    label="Type"
                    value={type}
                    {...getFieldProps(formik, {
                      disabled: isDisabled,
                      name: `conditions.[${i}].type`,
                    })}
                    onChange={handleTypeChange(i)}
                  >
                    {types.map((value) => (
                      <MenuItem key={value} value={value}>
                        {typeLabels[value]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {type &&
                  ![
                    "hour",
                    "activeDays",
                    "userStatePath",
                    "survey",
                    "subscribedToWelfare",
                  ].includes(type) && (
                    <Grid item style={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        select
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) =>
                            selected
                              .sort((a, b) => a - b)
                              .map(
                                (value) =>
                                  getConditionOptions(type).find(
                                    (option) => value === option.value
                                  ).name
                              )
                              .join(", "),
                        }}
                        label={typeLabels[type]}
                        {...valuesProps}
                        value={valuesProps.value || []}
                      >
                        {getConditionOptions(type).map(({ name, value }) => (
                          <MenuItem key={value} value={value}>
                            <Checkbox
                              checked={(valuesProps.value || []).includes(
                                value
                              )}
                              color="primary"
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                {type && type === "hour" && (
                  <>
                    <Grid item style={{ flex: 1 }}>
                      <TimePicker
                        InputLabelProps={{ shrink: true }}
                        ampm={false}
                        disablePast={false}
                        label="From"
                        variant="inline"
                        views={["hours", "minutes"]}
                        {...getFieldProps(formik, {
                          disabled: isDisabled,
                          name: `conditions.[${i}].from`,
                        })}
                        value={fromValue}
                        onChange={valueToEvent(`conditions.[${i}].from`)(
                          getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].from`,
                          }).onChange
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <TimePicker
                        InputLabelProps={{ shrink: true }}
                        ampm={false}
                        disablePast={false}
                        label="To"
                        variant="inline"
                        views={["hours", "minutes"]}
                        {...getFieldProps(formik, {
                          disabled: isDisabled,
                          name: `conditions.[${i}].to`,
                        })}
                        value={toValue}
                        onChange={valueToEvent(`conditions.[${i}].to`)(
                          getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].to`,
                          }).onChange
                        )}
                      />
                    </Grid>
                  </>
                )}
                {type && type === "activeDays" && (
                  <Grid item style={{ flex: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="From"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].from`,
                          })}
                          InputProps={{
                            ...inputProps,
                            inputProps: {
                              ...inputProps.inputProps,
                              max: days + 1,
                              min: 1,
                            },
                          }}
                          type="number"
                        />
                      </Grid>
                      {fromValue === days + 1 && (
                        <Grid item>
                          <Typography variant="body1">(CP finished)</Typography>
                        </Grid>
                      )}
                      <Grid item>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="To"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].to`,
                          })}
                          InputProps={{
                            ...inputProps,
                            inputProps: {
                              ...inputProps.inputProps,
                              max: days + 1,
                              min: 1,
                            },
                          }}
                          type="number"
                        />
                      </Grid>
                      {toValue === days + 1 && (
                        <Grid item>
                          <Typography variant="body1">(CP finished)</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {type && type === "userStatePath" && (
                  <Grid item style={{ flex: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Field"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].path`,
                          })}
                          fullWidth
                          select
                        >
                          {userStatePaths
                            .sort(function (a, b) {
                              const x = a.displayName.toLowerCase();
                              const y = b.displayName.toLowerCase();
                              if (x < y) {
                                return -1;
                              }
                              if (x > y) {
                                return 1;
                              }
                              return 0;
                            })
                            .map(({ name, displayName }) => (
                              <MenuItem key={name} value={name}>
                                {displayName}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Is"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].operator`,
                          })}
                          fullWidth
                          select
                        >
                          <MenuItem value="empty">Empty</MenuItem>
                          <MenuItem value="not-empty">Populated</MenuItem>
                          <MenuItem value="equal">Equal to</MenuItem>
                          <MenuItem value="exclusiveMinimum">
                            Greater than
                          </MenuItem>
                          <MenuItem value="exclusiveMaximum">
                            Lower than
                          </MenuItem>
                          <MenuItem value="minimum">
                            Equal or greater than
                          </MenuItem>
                          <MenuItem value="maximum">
                            Equal or lower than
                          </MenuItem>
                          <MenuItem value="between">Between</MenuItem>
                        </TextField>
                      </Grid>
                      {[
                        "equal",
                        "minimum",
                        "maximum",
                        "exclusiveMinimum",
                        "exclusiveMaximum",
                      ].includes(formik.values.conditions[i].operator) && (
                        <Grid item style={{ flex: 1 }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            label="Value"
                            {...getFieldProps(formik, {
                              disabled: isDisabled,
                              name: `conditions.[${i}].values`,
                            })}
                            fullWidth
                            type={
                              formik.values.conditions[i].operator === "equal"
                                ? "text"
                                : "number"
                            }
                          />
                        </Grid>
                      )}
                      {formik.values.conditions[i].operator === "between" && (
                        <>
                          <Grid item style={{ flex: 1 }}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              label="From"
                              {...getFieldProps(formik, {
                                disabled: isDisabled,
                                name: `conditions.[${i}].from`,
                              })}
                              fullWidth
                              type="number"
                            />
                          </Grid>
                          <Grid item style={{ flex: 1 }}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              label="To"
                              {...getFieldProps(formik, {
                                disabled: isDisabled,
                                name: `conditions.[${i}].to`,
                              })}
                              fullWidth
                              type="number"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
                {type && type === "survey" && (
                  <Grid item style={{ flex: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Survey name"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].values`,
                          })}
                          fullWidth
                          select
                        >
                          {surveys.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Value"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].status`,
                          })}
                          fullWidth
                          select
                        >
                          {[
                            { value: "completed", name: "Completed" },
                            {
                              value: "not-completed",
                              name: "Not completed yet",
                            },
                            { value: "succeeded", name: "Succeeded" },
                            { value: "failed", name: "Failed" },
                          ].map(({ name, value }) => (
                            <MenuItem key={value} value={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {type && type === "subscribedToWelfare" && (
                  <Grid item style={{ flex: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item style={{ flex: 1 }}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                {isListJourneyBenefitsLoading && (
                                  <CircularProgress size={25} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          label="Welfare"
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: `conditions.[${i}].values`,
                          })}
                          fullWidth
                          select
                        >
                          {journeyBenefits
                            .filter((b) => b.usercansubscribe)
                            .map(({ id, name, description }) => (
                              <MenuItem key={id} value={id}>
                                <ListItemText
                                  primary={name}
                                  secondary={description}
                                />
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <IconButton onClick={handleDelete(i)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      )}
      <Grid item xs={12}>
        <Button
          color="primary"
          disabled={isDisabled}
          endIcon={<AddIcon />}
          variant="contained"
          onClick={handleAdd}
        >
          Add condition
        </Button>
      </Grid>
      {formik.values.conditions?.length > 1 && (
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            {formik.values.conditions.map((_, i) => (
              <Fragment key={i}>
                {i !== 0 && (
                  <Grid item>
                    <TextField
                      select
                      InputLabelProps={{ shrink: true }}
                      {...getFieldProps(formik, {
                        disabled: isDisabled,
                        name: `conditions.[${i}].condition`,
                      })}
                    >
                      <MenuItem value="and">And</MenuItem>
                      <MenuItem value="or">Or</MenuItem>
                    </TextField>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="body1">Condition {i + 1}</Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SpecialConditions;
