import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Highlighter from "react-highlight-words";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../api/AppContext";

const TypeformFieldsSelect = ({
  typeformId,
  integrationId,
  onChange,
  value,
  disabled,
  type = "string",
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { fetchTypeform, isTypeformLoading, typeform } = useAppContext();
  // const options = useMemo(
  //   () => typeform?.fields.map(({ id, title }) => ({ id, title })) ?? [],
  //   [typeform]
  // );

  const options = useMemo(
    () =>
      typeform
        ? Object.entries(typeform.variables)
            .filter(([, value]) => type === typeof value)
            .map(([key]) => ({ id: key, title: key }))
        : [],
    [type, typeform]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    fetchTypeform({ id: typeformId, integrationId });
  }, [integrationId, isOpen, typeformId]);

  const handleChange = useCallback(
    (e, value, reason) => {
      setIsOpen(false);
      if (!["select-option"].includes(reason)) {
        return;
      }

      onChange?.(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableClearable
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => option.title}
      loading={isTypeformLoading}
      open={isOpen}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isTypeformLoading ? (
              <InputAdornment position="end">
                {isTypeformLoading && <CircularProgress size={25} />}
              </InputAdornment>
            ) : (
              params.InputProps?.startAdornment
            ),
          }}
          disabled={disabled}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.title}
            >
              {option.title}
            </Highlighter>
          }
        />
      )}
      value={options.find((option) => option.id === value?.id) ?? value}
      onChange={handleChange}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    />
  );
};

export default TypeformFieldsSelect;
