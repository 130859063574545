import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "../shared/Dialog";
import DialogActions from "../DialogActions";
import useConfirmLeave from "../../hooks/useConfirmLeave";
import useModal from "../../hooks/useModal";
import { getFieldProps, inputProps, multilineInputProps } from "../utils";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const editSchema = yup.object().shape({
  description: yup.string().required(),
  isPublished: yup.boolean(),
  name: yup.string().required(),
  days: yup.number().min(1).max(100).required(),
});

const EditStepModal = ({
  onClose,
  step,
  onConfirm,
  stepIndex,
  isNew = false,
}) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });
  const formik = useFormik({
    initialValues: step,
    onSubmit: handleConfirm,
    validationSchema: editSchema,
    validator: () => ({}),
  });

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isFinite(stepIndex) ? `Edit Step ${stepIndex + 1}` : `Add Step`}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Name"
              margin="none"
              placeholder="Step name..."
              variant="outlined"
              {...getFieldProps(formik, { name: "name" })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              InputProps={multilineInputProps}
              label="Description"
              margin="none"
              placeholder="Step description..."
              rows={5}
              variant="outlined"
              {...getFieldProps(formik, { name: "description" })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...inputProps,
                inputProps: {
                  ...inputProps,
                  min: 1,
                },
              }}
              label="Length (Days)"
              margin="none"
              placeholder="Step length..."
              type="number"
              variant="outlined"
              {...getFieldProps(formik, { name: "days", disabled: !isNew })}
            />
          </Grid>

          {isNew && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      getFieldProps(formik, { name: "isPublished" }).value
                    }
                    color="primary"
                    disabled={formik.isSubmitting || !step.isPublished}
                    name="isPublished"
                    onChange={
                      getFieldProps(formik, { name: "isPublished" }).onChange
                    }
                  />
                }
                label="Published"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStepModal;
