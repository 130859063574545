import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  ClickAwayListener,
  TextField,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@material-ui/icons/Search";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";

import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppContext } from "../../api/AppContext";

import { useFormik } from "formik";

const WECARE_IMPERSONATE_URL = "https://wecare.angeliniindustries.com";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

const CustomDropdown = ({ options, onSelect, loading, values }) => {
  return (
    <Paper
      style={{
        height: "200px",
        maxHeight: "200px",
        overflowY: "auto",
        position: "absolute",
        width: "100%",
        zIndex: 1,
      }}
    >
      {loading ? (
        <LoaderContainer>
          <CircularProgress size={30} />
        </LoaderContainer>
      ) : options.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.id}
            selected={values.some((item) => item.id === option.id)}
            onClick={() => onSelect(option)}
          >
            {option.mail ?? `${option.surname}, ${option.givenname}`}
          </MenuItem>
        ))
      ) : (
        <LoaderContainer>
          Oops.. There&apos;s no result for your search :(
        </LoaderContainer>
      )}
    </Paper>
  );
};

const Search = ({
  onChange,
  value,
  isDisabled,
  isLoading,
  options,
  onSelect,
  selectedValues,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClear = useCallback(() => {
    onChange({
      target: {
        value: "",
      },
    });
  }, [onChange]);

  useEffect(() => {
    const listener = (e) => {
      if ((e.key === "f" || e.key === "F") && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        ref.current?.focus();
      }
    };
    window.addEventListener("keypress", listener);
    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, []);

  const handleSelectOption = (selectedOption) => {
    onSelect(selectedOption);
    setShowDropdown(false);
    handleClear();
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative" }}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            inputComponent: DebounceInput,
            inputProps: {
              debounceTimeout: 500,
            },
          }}
          disabled={isDisabled}
          inputRef={ref}
          margin="none"
          placeholder="Pick a user..."
          size="small"
          style={{
            ...theme.typography.body2,
            width: "100%",
          }}
          value={value}
          variant="outlined"
          onChange={onChange}
          onClick={handleToggleDropdown}
        />
        {value && showDropdown && (
          <CustomDropdown
            loading={isLoading}
            options={options}
            values={selectedValues}
            onSelect={handleSelectOption}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

const WelfareSimulate = () => {
  // const history = useHistory();

  const { isAngeliniUsersLoading, fetchAngeliniUsers } = useAppContext();
  const [searchText, setSearchText] = useState("");
  const [angeliniUsers, setAngeliniUsers] = useState([]);

  const validationSchema = yup.object().shape({
    selectedUsers: yup.array(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedUsers: [],
    },
    onSubmit: async (values) => {
      window.open(
        `${WECARE_IMPERSONATE_URL}?impid=${values.selectedUsers[0].id}`,
        "_blank"
      );
    },
    validationSchema,
    validator: () => ({}),
  });

  const handleSkipLandingButtonClick = function () {
    window.open(
      `${WECARE_IMPERSONATE_URL}?impid=${formik.values.selectedUsers[0].id}&skipLanding=true`,
      "_blank"
    );
  };

  useEffect(() => {
    async function fetchData() {
      const dataObject = { searchtext: searchText };
      const users = await fetchAngeliniUsers(dataObject);
      // For tests
      // const users = [
      //   {
      //     id: "ae0ca328-e946-4b2f-812e-a0131ee8ad12",
      //     givenname: "Andrea",
      //     surname: "M",
      //     mail: "andream@test.com",
      //   },
      //   {
      //     id: "7689c798-a8a3-42f5-824f-b717d04b19c2",
      //     givenname: "Andrea",
      //     surname: "Di",
      //     mail: "andreaDi@test.com",
      //   },
      //   {
      //     id: "b2cf6d36-d409-42ab-9eca-6ac20600a5d5",
      //     givenname: "Andrea Mario",
      //     surname: "Bai",
      //     mail: "abai@test.it",
      //   },
      // ];
      setAngeliniUsers(users);
    }
    if (searchText && searchText.length > 3) fetchData();
  }, [fetchAngeliniUsers, searchText]);

  const handleSearch = useCallback(
    (e) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );

  const cancelSearch = () => {
    setSearchText("");
    setAngeliniUsers([]);
  };

  const handleOnSelect = useCallback((item) =>
    formik.setFieldValue("selectedUsers", [item])
  );
  // const handleOnSelect = useCallback(
  //   (item) => {
  //     let userAlreadyOnArray = 0;
  //     if (currentTabIndex === 0) {
  //       const enabledUsersCopy = formik.values.enabledUsers || [];

  //       userAlreadyOnArray = enabledUsersCopy.findIndex(
  //         (elem) => elem.id === item.id
  //       );
  //       if (!enabledUsersCopy.length || userAlreadyOnArray === -1) {
  //         formik.setFieldValue("enabledUsers", [...enabledUsersCopy, item]);
  //       }
  //     }
  //     if (currentTabIndex === 1) {
  //       const disabledUsersCopy = formik.values.disabledUsers || [];
  //       userAlreadyOnArray = disabledUsersCopy.findIndex(
  //         (elem) => elem.id === item.id
  //       );
  //       if (!disabledUsersCopy.length || userAlreadyOnArray === -1) {
  //         formik.setFieldValue("disabledUsers", [...disabledUsersCopy, item]);
  //       }
  //     }
  //   },
  //   [currentTabIndex, formik]
  // );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      {" "}
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Simulate/Impersonate</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                Choose a user and see Wecare user dashboard as he/she would see
                it
              </Typography>
            </Grid>
            <br></br>
            <Grid container direction="column" spacing={4}>
              <Grid item style={{ width: "100%" }} xs={4}>
                <Search
                  isDisabled={false}
                  isLoading={isAngeliniUsersLoading}
                  options={angeliniUsers}
                  selectedValues={formik.values.selectedUsers}
                  value={searchText}
                  onCancelSearch={cancelSearch}
                  onChange={handleSearch}
                  onSelect={handleOnSelect}
                />
              </Grid>
              {formik.values.selectedUsers.length > 0 && (
                <Grid item style={{ padding: 50 }} xs={6}>
                  <Grid item>
                    <Typography variant="body1">
                      Selected user:{" "}
                      <b>{formik.values.selectedUsers[0]?.mail}</b>
                    </Typography>
                    {/* <Typography variant="subtitle2">
                      Note: click this button and login with your personal
                      account
                    </Typography> */}
                  </Grid>
                  <br></br>
                  <Grid item>
                    <Tooltip
                      title="Note: access Wecare dashboard using this link and your personal
                      account"
                    >
                      <Button
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        variant="contained"
                        onClick={formik.handleSubmit}
                      >
                        See this user&apos;s dashboard
                      </Button>
                    </Tooltip>
                  </Grid>
                  <br></br>
                  <Grid item>
                    <Tooltip title="Note: same as before, except this time temporary landing page will be skipped">
                      <Button
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        variant="contained"
                        onClick={handleSkipLandingButtonClick}
                      >
                        Same, with skip landing page
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelfareSimulate;
