import React, { useCallback, useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import useLoading from "../../useLoading";
import { CircularProgress } from "@material-ui/core";
import { inputProps, multilineInputProps } from "../utils";

const ConfirmableInput = ({
  onChange,
  onCancel,
  value: initialValue,
  ...props
}) => {
  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleCancel = useCallback(() => {
    setValue(initialValue);
    onCancel?.();
  }, [initialValue, onCancel]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const { isLoading, handler: handleConfirm } = useLoading(
    useCallback(
      () => typeof onChange === "function" && onChange(value),
      [onChange, value]
    )
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
        return handleConfirm(value);
      }

      if (e.key === "Escape") {
        return handleCancel();
      }
    },
    [handleCancel, handleConfirm, value]
  );

  const handleBlur = useCallback(() => {
    if (initialValue === value) {
      handleCancel();
    }
  }, [handleCancel, initialValue, value]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      {...props}
      InputProps={{
        ...(props.multiline ? multilineInputProps : inputProps),
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <CircularProgress size={25} />
            ) : (
              value !== initialValue && (
                <>
                  <Tooltip title="Save">
                    <IconButton size="small" onClick={handleConfirm}>
                      <DoneIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton size="small" onClick={handleCancel}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              )
            )}
          </InputAdornment>
        ),
      }}
      disabled={isLoading || props.disabled}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default ConfirmableInput;
