import React, { useCallback } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { getFieldProps, inputProps } from "../../utils";
// import { Link } from "react-router-dom";
import ImageUpload from "../../ImageUpload";
import MultiSelect from "../../shared/MultiSelect";
import InfoTooltip from "../../InfoTooltip";

const PILLARS = [
  { id: "health", name: "Health" },
  { id: "people", name: "People" },
  { id: "family", name: "Family" },
];

const FirstInfo = ({ formik, welfareCategories, welfareSubcategories }) => {
  const buildTextField = (formik, placeholder, name, required = false) => {
    return (
      <>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={placeholder}
          margin="none"
          required={required}
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </>
    );
  };

  const handleChange = useCallback(
    (value, field) => {
      formik.setFieldValue(field, value);
      formik.setFieldTouched(field, true);
    },
    [formik]
  );

  const handleImageChange = useCallback(
    ({ url }) => {
      formik.setFieldValue(`image`, url);
      formik.setFieldTouched(`image`, true);
    },
    [formik]
  );

  const handleImageReset = useCallback(() => {
    formik.setFieldValue("image", null);
    formik.setFieldTouched("image", true);
  }, [formik]);

  const handlePreviewImageChange = useCallback(
    ({ url }) => {
      formik.setFieldValue(`previewImage`, url);
      formik.setFieldTouched(`previewImage`, true);
    },
    [formik]
  );

  const handlePreviewImageReset = useCallback(() => {
    formik.setFieldValue("previewImage", null);
    formik.setFieldTouched("previewImage", true);
  }, [formik]);

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          In this section you will need to upload the appearance of your
          benefit!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item>
          <Typography variant="h6">
            Here are the pillars of <b>WECARE</b>:
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            <ul>
              <li>
                <b>Health:</b> It concerns the physical or mental well-being,
                wellness, or care of your employees.
              </li>
              <li>
                <b>People:</b> It encompasses your employees from daily work to
                training, and even the small gestures of attention.
              </li>
              <li>
                <b>Family:</b> It pertains to the family context, including
                benefits that can only be used if you have children or have a
                direct impact on parents, grandparents, or partners.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
        xs={12}
      >
        <Grid item style={{ marginBottom: "1rem" }}>
          <Typography variant="h6">
            Select one, two, or all three pillars if you see it appropriate
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MultiSelect
              required
              {...getFieldProps(formik, {
                name: "pillars",
              })}
              items={PILLARS}
              label={"Pillars"}
              onChange={(value) => handleChange(value, "pillars")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item>
          <Typography variant="body2">
            Here are the categories of WECARE
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <ul>
              <li>
                <b>Conventions and Flexible Benefits:</b> You will find the
                subcategories Conventions (for all general conventions),
                Flexible Benefits Portal (AON), and industry Company Shops.
              </li>
              <li>
                <b>Value Initiatives:</b> Temporary opportunities, with a focus
                on time and temporary benefits, and Precious Moments,
                emphasizing the emotion and uniqueness of those moments.
              </li>
              <li>
                <b>Health and Welfare:</b> Healthcare for the entire medical
                field, Well-being e for both physical and mental well-being, and
                Retirement plans for the future.
              </li>
              <li>
                <b>Development and Training:</b> You will find Angelini Academy
                for executives, Angelini Training for training opportunities
                available to everyone, and Scholarships for children.
              </li>
              <li>
                <b>Services and Workplace Flexibility:</b> This category
                includes benefits specifically related to working at Angelini,
                such as Mobility (e.g., parking), On-Site Services (e.g., the
                restaurant), Flexibility (e.g., smart working), and Financial
                Services (e.g., tax assistance and loans).
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item style={{ marginBottom: "1rem" }}>
            <Typography variant="h6">
              Select the category to which the benefit belongs
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                select
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Category"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "category_id" })}
              >
                {welfareCategories.map((elem, idx) => (
                  <MenuItem key={idx} value={elem.category_id}>
                    {elem.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                select
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Subcategory"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  name: "subcategory_id",
                  disabled:
                    !formik.values.category_id ||
                    !welfareSubcategories.filter(
                      (elem) => elem.category_id === formik.values.category_id
                    ).length,
                })}
              >
                {welfareSubcategories
                  .filter(
                    (elem) => elem.category_id === formik.values.category_id
                  )
                  .map((elem, idx) => (
                    <MenuItem key={idx} value={elem.subcategory_id}>
                      {elem.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          {/* <Grid item style={{ marginTop: "1rem" }}>
            <Typography color="textSecondary" variant="body2">
              Do you think there is a benefit cross between categories ?{" "}
              <Link>Click here</Link>
            </Typography>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography variant="h6">
              Now the name of the benefit is needed! Be sure to make it engaging
              and intuitive! (max 40 characters)
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Default language",
                `text_default.name`,
                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {buildTextField(formik, "English", `text_en.name`)}
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography variant="h6">
              Determine a name for this welfare that will be used in reporting.
              Reuse the same name for similar benefits across different entities
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {buildTextField(formik, "Reporting name", `analytics_name`)}
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography variant="h6">
              Add a brief description of the benefit (max 100 characters)
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {buildTextField(
                formik,
                "Default language",
                `text_default.description`,
                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {buildTextField(formik, "English", `text_en.description`)}
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography variant="h6">
              Determine a name for this welfare that will be used in reporting.
              Reuse the same name for similar benefits across different entities
            </Typography>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              {buildTextField(formik, "Default language", `analytics_name`)}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h6"> Upload the cover image!</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="Upload .jpg, .png or .jpeg files. The uploaded picture will be displayed with 1200px width and 250px height.
                  In case of size mismatch, the picture will be scaled horizontally to 1200px and cropped vertically (a central stripe of 250px height will be taken)"
                  title="Image Dimensions"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <ImageUpload
              buttonProps={{
                children: "Upload",
              }}
              caption={
                <Typography
                  color="textSecondary"
                  style={{ color: "#BFBFBF" }}
                  variant="body2"
                >
                  Upload single benefit cover image (.png, .jpg, .jpeg)
                </Typography>
              }
              containerStyles={{ height: 150 }}
              customPathInfo={{ cmp: formik.values?.cmp }}
              errorMessage={getFieldProps(formik, { name: "image" }).helperText}
              maxSize={1.5 * 1024 * 1024} //1.5MB
              styles={{ maxHeight: 150, width: "unset" }}
              value={formik.values.image}
              onChange={handleImageChange}
              onReset={handleImageReset}
            />
          </Grid>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }} xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h6">
                  {" "}
                  Finally, upload the benefit preview image!
                </Typography>
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="Upload .jpg, .png or .jpeg files. The uploaded picture will be displayed with 170px width and 170px height.
                  In case of size mismatch, the picture will be scaled to fit the size.
                  "
                  title="Image Dimensions"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <ImageUpload
              caption={
                <Typography
                  color="textSecondary"
                  style={{ color: "#BFBFBF" }}
                  variant="body2"
                >
                  Upload single benefit preview image (.png, .jpg, .jpeg)
                </Typography>
              }
              containerStyles={{ height: 150 }}
              customPathInfo={{ cmp: formik.values?.cmp }}
              errorMessage={
                getFieldProps(formik, { name: "previewImage" }).helperText
              }
              styles={{ maxHeight: 150, width: "unset" }}
              value={formik.values.previewImage}
              onChange={handlePreviewImageChange}
              onReset={handlePreviewImageReset}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirstInfo;
