import VisibilitySensor from "react-visibility-sensor-v2";
import { useCallback, useState } from "react";

import Loader from "./shared/Loader";

const ViewPortRender = ({ children, width, height, loader }) => {
  const [isActive, setIsActive] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = useCallback(
    (value) => {
      if (isVisible) {
        return;
      }
      setIsVisible(value);

      if (value) {
        setIsActive(false);
      }
    },
    [isVisible]
  );

  return (
    <VisibilitySensor
      partialVisibility
      active={isActive}
      onChange={handleChange}
    >
      {isVisible ? (
        children
      ) : (
        <div style={{ height, width }}>{loader || <Loader inline />}</div>
      )}
    </VisibilitySensor>
  );
};

export default ViewPortRender;
