import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash/debounce";

const Input = ({
  maxLength,
  helperText,
  value = "",
  onChange,
  name,
  label,
  ...props
}) => {
  const [_value, setValue] = useState(value);
  const onChangeDebounced = useMemo(
    () => onChange && debounce(onChange, 500),
    [onChange]
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e) => {
      const newValue = maxLength
        ? e.target.value.slice(0, maxLength)
        : e.target.value;
      setValue(newValue);
      if (!onChangeDebounced) {
        return;
      }

      onChangeDebounced({
        target: {
          name,
          value: newValue,
        },
      });
    },
    [maxLength, onChangeDebounced, name]
  );

  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: true }}
      helperText={
        helperText ||
        (maxLength ? `${value.length}/${maxLength} characters max` : undefined)
      }
      label={label}
      name={name}
      value={_value}
      variant="outlined"
      onChange={handleChange}
      {...props}
    />
  );
};

export default Input;
