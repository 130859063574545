import React, { useEffect, useState } from "react";
import ReactCopyToClipboard from "react-copy-to-clipboard";

const CopyToClipboardBase = ({ text, children }) => {
  const [isCopied, setIsCopied] = useState(false);
  let timer = null;

  useEffect(() => () => timer && clearTimeout(timer), [timer]);

  const onCopy = () => {
    setIsCopied(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      {typeof children === "function" ? children({ isCopied }) : children}
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboardBase;
