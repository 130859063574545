import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppContext } from "../../api/AppContext";

const AdminRoute = (routeProps) => (
  <Route
    {...routeProps}
    component={(props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { user } = useAppContext();

      if (!user.groups.includes("Admins")) {
        return <Redirect to="/" />;
      }

      const { component: Component } = routeProps;
      return <Component {...props} />;
    }}
  />
);

export default AdminRoute;
