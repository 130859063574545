const getCookie = function (cookieName) {
  const c = document.cookie.split("; ");
  const cookies = {};

  for (let i = c.length - 1; i >= 0; i--) {
    const C = c[i].split("=");
    cookies[C[0]] = C[1];
  }

  return cookies[cookieName];
};

export { getCookie };
