import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { DebounceInput } from "react-debounce-input";

const getFieldProps = (
  formik,
  { name, disabled, helperText, defaultValue }
) => ({
  disabled: formik.isSubmitting || disabled,
  error: Boolean(get(formik.touched, name) && get(formik.errors, name)),
  helperText:
    (get(formik.touched, name) && get(formik.errors, name)) || helperText,
  name,
  onBlur: formik.handleBlur,
  onChange: (e) => {
    if (e.target.value === get(formik.values, e.target.name)) {
      return;
    }

    if (!e.target.name.includes(".")) {
      formik.handleChange(e);
      return;
    }

    const [rootFieldName, ...path] = e.target.name.split(".");
    const newValue = cloneDeep(formik.values[rootFieldName]);
    let value = e.target.value;

    if (e.target.type === "number") {
      value = parseFloat(value) || 0;
    }

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    set(newValue, path.join("."), value);
    formik.setFieldValue(rootFieldName, newValue);
    formik.setFieldTouched(rootFieldName, true);
  },
  value: get(
    formik.values,
    name,
    typeof defaultValue !== "undefined" ? defaultValue : ""
  ),
});

const inputProps = {
  inputComponent: DebounceInput,
  inputProps: {
    debounceTimeout: 100,
  },
};

const multilineInputProps = {
  ...inputProps,
  inputProps: {
    ...inputProps.inputProps,
    element: "textarea",
  },
};

const downloadAsCSV = (
  data,
  { filename = "download", delimiter = "," } = {}
) => {
  const columns = Object.keys(data[0]);
  const rows = data.map((row) =>
    columns
      .map((column) => (row[column] === undefined ? "" : row[column]))
      .join(delimiter)
  );
  const csv = [columns.join(delimiter), ...rows].join("\n");

  let csvData = new Blob([csv], { type: "text/csv" });
  let csvUrl = URL.createObjectURL(csvData);

  let link = document.createElement("a");
  link.href = csvUrl;
  link.target = "_blank";
  link.download = `${filename}.csv`;
  link.click();
  link.remove();
};

const valueToEvent = (name) => (func) => (value) =>
  func({ target: { name, value } });

const getIntersectedIntervals = (intervals) => {
  const orderedByFrom = orderBy(intervals, ["0"], ["ASC"]);

  return orderedByFrom.filter(
    ([from, to], i, intervals) => intervals[i + 1] && to >= intervals[i + 1][0]
  );
};

const removeEmpty = function (obj, removeAlsoEmptyStrings = true) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) =>
        removeAlsoEmptyStrings ? v != null && v !== "" : v != null
      )
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
};

export {
  getFieldProps,
  inputProps,
  multilineInputProps,
  downloadAsCSV,
  valueToEvent,
  removeEmpty,
  getIntersectedIntervals,
};
