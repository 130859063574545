import * as yup from "yup";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import useConfirmLeave from "./../hooks/useConfirmLeave";
import useModal from "./../hooks/useModal";
import { getFieldProps, inputProps } from "./utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import MultiSelect from "./shared/MultiSelect";

const editSchema = yup.object().shape({
  description: yup.string().nullable(),
  displayName: yup
    .string()
    .min(3)
    .required()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9 ]+$/g,
      "* This field should start with a char and only contain simple chars, numbers, spaces"
    ),
  setTriggerForMetadata: yup.bool().nullable(),
  selectedTriggersForMetadata: yup.array().when("setTriggerForMetadata", {
    is: (value) => value === true,
    then: yup
      .array()
      .required("Triggers is a required field")
      .of(yup.string())
      .min(1, "Select at least one trigger"),
    otherwise: yup.array().nullable(),
  }),
});

const EditUserMetaModal = ({
  item,
  onClose,
  onConfirm,
  isNew = false,
  triggers,
}) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });

  const formik = useFormik({
    initialValues: isNew
      ? {
          displayName: "",
          description: "",
          setTriggerForMetadata: false,
          selectedTriggersForMetadata: null,
        }
      : item,
    onSubmit: handleConfirm,
    validationSchema: editSchema,
    validator: () => ({}),
  });

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isNew ? "Add User's metatadata" : "Edit User's metatadata"}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} style={{ alignItems: "center" }}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Display name; single word"
              margin="none"
              placeholder="Name displayed in selection interface"
              variant="outlined"
              {...getFieldProps(formik, { name: "displayName" })}
            />
          </Grid>
          {isNew && (
            <Grid item xs={6}>
              <Typography color="textSecondary" variant="body2">
                Resulting key:{" "}
                {"CUSTOM_" + formik.values.displayName.trim().replace(/ /g, "")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Description"
              margin="none"
              placeholder="ex.: what do you plan to use this field for?"
              variant="outlined"
              {...getFieldProps(formik, { name: "description" })}
            />
          </Grid>
          <Grid item xs={12}>
            <TriggersForMetadata formik={formik} triggers={triggers} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TriggersForMetadata = ({ formik, triggers }) => {
  const handleTriggersChange = useCallback(
    (value) => {
      formik.setFieldValue("selectedTriggersForMetadata", value);
      formik.setFieldTouched("selectedTriggersForMetadata", true);
    },
    [formik]
  );

  useEffect(() => {
    if (!formik.values.setTriggerForMetadata) {
      formik.setFieldValue("selectedTriggersForMetadata", []);
      formik.setFieldTouched("selectedTriggersForMetadata", true);
    }
  }, [formik.values.setTriggerForMetadata]);
  return (
    <Grid container spacing={4} style={{ alignItems: "center" }}>
      <Grid item md={6} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.setTriggerForMetadata}
              color="primary"
              name="setTriggerForMetadata"
              onChange={formik.handleChange}
            />
          }
          label='Automatically set this field to "1" whenever any of these triggers is triggered '
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {formik.values.setTriggerForMetadata && (
          <MultiSelect
            required
            {...getFieldProps(formik, {
              name: "selectedTriggersForMetadata",
            })}
            items={triggers}
            label="Triggers"
            onChange={handleTriggersChange}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditUserMetaModal;
