import { useCallback, useEffect } from "react";
import { useModals } from "./useModals";

const useConfirmLeave = ({ formik, onClose } = {}) => {
  const modals = useModals();

  useEffect(() => {
    const listener = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, []);

  const handleClose = useCallback(async () => {
    if (!formik.dirty || formik.values.isNew) {
      return onClose();
    }

    await formik.validateForm();

    if (formik.isValid) {
      onClose();
      modals.openConfirmation({
        closeCTATitle: "Leave",
        confirmCTATitle: "Save and Leave",
        onConfirm: formik.handleSubmit,
        text: "Changes you made so far will not be saved.",
        title: "Quit editing?",
      });
      return;
    }

    modals.openConfirmation({
      closeCTATitle: "Cancel",
      confirmCTATitle: "Leave",
      onConfirm: onClose,
      text: "Changes you made so far will not be saved. Make sure to fill the compulsory fields.",
      title: "Quit editing?",
    });
  }, [formik, modals, onClose]);

  return {
    onClose: handleClose,
  };
};

export default useConfirmLeave;
