// Note: [. in path is a request to find an object with that field key and value in the array;
// Note: paths are always expected to end in .string
// That is a custom path specification sytanx (could have used jsonpath and jsonpatch in alternative)
// Note: "da_id": "dropdown", is added as a placeholder for possible dropdown
/* eslint-disable prettier/prettier */
const teamsSemanticTemplates = {
  "crd-1c51bc52-ea39-41cf-beba-3dfb44f84744": {
    templateId: "crd-1c51bc52-ea39-41cf-beba-3dfb44f84744",
    templateName: "Card 1A",
    tFamilyId: "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
    cardJson: {
      type: "AdaptiveCard",
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      version: "1.5",
      body: [
        {
          da_id: "mainImage",
          type: "Image",
          url: "",
          horizontalAlignment: "center",
          height: "180px",
        },
        {
          da_id: "title",
          type: "TextBlock",
          text: "",
          wrap: true,
          size: "Large",
          weight: "Bolder",
          color: "Default",
          horizontalAlignment: "center",
        },
        {
          da_id: "text",
          type: "TextBlock",
          text: "",
          wrap: true,
          color: "Default",
          horizontalAlignment: "Left",
        },
        {
          da_id: "dropdown",
          type: "TextBlock",
        },
        {
          type: "ActionSet",
          actions: [],
          horizontalAlignment: "left",
        },
      ],
      backgroundImage: {
        url: "",
      },
    },
    customVars: {
      actions: {
        path: "body[.type=ActionSet.actions",
      },
      dropdown: {
        path: "body[.da_id=dropdown.type",
      },
      pathSeparator: ".",
      pathFindSeparator: "[.",
    },
    userCustomizable: {
      mainImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.url",
          },
        ],
        title: "Main image",
        description: "applied above the title",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      backgroundImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "backgroundImage.url",
          },
        ],
        title: "Background image",
        description: "applied as a card background",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      imageHeight: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.height",
          },
        ],
        title: "Main image height",
        description: "main image height",
        type: "string",
        default: "180px",
        allowed: true,
        required: false,
      },
      imageWidth: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.width",
          },
        ],
        title: "Main image width",
        description: "main image width",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.text",
          },
        ],
        title: "title",
        description: "title",
        type: "string",
      },
      titleColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.color",
          },
        ],
        title: "title color",
        description: "title color",
        type: "string",
        default: "Default",
      },
      titleSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.size",
          },
        ],
        title: "title size",
        description: "title size",
        type: "string",
        default: "Large",
      },
      text: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.text",
          },
        ],
        title: "text",
        description: "text",
        type: "string",
      },
      textColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.color",
          },
        ],
        title: "text color",
        description: "text color",
        type: "string",
        default: "Default",
      },
      textSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.size",
          },
        ],
        title: "text size",
        description: "text size",
        type: "string",
        default: "Default",
      },
    },
    isPublished: true, // available in selectable catalogue
    copiedFrom: "",
  },
  "crd-e9e05ff3-3e6d-48ee-b411-eeb7e7224a60": {
    templateId: "crd-e9e05ff3-3e6d-48ee-b411-eeb7e7224a60",
    templateName: "Card 1B",
    tFamilyId: "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
    cardJson: {
      type: "AdaptiveCard",
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      version: "1.5",
      body: [
        {
          da_id: "title",
          type: "TextBlock",
          text: "",
          wrap: true,
          size: "Large",
          weight: "Bolder",
          color: "Default",
          horizontalAlignment: "center",
        },
        {
          da_id: "mainImage",
          type: "Image",
          url: "",
          horizontalAlignment: "Center",
          height: "180px",
        },
        {
          da_id: "text",
          type: "TextBlock",
          text: "",
          wrap: true,
          color: "Default",
          horizontalAlignment: "Left",
        },
        {
          da_id: "dropdown",
          type: "TextBlock",
        },
        {
          type: "ActionSet",
          actions: [],
          horizontalAlignment: "left",
        },
      ],
      backgroundImage: {
        url: "",
      },
    },
    customVars: {
      actions: {
        path: "body[.type=ActionSet.actions",
      },
      dropdown: {
        path: "body[.da_id=dropdown.type",
      },
      pathSeparator: ".",
      pathFindSeparator: "[.",
    },
    userCustomizable: {
      // Note: currently mainImage is outside of images object
      mainImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.url",
          },
        ],
        title: "Main image",
        description: "applied above the title",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      backgroundImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "backgroundImage.url",
          },
        ],
        title: "Background image",
        description: "applied as a card background",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      imageHeight: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.height",
          },
        ],
        title: "Main image height",
        description: "main image height",
        type: "string",
        default: "180px",
        allowed: true,
        required: false,
      },
      imageWidth: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=mainImage.width",
          },
        ],
        title: "Main image width",
        description: "main image width",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.text",
          },
        ],
        title: "title",
        description: "title",
        type: "string",
      },
      titleColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.color",
          },
        ],
        title: "title color",
        description: "title color",
        type: "string",
        default: "Default",
      },
      titleSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=title.size",
          },
        ],
        title: "title size",
        description: "title size",
        type: "string",
        default: "Large",
      },
      text: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.text",
          },
        ],
        title: "text",
        description: "text",
        type: "string",
      },
      textColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.color",
          },
        ],
        title: "text color",
        description: "text color",
        type: "string",
        default: "Default",
      },
      textSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.da_id=text.size",
          },
        ],
        title: "text size",
        description: "text size",
        type: "string",
        default: "Default",
      },
    },
    isPublished: true, // available in selectable catalogue
    copiedFrom: "",
  },
  "crd-3c08cdb8-877a-4d62-a292-71d0fe3b1e5c": {
    templateId: "crd-3c08cdb8-877a-4d62-a292-71d0fe3b1e5c",
    templateName: "Card 2A",
    tFamilyId: "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
    cardJson: {
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      type: "AdaptiveCard",
      version: "1.5",
      body: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              da_id: "c1",
              width: "stretch",
              items: [
                {
                  da_id: "title",
                  type: "TextBlock",
                  text: "",
                  weight: "Bolder",
                  size: "Large",
                  wrap: true,
                },
                {
                  da_id: "text",
                  type: "TextBlock",
                  text: "",
                  isSubtle: true,
                  spacing: "ExtraLarge",
                  wrap: true,
                },
                {
                  da_id: "dropdown",
                  type: "TextBlock",
                },
              ],
            },
            {
              type: "Column",
              da_id: "c2",
              width: "auto",
              items: [
                {
                  da_id: "mainImage",
                  type: "Image",
                  url: "",
                  horizontalAlignment: "Right",
                  size: "auto",
                  height: "180px",
                },
              ],
            },
          ],
        },
        {
          type: "ActionSet",
          actions: [],
          horizontalAlignment: "Left",
        },
      ],
      backgroundImage: {
        url: "",
      },
    },
    customVars: {
      actions: {
        path: "body[.type=ActionSet.actions",
      },
      dropdown: {
        path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=dropdown.type",
      },
      pathSeparator: ".",
      pathFindSeparator: "[.",
    },
    userCustomizable: {
      // Note: currently mainImage is outside of images object
      mainImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=mainImage.url",
          },
        ],
        title: "Main image",
        description: "applied on the right column",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      backgroundImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "backgroundImage.url",
          },
        ],
        title: "Background image",
        description: "applied as a card background",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      imageHeight: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=mainImage.height",
          },
        ],
        title: "Main image height",
        description: "main image height",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      imageWidth: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=mainImage.width",
          },
        ],
        title: "Main image width",
        description: "main image width",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=title.text",
          },
        ],
        title: "title",
        description: "title",
        type: "string",
      },
      titleColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=title.color",
          },
        ],
        title: "title color",
        description: "title color",
        type: "string",
        default: "Default",
      },
      titleSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=title.size",
          },
        ],
        title: "title size",
        description: "title size",
        type: "string",
        default: "Large",
      },
      text: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=text.text",
          },
        ],
        title: "text",
        description: "text",
        type: "string",
      },
      textColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=text.color",
          },
        ],
        title: "text color",
        description: "text color",
        type: "string",
        default: "Default",
      },
      textSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=text.size",
          },
        ],
        title: "text size",
        description: "text size",
        type: "string",
        default: "Default",
      },
    },
    isPublished: true, // available in selectable catalogue
    copiedFrom: "",
  },
  "crd-ec2a2801-9b85-44fc-96ed-859ab148df49": {
    templateId: "crd-ec2a2801-9b85-44fc-96ed-859ab148df49",
    templateName: "Card 2B",
    tFamilyId: "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
    cardJson: {
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      type: "AdaptiveCard",
      version: "1.5",
      body: [
        {
          type: "ColumnSet",
          columns: [
            {
              da_id: "c1",
              type: "Column",
              width: "Stretch",
              items: [
                {
                  da_id: "mainImage",
                  type: "Image",
                  url: "",
                  horizontalAlignment: "Center",
                  size: "Auto",
                  height: "180px",
                },
              ],
            },
            {
              type: "Column",
              da_id: "c2",
              width: "stretch",
              items: [
                {
                  da_id: "title",
                  type: "TextBlock",
                  text: "",
                  weight: "Bolder",
                  size: "Large",
                  wrap: true,
                },
                {
                  da_id: "text",
                  type: "TextBlock",
                  text: "",
                  isSubtle: true,
                  spacing: "ExtraLarge",
                  wrap: true,
                },
                {
                  da_id: "dropdown",
                  type: "TextBlock",
                },
              ],
            },
          ],
        },
        {
          type: "ActionSet",
          actions: [],
          horizontalAlignment: "Left",
        },
      ],
      backgroundImage: {
        url: "",
      },
    },
    customVars: {
      actions: {
        path: "body[.type=ActionSet.actions",
      },
      dropdown: {
        path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=dropdown.type",
      },
      pathSeparator: ".",
      pathFindSeparator: "[.",
    },
    userCustomizable: {
      // Note: currently mainImage is outside of images object
      mainImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=mainImage.url",
          },
        ],
        title: "Main image",
        description: "applied on the right column",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      backgroundImage: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "backgroundImage.url",
          },
        ],
        title: "Background image",
        description: "applied as a card background",
        type: "image",
        default: null,
        allowed: true,
        required: false,
      },
      imageHeight: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=mainImage.height",
          },
        ],
        title: "Main image height",
        description: "main image height",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      imageWidth: {
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c1.items[.da_id=mainImage.width",
          },
        ],
        title: "Main image width",
        description: "main image width",
        type: "string",
        default: null,
        allowed: true,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=title.text",
          },
        ],
        title: "title",
        description: "title",
        type: "string",
      },
      titleColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=title.color",
          },
        ],
        title: "title color",
        description: "title color",
        type: "string",
        default: "Default",
      },
      titleSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=title.size",
          },
        ],
        title: "title size",
        description: "title size",
        type: "string",
        default: "Large",
      },
      text: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=text.text",
          },
        ],
        title: "text",
        description: "text",
        type: "string",
      },
      textColor: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=text.color",
          },
        ],
        title: "text color",
        description: "text color",
        type: "string",
        default: "Default",
      },
      textSize: {
        allowed: true,
        required: false,
        apply: [
          {
            changes: [{ type: "simplyapply" }],
            path: "body[.type=ColumnSet.columns[.da_id=c2.items[.da_id=text.size",
          },
        ],
        title: "text size",
        description: "text size",
        type: "string",
        default: "Default",
      },
    },
    isPublished: true, // available in selectable catalogue
    copiedFrom: "",
  },
};

module.exports = { teamsSemanticTemplates };
