import React from "react";
import { useFormikContext } from "formik";
import { Select, FormControl, MenuItem } from "@material-ui/core/";

const OutputTypes = () => {
  const formik = useFormikContext();

  return (
    <FormControl>
      <Select
        displayEmpty
        required
        id="outputType"
        inputProps={{ "aria-label": "Select output type" }}
        name="outputType"
        value={formik.values.outputType}
        onChange={formik.handleChange}
      >
        <MenuItem disabled value="">
          Select Output Type
        </MenuItem>
        <MenuItem value="singleCTA">Single CTA</MenuItem>
        <MenuItem value="doubleCTA">Double CTA</MenuItem>
        <MenuItem value="dropdown">Dropdown</MenuItem>
        <MenuItem value="all">All of them</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OutputTypes;
