import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Button,
  //Card,
  Box,
} from "@material-ui/core/";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add";
import Loader from "../shared/Loader";
import LanguageChoices from "./steps/LanguageChoices";
import InputTypes from "./steps/InputTypes";
import OutputTypes from "./steps/OutputTypes";
import Emoji from "./steps/Emoji";
import Tone from "./steps/Tone";
import * as yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { useAppContext } from "../../api/AppContext";
import Alert from "@material-ui/lab/Alert";

const outputWrapper = {
  alignContent: "center",
  backgroundColor: "#f1f1f1",
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-start",
  padding: "20px",
};
const outputStyle = {
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
};
const buttonStyle = {
  alignContent: "center",
  display: "flex",
  justifyContent: "center",
  margin: "2rem",
};
const selectedOutputStyle = {
  border: "2px solid black",
};
/* const selectedImageStyle = {
  borderRadius: "0px",
  outline: "2px solid black",
  outlineOffset: "1rem",
}; */

/* const imageStyle = {
  alignContent: "center",
  backgroundColor: "#f1f1f1",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "2rem",
  padding: "20px",
}; */
const tipStyle = {
  backgroundColor: "#71bd99",
  borderRadius: "7px",
  color: "black",
  fontFamily: "Roboto, sans-serif",
  fontWeight: "bold",
  marginLeft: "auto",
  padding: "10px",
  textAlign: "center",
};

const AiIntegration = ({
  tab,
  setWizardVisible,
  journeyId,
  journeyLanguage,
  aiResult,
}) => {
  const [view, setView] = useState("INPUT");
  const [aiServiceResult, setAiServiceResult] = useState(null);
  const [isOutputSelected, setIsOutputSelected] = useState(false);
  //const [selectedResult, setSelectedResult] = useState(null);
  //const [selectedImage, setSelectedImage] = useState(null);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [firstDigit, setFirstDigit] = useState();
  const [lastDigit, setLastDigit] = useState();
  const [hoveredItem, setHoveredItem] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const { callAiService, user } = useAppContext();

  const outputRef = useRef(null);
  const scrollToBottom = () => {
    outputRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (isOutputSelected) {
      scrollToBottom();
    }
  }, [isOutputSelected, selectedProposal]);

  useEffect(() => {
    if (selectedProposal) {
      const digits = selectedProposal.split("");
      setFirstDigit(parseInt(digits[0], 10));
      setLastDigit(parseInt(digits[1], 10));
    } else {
      setFirstDigit(null);
      setLastDigit(null);
    }
  }, [selectedProposal]);

  const validationSchema = yup.object().shape({
    fileB64: yup.string().when("inputType", {
      is: "fileupload",
      otherwise: yup.string(),
      then: yup.string().required("File B64 is required"),
    }),
    fileName: yup.string().when("inputType", {
      is: "fileupload",
      otherwise: yup.string(),
      then: yup.string().required("File Name is required"),
    }),
    inputType: yup.string().required("Input Type is required"),
    language: yup.string().required("Language is required"),
    outputType: yup.string().required("Output Type is required"),
    tone: yup.string().required("Tone of Voice is required"),
    userinput: yup.string().when("inputType", {
      is: "text",
      otherwise: yup.string(),
      then: yup.string().required("User Input is required"),
    }),
  });

  const getLanguageOption = (languageCode) => {
    switch (languageCode) {
      case "en-GB":
        return "english";
      case "it-IT":
        return "italian";
      case "fr-FR":
        return "french";
      default:
        return "";
    }
  };
  const formik = useFormik({
    initialValues: {
      inputType: "",
      journeyId,
      language: getLanguageOption(journeyLanguage),
      outputType: "",
      tone: "",
      useEmojis: false,
    },
    onSubmit: async (values, { setSubmitting }) => {
      // To be changed: call endpoints/ai_lambda
      const result = await callAiService(values);
      setAiServiceResult(result);
      setSubmitting(false);
    },
    validateOnChange: true,
    validationSchema: validationSchema,
  });
  const handleRerun = async () => {
    setIsLoading(true);
    setAiServiceResult(null);
    const values = formik.values;
    const result = await callAiService(values);
    if (result) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
    setAiServiceResult(result);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!aiServiceResult) {
      const timer = setTimeout(() => {
        setButtonEnabled(true);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleSubmit = () => {
    if (formik.isValid && formik.dirty) {
      formik.handleSubmit();
      setView("OUTPUT");
    }
  };

  /*   const handleImageClick = (imageNumber) => {
    setSelectedImage(imageNumber);
  }; */

  const handleProposalClick = (proposalNumber) => {
    if (selectedProposal === proposalNumber) {
      setSelectedProposal(null);
      setIsOutputSelected(false);
    } else {
      setSelectedProposal(proposalNumber);
      setIsOutputSelected(true);
      setHoveredItem(null);
    }
  };

  const handleTipClick = () => {
    let result =
      aiServiceResult.results[parseInt(firstDigit)].messages[
        parseInt(lastDigit)
      ];
    result = {
      ...result,
      outputType: aiServiceResult.results[parseInt(firstDigit)].outputType,
    };
    setAiServiceResult(result);
    setWizardVisible(false);
    aiResult(result);
  };

  const handleRestart = () => {
    setAiServiceResult(null);
    formik.resetForm();
    setIsOutputSelected(false);
    setView("INPUT");
  };

  let proposalNumber = 0;
  const renderProposals = () => {
    if (!aiServiceResult || !aiServiceResult.results) {
      return <Loader />;
    }

    return (
      <>
        {aiServiceResult.results.map((result, resultIndex) => (
          <div key={resultIndex}>
            {result &&
              result.messages &&
              result.messages.map((item, index) => {
                proposalNumber++;
                const isHovered = hoveredItem === `${resultIndex}-${index}`;
                const isSelected =
                  selectedProposal === String(resultIndex) + String(index);

                return (
                  <Grid
                    key={`${resultIndex}-${index}`}
                    style={{
                      ...outputWrapper,
                      ...(selectedProposal ===
                      String(resultIndex) + String(index)
                        ? selectedOutputStyle
                        : {}),
                      alignItems: "flex-start",
                      border: isSelected
                        ? "2px solid black"
                        : isHovered
                        ? "2px dashed black"
                        : "2px solid transparent",
                    }}
                    onClick={() =>
                      handleProposalClick(String(resultIndex) + String(index))
                    }
                    onMouseEnter={() => {
                      setHoveredItem(`${resultIndex}-${index}`);
                    }}
                    onMouseLeave={() => {
                      setHoveredItem(null);
                    }}
                  >
                    <Grid ref={outputRef} style={outputStyle}>
                      <Typography gutterBottom style={{ marginBottom: "20px" }}>
                        Proposal #{proposalNumber}
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        Title: {item.title}
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        Text: {item.text}
                      </Typography>
                      {result.outputType === "dropdown" && (
                        <Typography style={{ fontWeight: "bold" }}>
                          Button Text: {result.messages[index].button}
                        </Typography>
                      )}
                      {result.outputType === "dropdown" && (
                        <Typography style={{ fontWeight: "bold" }}>
                          Placeholder: {result.messages[index].placeholder}
                        </Typography>
                      )}

                      <Typography style={{ fontWeight: "bold" }}>
                        {result.outputType === "dropdown"
                          ? "Options:"
                          : "Call to Action:"}
                      </Typography>

                      <List dense disablePadding>
                        {item.answers.map((answer, answerIndex) => (
                          <ListItem key={answerIndex}>
                            <AddIcon style={{ marginRight: "5px" }} />
                            <ListItemText
                              primary={answer}
                              style={{ color: "black" }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    {selectedProposal ===
                      String(resultIndex) + String(index) && (
                      <Box style={tipStyle}>Use this as a tip</Box>
                    )}
                  </Grid>
                );
              })}
          </div>
        ))}
      </>
    );
  };

  return (
    <FormikProvider value={formik}>
      {view === "INPUT" ? (
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid container justifyContent="center">
            {(user.groups.includes("Admins") ||
              !user.companies?.includes(
                "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
              )) && (
              <Alert severity="info" style={{ marginTop: "20px" }}>
                Be aware that using this AI feature you are providing data to{" "}
                <a href="https://openai.com/" rel="noreferrer" target="_blank">
                  OpenAI
                </a>
                . <br />
                Ensure compliance with company policies on your end 🛂
              </Alert>
            )}
          </Grid>
          <Grid item md={2} xs={12}>
            <LanguageChoices />
          </Grid>
          <Grid item md={4} style={{ justifyContent: "center" }} xs={12}>
            <InputTypes />
          </Grid>
          <Grid item md={2} style={{ justifyContent: "center" }} xs={12}>
            <Tone />
          </Grid>
          <Grid item md={2} style={{ justifyContent: "center" }} xs={12}>
            <OutputTypes />
          </Grid>
          <Grid item md={3} style={{ justifyContent: "center" }} xs={12}>
            <Emoji />
          </Grid>

          <Grid container style={{ justifyContent: "center" }} xs={12}>
            <Button
              color="primary"
              disabled={!formik.isValid || !formik.dirty}
              style={{
                cursor:
                  formik.isValid && formik.dirty ? "pointer" : "not-allowed",
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Start The Magic!
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid style={{ margin: "10px" }}>
          <Typography
            gutterBottom
            align="center"
            style={{ fontFamily: "Roboto, sans-serif", fontWeight: "bold" }}
            variant="h6"
          >
            Here{"'"}s what h{"(A)"}i produced for you! Select desired output!
            <br />
          </Typography>
          {aiServiceResult != null &&
            (aiServiceResult.results[0].outputType === "dropdown" ||
              aiServiceResult.results.length === 3) && (
              <Typography
                gutterBottom
                align="center"
                style={{ fontFamily: "Roboto, sans-serif" }}
                variant="body1"
              >
                {"("}The correct answer is marked with *{")"}
              </Typography>
            )}
          {isLoading ? <Loader /> : <div>{renderProposals()}</div>}
          {/*           <Grid style={{ backgroundColor: "#f1f1f1", marginTop: "4rem" }}>
            <Typography
              align="center"
              style={{ fontFamily: "Roboto, sans-serif", fontWeight: "bold" }}
              variant="h6"
            >
              Gallery of Images related to the topic
            </Typography>
            <Grid style={imageStyle}>
              <Grid>
                <Card
                  style={{
                    ...(selectedImage === 1 ? selectedImageStyle : {}),
                    backgroundColor: "black",
                    height: "256px",
                    width: "256px",
                  }}
                  onClick={() => handleImageClick(1)}
                ></Card>
                {selectedImage === 1 && (
                  <Box style={{ ...tipStyle, marginTop: "1.5rem" }}>
                    Use this as a main image
                  </Box>
                )}
              </Grid>
              <Grid>
                <Card
                  style={{
                    ...(selectedImage === 2 ? selectedImageStyle : {}),
                    backgroundColor: "black",
                    height: "256px",
                    width: "256px",
                  }}
                  onClick={() => handleImageClick(2)}
                ></Card>
                {selectedImage === 2 && (
                  <Box style={{ ...tipStyle, marginTop: "1.5rem" }}>
                    Use this as a main image
                  </Box>
                )}
              </Grid>
              <Grid>
                <Card
                  style={{
                    ...(selectedImage === 3 ? selectedImageStyle : {}),
                    backgroundColor: "black",
                    height: "256px",
                    width: "256px",
                  }}
                  onClick={() => handleImageClick(3)}
                ></Card>
                {selectedImage === 3 && (
                  <Box style={{ ...tipStyle, marginTop: "1.5rem" }}>
                    Use this as a main image
                  </Box>
                )}
              </Grid>
              <Grid>
                <Card
                  style={{
                    ...(selectedImage === 4 ? selectedImageStyle : {}),
                    backgroundColor: "black",
                    height: "256px",
                    width: "256px",
                  }}
                  onClick={() => handleImageClick(4)}
                ></Card>
                {selectedImage === 4 && (
                  <Box style={{ ...tipStyle, marginTop: "1.5rem" }}>
                    Use this as a main image
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid> */}
          <Grid style={buttonStyle}>
            <Button
              color="primary"
              style={{
                color: "white",
                cursor: "pointer",
                margin: "25px",
              }}
              variant="contained"
              onClick={handleRestart}
            >
              Restart H{"(A)"}I with new parameters
            </Button>
            {/* {!(selectedImage != null && selectedOutput) ? ( */}
            {!isOutputSelected ? (
              <>
                <Button
                  color="primary"
                  disabled={aiServiceResult === null || !buttonEnabled}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    margin: "25px",
                  }}
                  variant="contained"
                  onClick={handleRerun}
                >
                  Rerun with same parameters
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                style={{
                  color: "white",
                  cursor: "pointer",
                  margin: "25px",
                }}
                variant="contained"
                onClick={handleTipClick}
              >
                Let{"'s"} see the tip!
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </FormikProvider>
  );
};
export default AiIntegration;
