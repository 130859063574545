import Grid from "@material-ui/core/Grid";
import TitleTypography from "./TitleTypography";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import OutlinedButton from "./OutlinedButton";
import EditIcon from "@material-ui/icons/Edit";
import Preview from "./Preview";
import Button from "@material-ui/core/Button";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import React, { useCallback, useEffect, useState } from "react";
import useStickyHeight from "../hooks/useStickyHeight";
import useLocalStorage from "../hooks/useLocalStorage.js";
// import GetAppIcon from "@material-ui/icons/GetApp";

const FlowPreview = ({
  interactions,
  locale,
  onEdit,
  name,
  journeyId = undefined,
}) => {
  const [number, setNumber] = useState(0);

  const [localViewType] = useLocalStorage(`viewType`, "client");

  useEffect(() => {
    setNumber(0);
  }, [interactions]);

  const { containerRef: previewContainerRef, height } = useStickyHeight();

  const handleNext = useCallback(() => {
    setNumber((number) => Math.min(number + 1, interactions?.length - 1));
  }, [interactions?.length]);

  const handlePrev = useCallback(() => {
    setNumber((number) => Math.max(0, number - 1));
  }, []);

  const handleEditInteraction = useCallback(() => {
    onEdit?.(interactions[number]);
  }, [interactions, number, onEdit]);

  return (
    <div ref={previewContainerRef}>
      <Grid
        container
        spacing={4}
        style={{ flexDirection: "column", flexWrap: "nowrap", height }}
      >
        <Grid item>
          <TitleTypography variant="h6">Simulation</TitleTypography>
          <Typography color="textSecondary" variant="body2">
            {interactions[0]
              ? `See all interactions for selected ${name}`
              : `Please select a ${name} to preview all interactions`}
          </Typography>
        </Grid>
        <Grid item style={{ minHeight: 0 }}>
          {interactions[number] && (
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              height="100%"
            >
              {onEdit && (
                <Box mb={3}>
                  <OutlinedButton
                    endIcon={<EditIcon />}
                    size="small"
                    onClick={handleEditInteraction}
                  >
                    Edit
                  </OutlinedButton>
                </Box>
              )}
              <Preview
                semanticId={interactions[number].id}
                src={`${
                  process.env.REACT_APP_account_content_dashboard_render_url
                }?silent=true&preview=true&props=${btoa(
                  JSON.stringify({
                    did: `preview_${locale}`,
                    semanticId: interactions[number].id,
                  })
                )}`}
                title="preview"
                userId={`preview_${locale}`}
                viewType={localViewType}
              >
                {({ iframe, reload, width, height }) => (
                  <>
                    <Box
                      height={height}
                      style={{ overflowY: "auto" }}
                      width={width + 15}
                    >
                      {iframe}
                    </Box>
                    {/* <Grid> */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={3}
                      width={width + 15}
                    >
                      <Button
                        fullWidth
                        color="primary"
                        disabled={number === 0}
                        size="small"
                        variant="contained"
                        onClick={handlePrev}
                      >
                        Previous
                      </Button>
                      <Box ml={2} mr={2}>
                        <OutlinedButton
                          endIcon={<AutorenewIcon />}
                          size="small"
                          onClick={reload}
                        >
                          Reset
                        </OutlinedButton>
                      </Box>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={number >= interactions.length - 1}
                        size="small"
                        variant="contained"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </Box>
                    {/* <Box display="flex" justifyContent="center" mt={1}>
                        <OutlinedButton
                          endIcon={<GetAppIcon />}
                          size="small"
                          onClick={openExportImage}
                        >
                          Export
                        </OutlinedButton>
                      </Box> */}
                    {/* </Grid> */}
                  </>
                )}
              </Preview>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FlowPreview;
