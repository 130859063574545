/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useCallback, useMemo } from "react";
import { Button, Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../shared/Table";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";

const CopilotTable = () => {
  const {
    prompts,
    fetchPrompts,
    tools,
    fetchTools,
    tags,
    fetchTags,
    deletePrompt,
    deleteTool,
    deleteTag,
  } = useAppContext();

  const location = useLocation();
  const history = useHistory();
  const tableRef = useRef();
  const modals = useModals();

  const basePath = useMemo(() => location.pathname.split("/")[1], [location.pathname]);
  const currentTab = useMemo(() => location.pathname.split("/")[2], [location.pathname]);

  const getPathSpecificColumns = useCallback((tab) => {
    const customFilterAndSearch = (term, rowData, columnDef) => {
      const [baseField, lang] = columnDef.field.split('_');
      
      let searchValue;
      if (lang) {
        searchValue = rowData[baseField]?.[lang];
      } else if (columnDef.field === 'tags') {
        searchValue = rowData.tags?.join(', ');
      } else {
        searchValue = rowData[columnDef.field];
      }
  
      if (searchValue == null) return false;
  
      return String(searchValue)
        .toLowerCase()
        .includes(term.toLowerCase());
    };
  
    switch (tab) {
      case "prompt":
        return [
          { 
            customFilterAndSearch,
            field: "title_en", 
            render: (rowData) => rowData.title?.en || "",
            title: "Title (EN)", 
          },
          { 
            customFilterAndSearch,
            field: "title_it", 
            render: (rowData) => rowData.title?.it || "",
            title: "Title (IT)", 
          },
          { 
            customFilterAndSearch,
            field: "description_en", 
            render: (rowData) => rowData.description?.en || "",
            title: "Description (EN)", 
          },
          { 
            customFilterAndSearch,
            field: "description_it", 
            render: (rowData) => rowData.description?.it || "",
            title: "Description (IT)", 
          },
          { 
            customFilterAndSearch,
            field: "prompttext_en", 
            render: (rowData) => rowData.prompttext?.en || "",
            title: "Prompt (EN)", 
          },
          { 
            customFilterAndSearch,
            field: "prompttext_it", 
            render: (rowData) => rowData.prompttext?.it || "",
            title: "Prompt (IT)", 
          },
          { field: "toolname", title: "Tool" },
          {
            customFilterAndSearch,
            field: "tags",
            render: (rowData) =>
              rowData?.tags
                ? rowData.tags.map((tag) => tag?.en).join(", ")
                : "No Tags",
            title: "Tags",
          },
          { field: "isfromtypeform", title: "isFromTypeform" },
        ];
      case "tools":
        return [
          { field: "toolname", title: "Tool Name" },
          { field: "colortop", title: "Color Top" },
          { field: "colorbottom", title: "Color Bottom" },
        ];
      case "tags":
        return [
          { field: "tagname.en", title: "Name (EN)" },
          { field: "tagname.it", title: "Name (IT)" },
          { field: "tagcolor", title: "Color" },
        ];
      default:
        return [];
    }
  }, []);

  const columns = useMemo(
    () => getPathSpecificColumns(currentTab),
    [currentTab, getPathSpecificColumns]
  );

  const fetchData = useCallback(async () => {
    switch (currentTab) {
      case "prompt":
        await fetchPrompts(basePath);
        break;
      case "tools":
        await fetchTools(basePath);
        break;
      case "tags":
        await fetchTags(basePath);
        break;
      default:
        break;
    }
  }, [basePath, currentTab, fetchPrompts, fetchTools, fetchTags]);

  useEffect(() => {
    fetchData();
  }, [fetchData, currentTab]);

  const data = useMemo(() => {
    switch (currentTab) {
      case "prompt":
        return prompts;
      case "tools":
        return tools;
      case "tags":
        return tags;
      default:
        return [];
    }
  }, [currentTab, prompts, tools, tags]);

  const onRowDelete = useCallback(
    async (values) => {
      const { id } = values;
      switch (currentTab) {
        case "prompt":
          await deletePrompt({ id }, basePath);
          break;
        case "tools":
          modals.openConfirmation({
            closeCTATitle: "Go back",
            confirmCTATitle: "Delete",
            onConfirm: async () => {
              await deleteTool({ id }, basePath);
            },
            text: "All the prompts associated with this tool will be deleted automatically.",
          });
          break;
        case "tags":
          modals.openConfirmation({
            closeCTATitle: "Go back",
            confirmCTATitle: "Delete",
            onConfirm: async () => {
              await deleteTag({ id }, basePath);
            },
            text: "All the prompts which have only this tag will be deleted automatically and it will be removed from the prompts that have multiple tags.",
          });
          break;
        default:
          break;
      }
      fetchData();
    },
    [currentTab, fetchData, deletePrompt, basePath, modals, deleteTool, deleteTag]
  );

  const editable = useMemo(() => ({ onRowDelete }), [onRowDelete]);

  const getTitle = useCallback(() => {
    switch (currentTab) {
      case "prompt":
        return "Prompts";
      case "tools":
        return "Tools";
      case "tags":
        return "Tags";
      default:
        return "";
    }
  }, [currentTab]);

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Table
        actions={[
          {
            icon: (props) => <EditIcon {...props} color="action" />,
            onClick: (event, rowData) => {
              history.push(`/${basePath}/${currentTab}/${rowData.id}`, {
                isNew: false,
                rowData,
              });
            },
            tooltip: "Edit",
          },
        ]}
        columns={columns}
        data={data}
        editable={editable}
        refetch={fetchData}
        tableActions={[
          <Button
            key="add"
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push(`/${basePath}/${currentTab}/new`, { isNew: true });
            }}
          >
            Create new {getTitle().slice(0, -1)}
          </Button>,
        ]}
        tableRef={tableRef}
        title={getTitle()}
      />
    </Box>
  );
};

export default CopilotTable;