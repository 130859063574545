import React from "react";
import Grid from "@material-ui/core/Grid";

import { useHistory, useLocation } from "react-router-dom";
import useEditMaterial from "./useEditMaterial";
import SaveButtons from "../SaveButtons";

const EditMaterial = ({
  alreadySelectedFlowIds,
  material,
  locale,
  journey,
  journeys,
  onUpdate,
  isDisabled,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { formik, ui } = useEditMaterial({
    alreadySelectedFlowIds,
    material,
    locale,
    journey,
    journeys,
    isDisabled,
    onUpdate: async (values) => {
      await onUpdate(values);
      if (values.isNew) {
        history.replace({ ...location, state: null });
      }
    },
  });
  const { isNew } = material;

  return (
    <Grid container spacing={4}>
      <Grid item>{ui}</Grid>
      <Grid item>
        <SaveButtons formik={formik} isNew={isNew} />
      </Grid>
    </Grid>
  );
};

export default EditMaterial;
