import Highlighter from "react-highlight-words";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import format from "date-fns/format";
import styled from "styled-components";
import times from "lodash/times";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import keyBy from "lodash/keyBy";
import * as XLSX from "xlsx";

import { withStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAvatar from "@material-ui/core/Avatar";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import MuiCardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import { useTheme } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ImageIcon from "@material-ui/icons/Image";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";

import Image from "./shared/Image";
import { useAppContext } from "../api/AppContext";
import CardLoader from "./CardLoader";
import OutlinedButton from "./OutlinedButton";
// import TeamUsers from "./TeamUsers";
import ViewPortRender from "./ViewportRender";
import useLocalStorage from "../hooks/useLocalStorage";
import { inputProps } from "./utils";
import { useModals } from "../hooks/useModals";

const AddTile = styled.div`
  position: relative;
  width: 100%;
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(69.4deg, #765ec6 -75.12%, #6200ee 169.82%);
  color: white;
  z-index: 0;
  font-size: 1.25rem;
  font-weight: bold;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-image: url(/add-new-plan-tile-background.png);
  }
`;

const A = styled.a`
  text-decoration: none;
  color: initial;
`;

const AddButton = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LanguageChip = withStyles(({ palette }) => ({
  root: {
    " > svg": {
      color: "#F1EFF9",
    },
    backgroundColor: "#F1EFF9",
    color: palette.primary.main,
  },
}))(Chip);

const CardHeader = withStyles(({ palette, spacing }) => ({
  avatar: {
    marginRight: spacing(1),
  },
  root: {
    paddingBottom: 0,
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
}))(MuiCardHeader);

const CardActionArea = withStyles(({ palette, spacing }) => ({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
  },
}))(MuiCardActionArea);

const PlanCardActionArea = withStyles(({ palette, spacing }) => ({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}))(MuiCardActionArea);

const Avatar = withStyles(({ spacing, palette }) => ({
  root: {
    height: spacing(3),
    width: spacing(3),
  },
}))(MuiAvatar);

const getJourneyActivationLink = ({ journey, cid, force }) => {
  const props = btoa(
    unescape(
      encodeURIComponent(
        JSON.stringify({
          id: journey.id,
          locale: journey.locale,
          name: journey.name,
          ...(cid && {
            cid,
          }),
          ...(force && { force: "yes" }),
        })
      )
    )
  );
  return `https://deeplink.${
    process.env[
      `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
    ]
  }/dashboard/activate/${props}`;
};

const Caption = (props) => (
  <Typography
    color="textSecondary"
    component="div"
    style={{ fontSize: 10, ...props.styles }}
    variant="caption"
    {...props}
  />
);

const JourneyStats = ({ stats }) => {
  return (
    <Grid container columnSpacing={1} rowSpacing={0.5}>
      <Grid item xs={12}>
        <Box mt={1}>
          <Caption styles={{ fontWeight: "bold" }}>Content overview</Caption>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Caption>{stats.totalDays} Days</Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>
          {stats.totalPrimes} Tips ({stats.totalSinglePrimes} single,{" "}
          {stats.totalFlowsPrimes} flows, {stats.totalUnassignedPrimes}{" "}
          unassigned)
        </Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>
          {stats.totalScheduledSlots} Scheduled Slots (
          {stats.totalScheduledSlots - stats.totalScheduledPrimes} with
          unassigned message)
        </Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>{stats.totalNudges} Nudges</Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>{stats.totalTriggers} Triggers in use</Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>{stats.totalMaterials} Learning materials</Caption>
      </Grid>
      <Grid item xs={6}>
        <Caption>{stats.totalExternalLinks} External links</Caption>
      </Grid>
    </Grid>
  );
};

const JourneyCard = ({
  journey,
  onOpen,
  onCopy,
  onDelete,
  isShowHidden,
  searchValue,
  journeysById,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [, setSelectedJourney] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const modals = useModals();

  const handleMenuOpen = useCallback(
    (journey) => (e) => {
      setSelectedJourney(journey);
      setMenuAnchorEl(e.currentTarget);
    },
    []
  );

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    setSelectedJourney(null);
  }, []);

  const handleShareWithCid = useCallback(
    (journey) => async () => {
      modals.openModal(modals.modalTypes.CopyActivationLink, {
        onConfirm: async ({ cid, force }) => {
          await navigator.clipboard.writeText(
            getJourneyActivationLink({
              cid,
              force,
              journey,
            })
          );
          enqueueSnackbar("Activation link is copied to clipboard!", {
            variant: "success",
          });
        },
      });
    },
    [modals, enqueueSnackbar]
  );

  // const handleTeamsShare = useCallback(async () => {
  //   handleMenuClose();
  //
  //   // https://docs.microsoft.com/en-us/microsoftteams/platform/concepts/build-and-test/share-to-teams#full-launcherjs-definition
  //   const url = new URL("https://teams.microsoft.com/share");
  //   url.searchParams.append(
  //     "href",
  //     getJourneyActivationLink({
  //       journey: selectedJourney,
  //     })
  //   );
  //   url.searchParams.append(
  //     "msgText",
  //     `Activate "${selectedJourney.name}" journey`
  //   );
  //   window.open(url.toString(), "ms-teams-share-popup", "width=700,height=600");
  // }, [handleMenuClose, selectedJourney]);

  const theme = useTheme();

  return (
    <>
      <Menu
        keepMounted
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {/*<MenuItem>*/}
        {/*  <ListItemIcon />*/}
        {/*  Copy plan activation link*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={onCopy}>
          <ListItemIcon />
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleShareWithCid(journey)}>
          <ListItemIcon />
          <ListItemText>Copy link</ListItemText>
        </MenuItem>
        <MenuItem disabled={journey.isDisabled} onClick={onDelete}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText style={{ color: theme.palette.error.main }}>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
      <Card
        style={{
          display: "flex",
          flex: "1 1 0%",
          flexDirection: "column",
          height: 581.2,
          width: 306.5,
        }}
        variant="outlined"
      >
        <A
          href={`/journeys/${journey.id}/${journey.locale}`}
          onClick={(e) => e.preventDefault()}
        >
          <PlanCardActionArea onClick={onOpen}>
            <Image
              alt="image"
              loader={<Skeleton height={173} variant="rect" width="100%" />}
              src={journey.image}
              style={{ height: 164, objectFit: "contain", width: "100%" }}
              unloader={
                <Box
                  alignItems="center"
                  color="white"
                  display="flex"
                  height={164}
                  justifyContent="center"
                  style={{ background: grey[400] }}
                  width="100%"
                >
                  <ImageIcon color="inherit" fontSize="large" />
                </Box>
              }
            />
            <CardHeader
              avatar={
                <Avatar src={journey.icon} variant="circular" width={24}>
                  <ImageIcon fontSize="small" />
                </Avatar>
              }
              title={
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <LanguageChip
                      label={
                        <Box alignItems="center" display="flex">
                          <Box
                            alignItems="center"
                            component="span"
                            display="flex"
                            mr={0.75}
                          >
                            <LanguageIcon fontSize="small" />
                          </Box>
                          <Typography variant="caption">
                            {journey.locale}
                          </Typography>
                        </Box>
                      }
                      size="small"
                    />
                  </Grid>
                  {journey.isDisabled && (
                    <Grid item>
                      <Tooltip title="Journey is not editable! Copy it to edit 💡">
                        <LockIcon color="disabled" />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              }
            />
            <Box boxSizing="border-box" p={3} pb={0.75} pt={0.75} width="100%">
              <Typography
                style={{
                  fontSize: 18,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                variant="h6"
              >
                <Highlighter
                  searchWords={[searchValue]}
                  textToHighlight={journey.internalName || journey.name}
                />
              </Typography>
            </Box>
            <Box p={3} pb={0.75} pt={0.75}>
              <Typography
                style={{
                  height: 60,
                  overflow: "hidden",
                }}
                variant="body2"
              >
                <Highlighter
                  searchWords={[searchValue]}
                  textToHighlight={journey.description}
                />
              </Typography>
            </Box>
            <Box height={182.4} p={3} pb={1.25} pt={0.75}>
              {journey.createdBy && (
                <Tooltip
                  title={`${journey.createdBy.firstName} ${journey.createdBy.lastName}(${journey.createdBy.email})`}
                >
                  <Typography
                    color="textSecondary"
                    component="div"
                    style={{ fontSize: 10 }}
                    variant="caption"
                  >
                    Created by: {journey.createdBy.firstName}{" "}
                    {journey.createdBy.lastName}
                  </Typography>
                </Tooltip>
              )}
              {journey.createdAt && (
                <Tooltip
                  title={format(
                    new Date(journey.createdAt * 1000),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                >
                  <Typography
                    color="textSecondary"
                    component="div"
                    style={{ fontSize: 10 }}
                    variant="caption"
                  >
                    Created on:{" "}
                    {format(new Date(journey.createdAt * 1000), "dd/MM/yyyy")}
                    {journey.copiedFromJourneyId &&
                      journeysById[journey.copiedFromJourneyId]?.id && (
                        <>
                          {journey.copiedFromJourneyId && ` copied from `}
                          {journey.copiedFromJourneyId && (
                            <a
                              href={`/journeys/${journey.copiedFromJourneyId}/${journey.locale}`}
                              rel="noreferrer noopener"
                              target="_blank"
                            >
                              {journeysById[journey.copiedFromJourneyId]
                                ?.internalName ||
                                journeysById[journey.copiedFromJourneyId]?.name}
                            </a>
                          )}
                        </>
                      )}
                  </Typography>
                </Tooltip>
              )}
              {journey.updatedBy && (
                <Tooltip
                  title={`${journey.updatedBy.firstName} ${journey.updatedBy.lastName} (${journey.updatedBy.email})`}
                >
                  <Typography
                    color="textSecondary"
                    component="div"
                    style={{ fontSize: 10 }}
                    variant="caption"
                  >
                    Last modified by: {journey.updatedBy.firstName}{" "}
                    {journey.updatedBy.lastName}
                  </Typography>
                </Tooltip>
              )}
              {journey.updatedAt && (
                <Tooltip
                  title={format(
                    new Date(journey.updatedAt * 1000),
                    "dd/MM/yyyy HH:mm:ss"
                  )}
                >
                  <Typography
                    color="textSecondary"
                    component="div"
                    style={{ fontSize: 10 }}
                    variant="caption"
                  >
                    Last modified on:{" "}
                    {format(new Date(journey.updatedAt * 1000), "dd/MM/yyyy")}
                  </Typography>
                </Tooltip>
              )}
              {journey.stats && <JourneyStats stats={journey.stats.all} />}
            </Box>
          </PlanCardActionArea>
        </A>
        <Box display="flex" justifyContent="space-between" p={3} pt={1.25}>
          <CardActionButton
            color="primary"
            variant="contained"
            onClick={onOpen}
          >
            Open
          </CardActionButton>
          {!isShowHidden && (
            <>
              <CardActionButton onClick={handleShareWithCid(journey)}>
                Copy link
              </CardActionButton>
              <CardActionButton onClick={onCopy}>Duplicate</CardActionButton>
            </>
          )}

          {isShowHidden && (
            <IconButton size="small" onClick={handleMenuOpen(journey)}>
              <MoreHorizIcon />
            </IconButton>
          )}
        </Box>
      </Card>
    </>
  );
};

const JourneysSection = ({
  journeys,
  onOpen,
  onCopy,
  onDelete,
  isShowHidden,
  searchValue,
}) => {
  const journeysById = keyBy(journeys, "id");
  return journeys.map((journey) => (
    <Box
      key={`${journey.id}-${journey.locale}`}
      boxSizing="border-box"
      display="flex"
      flex="1"
      m={1}
      width="100%"
    >
      <ViewPortRender height={581.2} loader={<CardLoader />} width={306.5}>
        <JourneyCard
          isShowHidden={isShowHidden}
          journey={journey}
          journeysById={journeysById}
          searchValue={searchValue}
          onCopy={onCopy(journey)}
          onDelete={onDelete(journey)}
          onOpen={onOpen(journey)}
        />
      </ViewPortRender>
    </Box>
  ));
};

const SearchTextField = withStyles(({ typography }) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      fontSize: typography.body2.fontSize,
    },
    "& input::-webkit-input-placeholder": {
      color: typography.body2.color,
      opacity: 1,
    },
  },
}))(TextField);

const Search = ({ onChange, value, isDisabled }) => {
  const theme = useTheme();
  const handleClear = useCallback(() => {
    onChange({
      target: {
        value: "",
      },
    });
  }, [onChange]);

  const ref = useRef();

  useEffect(() => {
    const listener = (e) => {
      if ((e.key === "f" || e.key === "F") && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        ref.current?.focus();
      }
    };
    window.addEventListener("keypress", listener);

    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, []);

  return (
    <SearchTextField
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton size="small" onClick={handleClear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      }}
      disabled={isDisabled}
      inputRef={ref}
      margin="none"
      placeholder="Browse all journeys..."
      size="small"
      style={{
        ...theme.typography.body2,
        width: 300,
      }}
      value={value}
      variant="outlined"
      onChange={onChange}
    />
  );
};

const compare = (value, searchValue) =>
  value?.toLowerCase()?.includes(searchValue?.toLowerCase());

const Title = withStyles({
  root: {
    fontWeight: "bold",
    lineHeight: "80%",
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
})(Typography);

const SearchFailedIcon = withStyles({
  root: {
    border: "thin solid #e1e1e1",
  },
})(IconButton);

const CardActionButton = withStyles(({ palette }) => ({
  root: {
    minWidth: 79.5,
    padding: 4,
  },
}))(OutlinedButton);

const PlaceholderTitle = withStyles({
  root: {
    fontFamily: "Roboto",
  },
})(Box);

const StartLink = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.palette.primary.dark};
    text-decoration: underline;
  }
`;

const Placeholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 581.2px;
  border: 2px #e0e0e0 dashed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #e0e0e0;
  padding: 90px;
`;

const Journeys = () => {
  const {
    user,
    journeys,
    fetchJourneys,
    isJourneysLoading,
    copyJourney,
    // fetchCompanyUsers,
    deleteJourney,
    // companyUsers,
    // isCompanyUsersLoading,
    isShowHidden,
  } = useAppContext();

  useEffect(() => {
    fetchJourneys();
    // fetchCompanyUsers();
  }, []);

  const history = useHistory();
  const modals = useModals();

  const handleOpen = useCallback(
    (journey) => () => {
      history.push(`/journeys/${journey.id}/${journey.locale}`);
    },
    [history]
  );

  const handleCopy = useCallback(
    (journey) => () => {
      modals.openModal(modals.modalTypes.CopyJourney, {
        journey,
        onConfirm: () =>
          copyJourney({ journeyId: journey.id, locale: journey.locale }),
      });
    },
    [copyJourney, modals]
  );

  const handleCreate = useCallback(() => {
    history.push(`/journeys/${uuidv4()}/en-GB`, { isNew: true });
  }, [history]);

  const handleDelete = useCallback(
    (journey) => () => {
      modals.openModal(modals.modalTypes.DeleteJourney, {
        journey,
        onConfirm: ({ password }) =>
          deleteJourney({ journeyId: journey.id, password }),
      });
    },
    [deleteJourney, modals]
  );

  const [searchValue, setSearchValue] = useLocalStorage(
    `${user.sub}.Journeys.SearchValue`
  );
  const handleSearch = useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );
  const handleClearSearch = useCallback(
    (e) => {
      e.preventDefault();
      setSearchValue("");
    },
    [setSearchValue]
  );

  const filteredJourneys = useMemo(
    () =>
      journeys.filter(
        ({ name, description, id, internalName }) =>
          !searchValue ||
          compare(name, searchValue) ||
          compare(internalName, searchValue) ||
          compare(description, searchValue) ||
          compare(id, searchValue)
      ),
    [journeys, searchValue]
  );

  const handleDownloadStats = useCallback(() => {
    const allData = journeys.map(({ id, internalName, name, stats }) => ({
      id,
      internalName,
      name,
      ...stats.all,
    }));

    const publishedData = journeys.map(({ id, internalName, name, stats }) => ({
      id,
      internalName,
      name,
      ...stats.published,
    }));

    const allWorksheet = XLSX.utils.json_to_sheet(allData);

    const publishedWorksheet = XLSX.utils.json_to_sheet(publishedData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      allWorksheet,
      "including unpublished items"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      publishedWorksheet,
      "excluding unpublished items"
    );
    XLSX.writeFile(workbook, `stats-${new Date().toISOString()}.xlsx`);
  }, [journeys]);

  if (!user.companies?.length) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={7.5}
        width="100%"
      >
        <Typography style={{ fontSize: "1rem" }} variant="h6">
          Your account is not fully configured. Contact your technical account
          manager
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      p={7.5}
      width="100%"
    >
      <Box
        alignItems="center"
        boxSizing="border-box"
        display="flex"
        justifyContent="space-between"
        pl={1}
        pr={1}
        width="100%"
      >
        <Box display="flex">
          <Box
            alignItems="flex-end"
            display="flex"
            justifyContent="flex-end"
            mr={3}
          >
            <Title variant="h4">{user?.company?.name} Journeys</Title>
          </Box>

          {/* {user.groups.includes("Admins") && (
            <TeamUsers
              currentUser={user}
              isLoading={isCompanyUsersLoading}
              users={companyUsers}
            />
          )} */}
        </Box>
        <Box alignItems="center" display="flex">
          {user.groups.includes("Admins") ? (
            <Box mr={2}>
              <Button
                color="primary"
                disabled={isJourneysLoading}
                endIcon={<GetAppIcon />}
                onClick={handleDownloadStats}
              >
                Download journey list
              </Button>
            </Box>
          ) : (
            <></>
          )}
          <Search
            isDisabled={isJourneysLoading}
            value={searchValue}
            onChange={handleSearch}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flex="1"
        flexWrap="wrap"
        justifyContent={
          !isJourneysLoading &&
          (journeys.length === 0 || filteredJourneys.length === 0)
            ? "flex-start"
            : "center"
        }
        mt={5}
        width="100%"
      >
        {(isJourneysLoading ||
          (!isJourneysLoading &&
            (filteredJourneys.length !== 0 || journeys.length === 0))) && (
          <Box display="flex" flex="1" m={1}>
            <Card
              style={{
                display: "flex",
                flex: "1 1 0%",
                height: 556.2,
                width: 306.5,
              }}
              variant="outlined"
            >
              <CardActionArea onClick={handleCreate}>
                <AddTile>
                  <PlaceholderTitle mb={4}>New plan</PlaceholderTitle>
                  <AddButton>
                    <AddIcon color="primary" />
                  </AddButton>
                </AddTile>

                <Box p={3} pb={0.75}>
                  <Typography style={{ fontSize: "1rem" }} variant="h6">
                    Communicate effectively by focusing on the experience of
                    your people
                  </Typography>
                </Box>
                <Box p={3} pt={0.75} style={{ fontSize: "0.75rem" }}>
                  <Typography variant="body2">
                    Create your content, personalize it according to the
                    recipient, have fun with graphics and images, you change the
                    paradigm: it is the information that goes to the person and
                    not vice versa. <StartLink>Start now →</StartLink>
                  </Typography>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        )}

        {isJourneysLoading &&
          times(15, (i) => (
            <Box key={i} display="flex" flex="1" m={1}>
              <Card
                style={{
                  display: "flex",
                  flex: "1 1 0%",
                  height: 556.2,
                  width: 306.5,
                }}
              >
                <CardLoader />
              </Card>
            </Box>
          ))}
        {!isJourneysLoading && journeys.length === 0 && (
          <Box display="flex" flex="1" m={1}>
            <Typography color="textSecondary" style={{ width: "100%" }}>
              <Placeholder>You don’t have any journeys yet.</Placeholder>
            </Typography>
          </Box>
        )}
        {!isJourneysLoading &&
          journeys.length !== 0 &&
          filteredJourneys.length === 0 && (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              p={7.5}
              width="100%"
            >
              <Box m={3}>
                <SearchFailedIcon disabled color="textSecondary">
                  <ClearIcon />
                </SearchFailedIcon>
              </Box>
              <Box>
                <Title variant="h4">No matches found</Title>
              </Box>
              <Box m={4} style={{ textAlign: "center" }} width={500}>
                <Typography color="textSecondary">
                  {`It seems like there aren't journeys for `}
                  <b>{`“${searchValue}”`}</b>
                  {`. Please, try again or clear the search to get back to the journeys' list.`}
                </Typography>
              </Box>
              <Button
                color="primary"
                variant="contained"
                onClick={handleClearSearch}
              >
                Clear search
              </Button>
            </Box>
          )}
        {!isJourneysLoading && (
          <JourneysSection
            isShowHidden={isShowHidden}
            journeys={filteredJourneys}
            searchValue={searchValue}
            onCopy={handleCopy}
            onDelete={handleDelete}
            onOpen={handleOpen}
          />
        )}
      </Box>
    </Box>
  );
};

export default Journeys;
