import React, { useCallback, useEffect, useMemo, useRef } from "react";
import keyBy from "lodash/keyBy";
import format from "date-fns/format";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";

import Table from "../shared/Table";
import { useAppContext } from "../../api/AppContext";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {
  CHANNEL_BROWSERPLUGIN,
  CHANNEL_CLIENT,
  CHANNEL_EMBEDDED,
  CHANNEL_TEAMSAPP,
  DASHBOARD_ACCESS_ICON,
} from "./consts";

const columns = ({ journeys, journeysById }) => [
  {
    field: "name",
    title: "Company Name",
    type: "string",
  },
  {
    field: "cid",
    title: "CID",
  },
  {
    field: "channel",
    lookup: {
      "browser-plugin": CHANNEL_BROWSERPLUGIN.name,
      client: CHANNEL_CLIENT.name,
      embedded: CHANNEL_EMBEDDED.name,
      "teams-app": CHANNEL_TEAMSAPP.name,
    },
    title: "Channel",
  },
  {
    field: "leafVersion",
    title: "Leaf Version",
    type: "string",
  },
  {
    field: "availableJourneys",
    initialEditValue: [],
    title: "Available Journeys",
    type: "string",
    lookup: Object.fromEntries(
      journeys.map(({ id, name, internalName, locale }) => [
        id,
        `${internalName || name} - ${locale}`,
      ])
    ),
    render: ({ availableJourneys }) =>
      (availableJourneys ?? []).map((id) => (
        <Box key={id} p={0.5}>
          <Chip
            label={
              journeysById[id]?.internalName || journeysById[id]?.name || "-"
            }
            size="small"
          />
        </Box>
      )),
  },
  {
    field: "locale",
    lookup: {
      "en-GB": "English",
      "it-IT": "Italian",
      "fr-FR": "French",
    },
    title: "Default Language",
  },
  {
    field: "isEnabled",
    title: "Automated Journey activation",
    type: "boolean",
    render: (props) =>
      props.isEnabled ? (
        <Typography color="textPrimary" variant="body2">
          Yes (activated on{" "}
          {format(props.enabledAt * 1000, "dd MMMM yyyy HH:mm:ss")})
        </Typography>
      ) : (
        "No"
      ),
  },
  {
    field: "trackComputerName",
    title: 'Track "Computer Name"',
    type: "boolean",
    render: (props) =>
      props.trackComputerName ? (
        <Typography color="textPrimary" variant="body2">
          Yes
        </Typography>
      ) : (
        "No"
      ),
  },
  {
    title: "Customizations",
    render: ({
      channel,
      dashboardAccess,
      hiWebBtnIcon,
      logo,
      headerImage,
      colors,
      homePage,
      welcomeMessageSemantic,
    }) =>
      `${
        [
          hiWebBtnIcon,
          logo,
          headerImage,
          colors?.primary,
          colors?.secondary,
          homePage,
          welcomeMessageSemantic,
        ].filter(Boolean).length
      }/${
        dashboardAccess === DASHBOARD_ACCESS_ICON.value &&
        channel === CHANNEL_EMBEDDED.value
          ? "7"
          : "6"
      }`,
  },
  {
    field: "createdAt",
    defaultSort: "desc",
    hidden: true,
  },
];

const Configuration = () => {
  const ref = useRef();

  const {
    fetchJourneys,
    isJourneysLoading,
    journeys,
    fetchConfigurations,
    isConfigurationsLoading,
    configurations,
    deleteConfiguration,
  } = useAppContext();

  useEffect(() => {
    fetchJourneys();
    fetchConfigurations();
  }, []);

  const onRowDelete = useCallback(
    async (value) => deleteConfiguration(value),
    [deleteConfiguration]
  );

  const editable = useMemo(
    () => ({
      onRowDelete,
    }),
    [onRowDelete]
  );

  const history = useHistory();

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Table
        actions={[
          {
            icon: (props) => <EditIcon {...props} color="action" />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              history.push(`/settings/cid-configurations/${rowData.cid}`);
            },
          },
        ]}
        columns={columns({ journeys, journeysById: keyBy(journeys, "id") })}
        data={configurations}
        editable={editable}
        isLoading={isJourneysLoading || isConfigurationsLoading}
        options={{
          filtering: true,
        }}
        refetch={fetchConfigurations}
        tableActions={[
          <Button
            key="add"
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push("/settings/cid-configurations/new", {
                isNew: true,
              });
            }}
          >
            Create new Cid
          </Button>,
        ]}
        tableRef={ref}
        title="CID configurations"
      />
    </Box>
  );
};

export default Configuration;
