// AdaptiveCardPreview;
import { useRouteMatch } from "react-router-dom";
const AdaptiveCardPreview = () => {
  const match = useRouteMatch();
  const { semanticId, deviceId } = match.params;
  return (
    <p>
      {semanticId} {deviceId}
    </p>
  );
};

export default AdaptiveCardPreview;
