import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { useAppContext } from "../../api/AppContext";
import { getFieldProps } from "../utils";

const SurveySelect = ({ formik, journeyId, filterfunction }) => {
  const {
    isSurveysLoading,
    surveys,
    fetchAllSurveysForUser: fetchSurveys,
  } = useAppContext();

  useEffect(() => {
    if (surveys.length !== 0) return;
    fetchSurveys({ journeyId });
  }, [fetchSurveys, journeyId, surveys.length]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          label="Survey"
          placeholder="Select survey"
          variant="outlined"
          {...getFieldProps(formik, {
            name: "surveyId",
          })}
          InputProps={{
            startAdornment: isSurveysLoading && (
              <InputAdornment position="end">
                <CircularProgress size={25} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            formik.setFieldValue("surveyId", e.target.value);
            formik.setFieldTouched("surveyId", true);
          }}
        >
          {surveys
            .filter((survey) => filterfunction(survey))
            .map(({ id, name, description }) => (
              <MenuItem key={id} value={id}>
                <ListItemText primary={name} secondary={description} />
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default SurveySelect;
