import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import compose from "lodash/fp/compose";
import fpGroupBy from "lodash/fp/groupBy";
import fpOrderBy from "lodash/fp/orderBy";
import last from "lodash/last";
import pick from "lodash/pick";
import round from "lodash/round";
import styled from "styled-components/macro";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import deepPurple from "@material-ui/core/colors/deepPurple";
import grey from "@material-ui/core/colors/grey";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import CopyToClipboardBase from "../shared/CopyToClipboardBase";
import Dialog from "../shared/Dialog";
import Image from "../shared/Image";
import Loader from "../shared/Loader";
import OutlinedButton from "../OutlinedButton";
import TitleTypography from "../TitleTypography";
import useModal from "../../hooks/useModal";
import { useAppContext } from "../../api/AppContext";

const DeleteMaterial = ({ onClose, onConfirm }) => {
  const { handleConfirm, isLoading } = useModal({ onClose, onConfirm });

  return (
    <Dialog fullWidth open maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Are you sure you want to delete this Learning Material?
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          By deleting this Learning Material, you will remove it from the user
          Dashboard and it won’t be accessible anymore. Once deleted, you will
          not be able to retrieve this Learning Material.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Go back
        </Button>
        <Button
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={25} />}
          onClick={handleConfirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PublishMaterial = ({ material, onClose, onSave }) => {
  const { isLoading, handleConfirm } = useModal({
    onClose,
    onConfirm: useCallback(
      () =>
        onSave({
          isPublished: !material.isPublished,
          ...pick(material, ["id", "journeyId"]),
        }),
      [material, onSave]
    ),
  });

  return (
    <Dialog fullWidth open maxWidth="xs" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {material.isPublished ? "Unpublish" : "Publish"} material?
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Material will be {material.isPublished ? "hidden" : "visible"} for
          users
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={25} />}
          variant="contained"
          onClick={handleConfirm}
        >
          {material.isPublished ? "Unpublish" : "Publish"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DragHandleContainer = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

const renderers = {
  paragraph: "span",
};

const getMaterialDeeplink = (material) => {
  const qs = new URLSearchParams();

  qs.append("linkId", material.id);
  qs.append("linkTitle", material.name);
  qs.append("linkURL", material.ctaUrl);

  return `https://deeplink.${
    process.env[
      `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
    ]
  }/dashboard?${qs.toString()}`;
};

const Materials = ({ journeyId, locale, steps, isDisabled }) => {
  const {
    materials,
    fetchMaterials,
    isMaterialsLoading,
    deleteJourneyMaterial,
    updateJourneyMaterial,
  } = useAppContext();

  useEffect(() => {
    fetchMaterials({ journeyId, locale });
  }, [journeyId, locale]);

  const materialsByStepId = useMemo(
    () =>
      compose(fpGroupBy("stepId"), fpOrderBy(["order"], ["asc"]))(materials),
    [materials]
  );

  const [remove, setRemove] = useState(null);
  const [publish, setPublish] = useState(null);

  const handleDelete = useCallback(
    (material) => () => {
      setRemove(material);
    },
    []
  );

  const handleClose = useCallback(() => {
    setRemove(null);
    setPublish(null);
  }, []);

  const handlePublish = useCallback(
    (material) => () => {
      setPublish(material);
    },
    []
  );

  const handleDeleteMaterial = useCallback(
    async () => remove && deleteJourneyMaterial({ id: remove.id, journeyId }),
    [deleteJourneyMaterial, journeyId, remove]
  );

  const handleSave = useCallback(
    async (values) =>
      updateJourneyMaterial({
        ...values,
        journeyId,
      }),
    [journeyId, updateJourneyMaterial]
  );

  const history = useHistory();
  const handleEdit = useCallback(
    (material) => () => {
      history.push(
        `/journeys/${journeyId}/${locale}/learning-materials/${material.id}`
      );
    },
    [history, journeyId, locale]
  );

  const handleCreateMaterial = useCallback(
    ({ stepId }) =>
      async () => {
        const newMaterial = {
          id: `material-${uuidv4()}`,
          isNew: true,
          order: Math.floor((last(materialsByStepId[stepId])?.order ?? -1) + 1),
          stepId,
        };

        history.push(
          `/journeys/${journeyId}/${locale}/learning-materials/${newMaterial.id}`,
          newMaterial
        );
      },
    [history, journeyId, locale, materialsByStepId]
  );

  const handleOnDragEnd = useCallback(
    async (result) => {
      if (!result.destination) {
        return;
      }
      const { index, droppableId } = result.destination;

      const stepId = droppableId;
      const isSameStep = stepId === result.source.droppableId;

      if (isSameStep && index === result.source.index) {
        return;
      }

      const stepMaterials = materialsByStepId[stepId];
      let order =
        stepMaterials?.[index]?.order ?? (last(stepMaterials)?.order + 1 || 0);

      if (index > result.source.index && isSameStep) {
        order = (order + (stepMaterials?.[index + 1]?.order ?? order + 1)) / 2;
      } else {
        order = (order + (stepMaterials?.[index - 1]?.order ?? order - 1)) / 2;
      }

      return handleSave({
        id: result.draggableId,
        order: round(order, 4),
        stepId,
      });
    },
    [handleSave, materialsByStepId]
  );

  if (isMaterialsLoading) {
    return <Loader inline />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleTypography variant="h6">Learning Materials</TitleTypography>
        <Typography variant="body2">
          Use this tab to add files, documents, and videos for learning
          purposes. They will be visible in hi’s dashboard and can be the
          destination of tips&#39; and nudges&#39; CTAs.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            {steps.map(({ id, name, isPublished }) => (
              <Grid
                key={id}
                item
                style={{
                  ...(!isPublished && {
                    backgroundColor: deepPurple[50],
                  }),
                }}
                xs={12}
              >
                <Typography variant="h6">{name}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Droppable droppableId={id}>
                      {(provided) => (
                        <List
                          dense
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {materialsByStepId[id]?.map((material, i) => (
                            <Draggable
                              key={material.id}
                              draggableId={material.id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <ListItem
                                    style={{
                                      paddingRight: 232,
                                      ...(!material.isPublished && {
                                        backgroundColor: deepPurple[50],
                                      }),
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <div
                                        {...(!isDisabled &&
                                          provided.dragHandleProps)}
                                      >
                                        <DragHandleContainer
                                          isDisabled={isDisabled}
                                        >
                                          <DragHandleIcon
                                            color={
                                              isDisabled ? "disabled" : "action"
                                            }
                                          />
                                        </DragHandleContainer>
                                      </div>
                                    </ListItemAvatar>
                                    <Box height={44} mr={3} width={88}>
                                      <Image
                                        loader={
                                          <Skeleton
                                            height="100%"
                                            variant="rect"
                                            width="100%"
                                          />
                                        }
                                        src={material.image || "none"}
                                        style={{
                                          borderRadius: 4,
                                          maxHeight: 44,
                                          maxWidth: 88,
                                          objectFit: "cover",
                                        }}
                                        unloader={
                                          <Box
                                            alignItems="center"
                                            borderRadius={3.5}
                                            color="white"
                                            display="flex"
                                            height="100%"
                                            justifyContent="center"
                                            style={{ background: grey[400] }}
                                            width="100%"
                                          >
                                            <ImageIcon color="inherit" />
                                          </Box>
                                        }
                                      />
                                    </Box>

                                    <ListItemText
                                      primary={
                                        <Typography variant="body1">
                                          <ReactMarkdown renderers={renderers}>
                                            {material.name || "‎"}
                                          </ReactMarkdown>
                                        </Typography>
                                      }
                                      onDoubleClick={handleEdit(material)}
                                    />
                                    <ListItemSecondaryAction
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      <Tooltip title="Copy link">
                                        <span>
                                          <CopyToClipboardBase
                                            text={getMaterialDeeplink(material)}
                                          >
                                            {({ isCopied }) => (
                                              <Tooltip
                                                arrow
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                PopperProps={{
                                                  disablePortal: true,
                                                }}
                                                open={isCopied}
                                                title="Link copied to the clipboard"
                                              >
                                                <span>
                                                  <IconButton
                                                    disabled={!material.ctaUrl}
                                                  >
                                                    <LinkIcon />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            )}
                                          </CopyToClipboardBase>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          material.isPublished
                                            ? "Published (click to unpublish)"
                                            : "Unpublished (click to publish)"
                                        }
                                      >
                                        <span>
                                          <IconButton
                                            disabled={isDisabled}
                                            onClick={handlePublish(material)}
                                          >
                                            {material.isPublished ? (
                                              <VisibilityIcon />
                                            ) : (
                                              <VisibilityOffIcon />
                                            )}
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <span>
                                          <IconButton
                                            disabled={isDisabled}
                                            onClick={handleDelete(material)}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title="Edit">
                                        <span>
                                          <OutlinedButton
                                            disabled={isDisabled}
                                            onClick={handleEdit(material)}
                                          >
                                            Edit
                                          </OutlinedButton>
                                        </span>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  {i !== materials.length - 1 && <Divider />}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      disabled={isDisabled}
                      endIcon={<AddIcon />}
                      variant="contained"
                      onClick={handleCreateMaterial({ stepId: id })}
                    >
                      Add new material
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </DragDropContext>
        </Grid>
        {remove && (
          <DeleteMaterial
            material={remove}
            onClose={handleClose}
            onConfirm={handleDeleteMaterial}
          />
        )}
        {publish && (
          <PublishMaterial
            material={publish}
            onClose={handleClose}
            onSave={handleSave}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Materials;
