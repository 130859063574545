import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    justifyContent: "flex-start",
    padding: spacing(3),
  },
}))(MuiDialogActions);

export default DialogActions;
