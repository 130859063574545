import { useCallback, useEffect, useRef, useState } from "react";
import throttle from "lodash/throttle";

const useStickyHeight = () => {
  const containerRef = useRef();
  const [top, setTop] = useState();

  const handleFixedToggle = useCallback(
    throttle(() => {
      if (!containerRef.current) {
        return;
      }
      setTop(containerRef.current.getBoundingClientRect().top);
    }, 100),
    []
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setTop(containerRef.current.getBoundingClientRect().top);
  }, []);

  useEffect(() => {
    document
      .getElementsByClassName("scrollarea")[0]
      ?.addEventListener("scroll", handleFixedToggle);

    return () => {
      document
        .getElementsByClassName("scrollarea")[0]
        ?.removeEventListener("scroll", handleFixedToggle);
    };
  }, [handleFixedToggle]);

  return {
    containerRef,
    height: top && `calc(100vh - ${top}px - 36px)`,
  };
};

export default useStickyHeight;
