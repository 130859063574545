import { useLocation, useRouteMatch } from "react-router-dom";
import { useAppContext } from "../../api/AppContext";
import React, { useEffect, useMemo } from "react";
import Loader from "../shared/Loader";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import EditMaterial from "./EditMaterial";
import Typography from "@material-ui/core/Typography";

const Material = () => {
  const match = useRouteMatch();
  const { journeyId, locale, id } = match.params;

  const { state } = useLocation();
  const { isNew, ...newMaterial } = state || {};

  const {
    materials,
    fetchMaterials,
    isMaterialsLoading,
    journeys,
    fetchJourneys,
    isJourneysLoading,
    updateJourneyMaterial,
  } = useAppContext();

  useEffect(() => {
    if (!materials.length) {
      fetchMaterials({ journeyId, locale });
    }

    if (!journeys.length) {
      fetchJourneys();
    }
  }, [journeyId, locale]);

  const journey = useMemo(
    () =>
      journeys.find(
        (journey) => journey.locale === locale && journey.id === journeyId
      ),
    [id, journeys, locale]
  );

  const alreadySelectedFlowIds = useMemo(
    () => materials.map((m) => m.flowId),
    [materials]
  );

  const material = useMemo(
    () =>
      isNew
        ? { ...newMaterial, isNew, journeyId }
        : materials.find((material) => material.id === id),
    [id, isNew, journeyId, materials, newMaterial]
  );

  if (isMaterialsLoading || isJourneysLoading) {
    return <Loader />;
  }

  if (!material) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Material is not found 😢</Typography>
      </Box>
    );
  }

  const isDisabled = journey.isDisabled;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {isDisabled && (
        <Alert severity="info">
          Journey interactions is not editable! Copy Journey to edit 💡
        </Alert>
      )}
      <Box
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        height="100%"
        p={4}
        width="100%"
      >
        <EditMaterial
          alreadySelectedFlowIds={alreadySelectedFlowIds}
          isDisabled={isDisabled}
          journey={journey}
          journeys={journeys}
          locale={locale}
          material={material}
          onUpdate={updateJourneyMaterial}
        />
      </Box>
    </Box>
  );
};

export default Material;
