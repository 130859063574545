import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const callBehaviourOptionsMap = {
  delay: "is sent once the call is finished",
  doNotSend: "is not sent at all",
  sendAnyway: "is sent anyway",
};

const getCallBehaviourOptions = function () {
  return { ...callBehaviourOptionsMap };
};

const CallBehaviourSelect = (props) => {
  return (
    <TextField
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      label={props.label || "If the user is in call, this message:"}
      variant="outlined"
      {...props}
    >
      {Object.keys(callBehaviourOptionsMap).map((k) => {
        return (
          <MenuItem key={k} value={k}>
            {callBehaviourOptionsMap[k]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CallBehaviourSelect;
export { getCallBehaviourOptions };
