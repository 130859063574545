import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as AdaptiveCards from "adaptivecards";
import markdownit from "markdown-it";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useAppContext } from "../../api/AppContext";
import * as hostConfigLight from "./microsoft-teams-light.json";

import IFrame from "../shared/IFrame";

import "./teams-container-light.css";
import Loader from "../shared/Loader";

// To render markdown:
AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
  result.outputHtml = markdownit().render(text);
  result.didProcess = true;
};

function CardContainer({ child }) {
  const containerRef = useRef(null);
  useEffect(() => {
    if (child && containerRef.current)
      if (!containerRef.current.firstChild) {
        containerRef.current.appendChild(child);
      } else {
        containerRef.current.firstChild.replaceWith(child);
      }
  }, [child]);

  return <div ref={containerRef}></div>;
}

const Preview = ({
  semantic,
  semanticId,
  userId,
  src,
  defaultWidth,
  defaultHeight,
  children,
  isClientPreviewDefault = true,
  viewType,
}) => {
  const ref = useRef();

  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);
  const [isExportImageProcessing, setIsExportImageProcessing] = useState(false);
  const [isSizeReceived, setIsSizeReceived] = useState(false);

  const [isClientPreview, setIsClientPreview] = useState(
    viewType ? viewType === "client" : isClientPreviewDefault
  );

  useEffect(() => {
    if (viewType) {
      setIsClientPreview(viewType === "client");
    }
  }, [viewType]);

  const { exportImage } = useAppContext();

  useEffect(() => {
    const listener = (e) => {
      try {
        const { data } = e;

        if (data.type !== "rendered") {
          return;
        }

        setWidth(data.width);
        setHeight(data.height);
        setIsSizeReceived(true);
        // eslint-disable-next-line no-empty
      } catch {}
    };
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, []);

  const [card, setCard] = useState();
  const [htmlCard, sethtmlCard] = useState(null);

  useEffect(() => {
    if ((!semantic && !semanticId) || isClientPreview) {
      return;
    }

    setCard(null);

    fetch(
      process.env[
        `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_teams_get_card`
      ],
      // e.g.: "https://teams.knowledgehubapp.habitinspiringplatform.com/api/get-card",
      {
        body: JSON.stringify({
          semantic,
          semanticId,
          userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((body) => setCard(body.card));
  }, [semantic, isClientPreview, semanticId, userId]);

  useEffect(() => {
    if (card) {
      const adaptiveCard = new AdaptiveCards.AdaptiveCard();

      // Set its hostConfig property unless you want to use the default Host Config
      // Host Config defines the style and behavior of a card
      adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(hostConfigLight);

      // Set the adaptive card's event handlers. onExecuteAction is invoked
      // whenever an action is clicked in the card
      adaptiveCard.onExecuteAction = function (action) {
        alert(`Cta clicked! Action would be => ${JSON.stringify(action)}`);
      };
      // Parse the card payload
      adaptiveCard.parse(card);
      sethtmlCard(adaptiveCard.render());
    }
  }, [card, hostConfigLight]);

  const reload = useCallback(() => {
    setIsSizeReceived(false);
    ref.current?.contentWindow.location.replace(src);
  }, [src]);

  const callExportImageLambda = useCallback(async () => {
    setIsExportImageProcessing(true);

    const exportImageRequest = {
      clip: {
        height,
        width,
        x: 0,
        y: 0,
      },
      src,
    };
    // console.log("exportImageRequest: " + JSON.stringify(exportImageRequest));
    const result = await exportImage(exportImageRequest);

    if (result.imageB64) {
      console.log("there is a result");
      const linkSource = `data:image/png;base64,${result.imageB64}`;
      const downloadLink = document.createElement("a");
      const fileName = `export-${semantic.id}.png`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    setIsExportImageProcessing(false);
  }, [src, semantic, width, height, exportImage]);

  const openExportImage = useCallback(() => {
    const encodedParams = window.btoa(JSON.stringify(src));
    window.open(`/export?url=${encodedParams}`);
  }, [src]);

  const iframe = useMemo(
    () => (
      <Grid container spacing={2}>
        {!viewType && (
          <Grid item xs={12}>
            <Grid container alignItems="center" component="label" spacing={1}>
              <Grid item>
                <Typography
                  color={isClientPreview ? "default" : "primary"}
                  variant="button"
                >
                  Teams App
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={isClientPreview}
                  color="primary"
                  onChange={(e) => setIsClientPreview(e.target.checked)}
                />
              </Grid>
              <Grid item>
                <Typography
                  color={isClientPreview ? "primary" : "default"}
                  variant="button"
                >
                  Client
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {isClientPreview ? (
            <IFrame
              ref={ref}
              height={height}
              id="iframe"
              src={src}
              title="preview"
              width={width}
            />
          ) : (
            <>
              {htmlCard ? (
                // <AdaptiveCard hostConfig={{}} payload={card} />
                <div
                  className="teams-inner-frame"
                  // style={{ backgroundColor: "white" }}
                >
                  <CardContainer child={htmlCard} />
                </div>
              ) : (
                <Loader inline />
              )}
            </>
          )}
        </Grid>
      </Grid>
    ),
    [viewType, isClientPreview, height, src, width, htmlCard]
  );

  return children !== undefined
    ? children({
        callExportImageLambda,
        height,
        iframe,
        isExportImageProcessing,
        isSizeReceived,
        openExportImage,
        reload,
        width,
      })
    : iframe;
};

export default Preview;
