import * as yup from "yup";
import React, { useCallback, useEffect, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import useModal from "../hooks/useModal";
import { useAppContext } from "../api/AppContext";

const validationSchema = yup.object().shape({
  cid: yup.string().required(),
  force: yup.boolean(),
});

const Autocomplete = withStyles({
  option: {
    display: "block",
    padding: 0,
    paddingLeft: "0 !important",
  },
})(MuiAutocomplete);

const CopyActivationLinkModal = ({ onConfirm, onClose } = {}) => {
  const { configurations, fetchConfigurations, isConfigurationsLoading } =
    useAppContext();
  const { handleConfirm } = useModal({
    onClose,
    onConfirm,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cid: "",
      force: false,
    },
    onSubmit: async (values) => {
      await handleConfirm(values);
    },
    validationSchema,
    validator: () => ({}),
  });

  const handleCompanyChange = useCallback(
    (e, company) => {
      formik.setFieldValue("cid", company?.cid);
      formik.setFieldTouched("cid", true, false);
    },
    [formik]
  );

  const configurationsByCid = useMemo(
    () => keyBy(configurations, "cid"),
    [configurations]
  );

  useEffect(() => {
    fetchConfigurations();
  }, []);

  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Select company
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                getOptionLabel={({ name }) => name}
                loading={isConfigurationsLoading}
                options={configurations}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(formik.errors.cid)}
                    helperText={formik.errors.cid}
                    label="Company"
                    margin="none"
                    name="cid"
                    placeholder="Choose from the list"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                  />
                )}
                renderOption={(option) => (
                  <>
                    <Box
                      boxSizing="border-box"
                      display="flex"
                      justifyContent="space-between"
                      p={2}
                      width="100%"
                    >
                      <Box>{option.name}</Box>
                    </Box>
                  </>
                )}
                value={configurationsByCid[formik.values.cid] || null}
                onChange={handleCompanyChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.force}
                    color="primary"
                    name="force"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                }
                label="Force change (skip user confirmation dialog)"
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Confirm
        </Button>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyActivationLinkModal;
