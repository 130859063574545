import styled from "styled-components/macro";

import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import InfoIcon from "@material-ui/icons/Info";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const InfoTooltip = ({ title, text, color = "action" }) =>
  (title || text) && (
    <Tooltip
      placement="top-start"
      title={
        <Box p={2}>
          {title && <Typography variant="subtitle2">{title}</Typography>}
          {text && title && <br />}
          {text && <Typography variant="caption">{text}</Typography>}
        </Box>
      }
    >
      <Container>
        <InfoIcon color={color} fontSize="small" />
      </Container>
    </Tooltip>
  );

export default InfoTooltip;
