import * as yup from "yup";
import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "../shared/Dialog";
import DialogActions from "../DialogActions";
import Alert from "@material-ui/lab/Alert";
import useConfirmLeave from "../../hooks/useConfirmLeave";
import useModal from "../../hooks/useModal";
import SpecialConditions from "../Interaction/SpecialConditions";
import { DateTimePicker, TimePicker } from "@material-ui/pickers";
import { getFieldProps } from "../utils";
import TextField from "@material-ui/core/TextField";
import InfoTooltip from "../InfoTooltip";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import { interactionPeriodOptions } from "../../lib/scheduledSlotUtilities";

const validationSchema = ({ scheduledSlot, scheduledSlots, days }) =>
  yup.object().shape({
    conditions: yup.array().of(
      Yup.object({
        from: Yup.number()
          .min(1)
          .max(
            days + 1,
            `This Journey is ${days} days long. You cannot exceed ${
              days + 1
            } days.`
          ),
        to: Yup.number()
          .min(1)
          .max(
            days + 1,
            `This Journey is ${days} days long. You cannot exceed ${
              days + 1
            } days.`
          ),
      })
    ),
    datePeriod: yup.mixed().oneOf(Object.keys(interactionPeriodOptions)),
    datetimeEnd: yup
      .date()
      .when(
        "datetimeStart",
        (datetimeStart, yup) =>
          datetimeStart && yup.min(datetimeStart, "End should be after start")
      ),
    isAlwaysVisibleInUDashboard: yup.boolean(),
    specificInterval: yup.object().shape({
      from: yup.object(),
      to: yup
        .object()
        .test(
          "specificInterval.to",
          "To should be after From",
          function (spIntTo) {
            return spIntTo.hours === this.parent.from.hours
              ? spIntTo.minutes >= this.parent.from.minutes
              : spIntTo.hours >= this.parent.from.hours;
          }
        ),
    }),
  });

const EditScheduledSlotModal = ({
  scheduledSlot,
  onClose,
  onConfirm,
  days,
  isDisabled,
  isNew = false,
  scheduledSlots = [],
  userStatePaths,
  surveys,
  journeyId,
}) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });
  const defaultDatetimeStart = new Date();
  defaultDatetimeStart.setHours(24, 0, 0, 0); // next midnight, in current timezone
  const defaultDatetimeEnd = new Date(defaultDatetimeStart.getTime()); // copy defaultDatetimeStart
  defaultDatetimeEnd.setHours(defaultDatetimeEnd.getHours() + 23, 59); // add 23h and 59 minutes

  const formik = useFormik({
    initialValues: {
      conditions: [],
      ...scheduledSlot,
      datePeriod: scheduledSlot.datePeriod
        ? scheduledSlot.datePeriod
        : Object.keys(interactionPeriodOptions).filter(
            (e) => interactionPeriodOptions[e].isDefault
          )[0],
      datetimeEnd: scheduledSlot.datetimeEnd
        ? new Date(scheduledSlot.datetimeEnd)
        : defaultDatetimeEnd,
      datetimeStart: scheduledSlot.datetimeStart
        ? new Date(scheduledSlot.datetimeStart)
        : defaultDatetimeStart,
      isAlwaysVisibleInUDashboard:
        scheduledSlot.isAlwaysVisibleInUDashboard || false,
      specificInterval: scheduledSlot.specificInterval
        ? scheduledSlot.specificInterval
        : { from: { hours: 10, minutes: 0 }, to: { hours: 12, minutes: 0 } },
    },
    onSubmit: (values) => {
      if (values.datePeriod !== "SPECIFIC_INTERVAL") {
        // this is effective in avoiding saving extra data just on first creation;
        // the be service is not deleting fields during update
        delete values.specificInterval;
      }
      handleConfirm({
        ...values,
        datetimeStart: new Date(values.datetimeStart).getTime(), // store directly the milliseconds
        datetimeEnd: new Date(values.datetimeEnd).getTime(),
      });
    },
    validationSchema: validationSchema({
      days: days.length,
      scheduledSlot,
      scheduledSlots,
    }),
    validator: () => ({}),
  });

  const now = new Date();

  //=== initialize timeFromValue
  let timeFromValue = getFieldProps(formik, {
    disabled: isDisabled,
    name: `specificInterval.from`,
  }).value;

  if (timeFromValue?.hours >= 0) {
    timeFromValue = new Date(
      `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()} ${
        timeFromValue.hours
      }:${timeFromValue.minutes}`
    );
  }
  // console.log("timeFromValue => ", timeFromValue);
  //===

  //=== initialize timeToValue
  let timeToValue = getFieldProps(formik, {
    disabled: isDisabled,
    name: `specificInterval.to`,
  }).value;

  if (timeToValue?.hours >= 0) {
    timeToValue = new Date(
      `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()} ${
        timeToValue.hours
      }:${timeToValue.minutes}`
    );
  }
  //===

  const { onClose: handleClose } = useConfirmLeave({
    formik,
    onClose,
  });

  // const { enqueueSnackbar } = useSnackbar();

  const timePickerValueToEvent = (name) => (func) => (value) =>
    func({
      target: {
        name,
        value: {
          hours: value.getHours(),
          minutes: value.getMinutes(),
        },
      },
    });

  const handleDatetimeStartChange = useCallback(
    (value) => {
      formik.setFieldValue("datetimeStart", value);
      formik.setFieldTouched("datetimeStart");
    },
    [formik]
  );
  const handleDatetimeEndChange = useCallback(
    (value) => {
      formik.setFieldValue("datetimeEnd", value);
      formik.setFieldTouched("datetimeEnd");
    },
    [formik]
  );

  useEffect(() => {
    if (formik.values.datePeriod !== "SPECIFIC_INTERVAL") {
      // re-initialize specificInterval,
      // so to remove the possible validation error and allow saving
      formik.values.specificInterval = scheduledSlot.specificInterval
        ? scheduledSlot.specificInterval
        : { from: { hours: 10, minutes: 0 }, to: { hours: 12, minutes: 0 } };
    }
  }, [formik, scheduledSlot.specificInterval]);

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isNew ? "Create" : "Edit"} scheduled slot
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {journeyId && (
              <SpecialConditions
                days={days.length}
                defaultType="activeDays"
                formik={formik}
                isDisabled={isDisabled}
                journeyId={journeyId}
                surveys={surveys}
                types={[
                  "activeDays",
                  "userStatePath",
                  "survey",
                  "subscribedToWelfare",
                ]}
                userStatePaths={userStatePaths}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Time conditions</Typography>
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <DateTimePicker
                  autoOk
                  disablePast
                  fullWidth
                  ampm={false}
                  format="dd MMMM yyyy HH:mm"
                  inputVariant="outlined"
                  label={
                    <Box alignItems="center" display="flex">
                      <Box mr={1}>Start datetime</Box>
                      <InfoTooltip
                        color="primary"
                        text="Simultaneous for users in all time-zones. A unique/absolute point in time, for your convenience here formatted in browser's time-zone. Similar concept for the datetimeEnd picker."
                      />
                    </Box>
                  }
                  {...getFieldProps(formik, { name: "datetimeStart" })}
                  InputLabelProps={{
                    shrink: true,
                    style: { pointerEvents: "all" },
                  }}
                  maxDate={formik.values.datetimeEnd}
                  maxDateMessage="Date should not be after end datetime"
                  variant="inline"
                  onChange={handleDatetimeStartChange}
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <DateTimePicker
                  autoOk
                  disablePast
                  fullWidth
                  ampm={false}
                  format="dd MMMM yyyy HH:mm"
                  inputVariant="outlined"
                  label="End datetime"
                  {...getFieldProps(formik, { name: "datetimeEnd" })}
                  minDate={formik.values.datetimeStart}
                  minDateMessage="Date should not be before start datetime"
                  variant="inline"
                  onChange={handleDatetimeEndChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Interaction period"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: "datePeriod",
                  })}
                  value={
                    getFieldProps(formik, {
                      disabled: isDisabled,
                      name: "datePeriod",
                    }).value
                  }
                >
                  {Object.keys(interactionPeriodOptions).map((e) => (
                    <MenuItem key={e} value={e}>
                      {interactionPeriodOptions[e].label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {getFieldProps(formik, {
                disabled: isDisabled,
                name: "datePeriod",
              }).value === "SPECIFIC_INTERVAL" && (
                <>
                  <Grid item lg={2} md={2} xs={6}>
                    <TimePicker
                      autoOk
                      ampm={false}
                      disableFuture={false}
                      disablePast={false}
                      inputVariant="outlined"
                      label={
                        <Box alignItems="center" display="flex">
                          <Box mr={1}>From</Box>
                          <InfoTooltip
                            color="primary"
                            text="Not symultaneous for users across different time-zones: each user will have a chance receiving scheduled tips once that certain hour and minute is reached in his own timezone. Similar concept for the To time-picker."
                          />
                        </Box>
                      }
                      {...getFieldProps(formik, {
                        disabled: isDisabled,
                        name: `specificInterval.from`,
                      })}
                      InputLabelProps={{
                        shrink: true,
                        style: { pointerEvents: "all" },
                      }}
                      value={timeFromValue}
                      onChange={timePickerValueToEvent(`specificInterval.from`)(
                        getFieldProps(formik, {
                          disabled: isDisabled,
                          name: `specificInterval.from`,
                        }).onChange
                      )}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xs={6}>
                    <TimePicker
                      autoOk
                      ampm={false}
                      disableFuture={false}
                      disablePast={false}
                      inputVariant="outlined"
                      label="To"
                      {...getFieldProps(formik, {
                        disabled: isDisabled,
                        name: `specificInterval.to`,
                      })}
                      value={timeToValue}
                      onChange={timePickerValueToEvent(`specificInterval.to`)(
                        getFieldProps(formik, {
                          disabled: isDisabled,
                          name: `specificInterval.to`,
                        }).onChange
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              {
                // 36e5 = 60*60*1000; if difference is less than 2 hours, display the message
                formik.values.datePeriod === "SPECIFIC_INTERVAL" &&
                  Math.abs(timeToValue - timeFromValue) / 36e5 < 2 && (
                    <Grid item lg={7} md={7} xs={12}>
                      <Alert severity="warning">
                        Use an interaction period of at least 2 hours to reach
                        more users.
                      </Alert>
                    </Grid>
                  )
              }
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={7} md={7} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      {...getFieldProps(formik, {
                        defaultValue: false,
                        disabled: isDisabled,
                        name: "isAlwaysVisibleInUDashboard",
                      })}
                      checked={
                        getFieldProps(formik, {
                          name: "isAlwaysVisibleInUDashboard",
                        }).value
                      }
                    />
                  }
                  label={
                    <Box alignItems="center" display="flex">
                      <Box mr={1}>Always visible in the dashboard</Box>
                      <InfoTooltip
                        color="primary"
                        text="Check this checkbox if this tip will be shown in user's dashboard during all days of validity of this scheduled slot. If unchecked, this tip will be shown only on the day when the user saw it."
                      />
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={(!isNew && !formik.dirty) || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditScheduledSlotModal;
