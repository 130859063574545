import React, { useCallback, useEffect, useMemo, useState } from "react";
import removeMarkdown from "remove-markdown";
import Highlighter from "react-highlight-words";
import keyBy from "lodash/keyBy";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { listJourneySemantics } from "../../api";
import useLoading from "../../useLoading";

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.id} ${option.title} ${option.text}`,
});

const SemanticsSelect = ({
  journeyId,
  locale,
  onChange,
  value,
  isLoading,
  filter = () => true,
  primaryColumn = "id",
  multiple = false,
  disableClearable = true,
  disabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [semantics, setSemantics] = useState([]);

  const { isLoading: isSemanticsLoading, handler: fetchSemanticsHandler } =
    useLoading(listJourneySemantics);

  const fetchSemantics = async ({ journeyId, locale }) => {
    const semantics = await fetchSemanticsHandler({ journeyId, locale });
    setSemantics(semantics);
  };

  const options = useMemo(
    () =>
      semantics.filter(filter).map(({ id, title, text, flowId }) => ({
        id,
        flowId,
        title: removeMarkdown(title || "") || "No title.",
        text: removeMarkdown(text || "") || "No text.",
      })) ?? [],
    [filter, semantics]
  );

  const optionsByPrimaryColumn = keyBy(options, primaryColumn);

  useEffect(() => {
    if (semantics.length !== 0) {
      return;
    }

    fetchSemantics({ journeyId, locale });
  }, [journeyId, locale]);

  const handleChange = useCallback(
    (e, value, reason) => {
      setIsOpen(false);
      if (!["select-option", "remove-option", "clear"].includes(reason)) {
        return;
      }

      onChange?.(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      fullWidth
      disableClearable={disableClearable}
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option?.title}
      loading={isSemanticsLoading || isLoading}
      multiple={multiple}
      open={isOpen}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isSemanticsLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={25} />
              </InputAdornment>
            ) : (
              params.InputProps?.startAdornment
            ),
          }}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.title}
            >
              {option.title}
            </Highlighter>
          }
          secondary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.text}
            >
              {option.text}
            </Highlighter>
          }
        />
      )}
      value={
        multiple
          ? value.map((value) => optionsByPrimaryColumn[value])
          : optionsByPrimaryColumn[value] ?? null
      }
      onChange={handleChange}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    />
  );
};

export default SemanticsSelect;
