import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import invert from "lodash/invert";
import { DatePicker } from "@material-ui/pickers";

import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { getFieldProps, inputProps, multilineInputProps } from "../../utils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useTheme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";

import AsyncButton from "../../shared/AsyncButton";
import { useAppContext } from "../../../api/AppContext";
import Loader from "../../shared/Loader";
import { configureTypeform, getTypeform } from "../../../api";
import UserStatePathSelect from "../../Interaction/UserStatePathSelect";
import TypeformFieldsSelect from "../../shared/TypeformFieldsSelect";
import CopyToClipboard from "../../shared/CopyToClipboard";
import SemanticsSelect from "../../shared/SemanticsSelect";
import SaveButtons, { SaveErrors } from "../../SaveButtons";
import { Accordion, AccordionSummary } from "../../shared/Accordion";
import {
  hiSurveyOutcomeTypeTitles,
  typeformSurveyOutcomeTypeTitles,
} from "./consts";
import TypeformsSelect from "../../shared/TypeformsSelect";
import ReactMarkdown from "react-markdown";
import ListSubheader from "@material-ui/core/ListSubheader";
import { getScheduledSlotName } from "../../../lib/scheduledSlotUtilities";

const renderers = {
  paragraph: "span",
};

const validateTypeform = (form) => {
  const errors = [];
  if (!form.published_at) {
    errors.push("Form is not published");
  }

  if (!form.hidden?.includes("did")) {
    errors.push(`Hidden field "did" is missed in configuration`);
  }

  if (!form.hidden?.includes("survey_id")) {
    errors.push(`Hidden field "survey_id" is missed in configuration`);
  }

  const webhookUrl = `https://user.${
    process.env[
      `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
    ]
  }/device/typeform/webhook`;
  const webhookSecret = process.env.REACT_APP_TYPEFORM_WEBHOOK_SECRET;

  const webhook =
    form.webhooks?.find(
      ({ url, secret }) =>
        new URL(url).origin === new URL(webhookUrl).origin &&
        new URL(url).pathname === new URL(webhookUrl).pathname &&
        secret === webhookSecret
    ) ||
    form.webhooks?.find(
      ({ url }) =>
        new URL(url).origin === new URL(webhookUrl).origin &&
        new URL(url).pathname === new URL(webhookUrl).pathname
    );

  if (!webhook) {
    errors.push("Webhook is not configured");
  }

  if (webhook && !webhook.enabled) {
    errors.push("Webhook is not enabled");
  }

  if (webhook && !webhook.secret) {
    errors.push("Webhook secret is not configured");
  }

  if (webhook?.secret && webhook.secret !== webhookSecret) {
    errors.push("Webhook secret is not correct");
  }

  return errors;
};

const typeformUrlRegex = /^https:\/\/.+\.typeform\.com\/to\/(\w+)/;

const steps = [
  "General info",
  "Tool",
  "Distribution channels",
  "Outcomes",
  "Additional logics",
];

const Tools = ({
  formik,
  journeyId,
  locale,
  isSurveysLoading,
  surveys,
  isIntegrationsLoading,
  onIntegrationsFetch,
  integrations,
}) => {
  const [typeformErrors, setTypeformErrors] = useState([]);
  const [isTypeformLoading, setIsTypeformLoading] = useState(false);

  const handleConfigure = useCallback(async () => {
    const [, id] = formik.values.typeform.url.match(typeformUrlRegex) || [];

    if (!id) {
      return;
    }

    const form = await configureTypeform({
      id,
      integrationId: formik.values.typeformIntegrationId,
    });

    const errors = validateTypeform(form);
    setTypeformErrors(errors);
    formik.setFieldValue("typeform.isConfigured", errors.length === 0);
    formik.setFieldTouched("typeform.isConfigured", true);
  }, [formik]);

  const theme = useTheme();

  const handleTypeformAccountChange = useCallback(
    (e) => {
      if (e.target.value !== "new") {
        formik.setFieldValue("typeformIntegrationId", e.target.value);
        formik.setFieldTouched("typeformIntegrationId", true);
        return;
      }

      const state = crypto.randomUUID();
      const url = new URL("https://api.typeform.com/oauth/authorize");
      url.searchParams.append(
        "client_id",
        process.env[
          `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_typeform_client_id`
        ]
      );
      url.searchParams.append(
        "redirect_uri",
        `${window.location.origin}/settings/integrations/typeform`
      );
      url.searchParams.append("state", state);

      localStorage.setItem("HI::typeform:state", state);

      window.open(
        `${url}&scope=${[
          "accounts:read",
          "forms:write",
          "forms:read",
          "webhooks:read",
          "webhooks:write",
          "workspaces:read",
          "responses:read",
        ].join("+")}`
      );
    },
    [formik]
  );

  const handleTypeformChange = useCallback(
    async ({ id, _links }) => {
      formik.setFieldValue("typeform.url", _links.display);
      formik.setFieldTouched("typeform.url", true);
      formik.setFieldValue("typeformId", id);
      formik.setFieldTouched("typeformId", true);
      formik.setFieldValue("typeform.isConfigured", false);
      formik.setFieldTouched("typeform.isConfigured", true);

      setIsTypeformLoading(true);
      setTypeformErrors([]);
      try {
        const form = await getTypeform({
          id,
          integrationId: formik.values.typeformIntegrationId,
        });
        const errors = validateTypeform(form);
        setTypeformErrors(errors);
        formik.setFieldValue("typeform.isConfigured", errors.length === 0);
        formik.setFieldTouched("typeform.isConfigured", true);
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setIsTypeformLoading(false);
      }
    },
    [formik]
  );

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          select
          InputLabelProps={{ shrink: true }}
          label="Tool"
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, { name: "type" })}
        >
          <MenuItem value="hi">HI</MenuItem>
          <MenuItem value="typeform">Typeform</MenuItem>
        </TextField>
      </Grid>
      {formik.values.type === "hi" && (
        <Grid item xs={12}>
          <SemanticsSelect
            required
            filter={(semantic) =>
              semantic.isFirstInFlow &&
              surveys.every(
                (survey) =>
                  formik.values.id === survey.id ||
                  survey.flowId !== semantic.flowId
              )
            }
            isLoading={isSurveysLoading}
            journeyId={journeyId}
            label="Select which message contains this survey"
            locale={locale}
            primaryColumn="flowId"
            {...getFieldProps(formik, {
              name: "flowId",
            })}
            onChange={(value) => {
              formik.setFieldValue("flowId", value.flowId);
              formik.setFieldTouched("flowId", true);
            }}
          />
        </Grid>
      )}
      {formik.values.type === "typeform" && (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              label="Connected Typeform account"
              margin="none"
              variant="outlined"
              {...getFieldProps(formik, {
                name: "typeformIntegrationId",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isIntegrationsLoading && <CircularProgress size={25} />}
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                onOpen: onIntegrationsFetch,
              }}
              onChange={handleTypeformAccountChange}
            >
              <MenuItem value="new">Connect new</MenuItem>
              <Divider />
              {integrations.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TypeformsSelect
              InputProps={{
                startAdornment: isTypeformLoading ? (
                  <CircularProgress size={25} />
                ) : formik.values.typeform.isConfigured ? (
                  <CheckCircleIcon
                    style={{ color: theme.palette.success.main }}
                  />
                ) : (
                  <ErrorIcon color="error" />
                ),
              }}
              integrationId={formik.values.typeformIntegrationId}
              label="Form"
              {...getFieldProps(formik, {
                name: "typeformId",
                disabled: !formik.values.typeformIntegrationId,
                helperText: formik.values.typeformId && (
                  <Grid container style={{ flexDirection: "column" }}>
                    <Grid item>
                      <Typography variant="caption">View: </Typography>
                      <Link href={formik.values.typeform.url}>
                        {formik.values.typeform.url}
                      </Link>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">Edit: </Typography>
                      <Link
                        href={`https://admin.typeform.com/form/${formik.values.typeformId}/create`}
                      >
                        https://admin.typeform.com/form/
                        {formik.values.typeformId}
                        /create
                      </Link>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">Responses: </Typography>
                      <Link
                        href={`https://admin.typeform.com/form/${formik.values.typeformId}/results#responses`}
                      >
                        https://admin.typeform.com/form/
                        {formik.values.typeformId}
                        /results#responses
                      </Link>
                    </Grid>
                  </Grid>
                ),
              })}
              onChange={handleTypeformChange}
            />
          </Grid>
          {typeformErrors.length !== 0 && (
            <Grid item xs={12}>
              <Alert severity="error">
                <Grid container spacing={2} style={{ flexDirection: "column" }}>
                  <Grid item>
                    {typeformErrors.map((error, i) => (
                      <li key="i">{error}</li>
                    ))}
                  </Grid>
                  <Grid item>
                    <AsyncButton variant="contained" onClick={handleConfigure}>
                      Configure
                    </AsyncButton>
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">FAQ</Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1">
                  What configuration is needed in Typeform?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper>
                  <Box p={2}>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          {`Click "Configure" button`}
                        </Typography>
                      </li>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="body1"
                      >
                        OR
                      </Typography>
                      <li>
                        <Typography variant="body1">
                          Add these 2 hidden fields (did, survey_id)
                        </Typography>
                        <Box pb={2} pt={2}>
                          <img
                            alt=""
                            src="/surveys/faq/screenshot1.png"
                            style={{ maxWidth: "100%" }}
                          />
                        </Box>
                      </li>
                      <li>
                        <Typography variant="body1">
                          {`Add a webhook from "Connect" section with url`}
                          <CopyToClipboard
                            text={`https://user.${
                              process.env[
                                `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                              ]
                            }/device/typeform/webhook`}
                          >
                            <Link
                              href={`https://user.${
                                process.env[
                                  `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                                ]
                              }/device/typeform/webhook`}
                            >
                              {`https://user.${
                                process.env[
                                  `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                                ]
                              }/device/typeform/webhook`}
                            </Link>
                          </CopyToClipboard>{" "}
                          and secret{" "}
                          <CopyToClipboard
                            text={process.env.REACT_APP_TYPEFORM_WEBHOOK_SECRET}
                          >
                            *********************************
                          </CopyToClipboard>
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </>
      )}
    </>
  );
};

const DistributionChannels = ({ formik, semantics, journeyId, locale }) => {
  const { scheduledSlots } = useAppContext();
  const linkedSemantics = useMemo(
    () =>
      formik.values.id
        ? semantics?.filter(({ cta1LinkId, cta2LinkId, dropdownData }) =>
            [
              cta1LinkId,
              cta2LinkId,
              ...(dropdownData ?? []).map(({ ctaTarget }) => ctaTarget),
            ].includes(formik.values.id)
          )
        : [],
    [formik.values.id, semantics]
  );

  const linkedScheduledSlots = useMemo(
    () =>
      formik.values.id
        ? scheduledSlots?.filter(({ conditions }) =>
            [...(conditions ?? []).map(({ values }) => values)].includes(
              formik.values.id
            )
          )
        : [],
    [formik.values.id, scheduledSlots]
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    getFieldProps(formik, {
                      name: "distributionChannels.link.isEnabled",
                    }).value
                  }
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "distributionChannels.link.isEnabled",
                      e.target.checked
                    );
                    formik.setFieldTouched(
                      "distributionChannels.link.isEnabled",
                      true
                    );
                  }}
                />
              }
              label="Link"
            />
          </Grid>
          {formik.values.distributionChannels.link.isEnabled && (
            <Grid item style={{ flex: 1 }}>
              <Typography variant="body1">
                Share it via this link{" "}
                {formik.values.id ? (
                  <CopyToClipboard
                    text={`https://deeplink.${
                      process.env[
                        `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                      ]
                    }/surveys/${formik.values.id}`}
                  >
                    <Link
                      href={`https://deeplink.${
                        process.env[
                          `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                        ]
                      }/surveys/${formik.values.id}`}
                    >
                      {`https://deeplink.${
                        process.env[
                          `REACT_APP_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_hi_domain_name`
                        ]
                      }/surveys/${formik.values.id}`}
                    </Link>
                  </CopyToClipboard>
                ) : (
                  "available after save"
                )}
              </Typography>
              <Alert severity="info">
                Will work without opening the user dashboard nor blinking since
                version <b>24.0.112.0</b>
              </Alert>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    getFieldProps(formik, {
                      name: "distributionChannels.hi.isEnabled",
                    }).value
                  }
                  color="primary"
                  disabled={
                    getFieldProps(formik, {
                      name: "distributionChannels.hi.isEnabled",
                      disabled: formik.values.type === "typeform",
                    }).disabled
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "distributionChannels.hi.isEnabled",
                      e.target.checked
                    );
                    formik.setFieldTouched(
                      "distributionChannels.hi.isEnabled",
                      true
                    );
                  }}
                />
              }
              label="HI"
            />
          </Grid>
          {formik.values.distributionChannels.hi.isEnabled && (
            <>
              <Grid item style={{ flex: 1 }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="body1">
                      Repeat this tip until user completes the survey. Stop
                      after
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: 200 }}>
                    <TextField
                      fullWidth
                      select
                      InputLabelProps={{ shrink: true }}
                      margin="none"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        name: "distributionChannels.hi.stopAfterTimes",
                      })}
                    >
                      <MenuItem value={1}>1 times</MenuItem>
                      <MenuItem value={2}>2 times</MenuItem>
                      <MenuItem value={3}>3 times</MenuItem>
                      <MenuItem value={4}>4 times</MenuItem>
                      <MenuItem value={5}>5 times</MenuItem>
                      <MenuItem value={6}>6 times</MenuItem>
                      <MenuItem value={7}>7 times</MenuItem>
                      <MenuItem value={8}>8 times</MenuItem>
                      <MenuItem value={9}>9 times</MenuItem>
                      <MenuItem value={10}>10 times</MenuItem>
                      <MenuItem value={1000}>unlimited times</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">times</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {formik.values.type === "typeform" && (
        <Grid container>
          <Grid item xs={12}>
            <List
              dense
              subheader={<ListSubheader>Linked tips/nudges</ListSubheader>}
            >
              {linkedSemantics.length === 0 && (
                <Typography style={{ textAlign: "center" }} variant="body2">
                  No linked tips/nudges yet 😢
                </Typography>
              )}
              {linkedSemantics.map(
                ({
                  id,
                  type,
                  title,
                  text,
                  journeyId: semanticJourneyId,
                  locale: semanticLocale,
                }) => (
                  <a
                    key={id}
                    href={`/journeys/${semanticJourneyId}/${semanticLocale}/${type.toLowerCase()}s/${id}`}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <ListItem button>
                      <ListItemText
                        primary={
                          <ReactMarkdown renderers={renderers}>
                            {text}
                          </ReactMarkdown>
                        }
                        secondary={
                          <ReactMarkdown renderers={renderers}>
                            {title}
                          </ReactMarkdown>
                        }
                      />
                    </ListItem>
                  </a>
                )
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <List
              dense
              subheader={<ListSubheader>Linked scheduled slots</ListSubheader>}
            >
              {linkedScheduledSlots.length === 0 && (
                <Typography style={{ textAlign: "center" }} variant="body2">
                  No linked scheduled slots yet 😢
                </Typography>
              )}
              {linkedScheduledSlots.map(
                ({
                  journeyId: scheduledSlotJourneyId,
                  datePeriod,
                  datetimeEnd,
                  datetimeStart,
                  id,
                  specificInterval,
                }) => {
                  const scheduledSlotName = getScheduledSlotName({
                    datePeriod,
                    datetimeEnd,
                    datetimeStart,
                    specificInterval,
                  });
                  return (
                    <ListItem key={id}>
                      <ListItemText
                        primary={
                          <ReactMarkdown renderers={renderers}>
                            JourneyId: {scheduledSlotJourneyId}
                          </ReactMarkdown>
                        }
                        secondary={
                          <ReactMarkdown renderers={renderers}>
                            {scheduledSlotName}
                          </ReactMarkdown>
                        }
                      />
                    </ListItem>
                  );
                }
              )}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const Outcome = ({ formik, journeyId, locale }) => {
  const { fetchUserStatePaths, userStatePaths, isUserStatePathsLoading } =
    useAppContext();

  useEffect(() => {
    fetchUserStatePaths();
  }, []);

  const handleAddField = useCallback(() => {
    if (
      ![
        "category-multiple",
        "scoring-multiple",
        "scoring-category-multiple",
      ].includes(formik.values.outcome.type)
    ) {
      return;
    }
    formik.setFieldValue(
      `typeform.fields.${formik.values.typeform.fields.length}`,
      {
        fieldId:
          formik.values.outcome.type === "category"
            ? "category"
            : formik.values.outcome.type === "score"
            ? "score"
            : null,
        fieldName: null,
        type:
          formik.values.outcome.type === "scoring-multiple"
            ? "score"
            : "category",
        userStatePath: null,
      }
    );
    formik.setFieldTouched(
      `typeform.fields.${formik.values.typeform.fields.length}`,
      true
    );
  }, [formik]);

  const handleDeleteField = useCallback(
    (i) => () => {
      const newFields = [...formik.values.typeform.fields];
      newFields.splice(i, 1);
      formik.setFieldValue("typeform.fields", newFields);
      formik.setFieldTouched("typeform.fields", true);
    },
    [formik]
  );

  const handleAddSemanticCategoryMapping = useCallback(() => {
    formik.setFieldValue(
      `outcome.hiSemanticCategoryMapping.${formik.values.outcome.hiSemanticCategoryMapping.length}`,
      {
        category: null,
        semanticId: null,
      }
    );
    formik.setFieldTouched(
      `outcome.hiSemanticCategoryMapping.${formik.values.outcome.hiSemanticCategoryMapping.length}`,
      true
    );
  }, [formik]);

  const handleDeleteSemanticCategoryMapping = useCallback(
    (i) => () => {
      const newMappings = [...formik.values.outcome.hiSemanticCategoryMapping];
      newMappings.splice(i, 1);
      formik.setFieldValue("outcome.hiSemanticCategoryMapping", newMappings);
      formik.setFieldTouched("outcome.hiSemanticCategoryMapping", true);
    },
    [formik]
  );

  const handleOutcomeTypeChange = useCallback(
    (e) => {
      formik.setFieldValue("outcome.type", e.target.value);
      formik.setFieldTouched("outcome.type", true);

      if (formik.values.type !== "typeform") {
        formik.setFieldValue("typeform.fields", []);
        formik.setFieldTouched("typeform.fields", true);
        return;
      }

      formik.setFieldValue("typeform.outcome.hiSemanticCategoryMapping", []);
      formik.setFieldTouched(
        "typeform.outcome.hiSemanticCategoryMapping",
        true
      );

      let fields = [];

      if (
        formik.values.type === "typeform" &&
        ["category-single", "scoring-single"].includes(e.target.value)
      ) {
        fields = [
          {
            fieldId: e.target.value === "scoring-single" ? "score" : "category",
            fieldName: null,
            type: e.target.value === "scoring-single" ? "score" : "category",
            userStatePath: null,
          },
        ];
      }

      if (e.target.value === "scoring-category-single") {
        fields = [
          {
            fieldId: null,
            fieldName: null,
            userStatePath: null,
            type: "score",
          },
          {
            fieldId: null,
            fieldName: null,
            userStatePath: null,
            type: "category",
          },
        ];
      }

      formik.setFieldValue("typeform.fields", fields);
      formik.setFieldTouched("typeform.fields", true);

      formik.setFieldValue(
        "additionalLogic.successFail.type",
        e.target.value === "scoring-category-multiple"
          ? null
          : e.target.value.startsWith("scoring")
          ? "score"
          : "category"
      );
      formik.setFieldTouched("additionalLogic.successFail.type", true);
    },
    [formik]
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">
          {`Determine and configure how the survey ends and what's the returned
          outcome for the user`}
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, {
            name: "outcome.type",
          })}
          onChange={handleOutcomeTypeChange}
        >
          {formik.values.type === "hi" &&
            Object.entries(hiSurveyOutcomeTypeTitles).map(([value, title]) => (
              <MenuItem key={value} value={value}>
                {title}
              </MenuItem>
            ))}

          {formik.values.type === "typeform" &&
            Object.entries(typeformSurveyOutcomeTypeTitles).map(
              ([value, title]) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              )
            )}
        </TextField>
      </Grid>

      {formik.values.type === "hi" &&
        formik.values.outcome.type === "category-single" && (
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              margin="none"
              variant="outlined"
              {...getFieldProps(formik, {
                name: "outcome.hiAssignType",
              })}
              label="Define how the category will be assigned"
            >
              <MenuItem value="dropdown-selection">Dropdown selection</MenuItem>
              <MenuItem value="tip-reached">Tip reached</MenuItem>
            </TextField>
          </Grid>
        )}
      {formik.values.type === "hi" &&
        formik.values.outcome.type === "category-single" && (
          <>
            {formik.values.outcome.hiAssignType === "dropdown-selection" && (
              <Grid item md={4} xs={12}>
                <SemanticsSelect
                  fullWidth
                  required
                  filter={(item) =>
                    item.flowId === formik.values.flowId &&
                    item.ctaType === "DROPDOWN"
                  }
                  journeyId={journeyId}
                  label="Select which dropdown corresponds to outcome"
                  locale={locale}
                  {...getFieldProps(formik, {
                    name: "outcome.hiDropdownSemanticId",
                  })}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "outcome.hiDropdownSemanticId",
                      value.id
                    );
                    formik.setFieldTouched("outcome.hiDropdownSemanticId");
                  }}
                />
              </Grid>
            )}
            {formik.values.outcome.hiAssignType === "tip-reached" && (
              <>
                <Grid item md={4} xs={12}>
                  <UserStatePathSelect
                    required
                    isLoading={isUserStatePathsLoading}
                    label="Save the result in the following field"
                    userStatePaths={userStatePaths}
                    {...getFieldProps(formik, {
                      name: "outcome.hiUserStatePath",
                    })}
                    onChange={(value) => {
                      formik.setFieldValue("outcome.hiUserStatePath", value);
                      formik.setFieldTouched("outcome.hiUserStatePath", true);
                    }}
                  />
                </Grid>

                {formik.values.outcome.hiSemanticCategoryMapping.map(
                  ({ semanticId, category }, i) => (
                    <Grid key={`${semanticId}-${category}`} item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item style={{ flex: 1 }}>
                          <SemanticsSelect
                            required
                            filter={(semantic) =>
                              semantic.flowId === formik.values.flowId
                            }
                            journeyId={journeyId}
                            label="Tip"
                            locale={locale}
                            {...getFieldProps(formik, {
                              name: `outcome.hiSemanticCategoryMapping.${i}.semanticId`,
                              disabled: !formik.values.flowId,
                              helperText:
                                !formik.values.flowId &&
                                'Select message first in "Tool" tab',
                            })}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `outcome.hiSemanticCategoryMapping.${i}.semanticId`,
                                value.id
                              );
                              formik.setFieldTouched(
                                `outcome.hiSemanticCategoryMapping.${i}.semanticId`,
                                true
                              );
                            }}
                          />
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            InputProps={inputProps}
                            label="Category"
                            margin="none"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              name: `outcome.hiSemanticCategoryMapping.${i}.category`,
                              disabled: !formik.values.flowId,
                              helperText:
                                !formik.values.flowId &&
                                'Select message first in "Tools" tab',
                            })}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={handleDeleteSemanticCategoryMapping(i)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={handleAddSemanticCategoryMapping}
                  >
                    Add new mapping
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}

      {["no-outcome", "self-evaluation"].includes(formik.values.outcome.type) &&
        formik.values.type === "hi" && (
          <>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  name: "outcome.hiCompleteType",
                })}
                label="Define when the survey can be considered completed"
              >
                <MenuItem value="cta-clicked">
                  {`CTA clicked (doesn't work in Teams App)`}
                </MenuItem>
                <MenuItem value="tip-reached">Tip reached</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <SemanticsSelect
                fullWidth
                multiple
                required
                disableClearable={false}
                filter={(item) => item.flowId === formik.values.flowId}
                journeyId={journeyId}
                label="Select which tip(s) will end the survey"
                locale={locale}
                {...getFieldProps(formik, {
                  name: "outcome.hiCompleteSemanticIds",
                  disabled: !formik.values.flowId,
                  helperText:
                    !formik.values.flowId &&
                    'Select message first in "Tool" tab',
                })}
                onChange={(values) => {
                  formik.setFieldValue(
                    "outcome.hiCompleteSemanticIds",
                    values.map(({ id }) => id)
                  );
                  formik.setFieldTouched("outcome.hiCompleteSemanticIds", true);
                }}
              />
            </Grid>
          </>
        )}

      {formik.values.type === "typeform" && (
        <>
          {[
            "scoring-category-single",
            "category-multiple",
            "scoring-multiple",
            "scoring-category-multiple",
          ].includes(formik.values.outcome.type) && (
            <Grid item xs={12}>
              <Typography variant="body1">
                Save the result of the survey in the fields:
              </Typography>
            </Grid>
          )}

          {["category-single", "scoring-single"].includes(
            formik.values.outcome.type
          ) && (
            <Grid item xs={12}>
              <Typography variant="body1">
                Save the result of the survey in the field:
              </Typography>
            </Grid>
          )}
          {!["no-outcome", "self-evaluation"].includes(
            formik.values.outcome.type
          ) && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {(formik.values.typeform.fields ?? []).map(
                  ({ fieldId, fieldName, type, userStatePath }, i) => (
                    <Grid key={i} item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item style={{ flex: 1 }}>
                          <UserStatePathSelect
                            isLoading={isUserStatePathsLoading}
                            label={`User state field for ${type}`}
                            userStatePaths={userStatePaths}
                            {...getFieldProps(formik, {
                              name: `typeform.fields.${i}.userStatePath`,
                            })}
                            onChange={(value) => {
                              formik.setFieldValue(
                                `typeform.fields.${i}.userStatePath`,
                                value
                              );
                              formik.setFieldTouched(
                                `typeform.fields.${i}.userStatePath`,
                                true
                              );
                            }}
                          />
                        </Grid>
                        {formik.values.outcome.type ===
                          "scoring-category-multiple" && (
                          <Grid item style={{ flex: 1 }}>
                            <TextField
                              fullWidth
                              required
                              select
                              InputLabelProps={{ shrink: true }}
                              margin="none"
                              variant="outlined"
                              {...getFieldProps(formik, {
                                name: `typeform.fields.${i}.type`,
                              })}
                            >
                              <MenuItem value="score">Score</MenuItem>
                              <MenuItem value="category">Category</MenuItem>
                            </TextField>
                          </Grid>
                        )}

                        <Grid item style={{ flex: 1 }}>
                          <TypeformFieldsSelect
                            integrationId={formik.values.typeformIntegrationId}
                            label="Field in Typeform"
                            type={type === "category" ? "string" : "number"}
                            typeformId={formik.values.typeformId}
                            {...getFieldProps(formik, {
                              name: `typeform.fields.${i}.fieldId`,
                              helperText: !formik.values.typeformId
                                ? "Select Typeform Form first"
                                : !formik.values.typeformIntegrationId &&
                                  "Select Typeform account and form first",
                              disabled:
                                !formik.values.typeformId ||
                                !formik.values.typeformIntegrationId,
                            })}
                            value={
                              formik.values.typeform.fields[i].fieldId && {
                                id: formik.values.typeform.fields[i].fieldId,
                                title:
                                  formik.values.typeform.fields[i].fieldName,
                              }
                            }
                            onChange={({ id, title }) => {
                              formik.setFieldValue(
                                `typeform.fields.${i}.fieldId`,
                                id
                              );
                              formik.setFieldTouched(
                                `typeform.fields.${i}.fieldId`,
                                true
                              );
                              formik.setFieldValue(
                                `typeform.fields.${i}.fieldName`,
                                title
                              );
                              formik.setFieldTouched(
                                `typeform.fields.${i}.fieldName`,
                                true
                              );
                            }}
                          />
                        </Grid>
                        {[
                          "scoring-multiple",
                          "category-multiple",
                          "scoring-category-multiple",
                        ].includes(formik.values.outcome.type) && (
                          <Grid item>
                            <IconButton onClick={handleDeleteField(i)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
                {[
                  "category-multiple",
                  "scoring-multiple",
                  "scoring-category-multiple",
                ].includes(formik.values.outcome.type) && (
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={handleAddField}
                    >
                      Add field
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const GeneralInfo = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">
          Define overall info of the survey
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label="Name"
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, { name: "name" })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          required
          InputLabelProps={{ shrink: true }}
          InputProps={multilineInputProps}
          label="Description"
          margin="none"
          rows={5}
          variant="outlined"
          {...getFieldProps(formik, {
            name: "description",
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={multilineInputProps}
          label="Internal Notes"
          margin="none"
          rows={5}
          variant="outlined"
          {...getFieldProps(formik, {
            name: "notes",
          })}
        />
      </Grid>
    </>
  );
};

const CategorySuccessFail = ({ formik }) => {
  const handleAddOption = useCallback(() => {
    formik.setFieldValue("additionalLogic.successFail.values", [
      ...(formik.values.additionalLogic.successFail?.values ?? []),
      {
        isSuccess: true,
        value: "",
      },
    ]);
    formik.setFieldTouched("additionalLogic.successFail.values", true);
  }, [formik]);

  const handleDeleteOption = useCallback(
    (i) => () => {
      const newValues = [...formik.values.additionalLogic.successFail.values];
      newValues.splice(i, 1);
      formik.setFieldValue("additionalLogic.successFail.values", newValues);
      formik.setFieldTouched("additionalLogic.successFail.values", true);
    },
    [formik]
  );

  return (
    <Grid container spacing={2}>
      {(formik.values.additionalLogic.successFail?.values ?? []).map((_, i) => (
        <Grid key={i} item xs={12}>
          <Grid container spacing={4}>
            <Grid item>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Input  text"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  name: `additionalLogic.successFail.values.${i}.value`,
                })}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  name: `additionalLogic.successFail.values.${i}.isSuccess`,
                })}
                select
              >
                <MenuItem value={true}>Success</MenuItem>
                <MenuItem value={false}>Fail</MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDeleteOption(i)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={handleAddOption}
        >
          Add option
        </Button>
      </Grid>
    </Grid>
  );
};

const ScoringSuccessFail = ({ formik }) => {
  return (
    <TextField
      fullWidth
      required
      InputLabelProps={{ shrink: true }}
      label="Threshold"
      margin="none"
      type="number"
      variant="outlined"
      {...getFieldProps(formik, {
        name: "additionalLogic.successFail.threshold",
      })}
    />
  );
};

const AdditionalLogic = ({ formik, journey }) => {
  return (
    <>
      {!["no-outcome", "self-evaluation"].includes(
        formik.values.outcome.type
      ) && (
        <>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    getFieldProps(formik, {
                      name: "additionalLogic.successFail.isEnabled",
                    }).value
                  }
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "additionalLogic.successFail.isEnabled",
                      e.target.checked
                    );
                    formik.setFieldTouched(
                      "additionalLogic.successFail.isEnabled",
                      true
                    );
                  }}
                />
              }
              label="Associate concept of Success vs Fail"
            />
          </Grid>
          {formik.values.additionalLogic?.successFail?.isEnabled && (
            <>
              {formik.values.outcome.type === "category-single" && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      List all the available options and map success vs fail
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CategorySuccessFail formik={formik} />
                  </Grid>
                </>
              )}

              {formik.values.outcome.type === "scoring-single" && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Set the threshold. Results equal or higher it will be
                      considered as Success
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ScoringSuccessFail formik={formik} />
                  </Grid>
                </>
              )}

              {formik.values.outcome.type === "scoring-category-single" && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      margin="none"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        name: `additionalLogic.successFail.type`,
                      })}
                      select
                      label="The success is based on the"
                    >
                      <MenuItem value="score">Score</MenuItem>
                      <MenuItem value="category">Category</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    {formik.values.additionalLogic.successFail.type ===
                      "score" && <ScoringSuccessFail formik={formik} />}
                    {formik.values.additionalLogic.successFail.type ===
                      "category" && <CategorySuccessFail formik={formik} />}
                  </Grid>
                </>
              )}

              {[
                "category-multiple",
                "scoring-multiple",
                "scoring-category-multiple",
              ].includes(formik.values.outcome.type) && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      margin="none"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        name: "additionalLogic.successFail.category",
                      })}
                      select
                      label="The success is based on the category"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "additionalLogic.successFail.category",
                          e.target.value
                        );
                        formik.setFieldTouched(
                          "additionalLogic.successFail.category",
                          true
                        );

                        const type = formik.values.typeform.fields.find(
                          ({ userStatePath }) =>
                            userStatePath === e.target.value
                        ).type;
                        formik.setFieldValue(
                          "additionalLogic.successFail.type",
                          type
                        );
                        formik.setFieldTouched(
                          "additionalLogic.successFail.type",
                          true
                        );

                        if (
                          type !== formik.values.additionalLogic.type &&
                          type === "category"
                        ) {
                          formik.setFieldValue(
                            "additionalLogic.successFail.values",
                            [
                              {
                                value: "",
                                isSuccess: true,
                              },
                            ]
                          );
                          formik.setFieldTouched(
                            "additionalLogic.successFail.values",
                            true
                          );
                        }

                        if (
                          type !== formik.values.additionalLogic.type &&
                          type === "score"
                        ) {
                          formik.setFieldValue(
                            "additionalLogic.successFail.threshold",
                            0
                          );
                          formik.setFieldTouched(
                            "additionalLogic.successFail.threshold",
                            true
                          );
                        }
                      }}
                    >
                      {formik.values.typeform.fields.map(
                        ({ userStatePath }) => (
                          <MenuItem key={userStatePath} value={userStatePath}>
                            {userStatePath}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Grid>
                  {formik.values.additionalLogic.successFail?.type ===
                    "category" && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          List all the available options for this category and
                          map success vs fail
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <CategorySuccessFail formik={formik} />
                      </Grid>
                    </>
                  )}
                  {formik.values.additionalLogic.successFail?.type ===
                    "score" && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Set the threshold. Results equal or higher it will be
                          considered as Success
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ScoringSuccessFail formik={formik} />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    getFieldProps(formik, {
                      name: "additionalLogic.complete.isEnabled",
                    }).value
                  }
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "additionalLogic.complete.isEnabled",
                      e.target.checked
                    );
                    formik.setFieldTouched(
                      "additionalLogic.complete.isEnabled",
                      true
                    );
                  }}
                />
              }
              label="Let the user complete this survey from the dashboard only if"
            />
          </Grid>
          {formik.values.additionalLogic?.complete?.isEnabled && (
            <Grid item style={{ flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    required
                    select
                    InputLabelProps={{ shrink: true }}
                    margin="none"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "additionalLogic.complete.variant",
                    })}
                  >
                    <MenuItem value="received">
                      The related message has been received by user
                    </MenuItem>
                    <MenuItem value="active-days">
                      {`User's Journey day progress is after`}
                    </MenuItem>
                    <MenuItem value="date">Today is or after</MenuItem>
                  </TextField>
                </Grid>
                {formik.values.additionalLogic?.complete?.variant ===
                  "active-days" && (
                  <Grid item style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      required
                      select
                      InputLabelProps={{ shrink: true }}
                      label="Days of Journey"
                      margin="none"
                      variant="outlined"
                      {...getFieldProps(formik, {
                        name: "additionalLogic.complete.activeDays",
                      })}
                    >
                      {(journey?.days ?? []).map(({ id, name }, i) => (
                        <MenuItem key={i} value={i}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {formik.values.additionalLogic?.complete?.variant ===
                  "date" && (
                  <Grid item style={{ flex: 1 }}>
                    <DatePicker
                      autoOk
                      disablePast
                      fullWidth
                      required
                      format="dd MMMM yyyy"
                      inputVariant="outlined"
                      label="Date"
                      {...getFieldProps(formik, {
                        name: "additionalLogic.complete.date",
                        defaultValue: null,
                      })}
                      variant="inline"
                      onChange={(value) => {
                        formik.setFieldValue(
                          "additionalLogic.complete.date",
                          value
                        );
                        formik.setFieldTouched(
                          "additionalLogic.complete.date",
                          true
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    getFieldProps(formik, {
                      name: "additionalLogic.repeat.isEnabled",
                    }).value
                  }
                  color="primary"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "additionalLogic.repeat.isEnabled",
                      e.target.checked
                    );
                    formik.setFieldTouched(
                      "additionalLogic.repeat.isEnabled",
                      true
                    );
                  }}
                />
              }
              label="Let the user repeat this survey"
            />
          </Grid>
          {formik.values.additionalLogic?.repeat?.isEnabled && (
            <Grid item style={{ flex: 1 }}>
              <TextField
                fullWidth
                required
                select
                InputLabelProps={{ shrink: true }}
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, {
                  name: "additionalLogic.repeat.variant",
                })}
              >
                <MenuItem value="unlimited">
                  How many times (s)he wants
                </MenuItem>
                {formik.values.additionalLogic.successFail.isEnabled && (
                  <MenuItem value="only-failed">Only if it was failed</MenuItem>
                )}
              </TextField>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                getFieldProps(formik, {
                  name: "additionalLogic.isShowPersonalResultsEnabled",
                }).value
              }
              color="primary"
              onChange={(e) => {
                formik.setFieldValue(
                  "additionalLogic.isShowPersonalResultsEnabled",
                  e.target.checked
                );
                formik.setFieldTouched(
                  "additionalLogic.isShowPersonalResultsEnabled",
                  true
                );
              }}
            />
          }
          label="Show personal results in hi dashboard"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                getFieldProps(formik, {
                  name: "additionalLogic.isShowCidResultsEnabled",
                }).value
              }
              color="primary"
              onChange={(e) => {
                formik.setFieldValue(
                  "additionalLogic.isShowCidResultsEnabled",
                  e.target.checked
                );
                formik.setFieldTouched(
                  "additionalLogic.isShowCidResultsEnabled",
                  true
                );
              }}
            />
          }
          label="Show global results of users with same CID in hi dashboard"
        />
      </Grid>
    </>
  );
};

const Survey = ({ formik, tab, locale, journeyId, semantics, journey }) => {
  const [activeStep, setActiveStep] = useState(tabs[tab] ?? 0);
  const history = useHistory();
  const location = useLocation();

  const {
    fetchSurveys,
    isSurveysLoading,
    surveys,
    isIntegrationsLoading,
    fetchIntegrations,
    integrations,
    fetchTypeforms,
  } = useAppContext();

  const handleStep = useCallback(
    (step) => () => {
      history.replace({
        ...location,
        pathname: location.pathname.replace(
          new RegExp(
            `/surveys/${formik.values.isNew ? "new" : formik.values.id}(/.+)?`,
            "g"
          ),
          `/surveys/${formik.values.isNew ? "new" : formik.values.id}/${
            invert(tabs)[step]
          }`
        ),
      });
      setActiveStep(step);
    },
    [formik.values.id, formik.values.isNew, history, location]
  );

  useEffect(() => {
    if (formik.values.type !== "hi") {
      fetchIntegrations();
      return;
    }

    fetchSurveys({ journeyId });
  }, [formik.values.type, journeyId, locale]);

  useEffect(() => {
    if (
      formik.values.type !== "typeform" ||
      !formik.values.typeformIntegrationId
    ) {
      return;
    }

    fetchTypeforms({ integrationId: formik.values.typeformIntegrationId });
  }, [formik.values.type, formik.values.typeformIntegrationId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <SaveErrors formik={formik} />
      <Grid item xs={12}>
        <div style={{ display: activeStep === 0 ? "block" : "none" }}>
          <Grid container spacing={4}>
            <GeneralInfo formik={formik} />
          </Grid>
        </div>
        <div style={{ display: activeStep === 1 ? "block" : "none" }}>
          <Grid container spacing={4}>
            <Tools
              formik={formik}
              integrations={integrations}
              isIntegrationsLoading={isIntegrationsLoading}
              isSurveysLoading={isSurveysLoading}
              journeyId={journeyId}
              locale={locale}
              surveys={surveys}
              onIntegrationsFetch={fetchIntegrations}
            />
          </Grid>
        </div>
        <div style={{ display: activeStep === 2 ? "block" : "none" }}>
          <Grid container spacing={4}>
            <DistributionChannels
              formik={formik}
              journeyId={journeyId}
              locale={locale}
              semantics={semantics}
            />
          </Grid>
        </div>
        <div style={{ display: activeStep === 3 ? "block" : "none" }}>
          <Grid container spacing={4}>
            <Outcome formik={formik} journeyId={journeyId} locale={locale} />
          </Grid>
        </div>
        <div style={{ display: activeStep === 4 ? "block" : "none" }}>
          <Grid container spacing={4}>
            <AdditionalLogic formik={formik} journey={journey} />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const editSchema = yup.object({
  name: yup.string().required().min(5),
  description: yup.string().required().min(5),
  notes: yup.string(),
  type: yup.string().required().oneOf(["hi", "typeform"]),
  flowId: yup.string().when("type", {
    is: (type) => type === "hi",
    then: yup.string().nullable().required("message is a required field"),
    otherwise: yup.string().nullable(),
  }),
  outcome: yup.object({
    hiSemanticCategoryMapping: yup.array().of(
      yup.object({
        semanticId: yup.string().nullable().required("tip is a required field"),
        category: yup
          .string()
          .nullable()
          .required("category is a required field"),
      })
    ),
  }),
  typeform: yup.object({
    url: yup.string().when("type", {
      is: (type) => type === "typeform",
      then: yup
        .string()
        .url()
        .required()
        .test("url", "invalid Typeform URL", (value) => {
          const [, id] = value?.match(typeformUrlRegex) || [];

          return Boolean(id);
        }),
      otherwise: yup.string().nullable(),
    }),
    fields: yup.array().of(
      yup.object({
        userStatePath: yup
          .string()
          .nullable()
          .required("user state field is a required field"),
        fieldId: yup
          .string()
          .nullable()
          .required("Typeform field is a required field"),
        fieldName: yup.string().nullable(),
        type: yup.mixed().oneOf(["score", "category"]).required(),
      })
    ),
  }),
});
const tabs = {
  info: 0,
  tool: 1,
  "distribution-channels": 2,
  outcomes: 3,
  "additional-logics": 4,
};

const SurveyContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { id, tab, locale, journeyId } = match.params;
  const { isNew } = location.state || {};
  const {
    updateSurvey,
    fetchSurvey,
    isSurveyLoading,
    survey,
    fetchAllSemanticsForUser: fetchSemantics,
    isAllSemanticsForUserLoading: isSemanticsLoading,
    semantics,
    fetchJourneys,
    isJourneysLoading,
    journeys,
  } = useAppContext();

  useEffect(() => {
    if (!id || isNew || id === "new") {
      return;
    }

    fetchSurvey({ id });
  }, [id, isNew]);

  useEffect(() => {
    fetchSemantics({ journeyId, locale, surveyId: id });
    fetchJourneys();
  }, [journeyId, locale, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isNew
      ? {
          isNew,
          journeyId,
          name: "",
          description: "",
          notes: "",
          type: "hi",
          flowId: null,
          distributionChannels: {
            hi: {
              isEnabled: false,
              stopAfterTimes: 1,
            },
            link: {
              isEnabled: false,
              link: null,
            },
          },
          outcome: {
            type: "no-outcome",
            hiAssignType: "dropdown-selection",
            hiCompleteType: "tip-reached",
            hiCompleteSemanticIds: [],
            hiDropdownSemanticId: null,
            hiSemanticCategoryMapping: [],
            hiUserStatePath: null,
          },
          typeform: {
            fields: [],
            isConfigured: false,
            url: null,
          },
          additionalLogic: {
            successFail: {
              category: null,
              isEnabled: false,
              threshold: 0,
              type: "category",
              values: [
                {
                  value: "",
                  isSuccess: true,
                },
              ],
            },
            isShowPersonalResultsEnabled: false,
            isShowCidResultsEnabled: false,
            complete: {
              isEnabled: false,
              variant: "received",
              activeDays: null,
              date: null,
            },
            repeat: {
              isEnabled: false,
              variant: "unlimited",
            },
          },
        }
      : survey
      ? {
          ...survey,
          ...(survey.type === "typeform" && {
            distributionChannels: {
              ...survey.distributionChannels,
              hi: {
                ...survey.distributionChannels?.hi,
                isEnabled: semantics.some(
                  ({ cta1LinkId, cta2LinkId }) =>
                    cta1LinkId === survey.id || cta2LinkId === survey.id
                ),
              },
            },
          }),
        }
      : null,
    onSubmit: async (values) => {
      const updatedSurvey = await updateSurvey(values);

      if (!values.isNew || !updatedSurvey?.id) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace("new", updatedSurvey.id),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  if (isSurveyLoading || isSemanticsLoading || isJourneysLoading) {
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Survey is not found 😢</Typography>
      </Box>
    );
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Survey
            formik={formik}
            journey={journeys.find(
              (journey) => journey.id === journeyId && journey.locale === locale
            )}
            journeyId={journeyId}
            locale={locale}
            semantics={semantics}
            tab={tab}
          />
        </Grid>
        <Grid item xs={12}>
          <SaveButtons formik={formik} isNew={isNew} isShowSaveErrors={false} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyContainer;
