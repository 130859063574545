import React from "react";
import { useFormikContext } from "formik";
import {
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import bytes from "bytes";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { useSnackbar } from "notistack";

const InputTypes = () => {
  const formik = useFormikContext();
  const [fileName, setFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const acceptedFileTypes = ".pdf, .docx, .ppt, .xls, .odt, .doc";

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeLimit = bytes("4MB");
      if (file.size > fileSizeLimit) {
        enqueueSnackbar("File size exceeds the limit.", {
          variant: "error",
        });
        event.target.value = null;
        setFileName("");
        return;
      }

      const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        !acceptedFileTypes
          .split(",")
          .map((e) => e.trim().substr(1))
          .includes(extension)
      ) {
        enqueueSnackbar(`File type not supported.`, {
          variant: "error",
        });
        event.target.value = null;
        setFileName("");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result;
          const base64Data = base64String?.split(";base64,")?.[1];
          setFileName(file.name);
          formik.setFieldValue("fileName", file.name);
          formik.setFieldValue("fileB64", base64Data);
        } else {
          console.error("Error reading file");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <FormControl>
        <Select
          displayEmpty
          required
          id="inputType"
          inputProps={{ "aria-label": "Select input type" }}
          name="inputType"
          style={{ textAlign: "center" }}
          value={formik.values.inputType}
          onChange={formik.handleChange}
        >
          <MenuItem disabled value="">
            Select Input Type
          </MenuItem>
          <MenuItem value="text">Input Text</MenuItem>
          {/* <MenuItem value="video">Video</MenuItem> */}
          <MenuItem value="fileupload">File Upload</MenuItem>
          {/* <MenuItem value="website">Website</MenuItem> */}
        </Select>
      </FormControl>
      {/*       {(formik.values.inputType === "video" ||
        formik.values.inputType === "website") && (
        <FormControl>
          <TextField
            required
            id="userinput"
            label={"Paste the link here"}
            name="userinput"
            style={{ marginTop: "10px" }}
            value={formik.values.userinput}
            variant="outlined"
            onChange={formik.handleChange}
          />
        </FormControl>
      )} */}
      {formik.values.inputType === "text" && (
        <FormControl>
          <TextField
            fullWidth
            multiline
            required
            id="userinput"
            label={"Paste or write text here"}
            minRows={4}
            name="userinput"
            style={{ marginTop: "10px", width: "100%" }}
            value={formik.values.userinput}
            variant="outlined"
            onChange={formik.handleChange}
          />
          <Alert severity="info">
            Please make sure that your input is in{" "}
            {formik.values.language.charAt(0).toUpperCase() +
              formik.values.language.slice(1)}
            💡
          </Alert>
        </FormControl>
      )}
      {formik.values.inputType === "fileupload" && (
        <Grid
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <input
            accept={acceptedFileTypes}
            id="fileupload"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="fileupload">
            <Button
              color="primary"
              component="span"
              startIcon={<AddIcon />}
              type="button"
              variant="outlined"
            >
              Upload
            </Button>
          </label>
          {fileName && (
            <Typography gutterBottom style={{ margin: "5px" }} variant="body1">
              Selected file: {fileName}
            </Typography>
          )}
          {!fileName && <br />}
          <Alert severity="info">
            Please make sure that file content is in{" "}
            {formik.values.language.charAt(0).toUpperCase() +
              formik.values.language.slice(1)}
            💡
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default InputTypes;
