import React, { useCallback } from "react";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core";

const SemanticsWithEmptyCTALinkModal = ({
  items,
  journeyId,
  locale,
  onClose,
  title = "Messages with missing CTA link",
  color,
} = {}) => {
  const handleEditSemantic = useCallback(
    ({ id, type }) =>
      () => {
        window.open(
          `/journeys/${journeyId}/${locale}/${type.toLowerCase()}s/${id}`,
          "_blank"
        );
      },
    [journeyId, locale]
  );

  const theme = useTheme();

  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle>
        <Box
          color={color === "error" ? theme.palette.error.main : undefined}
          display="flex"
          justifyContent="space-between"
        >
          {title}
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <List subheader={<ListSubheader disableSticky>Tips</ListSubheader>}>
          {items
            .filter(({ type }) => type === "PRIME")
            .map(({ title, text, id, type }) => (
              <ListItem
                key={id}
                button
                onClick={handleEditSemantic({
                  id,
                  type,
                })}
              >
                <ListItemText primary={title} secondary={text} />
              </ListItem>
            ))}
        </List>
        <List subheader={<ListSubheader disableSticky>Nudges</ListSubheader>}>
          {items
            .filter(({ type }) => type === "NUDGE")
            .map(({ title, text, id, type }) => (
              <ListItem
                key={id}
                button
                onClick={handleEditSemantic({
                  id,
                  type,
                })}
              >
                <ListItemText primary={title} secondary={text} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SemanticsWithEmptyCTALinkModal;
