/* eslint-disable prettier/prettier */
export const teamsTemplateFamilies = [
  {
    semanticTemplateCatalogue: [
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-1c51bc52-ea39-41cf-beba-3dfb44f84744",
        templateName: "Card 1A",
        templatePreviewUrl: "templates/teams_01A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-e9e05ff3-3e6d-48ee-b411-eeb7e7224a60",
        templateName: "Card 1B",
        templatePreviewUrl: "templates/teams_01B.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-3c08cdb8-877a-4d62-a292-71d0fe3b1e5c",
        templateName: "Card 2A",
        templatePreviewUrl: "templates/teams_02A.svg",
      },
      {
        isActive: true,
        isSelectorSmall: false,
        modelSelectorColumns: 6,
        templateId: "crd-ec2a2801-9b85-44fc-96ed-859ab148df49",
        templateName: "Card 2B",
        templatePreviewUrl: "templates/teams_02B.svg",
      },
    ],
    tFamilyId: "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
    tFamilyName: "Family 1 Teams",
  },
];
