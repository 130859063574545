import Sticky from "react-sticky-el";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

const Error = ({ errors }) =>
  Object.entries(errors).map(([key, error]) =>
    typeof error === "string" ? (
      <li key={key}>{error}</li>
    ) : typeof error === "object" ? (
      <Error errors={error} />
    ) : null
  );

const SaveErrors = ({ formik }) =>
  Object.keys(formik.errors).filter((key) => formik.touched[key]).length > 0 ? (
    <Grid item xs={12}>
      <Alert severity="error">
        <ul>
          <Error
            errors={Object.fromEntries(
              Object.entries(formik.errors).filter(
                ([key]) => formik.touched[key]
              )
            )}
          />
        </ul>
      </Alert>
    </Grid>
  ) : null;

const SaveButtons = ({ formik, isNew, isShowSaveErrors = true }) => {
  const history = useHistory();
  const { state } = useLocation();
  const handleCancel = useCallback(() => {
    formik.resetForm();
    if (state?.isNew || isNew) {
      history.goBack();
    }
  }, [formik, history, isNew, state?.isNew]);

  return (
    <Sticky
      mode="bottom"
      scrollElement=".scrollarea"
      stickyStyle={{
        backgroundColor: "#F8F8F8",
        paddingTop: 8,
        zIndex: 100,
      }}
    >
      <Grid container spacing={2}>
        {isShowSaveErrors && <SaveErrors formik={formik} />}
        <Grid item>
          <Button
            color="primary"
            disabled={formik.isSubmitting || !formik.dirty}
            endIcon={formik.isSubmitting && <CircularProgress size={25} />}
            variant="contained"
            onClick={() => {
              // console.log("formik => ", formik); // to debug issues (ex.: click button and nothing happens)
              formik.submitForm();
            }}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={!isNew && (formik.isSubmitting || !formik.dirty)}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Sticky>
  );
};

export { SaveErrors };
export default SaveButtons;
