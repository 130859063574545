import React, { useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
// import MarkdownEditor from "../../shared/MarkdownEditor";
import { getFieldProps } from "../../utils";
import styled from "styled-components/macro";
// import { useField } from "formik";
import MarkdownEditorV2 from "../../shared/MarkdownEditorV2";

const CustomTypography = styled(Typography)`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const BuildMarkdownEditor = ({ formik, placeholder, name, value }) => {
  const onChange = useCallback(
    (value) => {
      formik.setFieldValue(name, value);
      formik.setFieldTouched(name, true);
    },
    [formik, name]
  );

  return (
    <Grid item md={6} xs={12}>
      <MarkdownEditorV2
        error={false}
        label={``}
        placeHolder={placeholder}
        {...getFieldProps(formik, {
          name,
        })}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};

const NeedToKnow = ({ formik }) => {
  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          In this section you will have to write down all the information to
          understand what it is all about!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item>
            <CustomTypography color="textSecondary" variant="h6">
              Write the section <b>&quot;What is it about?&quot;</b>. Tell the
              employee what the benefit is about, what it consists of, and why
              it is useful and beneficial! *
            </CustomTypography>
          </Grid>

          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.about`}
              placeholder={"Default language"}
              value={formik.values.text_default.about}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.about`}
              placeholder={"English"}
              value={formik.values.text_en.about}
            />
            {/* {buildMarkdownEditor(
              formik,
              "Default language",
              "text_default.about",
              textDefault.value?.about
            )}

            {buildMarkdownEditor(
              formik,
              "English",
              "text_en.about",
              textEnglish.value?.about
            )} */}
          </Grid>
        </>
        <>
          <Grid item style={{ marginTop: "2rem" }}>
            <CustomTypography color="textSecondary" variant="h6">
              Inside the &quot;What is it about?&quot; section, you have a{" "}
              <b>Highlight section (in pink)</b> if there are very important
              things to point out or maybe special cases in the use of the
              benefit
            </CustomTypography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.highlighted`}
              placeholder={"Default language"}
              value={formik.values.text_default.highlighted}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.highlighted`}
              placeholder={"English"}
              value={formik.values.text_en.highlighted}
            />
            {/* {buildMarkdownEditor(
              formik,
              "Default language",
              "text_default.highlighted",
              textDefault.value?.highlighted
            )}
            {buildMarkdownEditor(
              formik,
              "English",
              "text_en.highlighted",
              textEnglish.value?.highlighted
            )} */}
          </Grid>
        </>
        <>
          <Grid item style={{ marginTop: "2rem" }}>
            <CustomTypography color="textSecondary" variant="h6">
              Write the section <b>&quot;How to apply for activation?&quot;</b>,
              an effective format is to write down in a list the steps the
              employee needs to take to activate the benefit (Ex. Print
              documentation and send it in? Enroll in a website?).
            </CustomTypography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.howToApply`}
              placeholder={"Default language"}
              value={formik.values.text_default.howToApply}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.howToApply`}
              placeholder={"English"}
              value={formik.values.text_en.howToApply}
            />

            {/* {buildMarkdownEditor(
              formik,
              "Default language",
              "text_default.howToApply",
              textDefault.value?.howToApply
            )}
            {buildMarkdownEditor(
              formik,
              "English",
              "text_en.howToApply",
              textEnglish.value?.howToApply
            )} */}
          </Grid>
        </>
        <>
          <Grid item style={{ marginTop: "2rem" }}>
            <CustomTypography color="textSecondary" variant="h6">
              Finally, the section <b>&quot;How to redeem the benefit?&quot;</b>{" "}
              what should the employee do to get the benefit? (e.g., Collect it
              from the office, redeem it from the site, request a refund)
            </CustomTypography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.howToRedeem`}
              placeholder={"Default language"}
              value={formik.values.text_default.howToRedeem}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.howToRedeem`}
              placeholder={"English"}
              value={formik.values.text_en.howToRedeem}
            />
            {/* {buildMarkdownEditor(
              formik,
              "Default language",
              "text_default.howToRedeem",
              textDefault.value?.howToRedeem
            )}
            {buildMarkdownEditor(
              formik,
              "English",
              "text_en.howToRedeem",
              textEnglish.value?.howToRedeem
            )} */}
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default NeedToKnow;
