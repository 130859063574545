import { CircularProgress, Button, useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import useLoading from "../../useLoading";

const AsyncButton = ({ onClick, disabled, ...props }) => {
  const { isLoading, isSuccess, handler } = useLoading(onClick);

  const theme = useTheme();

  return (
    <Button
      {...props}
      disabled={disabled || isLoading}
      endIcon={
        isLoading ? (
          <CircularProgress size={25} />
        ) : typeof isSuccess === "boolean" ? (
          isSuccess ? (
            <CheckCircleIcon style={{ color: theme.palette.success.main }} />
          ) : (
            <ErrorIcon color="error" />
          )
        ) : (
          props.endIcon
        )
      }
      onClick={handler}
    />
  );
};

export default AsyncButton;
