/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo } from "react";
//import orderBy from "lodash/orderBy";
//import groupBy from "lodash/groupBy";
import Highlighter from "react-highlight-words";
import removeMarkdown from "remove-markdown";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import SearchIcon from "@material-ui/icons/Search";

import { useAppContext } from "../../api/AppContext";

const filterOptions = createFilterOptions({
  stringify: (option) =>
    `${option.id} ${removeMarkdown(option.text_default.name ?? "")} ${removeMarkdown(
      option.text_default.name ?? "",
    )} ${removeMarkdown(option.text_default?.title ?? "")} ${removeMarkdown(
      option.text_default?.title ?? "",
    )}`,
});

const WelfareSearch = () => {
  const {
    benefits,
    fetchAllBenefits,
    isAllBenefitsLoading,
    news,
    isAllNewsLoading,
    fetchAllNews,
    welfareSelectors,
    fetchWelfareSelector,
  } = useAppContext();

  useEffect(() => {
    if (!news.length) {
      fetchAllNews();
    }
    if (!benefits.length) {
      fetchAllBenefits();
    }
    if (!welfareSelectors.entities.length) {
      fetchWelfareSelector("entities");
    }
  }, [
    fetchAllBenefits,
    fetchAllNews,
    news.length,
    benefits.length,
    fetchWelfareSelector,
    welfareSelectors.entities.length,
  ]);

  const sortedWelfares = useMemo(() => {
    const mappedBenefits = benefits.map((benefit) => {
      const matchingEntityName = welfareSelectors.entities.find(
        (selector) => selector.id === benefit.legalentity_id,
      );
      return {
        ...benefit,
        displayname: matchingEntityName ? matchingEntityName.displayname : "",
        type: "welfare",
      };
    });

    mappedBenefits.sort((a, b) => {
      const nameComparison = (a.text_default?.name || "")
        .toLowerCase()
        .localeCompare((b.text_default?.name || "").toLowerCase());
      if (nameComparison !== 0) return nameComparison;

      return (a.displayname || "")
        .toLowerCase()
        .localeCompare((b.displayname || "").toLowerCase());
    });

    const mappedNews = news.map((newsItem) => {
      const matchingEntityName = welfareSelectors.entities.find(
        (selector) => selector.id === newsItem.legalentity_id,
      );
      return {
        ...newsItem,
        displayname: matchingEntityName ? matchingEntityName.displayname : "",
        type: "news",
      };
    });
    mappedNews.sort((a, b) => {
      const nameComparison = (a.text_default?.title || "")
        .toLowerCase()
        .localeCompare((b.text_default?.title || "").toLowerCase());
      if (nameComparison !== 0) return nameComparison;

      return (a.displayname || "")
        .toLowerCase()
        .localeCompare((b.displayname || "").toLowerCase());
    });

    return [...mappedBenefits, ...mappedNews];
  }, [benefits, news, welfareSelectors]);

  const handleChange = useCallback((e, value, reason) => {
    if (reason !== "select-option" || !value) {
      return;
    }

    const url =
      value.type === "welfare"
        ? `/welfares/${value.id}`
        : `/welfares/news/${value.id}`;

    //window.open(url, "_blank");
    window.open(url);
  }, []);

  return (
    <Autocomplete
      fullWidth
      filterOptions={filterOptions}
      getOptionLabel={(option) =>
        option.type === "welfare"
          ? option.text_default?.name
          : option.text_default?.title
      }
      groupBy={(option) => (option.type === "welfare" ? "Welfares" : "News")}
      loading={isAllNewsLoading || isAllBenefitsLoading}
      options={sortedWelfares}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              "hi-web-id": "welfare-search",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
          size="small"
          style={{ maxWidth: 500 }}
        />
      )}
      renderOption={(option, { inputValue }) => (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2">
              <Highlighter
                hi
                searchWords={[inputValue || ""]}
                textToHighlight={removeMarkdown(
                  option.text_default?.name ?? option.text_default?.title ?? "",
                )}
              />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ textAlign: "right" }} variant="body2">
              {option.displayname ?? option.text_default?.title ?? ""}
            </Typography>
          </Grid>
        </Grid>
      )}
      style={{ textAlign: "right" }}
      onChange={handleChange}
    />
  );
};

export default WelfareSearch;
