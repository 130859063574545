export const tmpSemanticTemplates = {
  "crd-2e4c95b5-dd68-41b4-9c31-33e3703ea439": {
    templateId: "crd-2e4c95b5-dd68-41b4-9c31-33e3703ea439",
    templateName: "Card 1A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 322,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "20px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "20px",
        paddingLeft: "33px",
        boxSizing: "border-box",
        position: "relative", // filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
        filter: "drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.14))",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "9px",
        right: "12px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "46px",
        zIndex: "10",
        minHeight: "180px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M284.744 182.76C283.928 284.492 235.9 314.475 154.458 314.475C73.0158 314.475 -41.8501 275.85 16.3452 137.203C61.2271 30.2747 44.6155 0.537109 126.058 0.537109C207.5 0.537109 285.561 81.0276 284.744 182.76Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        width: "285px",
        height: "314px",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='285' height='315' viewBox='0 0 285 315' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M284.744 182.76C283.928 284.492 235.9 314.475 154.458 314.475C73.0158 314.475 -41.8501 275.85 16.3452 137.203C61.2271 30.2747 44.6155 0.537109 126.058 0.537109C207.5 0.537109 285.561 81.0276 284.744 182.76Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },
      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25.48px",
        height: "25.48px",
        top: "120px",
        marginLeft: "-312px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        marginRight: "30px",
        top: "30px",
        zIndex: "1",
        position: "absolute",
        width: "281.71px",
        height: "281.71px",
        marginTop: "-25px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    userCustomizable: {
      cta: {
        CTA: {
          allowed: true,
          required: false,
        },
        DOUBLE_CTA: {
          allowed: true,
          required: false,
        },
        DROPDOWN: {
          allowed: true,
          required: false,
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
      tag: {
        allowed: false,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
      },
      text: {
        allowed: true,
        required: false,
      },
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
  },
  "crd-f5a20b7c-73b9-478f-a1b9-8595e45dd969": {
    templateId: "crd-f5a20b7c-73b9-478f-a1b9-8595e45dd969",
    templateName: "Card 8A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "10px",
        paddingLeft: "22px",
        paddingRight: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid #fbfbfb",
        borderRadius: "0px",
        boxSizing: "border-box",
        // backgroundColor: "var(--color3)",
        backgroundColor: "transparent",
        overflow: "hidden",
        // boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "2px",
        right: "2px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        width: "170px",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "185px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        top: "25px",
        marginRigh: "6px",
        position: "absolute",
        width: "250px",
        height: "227px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M133.893 214.914C56.5437 234.976 24.0327 204.633 7.47625 142.843C-9.08017 81.0541 -3.12763 -13.9456 113.893 2.021C204.143 14.3349 223.328 -4.31351 239.884 57.4759C256.441 119.265 211.242 194.852 133.893 214.914Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='244' height='222' viewBox='0 0 244 222' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M133.893 214.914C56.5437 234.976 24.0327 204.633 7.47625 142.843C-9.08017 81.0541 -3.12763 -13.9456 113.893 2.021C204.143 14.3349 223.328 -4.31351 239.884 57.4759C256.441 119.265 211.242 194.852 133.893 214.914Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
        transform: matrix(1, 0, -0.01, 1, 0, 0), */
        zIndex: "2",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        top: "181px",
        marginLeft: "240px",

        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        marginLeft: "31px",
        top: "34px",
        zIndex: "1",
        position: "absolute",
        width: "246px",
        height: "246px",
        marginTop: "-25px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#FFFFFF", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-0dd751ee-53f3-4445-beba-acb2d359c360": {
    templateId: "crd-0dd751ee-53f3-4445-beba-acb2d359c360",
    templateName: "Card 1B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 328,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "30px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "30px",
        marginRight: "20px",
        marginLeft: "20px",
        paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "9px",
        right: "10px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "46px",
        zIndex: "10",
        minHeight: "180px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        top: "-8px",
        left: "8px",
        width: "318px",
        height: "305px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M43.3499 238.403C-23.344 161.579 -7.16641 107.321 53.9036 53.4387C114.974 -0.443472 226.66 -47.4763 274.751 94.9912C311.84 204.866 343.971 216.175 282.901 270.057C221.831 323.939 110.044 315.228 43.3499 238.403Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='318' height='305' viewBox='0 0 318 305' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M43.3499 238.403C-23.344 161.579 -7.16641 107.321 53.9036 53.4387C114.974 -0.443477 226.66 -47.4763 274.751 94.9912C311.84 204.866 343.971 216.175 282.901 270.057C221.831 323.939 110.044 315.228 43.3499 238.403Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        top: "250px",
        marginLeft: "130px",

        zIndex: "1",

        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "26px",
        top: "30px",
        zIndex: "1",
        position: "absolute",
        width: "281.71px",
        height: "281.71px",
        marginTop: "-25px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
    },
  },
  "crd-3bcac35d-b562-4fec-be51-75ea3393fa1a": {
    templateId: "crd-3bcac35d-b562-4fec-be51-75ea3393fa1a",
    templateName: "Card 1C",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 328,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "30px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "30px",
        marginRight: "20px",
        marginLeft: "20px",
        paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
        paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "9px",
        right: "12px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "46px",
        zIndex: "10",
        minHeight: "180px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        top: "-8px",
        marginLeft: "24px",
        width: "303px",
        height: "320px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M61.0587 47.3246C135.343 -22.1874 190.166 -8.04417 246.288 50.9743C302.41 109.993 353.574 219.848 212.999 273.217C104.583 314.377 94.4805 346.908 38.3587 287.889C-17.763 228.871 -13.2259 116.837 61.0587 47.3246Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='304' height='321' viewBox='0 0 304 321' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M61.0587 47.3246C135.343 -22.1874 190.166 -8.04417 246.288 50.9743C302.41 109.993 353.574 219.848 212.999 273.217C104.583 314.377 94.4805 346.908 38.3587 287.889C-17.763 228.871 -13.2259 116.837 61.0587 47.3246Z' fill='#F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover" */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        top: "260px",
        marginRight: "135px",

        zIndex: "1",

        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "26px",
        top: "30px",
        zIndex: "1",
        position: "absolute",
        width: "281.71px",
        height: "281.71px",
        marginTop: "-25px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
    },
  },
  "crd-692b782d-858f-4838-bd41-c9ac37420201": {
    templateId: "crd-692b782d-858f-4838-bd41-c9ac37420201",
    templateName: "Card 7A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 322,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "20px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "35px",
        marginRight: "10px",
        marginLeft: "18px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
        padding: "0px 38px 48px 22px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid #fbfbfb",
        borderRadius: "0px",
        boxSizing: "border-box",
        // backgroundColor: "var(--color3)",
        backgroundColor: "transparent",
        overflow: "hidden",
        // boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        // position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        zIndex: "2",
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "2px",
        right: "20px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "120px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "90%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        marginTop: "0px",
        marginLeft: "22px",
        position: "absolute",
        width: "308px",
        height: "278px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M114.25 276.253C16.7384 264.112 -6.66495 214.686 2.42765 136.55C11.5202 58.4135 61.4007 -47.4777 187.923 23.8346C270.5 70.3782 315.885 66.2155 306.792 144.352C297.7 222.488 211.762 288.394 114.25 276.253Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='308' height='278' viewBox='0 0 308 278' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M114.25 276.253C16.7384 264.112 -6.66495 214.686 2.42765 136.55C11.5202 58.4135 61.4007 -47.4777 187.923 23.8346C270.5 70.3782 315.885 66.2155 306.792 144.352C297.7 222.488 211.762 288.394 114.25 276.253Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
        transform: matrix(1, 0, -0.01, 1, 0, 0), */
        zIndex: "1",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        marginTop: "284px",
        marginRight: "51px",

        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        marginRight: "16px",
        zIndex: "1",
        position: "absolute",
        width: "281px",
        height: "281px",
        marginTop: "18px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='282' height='283' viewBox='0 0 282 283' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 141.056 141.391)' r='140.219' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        transform: "matrix(0, -1, 1, 0.03, 0, 0)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },

    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#FFFFFF", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-6d50a381-8527-4e77-affc-d1c6a97b2f3a": {
    templateId: "crd-6d50a381-8527-4e77-affc-d1c6a97b2f3a",
    templateName: "Card 7B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 322,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "18px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "20px",
        marginLeft: "15px",
        marginRight: "10px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
        padding: "0px 22px 22px 22px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid #fbfbfb",
        borderRadius: "0px",
        boxSizing: "border-box",
        // backgroundColor: "var(--color3)",
        backgroundColor: "transparent",
        overflow: "hidden",
        // boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        // position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "44px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        zIndex: "2",
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "2px",
        right: "20px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "5px",
        zIndex: "10",
        minHeight: "100px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "90%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        marginTop: "16px",
        marginRight: "18px",
        position: "absolute",
        width: "290px",
        height: "246px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M127.506 11.0602C219.348 -17.2735 258.938 13.8403 280.28 81.0982C301.622 148.356 296.935 254.23 156.809 245.704C65.3529 240.139 26.3093 261.326 4.96717 194.068C-16.375 126.811 35.6647 39.394 127.506 11.0602Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='290' height='247' viewBox='0 0 290 247' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M127.507 11.0602C219.348 -17.2735 258.938 13.8403 280.28 81.0982C301.623 148.356 296.936 254.23 156.81 245.704C65.3534 240.139 26.3098 261.326 4.96766 194.068C-16.3745 126.811 35.6652 39.394 127.507 11.0602Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
        transform: matrix(1, 0, -0.01, 1, 0, 0), */
        zIndex: "1",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        marginTop: "8px",
        marginRight: "136px",

        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        marginTop: "2px",
        marginRight: "0px",
        zIndex: "1",
        position: "absolute",
        width: "281px",
        height: "281px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='282' height='283' viewBox='0 0 282 283' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 141.056 141.391)' r='140.219' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        transform: "matrix(0, -1, 1, 0.03, 0, 0)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#FFFFFF", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-d9ec1b3a-af13-490e-addf-ac1f832591b1": {
    templateId: "crd-d9ec1b3a-af13-490e-addf-ac1f832591b1",
    templateName: "Card 7C",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 322,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "20px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "10px",
        marginLeft: "10px",
        marginRight: "10px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 16px rgba(0, 0, 0, 0.14))",
        padding: "0px 22px 64px 22px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // border: "1px solid #fbfbfb",
        borderRadius: "0px",
        boxSizing: "border-box",
        // backgroundColor: "var(--color3)",
        backgroundColor: "transparent",
        overflow: "hidden",
        // boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        // position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "14px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        zIndex: "2",
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "2px",
        right: "20px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "5px",
        zIndex: "10",
        minHeight: "100px",
        marginLeft: "8px",
        marginRight: "8px",
        justifyContent: "center",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "90%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        marginTop: "6px",
        marginRight: "18px",
        position: "absolute",
        width: "284px",
        height: "268px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M190.596 241.725C107.341 289.749 61.9774 268.456 26.4822 208.046C-9.01306 147.636 -27.6016 43.9714 110.742 20.863C201.035 5.78075 234.429 -23.4955 269.924 36.9144C305.42 97.3244 273.851 193.702 190.596 241.725Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='285' height='269' viewBox='0 0 285 269' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M190.596 241.725C107.341 289.749 61.9774 268.456 26.4822 208.046C-9.01306 147.636 -27.6016 43.9714 110.742 20.863C201.035 5.78075 234.429 -23.4955 269.924 36.9144C305.42 97.3244 273.851 193.702 190.596 241.725Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transform: matrix(1, 0, -0.01, 1, 0, 0), */
        zIndex: "1",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        marginTop: "233px",
        marginLeft: "176px",

        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        marginTop: "8px",
        marginRight: "28px",
        zIndex: "1",
        position: "absolute",
        width: "282px",
        height: "282px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='282' height='283' viewBox='0 0 282 283' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 141.056 141.391)' r='140.219' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        transform: "matrix(0, -1, 1, 0.03, 0, 0)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#FFFFFF", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-703add2c-d20e-4b5d-886e-9c2d9a518c99": {
    templateId: "crd-703add2c-d20e-4b5d-886e-9c2d9a518c99",
    templateName: "Card 11A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 500,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "30px",
        marginLeft: "15px",
        marginRight: "10px",
        paddingLeft: "36px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "465px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "relative", */
        padding: "16px 22px 20px 22px",
        marginTop: "20px",
        // backgroundSize: "contain",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "30px",
        right: "30px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 120px",
        position: "absolute",
        zIndex: 4,
        height: "242px",
        width: "214px",
        top: "10px",
        left: "5px",
        backgroundSize: "contain",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content, #semantic-root .actions": {
        marginLeft: "198px",
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "22px",
        zIndex: "10",
        width: "223px",
        minHeight: "142px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        marginLeft: 198,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      "#extra1": {
        position: "absolute",
        zIndex: 2,
        marginTop: "36px",
        marginRight: "278px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M81.2816 195.886C12.3237 187.3 -4.22665 152.348 2.20342 97.0916C8.6335 41.8354 43.9078 -33.0484 133.381 17.382C202.386 56.2755 223.873 47.3528 217.443 102.609C211.013 157.865 150.239 204.472 81.2816 195.886Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // transform: matrix(1, 0, -0.01, 1, 0, 0);
        width: "219px",
        height: "197px",
      },
      "#extra2": {
        boxSizing: "border-box",
        marginRight: "262px",
        marginTop: "44px",
        zIndex: 3,
        position: "absolute",
        width: "201px",
        height: "201px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 101.342 100.995)' r='99.7805' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            /* applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ], */
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-780fa506-9814-469e-8758-32ec684c96bb": {
    templateId: "crd-780fa506-9814-469e-8758-32ec684c96bb",
    templateName: "Card 12A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",
        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "32px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "20px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "absolute", */
        padding: "166px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "32px",
        right: "33px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 215px",
        position: "absolute",
        width: "190px",
        height: "215px",
        top: "-37px",
        zIndex: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "46px",
        zIndex: "10",
        // minHeight: "120px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        zIndex: 2,
        top: "-14px",
        marginRight: "16px",
        width: "195px",
        height: "175px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M72.2406 173.922C11.0374 166.302 -3.65179 135.28 2.0552 86.2374C7.76218 37.195 39.0697 -29.2677 118.481 15.4915C179.726 50.0112 198.797 42.092 193.09 91.1343C187.383 140.177 133.444 181.543 72.2406 173.922Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "10px",
        top: "12px",
        zIndex: "3",
        position: "absolute",
        width: "179px",
        height: "179px",
        marginTop: "-18px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='179' height='179' viewBox='0 0 179 179' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='88.4881' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 89.6982 89.8145)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-09501028-80e3-4e24-8b83-15957648463e": {
    templateId: "crd-09501028-80e3-4e24-8b83-15957648463e",
    templateName: "Card 9A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 328,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "20px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "32px 16px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "9px",
        right: "12px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "46px",
        zIndex: "10",
        // marginTop: "50px",
        minHeight: "162px",
        marginLeft: "8px",
        justifyContent: "center",
        // marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        marginTop: "1px",
        marginLeft: "6px",
        width: "276px",
        height: "250px",
        zIndex: 2,
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M101.278 246.359C14.5552 235.562 -6.25878 191.605 1.82778 122.114C9.91434 52.6227 54.2758 -41.5523 166.799 21.8697C253.58 70.7827 280.603 59.5615 272.517 129.052C264.43 198.543 188 257.157 101.278 246.359Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        marginRight: "289px",
        marginTop: "66px",

        zIndex: "1",

        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "48px",
        marginTop: "0px",
        zIndex: "1",
        position: "absolute",
        width: "263px",
        height: "263px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-63a9d59c-3a08-477c-b673-ebd583c72331": {
    templateId: "crd-63a9d59c-3a08-477c-b673-ebd583c72331",
    templateName: "Card 9B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginBottom: "25px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "42px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "50px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "9px",
        right: "12px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        zIndex: "10",
        marginTop: "50px",
        minHeight: "162px",
        justifyContent: "center",
        // marginLeft: "8px",
        // marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        marginTop: "78px",
        marginRight: "4px",
        width: "268px",
        height: "230px",
        zIndex: 2,
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M112.932 229.214C26.3442 228.519 0.825182 189.906 0.825184 124.447C0.825187 58.9889 33.6994 -33.3192 151.706 13.5076C254.542 75.5062 268.027 36.2817 268.027 101.74C268.027 167.199 199.519 229.909 112.932 229.214Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },

      "#extra3": {
        boxSizing: "border-box",

        position: "absolute",
        width: "26px",
        height: "26px",

        marginLeft: "168px",
        marginTop: "56px",

        zIndex: "1",

        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "11px",
        marginTop: "34px",
        zIndex: "1",
        position: "absolute",
        width: "282px",
        height: "282px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-730ce5bd-fb82-4c5b-9498-2535a64326f9": {
    templateId: "crd-730ce5bd-fb82-4c5b-9498-2535a64326f9",
    templateName: "Card 5A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // NO PREVIEW
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        marginRight: "8px",
        marginBottom: "18px",
        marginTop: "5px",
        marginLeft: "8px",
      },
      "#semantic-root .card": {
        width: "300px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        overflow: "hidden",
        position: "relative",
        padding: "42px 44px 0px 0px",
        minHeight: "215px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
        display: "none",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        padding: "0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        backgroundColor: "#B9B9B9",
        top: "10px",
        right: "82px",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        width: "146px",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "118px",
        marginLeft: "32px",
        marginTop: "20px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "48%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "8px",
        transition: "color 0.1s, border 0.1s",
        zIndex: "2",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        top: "18px",
        left: "10px",
        position: "absolute",
        width: "191px",
        height: "174px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M87.3013 173.133C25.2932 177.657 4.6598 149.881 0.640559 100.273C-3.37868 50.6639 14.4796 -21.2102 101.805 7.3958C169.153 29.4576 186.448 17.8714 190.467 67.4801C194.486 117.089 149.31 168.61 87.3013 173.133Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='285' height='315' viewBox='0 0 285 315' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M284.744 182.76C283.928 284.492 235.9 314.475 154.458 314.475C73.0158 314.475 -41.8501 275.85 16.3452 137.203C61.2271 30.2747 44.6155 0.537109 126.058 0.537109C207.5 0.537109 285.561 81.0276 284.744 182.76Z' fill='#F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transform: "matrix(0.08, 1, -1, 0.07, 0, 0)", */
        zIndex: "2",
      },

      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "126px",
        left: "193px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        top: "12px",
        left: "15px",
        zIndex: "2",
        position: "absolute",
        width: "194px",
        height: "194px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-dde54333-c2e5-4c79-9e04-ba0fc998e592": {
    templateId: "crd-dde54333-c2e5-4c79-9e04-ba0fc998e592",
    templateName: "Card 6A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // NO PREVIEW
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        marginRight: "8px",
        marginBottom: "18px",
        marginTop: "5px",
        marginLeft: "8px",
      },
      "#semantic-root .card": {
        width: "300px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        overflow: "hidden",
        position: "relative",
        padding: "42px 44px 0px 0px",
        minHeight: "215px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
        display: "none",
      },
      "#semantic-root .actions": {
        padding: "0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "10px",
        right: "82px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        width: "115px",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "118px",
        marginLeft: "55px",
        marginTop: "20px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "48%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "8px",
        transition: "color 0.1s, border 0.1s",
        zIndex: "2",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      "#extra1": {
        top: "17px",
        left: "10px",
        position: "absolute",
        width: "191px",
        height: "191px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M87.3013 173.133C25.2932 177.657 4.6598 149.881 0.640559 100.273C-3.37868 50.6639 14.4796 -21.2102 101.805 7.3958C169.153 29.4576 186.448 17.8714 190.467 67.4801C194.486 117.089 149.31 168.61 87.3013 173.133Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='285' height='315' viewBox='0 0 285 315' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M284.744 182.76C283.928 284.492 235.9 314.475 154.458 314.475C73.0158 314.475 -41.8501 275.85 16.3452 137.203C61.2271 30.2747 44.6155 0.537109 126.058 0.537109C207.5 0.537109 285.561 81.0276 284.744 182.76Z' fill='#F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transform: "matrix(0.08, 1, -1, 0.07, 0, 0)", */
        zIndex: "2",
      },
      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "126px",
        left: "193px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        top: "12px",
        left: "15px",
        zIndex: "2",
        position: "absolute",
        width: "194px",
        height: "194px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-71b8a8f9-b9a7-497e-b6f5-836614b4890c": {
    templateId: "crd-71b8a8f9-b9a7-497e-b6f5-836614b4890c",
    templateName: "Card 10A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // TO DO DEFAULT PREVIEW IS L3 LAYOUT
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Passion One",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Passion_One/static/PassionOne/PassionOne-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "'Passion One', cursive",
        "--textfontfamily": "'Open Sans', sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 541,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "136px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginLeft: "50px",
        marginBottom: "30px",
        marginRight: "20px",
        paddingLeft: "36px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "541px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "160px 21px 21px 21px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        padding: "12px 22px 20px 22px",
        minHeight: "250px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        alignSelf: "flex-end",
        width: "300px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "118%",
        marginTop: 9,
        marginBottom: "17px",
        letterSpacing: "0.9px",
        minHeight: "90px",
      },
      "#semantic-root .title .markdown p": { margin: "4px 0 0 12px" },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "25px",
        padding: "0px 32px",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 11,
        width: "15px",
        height: "15px",
        top: "14px",
        right: "12px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 120px",
        position: "absolute",
        zIndex: 4,
        height: "276px",
        width: "245px",
        top: "-147px",
        right: "328px",
        backgroundSize: "contain",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content, #semantic-root .actions": {
        /* marginLeft: "198px", */
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "22px",
        zIndex: "10",
        /* width: "223px", */
        minHeight: "150px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        padding: "0px 32px",
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        left: "-29px",
        top: "-120px",
        zIndex: 2,
        /* marginTop: "36px",
        marginRight: "278px", */
        width: "250px",
        height: "226px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M92.1825 224.31C13.2573 214.483 -5.68521 174.478 1.67428 111.235C9.03376 47.9922 49.4067 -37.7154 151.813 20.0043C230.791 64.5194 255.385 54.3071 248.025 117.55C240.666 180.793 171.108 234.137 92.1825 224.31Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        right: "329px",
        top: "-109px",
        zIndex: 3,
        position: "absolute",
        width: "229px",
        height: "229px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 101.342 100.995)' r='99.7805' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "27px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "'Passion One', cursive",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-644b5940-804b-406a-b0ba-6995b58e8e34": {
    templateId: "crd-644b5940-804b-406a-b0ba-6995b58e8e34",
    templateName: "Card 11B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 500,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        paddingLeft: "36px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "465px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "relative", */
        padding: "16px 22px 20px 22px",
        marginTop: "20px",
        minHeight: "230px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "30px",
        right: "30px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 120px",
        position: "absolute",
        zIndex: 4,
        height: "242px",
        width: "214px",
        top: "5px",
        right: "17px",
        backgroundSize: "contain",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content, #semantic-root .actions": {
        marginRight: "198px",
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "22px",
        zIndex: "10",
        width: "223px",
        minHeight: "142px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        marginRight: 198,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        zIndex: 2,
        top: "34px",
        right: "14px",
        width: "222px",
        height: "197px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M81.2796 195.846C12.3217 187.26 -4.2286 152.307 2.20147 97.0509C8.63154 41.7948 43.9058 -33.089 133.379 17.3414C202.384 56.2348 223.871 47.3122 217.441 102.568C211.011 157.824 150.237 204.432 81.2796 195.846Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        marginLeft: "258px",
        marginTop: "44px",
        zIndex: 3,
        position: "absolute",
        width: "201px",
        height: "201px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 101.342 100.995)' r='99.7805' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-3f1ce8ce-5599-4180-8fae-e49ecb6e43b6": {
    templateId: "crd-3f1ce8ce-5599-4180-8fae-e49ecb6e43b6",
    templateName: "Card 4A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // DEFAULT PREVIEW L4
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],
      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "32px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        // paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        // paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "absolute", */
        padding: "80px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "32px",
        right: "33px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 215px",
        position: "absolute",
        width: "100px",
        height: "100px",
        top: "-17px",
        left: "93px",
        zIndex: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "46px",
        zIndex: "10",
        // minHeight: "120px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        zIndex: 2,
        top: "-14px",
        marginRight: "16px",
        width: "111px",
        height: "100px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M42.0323 101.1C6.47873 96.6728 -2.05436 78.6518 1.26089 50.1626C4.57613 21.6734 22.763 -16.9355 68.894 9.0656C99.0005 26.0347 115.55 24.5181 112.235 53.0073C108.92 81.4965 77.5859 105.526 42.0323 101.1Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='194' height='175' viewBox='0 0 194 175' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M71.4672 173.709C10.2639 166.089 -4.42522 135.067 1.28176 86.0245C6.98874 36.9821 38.2963 -29.4806 117.708 15.2786C178.953 49.7983 198.024 41.8791 192.317 90.9214C186.61 139.964 132.67 181.33 71.4672 173.709Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },

      "#extra2": {
        boxSizing: "border-box",
        marginRight: "10px",
        top: "4px",
        zIndex: "3",
        position: "absolute",
        width: "103px",
        height: "103px",
        marginTop: "-18px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='179' height='179' viewBox='0 0 179 179' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='88.4881' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 89.6982 89.8145)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-a9cf769f-bacf-4d52-9240-07c672bb0563": {
    templateId: "crd-a9cf769f-bacf-4d52-9240-07c672bb0563",
    templateName: "Card 2A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8", // DEFAULT PREVIEW M1
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "32px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        // paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        // paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "absolute", */
        padding: "115px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
        position: "absolute",
        top: "40px",
        left: "55px",
        width: "130px",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "32px",
        right: "33px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 215px",
        position: "absolute",
        width: "70px",
        height: "18px",
        top: "27px",
        left: "108px",
        zIndex: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "46px",
        zIndex: "10",
        // minHeight: "120px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      "#extra1": {
        position: "absolute",
        zIndex: 2,
        top: "-14px",
        left: "40px",
        width: "157px",
        height: "141px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M59.4706 142.493C9.23774 136.238 -2.81845 110.777 1.86558 70.525C6.54961 30.2733 32.2454 -24.2763 97.4228 12.4601C147.69 40.7922 163.342 34.2925 158.658 74.5442C153.974 114.796 109.703 148.747 59.4706 142.493Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='194' height='175' viewBox='0 0 194 175' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M71.4672 173.709C10.2639 166.089 -4.42522 135.067 1.28176 86.0245C6.98874 36.9821 38.2963 -29.4806 117.708 15.2786C178.953 49.7983 198.024 41.8791 192.317 90.9214C186.61 139.964 132.67 181.33 71.4672 173.709Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },
      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "-19px",
        left: "166px",
        zIndex: "2",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        zIndex: "1",
        position: "absolute",
        top: "-13px",
        width: "134px",
        height: "134px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='179' height='179' viewBox='0 0 179 179' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='88.4881' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 89.6982 89.8145)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-382a1382-4c0a-4c39-8b87-05601dcdcec2": {
    templateId: "crd-382a1382-4c0a-4c39-8b87-05601dcdcec2",
    templateName: "Card 3A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "32px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        // paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        // paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "254px",
        minHeight: "235px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "127px 21px 21px 21px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "absolute", */
        padding: "94px 22px 20px 22px",
        marginTop: "20px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
        position: "absolute",
        top: "33px",
        left: "58px",
        width: "130px",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "32px",
        right: "33px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 215px",
        position: "absolute",
        width: "70px",
        height: "18px",
        top: "27px",
        left: "108px",
        zIndex: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "46px",
        zIndex: "10",
        // minHeight: "120px",
        marginLeft: "8px",
        marginRight: "8px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      "#extra1": {
        position: "absolute",
        zIndex: 2,
        top: "-14px",
        left: "40px",
        width: "127px",
        height: "114px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M47.5795 115.043C6.97065 109.987 -2.77571 89.4037 1.01091 56.8637C4.79753 24.3238 25.5703 -19.7747 78.2605 9.92338C118.897 32.8274 131.551 27.573 127.764 60.1129C123.977 92.6528 88.1882 120.099 47.5795 115.043Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='194' height='175' viewBox='0 0 194 175' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M71.4672 173.709C10.2639 166.089 -4.42522 135.067 1.28176 86.0245C6.98874 36.9821 38.2963 -29.4806 117.708 15.2786C178.953 49.7983 198.024 41.8791 192.317 90.9214C186.61 139.964 132.67 181.33 71.4672 173.709Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", */ // transform: matrix(1, 0, -0.01, 1, 0, 0);
      },
      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "-19px",
        left: "192px",
        zIndex: "2",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        zIndex: "-1",
        position: "absolute",
        top: "-18px",
        left: "16px",
        width: "280px",
        height: "280px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='179' height='179' viewBox='0 0 179 179' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='88.4881' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 89.6982 89.8145)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-7e999e37-9233-43ee-ae82-5f23772442f7": {
    templateId: "crd-7e999e37-9233-43ee-ae82-5f23772442f7",
    templateName: "Card 13A",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // DEFAULT PREVIEW L4
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "0px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        // paddingLeft: "22px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        // paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "absolute", */
        padding: "22px 22px 20px 22px",
        marginTop: "20px",
        minHeight: "412px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "12px",
        padding: "0",
        display: "flex",
        alignItems: "self-end",
        order: 4,
        flex: 1,
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "32px",
        right: "33px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".mediaSVG": {
        order: 2,
        height: 200,
        position: "relative",
        marginTop: "15px",
      },
      ".mediaSVG #extra4": {
        width: "195px",
        height: "175px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M72.3266 173.421C11.0569 165.792 -3.64815 134.736 2.06502 85.6409C7.7782 36.5454 39.1197 -29.9895 118.617 14.8183C179.929 49.3755 199.021 41.4476 193.307 90.5432C187.594 139.639 133.596 181.05 72.3266 173.421Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "inline-block",
      },
      ".media": {
        width: "190px",
        height: "215px",
        zIndex: 4,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
        position: "absolute",
        top: "-22px",
      },
      "#semantic-root .content": {
        order: 1,
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "46px",
        zIndex: "10",
        minHeight: "100px",
        margin: "0 10px 10px 10px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        minHeight: "33px",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        order: 2,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      ".mediaSVG #extra5": {
        boxSizing: "border-box",
        marginRight: "10px",
        zIndex: "3",
        width: "179px",
        height: "179px",
        //backgroundImage: "url(tmpTemplates/circle.svg)",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='179' height='179' viewBox='0 0 179 179' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='88.5848' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 89.5899 89.4404)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "inline-block",
        position: "absolute",
        top: "7px",
        left: "11px",
      },
      "#extra1": {},
      "#extra2": {},
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".mediaSVG #extra4///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: ".mediaSVG #extra5///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".mediaSVG #extra4///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-3ad44bbb-ccf0-4739-b1cc-93de7aad54a2": {
    templateId: "crd-3ad44bbb-ccf0-4739-b1cc-93de7aad54a2",
    templateName: "Card 5B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // NO PREVIEW
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        marginRight: "8px",
        marginBottom: "20px",
        marginTop: "5px",
        marginLeft: "8px",
      },
      "#semantic-root .card": {
        width: "300px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        overflow: "hidden",
        position: "relative",
        padding: "42px 44px 0px 0px",
        minHeight: "215px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
        display: "none",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        padding: "0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "10px",
        right: "98px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        width: "146px",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "118px",
        marginLeft: "32px",
        marginTop: "20px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "48%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "8px",
        transition: "color 0.1s, border 0.1s",
        zIndex: "2",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        top: "7px",
        left: "6px",
        position: "absolute",
        width: "187px",
        height: "170px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M78.147 6.59454C135.732 -11.4849 161.413 10.1668 176.256 56.148C191.098 102.129 190.225 174.02 101.341 166.432C32.791 160.579 19.029 175.378 4.18644 129.396C-10.6561 83.4152 20.5615 24.6739 78.147 6.59454Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* transform: "matrix(-0.31, -0.95, 0.96, -0.3, 0, 0)", */
        zIndex: "1",
      },

      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "187px",
        left: "67px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        top: "12px",
        left: "15px",
        zIndex: "1",
        position: "absolute",
        width: "188px",
        height: "188px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-5aefe275-cdb1-4966-aaa4-656cee917e0f": {
    templateId: "crd-5aefe275-cdb1-4966-aaa4-656cee917e0f",
    templateName: "Card 6B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // NO PREVIEW
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        marginRight: "8px",
        marginBottom: "20px",
        marginTop: "8px",
        marginLeft: "10px",
      },
      "#semantic-root .card": {
        width: "300px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        overflow: "hidden",
        position: "relative",
        padding: "42px 44px 0px 0px",
        minHeight: "215px",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
        display: "none",
      },
      "#semantic-root .actions": {
        padding: "0",
        display: "flex",
        justifyContent: "flex-end",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "10px",
        right: "98px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        width: "115px",
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "5px",
        zIndex: "10",
        minHeight: "118px",
        marginLeft: "55px",
        marginTop: "20px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "48%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "8px",
        transition: "color 0.1s, border 0.1s",
        zIndex: "2",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
        paddingTop: 6,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },
      "#extra1": {
        top: "7px",
        left: "6px",
        position: "absolute",
        width: "187px",
        height: "170px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M78.147 6.40774C135.732 -11.6717 161.413 9.98 176.256 55.9612C191.098 101.942 190.225 173.833 101.341 166.245C32.791 160.393 19.029 175.191 4.18644 129.21C-10.6561 83.2284 20.5615 24.4871 78.147 6.40774Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='184' height='167' viewBox='0 0 184 167' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M78.147 6.59454C135.732 -11.4849 161.413 10.1668 176.256 56.148C191.098 102.129 190.225 174.02 101.341 166.432C32.791 160.579 19.029 175.378 4.18644 129.396C-10.6561 83.4152 20.5615 24.6739 78.147 6.59454Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transform: "matrix(-0.31, -0.95, 0.96, -0.3, 0, 0)", */
        zIndex: "1",
      },

      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        width: "25px",
        height: "25px",
        top: "187px",
        left: "67px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='13.4415' cy='13.5702' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      // for some reason, if extra2 is before extra3, extra3 not visible
      "#extra2": {
        boxSizing: "border-box",
        top: "12px",
        left: "15px",
        zIndex: "1",
        position: "absolute",
        width: "188px",
        height: "188px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53", // "#2C4D9C", // "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-120752be-6a00-4c6f-8560-482ecf7cdc07": {
    templateId: "crd-120752be-6a00-4c6f-8560-482ecf7cdc07",
    templateName: "Card 10B",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-b492645d-fd89-4209-9c60-b38399fdf830", // TO DO DEFAULT PREVIEW IS L3 LAYOUT
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Passion One",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Passion_One/static/PassionOne/PassionOne-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "'Passion One', cursive",
        "--textfontfamily": "'Open Sans', sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 541,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "148px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginLeft: "41px",
        marginRight: "20px",
        marginBottom: "20px",
        paddingLeft: "36px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "30px",
      },
      "#semantic-root .card": {
        width: "541px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "160px 21px 21px 21px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        padding: "12px 22px 20px 22px",
        minHeight: "260px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "118%",
        marginTop: 9,
        marginBottom: 14,
        letterSpacing: "0.9px",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "25px",
        padding: "0px 32px",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "14px",
        right: "11px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      ".media": {
        // flex: "0 1 120px",
        position: "absolute",
        zIndex: 4,
        height: "276px",
        width: "245px",
        top: "-161px",
        right: "132px",
        backgroundSize: "contain",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content, #semantic-root .actions": {
        /* marginLeft: "198px", */
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        // paddingBottom: "22px",
        zIndex: "10",
        /* width: "223px", */
        minHeight: "100px",
        marginTop: "95px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 20,
        padding: "0px 32px",
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        right: "118px",
        top: "-135px",
        zIndex: 2,
        width: "250px",
        height: "226px",
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M92.1845 224.31C13.2593 214.483 -5.68326 174.478 1.67623 111.235C9.03572 47.9922 49.4086 -37.7154 151.815 20.0043C230.793 64.5194 255.387 54.3071 248.027 117.55C240.668 180.793 171.11 234.137 92.1845 224.31Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#extra2": {
        boxSizing: "border-box",
        right: "132px",
        top: "-123px",
        zIndex: 3,
        position: "absolute",
        width: "229px",
        height: "229px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='202' height='202' viewBox='0 0 202 202' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle transform='matrix(0.001737 -0.999999 0.999685 0.0251062 101.342 100.995)' r='99.7805' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "27px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "'Passion One', cursive",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-cc8ac643-1753-475a-b693-165fae4e6a40": {
    templateId: "crd-cc8ac643-1753-475a-b693-165fae4e6a40",
    templateName: "Card 9C",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 328,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "32px 16px 20px 22px",
        marginTop: "20px",
        justifyContent: "flex-end",
        minHeight: "371px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
        minHeight: "31.5px",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "9px",
        right: "12px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "7px",
        zIndex: "10",
        minHeight: "162px",
        margin: "96px 0px 8px 1px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginTop: 10,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        top: "86px",
        left: "24px",
        width: "268px",
        height: "229px",
        zIndex: 2,
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M156.08 0.244905C242.667 0.93978 268.187 39.5532 268.187 105.012C268.187 170.47 235.312 262.778 117.305 215.952C14.4701 153.953 0.984374 193.178 0.984371 127.719C0.984368 62.2603 69.4924 -0.44997 156.08 0.244905Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },

      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        top: "48px",
        left: "56px",
        width: "26px",
        height: "26px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        zIndex: "1",
        position: "absolute",
        top: "25px",
        left: "25px",
        width: "282px",
        height: "282px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-112300ab-39c9-41f3-a51b-90955d675988": {
    templateId: "crd-112300ab-39c9-41f3-a51b-90955d675988",
    templateName: "Card 9D",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    defaultSemanticPreviewTemplateId:
      "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
      {
        isActive: true,
        templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
        templateName: "S1",
      },
      {
        isActive: true,
        templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
        templateName: "M1",
      },
      {
        templateId: "prw-2630d177-d487-42ba-9954-aa594df00533",
        templateName: "L1",
      },
      {
        templateId: "prw-3806d8bd-a272-4c22-b823-9804c01d93ae",
        templateName: "S2",
      },
      {
        templateId: "prw-e57d8efc-c377-4af3-8a49-6f1fb69bac29",
        templateName: "L2",
      },
      {
        isActive: false,
        templateId: "prw-1af1dda4-a249-4fc9-8d04-d9cf2c2d9e13",
        templateName: "L4",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color4": "#B9B9B9",
        "--color1cta": "#333333",
        "--color2cta": "#F6CF53",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        width: 328,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px", // "130px", // TODO properly size the view; switch back to 10 px..!
        marginRight: "8px",
        marginBottom: "20px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(0 2px 10px rgba(0, 0, 0, 0.14))",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
      "#semantic-root .card": {
        width: "254px",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        overflow: "hidden",
        /* boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)", */
        position: "relative",
        padding: "32px 16px 20px 22px",
        marginTop: "20px",
        justifyContent: "flex-end",
        minHeight: "371px",
        backgroundColor: "var(--color3)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "",
      },
      "// topLeft\n  #semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n  #semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n  #semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      "#semantic-root .text": {
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "150%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        padding: "0",
        display: "flex",
        alignItems: "stretch",
        minHeight: "31.5px",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        zIndex: 5,
        width: "15px",
        height: "15px",
        top: "9px",
        right: "12px",
        backgroundColor: "#B9B9B9",
        padding: "0",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn #closeBtnContent": {
        width: "11px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23ffffff'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      "#semantic-root .closeBtn:hover": {
        backgroundColor: "#B9B9B9" /* "#f2f2f2" */,
      },
      "#semantic-root .closeBtn:active": {
        backgroundColor: "#B9B9B9" /* "#e2e2e2" */,
      },
      "#semantic-root .media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
      },
      ".media": {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
        paddingBottom: "7px",
        zIndex: "10",
        minHeight: "162px",
        margin: "116px 0px 0px 9px",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        // backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        width: "100%",
        color: "var(--color1cta)",
        fontWeight: 600,
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        padding: "6px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        transform: "scale(1.05)",
      },
      ".cta-button-default:focus": { outline: "none" },
      ".cta-button-default.disabled": {
        opacity: 0.35,
        cursor: "not-allowed",
      },

      "#custom-select": {
        marginBottom: 20,
      },

      ".dropDownContainer": {
        borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px",
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",

        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        transform: "scale(1.05)",
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)",
      },

      "#extra1": {
        position: "absolute",
        top: "86px",
        left: "30px",
        width: "268px",
        height: "236px",
        zIndex: 2,
        backgroundColor: "var(--color1)",
        clipPath:
          "path('M64.0276 219.855C-10.1518 175.186 -12.465 128.96 20.8515 72.6145C54.1681 16.2688 129.448 -46.4564 207.193 53.9135C271.176 136.517 295.729 132.721 262.412 189.067C229.095 245.413 138.207 264.524 64.0276 219.855Z')",
        backgroundImage: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },

      "#extra3": {
        boxSizing: "border-box",
        position: "absolute",
        top: "97px",
        left: "278px",
        width: "26px",
        height: "26px",
        zIndex: "1",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='12.7413' cy='13.2472' r='10.8301' stroke='%23F6CF53' stroke-width='3.82239'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "#extra2": {
        boxSizing: "border-box",
        zIndex: "2",
        position: "absolute",
        top: "34px",
        left: "28px",
        width: "282px",
        height: "282px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='241' height='241' viewBox='0 0 241 241' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle r='119.542' transform='matrix(0.001737 -0.999999 0.999685 0.0251062 120.353 120.298)' stroke='%23B9B9B9' stroke-width='1.27413'/>\n</svg>\")",
        // backgroundImage:
        //  "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1A/EllipseForegroundBig.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", // border: "1.27413px solid #B9B9B9", // transform: matrix(0, -1, 1, 0.03, 0, 0);
      },
    },
    prevent: {
      cta: [],
      picture: ["main", "bg1", "bg2"],
      tag: true,
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
    userCustomizable: {
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "14px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color4: {
            title: "Color4",
            description: "custom circle",
            type: "string",
            default: "#B9B9B9",
            cssProperty: "--color4",
            applyToSvg: [
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra2///backgroundImage",
              },
            ],
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color2cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              },
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
      images: {
        bg1: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#extra1///backgroundImage",
            },
          ],
          title: "Background image 1",
          description: "applied as a blob background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
        bg2: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: "#semantic-root .card///backgroundImage",
            },
          ],
          title: "Background image 2",
          description: "applied as a card background",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
    },
  },
  "crd-50ad24d4-c833-4aa9-9958-3a0cfc59c54f": {
    templateId: "crd-50ad24d4-c833-4aa9-9958-3a0cfc59c54f",
    templateName: "Card Legacy",
    tFamilyId: "fam-6fae4cea-82c4-4f2f-89c8-a04973c7fbfa",
    defaultSemanticPreviewTemplateId:
      "prw-f0c8d386-c174-43ae-abfc-a028c1b68ef0",
    semanticPreviewTemplateCatalogue: [
      {
        isActive: true,
        templateId: "prw-f0c8d386-c174-43ae-abfc-a028c1b68ef0",
        templateName: "Preview Legacy",
      },
      {
        isActive: true,
        templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
        templateName: "No preview",
      },
    ],
    semanticStyle: {
      "@font-face": [
        {
          fontFamily: "Lora",
          fontStyle: "normal",
          src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
        },
        {
          fontFamily: "Open Sans",
          fontStyle: "normal",
          // prettier-ignore
          src: 'url(fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("truetype")',
        },
      ],

      "#root": {
        width: "316px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      },
      "#semantic-root": {
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF",
        "--color1cta": "#241751",
        "--color2cta": "#241751",
        "--titlefontsize": "16px",
        "--textfontsize": "14px",
        "--titlefontfamily": "Lora, serif",
        "--textfontfamily": "Open Sans, sans-serif",
        "--ctafontfamily": "Lora, serif",
        "--ctafontsize": "15px",
        "--doublectafontsize": "13px",

        // fixed customVars
        "--dropdownitemheight": "30px",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "stretch",
        marginTop: "10px",
        marginBottom: "20px",
        boxSizing: "border-box",
        position: "relative",
        filter: "drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.14))",
      },
      "#semantic-root .card": {
        width: "calc(100% - 20px)",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid var(--color3)",
        borderRadius: "20px",
        boxSizing: "border-box",
        backgroundColor: "var(--color3)",
        overflow: "hidden",
        boxShadow: "0 2px 16px rgba(0, 0, 0, 0.14)",
        position: "relative",
        padding: "42px 22px 20px 22px",
      },
      "// topLeft\n#semantic-root .card.topLeft": {
        borderRadius: "0 20px 20px 20px",
      },
      "// topRight\n#semantic-root .card.topRight": {
        borderRadius: "20px 0 20px 20px",
      },
      "// bottomRight\n#semantic-root .card.bottomRight": {
        borderRadius: "20px 20px 0 20px",
      },
      "#semantic-root .logo": {
        position: "absolute",
        top: "20px",
        left: "22px",
        zIndex: 999999,
      },
      "#semantic-root .markdown p": { margin: "0" },
      "#semantic-root .title": {
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "115%",
        margin: "12px 0",
      },
      "#semantic-root .text": {
        opacity: 0.75,
        fontFamily: "var(--textfontfamily)",
        fontWeight: 400,
        fontSize: "var(--textfontsize)",
        lineHeight: "135%",
        color: "var(--color2)",
      },
      "#semantic-root .actions": {
        marginTop: "20px",
        padding: "0",
        display: "flex",
        alignItems: "stretch",
      },
      "#semantic-root .closeBtn": {
        position: "absolute",
        top: "20px",
        right: "22px",
        width: "22px",
        height: "22px",
        padding: "0",
        zIndex: 999999,
        borderRadius: "5px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      "#semantic-root .closeBtn:hover": { backgroundColor: "#f2f2f2" },
      "#semantic-root .closeBtn:active": { backgroundColor: "#e2e2e2" },
      "#semantic-root .closeBtn.hidden": { visibility: "hidden" },
      /* "#semantic-root .media": { flex: "0 1 120px", backgroundSize: "contain" }, */
      ".media": {
        flex: "0 1 120px",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: "", // url(https://files.habitinspiring.com/content-dashboard/qa/d2f959ca-d898-48a7-ac52-668abf41dc27.png)
      },
      "#semantic-root .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0",
      },
      "#semantic-root .content.noText .title": { marginBottom: "0" },
      "#semantic-root .cta-button-default:disabled": {
        border: "1px solid #b5b5b5",
        backgroundColor: "#efefef",
        color: "var(--color3)",
        cursor: "default",
        boxShadow: "none",
      },
      "#semantic-root .cta-button-default:not(:first-child)": {
        marginLeft: "10px",
      },
      ".cta-button-default": {
        fontFamily: "var(--ctafontfamily)",
        backgroundColor: "var(--color2cta)",
        width: "100%",
        color: "var(--color1cta)",
        border: "1px solid var(--color1cta)",
        fontWeight: "bold",
        fontSize: "var(--ctafontsize)",
        lineHeight: "130%",
        borderRadius: "10px",
        padding: "8px",
        transition: "color 0.1s, border 0.1s",
      },
      ".isDouble .cta-button-default": {
        fontSize: "var(--doublectafontsize)",
      },
      ".cta-button-default:hover": {
        /* transform: "scale(1.05)", */
        color: "var(--color2cta)",
        backgroundColor: "var(--color1cta)",
      },
      ".cta-button-default:focus": { outline: "none" },
      /* ".cta-button-default:active": {
        backgroundColor: "blue",
        border: "1px solid green",
        color: "white",
      }, */
      ".cta-button-default.disabled": { opacity: 0.5, cursor: "not-allowed" },
      "#custom-select": {
        marginTop: 10,
      },
      ".select": {
        width: "100%",
        padding: "8px 28px 8px 8px",
        borderRadius: "8px",
        border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg fill='none' width='12' viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'>\n<path d='m6 6.5002c-0.1316 7.6e-4 -0.26207-0.02447-0.3839-0.07423-0.12184-0.04977-0.23266-0.12309-0.3261-0.21577l-4-4c-0.09324-0.09324-0.1672-0.20393-0.21766-0.32576-0.05046-0.12182-0.076429-0.25239-0.076429-0.38424 0-0.13186 0.025969-0.26243 0.076429-0.38425 0.05046-0.12183 0.12442-0.23252 0.21766-0.32576s0.20393-0.1672 0.32575-0.21766c0.12183-0.05046 0.25239-0.076431 0.38425-0.076431s0.26243 0.025971 0.38425 0.076431 0.23251 0.12442 0.32575 0.21766l3.29 3.31 3.3-3.18c0.09199-0.10229 0.2041-0.1845 0.32931-0.24148 0.12522-0.056986 0.26084-0.087523 0.39839-0.089702 0.1376-0.002179 0.2741 0.024046 0.401 0.077037 0.127 0.052991 0.2416 0.13161 0.3368 0.23093s0.1689 0.21722 0.2164 0.34631c0.0476 0.1291 0.068 0.26661 0.0599 0.40395-8e-3 0.13733-0.0443 0.27154-0.1065 0.39421-0.0623 0.12268-0.1492 0.23119-0.2553 0.31874l-4 3.86c-0.18287 0.17631-0.42599 0.27642-0.68 0.28z' fill='%23FFFFFF'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 8px)",
        backgroundPositionY: "center",
        color: "rgba(0, 0, 0, 0.6)",
      },
      ".select:focus": { outline: "none" },
      ".dropDownContainer": {
        /* borderColor: "var(--color2cta)",
        borderRadius: "6px 31px",
        borderStyle: "solid",
        borderWidth: "2px", */
      },
      ".dropDownHeader": {
        minHeight: "var(--dropdownitemheight)",
        cursor: "pointer",
        fontFamily: "var(--ctafontfamily)", // backgroundColor: "#F6CF53", // textAlign: "center",
        fontWeight: 600,
        fontSize: 13,
        lineHeight: "115%",
        border: "1px solid rgb(226, 226, 226)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center", // justifyContent: "center",
        textIndent: "1.3em",
        textAlign: "left",

        opacity: 0.7, // width: "226px",

        width: "100%",
        // color: "var(--color1cta)", // padding: "8px 28px 8px 8px", // borderRadius: "8px", // border: "1px solid #e2e2e2",
        MozAppearance: "none",
        WebkitAppearance: "none",
        appearance: "none", // backgroundImage: // 'url("data:image/svg+xml;utf8,<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z" fill="#333333"/></svg>")',
        /* backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.609428 0.802414L4.15372 5.46907C4.35534 5.73453 4.75535 5.7321 4.95372 5.4642L8.40941 0.797553C8.65377 0.467561 8.4182 0 8.00758 0L1.00761 0C0.594189 0 0.359382 0.473186 0.609428 0.802414Z' fill='%23e6444e'/>\n</svg>\")", */
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg fill='none' width='12' viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'>\n<path d='m6 6.5002c-0.1316 7.6e-4 -0.26207-0.02447-0.3839-0.07423-0.12184-0.04977-0.23266-0.12309-0.3261-0.21577l-4-4c-0.09324-0.09324-0.1672-0.20393-0.21766-0.32576-0.05046-0.12182-0.076429-0.25239-0.076429-0.38424 0-0.13186 0.025969-0.26243 0.076429-0.38425 0.05046-0.12183 0.12442-0.23252 0.21766-0.32576s0.20393-0.1672 0.32575-0.21766c0.12183-0.05046 0.25239-0.076431 0.38425-0.076431s0.26243 0.025971 0.38425 0.076431 0.23251 0.12442 0.32575 0.21766l3.29 3.31 3.3-3.18c0.09199-0.10229 0.2041-0.1845 0.32931-0.24148 0.12522-0.056986 0.26084-0.087523 0.39839-0.089702 0.1376-0.002179 0.2741 0.024046 0.401 0.077037 0.127 0.052991 0.2416 0.13161 0.3368 0.23093s0.1689 0.21722 0.2164 0.34631c0.0476 0.1291 0.068 0.26661 0.0599 0.40395-8e-3 0.13733-0.0443 0.27154-0.1065 0.39421-0.0623 0.12268-0.1492 0.23119-0.2553 0.31874l-4 3.86c-0.18287 0.17631-0.42599 0.27642-0.68 0.28z' fill='%23241751'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "calc(100% - 12px)",
        backgroundPositionY: "center",
      },
      ".dropDownContainer:hover": {
        /* transform: "scale(1.05)", */
      },
      ".dropDownHeader.selected": {
        opacity: 1,
      },
      ".dropDownListContainer ": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        border: "1px solid rgb(226, 226, 226)",
        borderRadius: "8px",
        paddingBottom: "1px", // to avoid having scrollbar in case of <5 options
        overflowY: "auto",
        maxHeight: "calc(5 * var(--dropdownitemheight))",
      },
      ".dropDownList": {
        zIndex: 15,
        fontFamily: "var(--ctafontfamily)",
        textIndent: "1em", // position: "absolute", // top: "-100px", // left: "0", // width: "100%", // height: "100%", // padding: "0", // margin: "0", // paddingLeft: "1em", // background: "#ffffff", // border: "2px solid #e5e5e5", // boxSizing: "border-box", // color: "#3faffa", // fontSize: "1.3rem", // fontWeight: 500, // "&:first-child": { paddingTop: "0.8em" },
      },
      ".listItem": {
        marginLeft: "-1px", // to make it touch the border // width: "90%", // marginLeft: 8,
        cursor: "pointer",
        listStyle: "none",
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "115%",
        display: "flex",
        alignItems: "center",

        // color: "var(--color1cta)", // marginBottom: "0.8em",
        minHeight: "var(--dropdownitemheight)", // marginTop: "auto", // marginBottom: "auto",
      },
      ".listItem:last-child": {
        marginBottom: "-1px",
      },
      ".listItem.selected, .listItem:hover": {
        borderRadius: "6px 31px", // borderStyle: "solid", // borderWidth: "2px",
        backgroundClip: "padding-box",
      },
      ".listItem.selected": {
        backgroundColor: "rgb(246, 207, 83, .75)", // "#F6CF53", with opacity
        borderColor: "rgb(246, 207, 83, .75)",
      },
      ".listItem:hover": {
        /* backgroundColor: "var(--color2cta)",
        borderColor: "var(--color2cta)", */
        backgroundColor: "#241751",
        borderColor: "#241751",
        color: "white",
      },
    },
    prevent: {
      cta: [],
      picture: ["main"],
      tag: true,
    },
    userCustomizable: {
      cta: {
        CTA: {
          allowed: true,
          required: false,
        },
        DOUBLE_CTA: {
          allowed: true,
          required: false,
        },
        DROPDOWN: {
          allowed: true,
          required: false,
        },
      },
      images: {
        image: {
          applyImage: [
            {
              changes: [{ type: "simplyapply" }],
              path: ".media///backgroundImage",
            },
          ],
          title: "Main image",
          description: "placed on top of the blob shape",
          type: "image",
          default: null,
          allowed: true,
          required: false,
        },
      },
      tag: {
        allowed: false,
        required: false,
      },
      title: {
        allowed: true,
        required: false,
      },
      text: {
        allowed: true,
        required: false,
      },
      vars: {
        templateFonts: {
          ctafontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: '"Segoe UI", sans-serif',
            cssProperty: "--ctafontfamily",
          },
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "20px",
            cssProperty: "--titlefontsize",
          },
          textfontsize: {
            title: "Text font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--textfontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: '"Segoe UI", sans-serif',
            cssProperty: "--titlefontfamily",
          },
          textfontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Open Sans, sans-serif",
            cssProperty: "--textfontfamily",
          },
          ctafontsize: {
            title: "Cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "15px",
            cssProperty: "--ctafontsize",
          },
          doublectafontsize: {
            title: "double cta font size",
            description: "string; ex. 14px, or 1em etc.",
            type: "string",
            default: "13px",
            cssProperty: "--doublectafontsize",
          },
        },
        rootCssVars: {
          color1: {
            title: "Color1",
            description: "cerchio piccolo, bolla gialla",
            type: "string",
            default: "#241751",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#extra3///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title, text, CTA text, x to close",
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                // eslint-disable-next-line prettier/prettier
                path: "#semantic-root .closeBtn #closeBtnContent///backgroundImage",
              }, */
            ],
          },
          color3: {
            title: "Color3",
            description: "riquadro card",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "testo Cta",
            type: "string",
            default: "#241751",
            cssProperty: "--color1cta",
          },
          color2cta: {
            title: "Color2Cta",
            description: "background or border di Cta",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color2cta",
            applyToSvg: [
              /* {
                changes: [{ element: "path", attribute: "fill" }],
                path: ".dropDownHeader///backgroundImage",
              }, */
            ],
            applyToRgb: [
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///backgroundColor",
              },
              {
                changes: [{ element: "fieldValueWithTransparency" }],
                path: ".listItem.selected///borderColor",
              },
            ],
          },
        },
      },
    },
    isPrincipal: true, // available in selectable catalogue
    copiedFrom: "",
    rootCssSelector: "#semantic-root",
    stringPathSeparator: "///",
    customVars: {
      rootCssVars: {
        dropdownitemheight: "--dropdownitemheight",
      },
    },
  },
};

export const tmpSemanticPreviewTemplates = {
  "prw-b492645d-fd89-4209-9c60-b38399fdf830": {
    templateId: "prw-b492645d-fd89-4209-9c60-b38399fdf830",
    templateName: "No preview",
    tFamilyId: "fam-6fae4cea-82c4-4f2f-89c8-a04973c7fbfa",
    previewStyle: {},
    rootCssSelector: "body #root",
    stringPathSeparator: "///",
    userCustomizable: {
      vars: {
        templateFonts: {
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "12px",
            cssProperty: "--titlefontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
        },
        rootCssVars: {
          // the values for these is taken from the semantic
          color1: {
            title: "Color1",
            description: "bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                // prettier-ignore
                path: ".prime-mini__clickable-area .prime-mini__card///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title", // TODO missing x to close
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [],
          },
          color3: {
            title: "Color3",
            description: "background freccia",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "colore freccia cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#prime-mini__card-extra1///backgroundImage",
              },
            ],
          },
          // color2cta: {
          //   title: "Color2Cta",
          //   description: "background or border di Cta",
          //   type: "string",
          //   default: "#F6CF53",
          //   cssProperty: "--color2cta",
          //   applyToSvg: [
          //     {
          //       changes: [{ element: "path", attribute: "fill" }],
          //       path: ".dropDownHeader///backgroundImage",
          //     },
          //   ],
          //   applyToRgb: [
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///backgroundColor",
          //     },
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///borderColor",
          //     },
          //   ],
          // },
        },
      },
    },
    windowInfo: {
      totalWidth: 160,
      totalHeight: 150,
    },
    allowedCustomization: {
      "semanticPreview.title": false,
      "semanticPreview.text": false,
      "semanticPreview.cta1Text": false,
    },
  },
  "prw-f0c8d386-c174-43ae-abfc-a028c1b68ef0": {
    templateId: "prw-f0c8d386-c174-43ae-abfc-a028c1b68ef0",
    templateName: "Preview Legacy",
    tFamilyId: "fam-6fae4cea-82c4-4f2f-89c8-a04973c7fbfa",
    previewStyle: {
      "@font-face": {
        fontFamily: "Lora",
        fontStyle: "normal",
        src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
      },

      "body #root": {
        width: "78px", // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF", // temporarily applying this also to preview cta background
        "--color1cta": "#333333",
        // "--color2cta": "#F6CF53",
        "--titlefontsize": "12px",
        "--titlefontfamily": "Lora, serif",
      },
      ".prime-mini": {
        display: '"flex"',
        flexdirection: '"column"',
        alignitems: '"center"',
        position: '"relative"',
        justifycontent: '"flex-end"',
      },
      ".prime-mini .prime-mini__clickable-area": {
        height: "72px",
        width: "78px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        position: "relative",
      },
      ".prime-mini__clickable-area .prime-mini__card-flow-length": {
        position: "absolute",
        top: "-7px",
        right: "-9px",
        fontFamily: '"Segoe UI", sans-serif',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "100%",
        color: "#ffffff",
        backgroundColor: "#ffd038",
        boxShadow: "-1px 1px 3px rgba(0, 0, 0, 0.1)",
        width: "23px",
        height: "23px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
      },
      ".prime-mini__clickable-area .prime-mini__card": {
        background: "#241751",
        borderRadius: "18px",
        width: "57px",
        height: "57px",
        marginRight: "11px",
        marginBottom: "4px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".prime-mini #prime-mini__close": {
        transition: "all 0.2s ease-in-out",
        visibility: "hidden",
        cursor: "pointer",
        position: "absolute",
        top: "0",
        left: "0",
        transform: "translateY(15%)",
      },
      ".prime-mini #prime-mini__close:active": {
        transform: "scale(0.9) translateY(15%)",
      },
      ".prime-mini .prime-mini__clickable-area:active": {
        transform: "rotate(0) scale(0.95)",
      },
      ".prime-mini__clickable-area .prime-mini__card:hover": {
        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
      },
      ".prime-mini:hover #prime-mini__close": { visibility: "visible" },
      ".prime-mini.loading #prime-mini__close": {
        visibility: "hidden !important",
        pointerEvents: "none",
      },
      ".prime-mini.loading .prime-mini__card-hilogo,\n.prime-mini.loading .prime-mini__card-flow-length":
        {
          display: "none !important",
        },
      ".prime-mini.loading .prime-mini__card-loader": { display: "block" },
      ".prime-mini__card-loader": {
        display: "none",
        fontSize: "10px",
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        background: [
          "#ffffff",
          "linear-gradient(to right,\n  #ffffff 10%,\n  rgba(255, 255, 255, 0) 42%)",
        ],
        position: "relative",
        animation: "load3 1.4s infinite linear",
        transform: "translateZ(0)",
      },
      ".prime-mini__card-loader:before": {
        width: "50%",
        height: "50%",
        background: "#ffffff",
        borderRadius: "100% 0 0 0",
        position: "absolute",
        top: "0",
        left: "0",
        content: '""',
      },
      ".prime-mini__card-loader:after": {
        background: "#241751",
        width: "75%",
        height: "75%",
        borderRadius: "50%",
        content: '""',
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
      },
      "@-webkit-keyframes load3": {
        "0%": { WebkitTransform: "rotate(0deg)", transform: "rotate(0deg)" },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      },
      "@keyframes load3": {
        "0%": { WebkitTransform: "rotate(0deg)", transform: "rotate(0deg)" },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      },
    },
    rootCssSelector: "body #root",
    stringPathSeparator: "///",
    userCustomizable: {
      vars: {
        templateFonts: {
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "12px",
            cssProperty: "--titlefontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
        },
        rootCssVars: {
          // the values for these is taken from the semantic
          color1: {
            title: "Color1",
            description: "bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                // prettier-ignore
                path: ".prime-mini__clickable-area .prime-mini__card///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title", // TODO missing x to close
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [],
          },
          color3: {
            title: "Color3",
            description: "background freccia",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "colore freccia cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#prime-mini__card-extra1///backgroundImage",
              },
            ],
          },
          // color2cta: {
          //   title: "Color2Cta",
          //   description: "background or border di Cta",
          //   type: "string",
          //   default: "#F6CF53",
          //   cssProperty: "--color2cta",
          //   applyToSvg: [
          //     {
          //       changes: [{ element: "path", attribute: "fill" }],
          //       path: ".dropDownHeader///backgroundImage",
          //     },
          //   ],
          //   applyToRgb: [
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///backgroundColor",
          //     },
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///borderColor",
          //     },
          //   ],
          // },
        },
      },
    },
    windowInfo: {
      totalWidth: 100,
      totalHeight: 80,
    },
    allowedCustomization: {
      "semanticPreview.title": true,
      "semanticPreview.text": false,
      "semanticPreview.cta1Text": false,
    },
  },
  "prw-f0585dff-c96f-45ac-beaa-672fa69d7062": {
    templateId: "prw-f0585dff-c96f-45ac-beaa-672fa69d7062",
    templateName: "S1",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    previewStyle: {
      "@font-face": {
        fontFamily: "Lora",
        fontStyle: "normal",
        src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
      },

      ".prime-mini": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        justifyContent: "flex-end",
      },

      "body #root": {
        width: "100px",
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF", // temporarily applying this also to preview cta background
        "--color1cta": "#333333",
        // "--color2cta": "#F6CF53",
        "--titlefontsize": "12px",
        "--titlefontfamily": "Lora, serif",
      },
      ".prime-mini .prime-mini__clickable-area": {
        height: "90px",
        width: "100px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        position: "relative",
      },
      ".prime-mini__clickable-area .prime-mini__card": {
        // backgroundImage:
        // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/backgroundShape.svg)",
        filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.12))",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='78' height='71' viewBox='0 0 78 71' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M46.9599 67.1323C23.1146 75.9259 11.8666 67.3505 4.67413 48.3188C-2.51838 29.2871 -3.63685 -0.966292 33.9022 0.388502C62.8534 1.43336 68.3356 -5.07476 75.5281 13.957C82.7206 32.9887 70.8051 58.3387 46.9599 67.1323Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "absolute",
        width: "77px",
        height: "70px",
        marginTop: "10px",
        marginRight: "15px",
        top: 0, // marginRight: "11px", // marginBottom: "4px", // background: "#e6444e", // borderRadius: "18px", // width: "57px", // height: "57px", // marginRight: "11px", // marginBottom: "4px", // position: "relative", // display: "flex", // justifyContent: "center", // alignItems: "center",
      },

      ".prime-mini__card .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0", // paddingBottom: "8px",
        zIndex: "10",
        minHeight: "36px",
        minWidth: "47px",
        marginLeft: "12px",
        marginRight: "12px",
      },

      ".prime-mini__card .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      ".prime-mini__card .title p": {
        marginBlockEnd: 0,
      },
      ".prime-mini #prime-mini__close": {
        transition: "all 0.2s ease-in-out",
        /* visibility: "hidden", */
        cursor: "pointer",
        position: "absolute",
        top: "8px",
        right: "8px",
        transform: "translateY(15%)",
        zIndex: 10,
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "#B9B9B9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".prime-mini #prime-mini__close:hover": {
        transform: "scale(1.05)",
      },
      "#closeBtnContent": {
        width: "6px",
        height: "6px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23fafafa'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      ".prime-mini #prime-mini__close:active": {
        transform: "scale(0.9) translateY(15%)",
      },
      ".prime-mini .prime-mini__clickable-area:active": {
        transform: "rotate(0) scale(0.95)",
      }, // ".prime-mini .prime-mini__container": { // display: "flex", // flexDirection: "column", // alignItems: "center", // position: "relative", // },
      ".prime-mini__clickable-area .prime-mini__card:hover": {
        /* boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
        filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.12))", */
      },
      ".prime-mini:hover #prime-mini__close": {
        visibility: "visible",
      },
      ".prime-mini.loading .content": {
        visibility: "hidden",
        pointerEvents: "none",
      },
      ".prime-mini.loading #prime-mini__close": {
        visibility: "hidden !important",
        pointerEvents: "none",
      },
      // prettier-ignore
      ".prime-mini.loading .prime-mini__card-hilogo,\n  .prime-mini.loading .prime-mini__card-flow-length":
        {
          display: "none !important",
        },
      ".prime-mini.loading .prime-mini__card-loader": {
        display: "block",
        top: "10px",
        left: "22px",
      },
      ".prime-mini__card-loader": {
        display: "none",
        fontSize: "10px",
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        background: [
          "var(--color3)",
          "linear-gradient(to right,\n    var(--color3) 10%,\n    var(--color1) 42%)",
        ],
        position: "relative",
        animation: "load3 1.4s infinite linear",
        transform: "translateZ(0)",
      },
      ".prime-mini__card-loader:before": {
        width: "50%",
        height: "50%",
        background: "var(--color3)",
        borderRadius: "100% 0 0 0",
        position: "absolute",
        top: "0",
        left: "0",
        content: '""',
      },
      ".prime-mini__card-loader:after": {
        background: "var(--color1)",
        width: "75%",
        height: "75%",
        borderRadius: "50%",
        content: '""',
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
      },
      "@-webkit-keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      },
      "@keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      }, // "#extra1": { // position: "absolute", // marginLeft: "30px", // width: "26px", // height: "15px", // background: "#FFFFFF", // borderRadius: "6px 31px", // backgroundImage: // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/vector.svg )", // backgroundRepeat: "no-repeat", // backgroundSize: "auto", // backgroundPosition: "center", // },
      "#prime-mini__card-extra1": {
        position: "relative",
        marginLeft: "39px",
        width: "26px",
        height: "15px",
        background: "var(--color3)",
        borderRadius: "6px 31px",
        // backgroundImage:
        // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/vector.svg )",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.624512 5.52781H8.03701L5.76826 8.01901C5.71573 8.07679 5.67617 8.14346 5.65182 8.21521C5.62748 8.28697 5.61883 8.36241 5.62637 8.43722C5.64161 8.58831 5.72188 8.72767 5.84951 8.82466C5.97715 8.92164 6.1417 8.9683 6.30696 8.95437C6.47223 8.94044 6.62468 8.86706 6.73076 8.75038L9.85576 5.32211C9.87679 5.29484 9.89559 5.2662 9.91201 5.2364C9.91201 5.20784 9.94326 5.19069 9.95576 5.16213C9.98409 5.09661 9.99892 5.02687 9.99951 4.95643C9.99892 4.88599 9.98409 4.81625 9.95576 4.75073C9.95576 4.72217 9.92451 4.70502 9.91201 4.67646C9.89559 4.64666 9.87679 4.61802 9.85576 4.59075L6.73076 1.16248C6.672 1.09799 6.59841 1.04612 6.51523 1.01056C6.43206 0.975012 6.34133 0.956652 6.24951 0.956788C6.10348 0.956527 5.96196 1.00302 5.84951 1.08821C5.78622 1.13617 5.73391 1.19508 5.69557 1.26156C5.65722 1.32804 5.6336 1.40078 5.62605 1.47562C5.6185 1.55045 5.62718 1.62592 5.65158 1.69768C5.67599 1.76945 5.71564 1.83611 5.76826 1.89385L8.03701 4.38505H0.624512C0.458751 4.38505 0.29978 4.44525 0.18257 4.55241C0.0653599 4.65956 -0.000488281 4.80489 -0.000488281 4.95643C-0.000488281 5.10797 0.0653599 5.2533 0.18257 5.36045C0.29978 5.46761 0.458751 5.52781 0.624512 5.52781Z' fill='%23333333'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center",
      },
      "#prime-mini__card-extra1:hover": {
        transform: "scale(1.05)",
      },
    },
    rootCssSelector: "body #root",
    stringPathSeparator: "///",
    userCustomizable: {
      vars: {
        templateFonts: {
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "12px",
            cssProperty: "--titlefontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
        },
        rootCssVars: {
          // the values for these is taken from the semantic
          color1: {
            title: "Color1",
            description: "bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                // prettier-ignore
                path: ".prime-mini__clickable-area .prime-mini__card///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title", // TODO missing x to close
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [],
          },
          color3: {
            title: "Color3",
            description: "background freccia",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "colore freccia cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#prime-mini__card-extra1///backgroundImage",
              },
            ],
          },
          // color2cta: {
          //   title: "Color2Cta",
          //   description: "background or border di Cta",
          //   type: "string",
          //   default: "#F6CF53",
          //   cssProperty: "--color2cta",
          //   applyToSvg: [
          //     {
          //       changes: [{ element: "path", attribute: "fill" }],
          //       path: ".dropDownHeader///backgroundImage",
          //     },
          //   ],
          //   applyToRgb: [
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///backgroundColor",
          //     },
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///borderColor",
          //     },
          //   ],
          // },
        },
      },
    },
    windowInfo: {
      totalWidth: 100,
      totalHeight: 90,
    },
    allowedCustomization: {
      "semanticPreview.title": true,
      "semanticPreview.text": false,
      "semanticPreview.cta1Text": false,
    },
  },
  "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8": {
    templateId: "prw-6a08280f-54b5-4cd4-bf94-a5cb4c98a3b8",
    templateName: "M1",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    previewStyle: {
      "@font-face": {
        fontFamily: "Lora",
        fontStyle: "normal",
        src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
      },

      ".prime-mini": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        justifyContent: "flex-end",
      },

      "body #root": {
        width: "160px",
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF", // temporarily applying this also to preview cta background
        "--color1cta": "#333333",
        // "--color2cta": "#F6CF53",
        "--titlefontsize": "12px",
        "--titlefontfamily": "Lora, serif",
      },
      ".prime-mini .prime-mini__clickable-area": {
        height: "150px",
        width: "160px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        position: "relative",
      },
      ".prime-mini__clickable-area .prime-mini__card": {
        // backgroundImage:
        // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/backgroundShape.svg)",
        filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.12))",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='78' height='71' viewBox='0 0 78 71' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M46.9599 67.1323C23.1146 75.9259 11.8666 67.3505 4.67413 48.3188C-2.51838 29.2871 -3.63685 -0.966292 33.9022 0.388502C62.8534 1.43336 68.3356 -5.07476 75.5281 13.957C82.7206 32.9887 70.8051 58.3387 46.9599 67.1323Z' fill='%23F6CF53'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "absolute",
        width: "132px",
        height: "120px",
        marginTop: "10px",
        marginRight: "15px",
        top: 0, // marginRight: "11px", // marginBottom: "4px", // background: "#e6444e", // borderRadius: "18px", // width: "57px", // height: "57px", // marginRight: "11px", // marginBottom: "4px", // position: "relative", // display: "flex", // justifyContent: "center", // alignItems: "center",
      },

      ".prime-mini__card .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0", // paddingBottom: "8px",
        zIndex: "10",
        minHeight: "120px",
        minWidth: "80px",
        marginTop: "2px",
        marginLeft: "25px",
        marginRight: "12px",
        width: "80px",
      },

      ".prime-mini__card .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      ".prime-mini__card .title p": {
        marginBlockEnd: 0,
      },
      ".prime-mini #prime-mini__close": {
        transition: "all 0.2s ease-in-out",
        /* visibility: "hidden", */
        cursor: "pointer",
        position: "absolute",
        top: "7px",
        right: "7px",
        transform: "translateY(15%)",
        zIndex: 10,
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "#B9B9B9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".prime-mini #prime-mini__close:hover": {
        transform: "scale(1.05)",
      },
      "#closeBtnContent": {
        width: "6px",
        height: "6px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23fafafa'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      ".prime-mini #prime-mini__close:active": {
        transform: "scale(0.9) translateY(15%)",
      },
      ".prime-mini .prime-mini__clickable-area:active": {
        transform: "rotate(0) scale(0.95)",
      }, // ".prime-mini .prime-mini__container": { // display: "flex", // flexDirection: "column", // alignItems: "center", // position: "relative", // },
      ".prime-mini__clickable-area .prime-mini__card:hover": {
        /* boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
        filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.12))", */
      },
      ".prime-mini:hover #prime-mini__close": {
        visibility: "visible",
      },
      ".prime-mini.loading .content": {
        visibility: "hidden",
        pointerEvents: "none",
      },
      ".prime-mini.loading #prime-mini__close": {
        visibility: "hidden !important",
        pointerEvents: "none",
      },
      // prettier-ignore
      ".prime-mini.loading .prime-mini__card-hilogo,\n  .prime-mini.loading .prime-mini__card-flow-length":
        {
          display: "none !important",
        },
      ".prime-mini.loading .prime-mini__card-loader": {
        display: "block",
        top: "10px",
        left: "22px",
      },
      ".prime-mini__card-loader": {
        display: "none",
        fontSize: "10px",
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        background: [
          "var(--color3)",
          "linear-gradient(to right,\n    var(--color3) 10%,\n    var(--color1) 42%)",
        ],
        position: "relative",
        animation: "load3 1.4s infinite linear",
        transform: "translateZ(0)",
      },
      ".prime-mini__card-loader:before": {
        width: "50%",
        height: "50%",
        background: "var(--color3)",
        borderRadius: "100% 0 0 0",
        position: "absolute",
        top: "0",
        left: "0",
        content: '""',
      },
      ".prime-mini__card-loader:after": {
        background: "var(--color1)",
        width: "75%",
        height: "75%",
        borderRadius: "50%",
        content: '""',
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
      },
      "@-webkit-keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      },
      "@keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      }, // "#extra1": { // position: "absolute", // marginLeft: "30px", // width: "26px", // height: "15px", // background: "#FFFFFF", // borderRadius: "6px 31px", // backgroundImage: // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/vector.svg )", // backgroundRepeat: "no-repeat", // backgroundSize: "auto", // backgroundPosition: "center", // },
      "#prime-mini__card-extra1": {
        position: "relative",
        top: "-38px",
        marginLeft: "39px",
        width: "32px",
        height: "19px",
        background: "var(--color3)",
        borderRadius: "6px 31px",
        // backgroundImage:
        // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/vector.svg )",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.624512 5.52781H8.03701L5.76826 8.01901C5.71573 8.07679 5.67617 8.14346 5.65182 8.21521C5.62748 8.28697 5.61883 8.36241 5.62637 8.43722C5.64161 8.58831 5.72188 8.72767 5.84951 8.82466C5.97715 8.92164 6.1417 8.9683 6.30696 8.95437C6.47223 8.94044 6.62468 8.86706 6.73076 8.75038L9.85576 5.32211C9.87679 5.29484 9.89559 5.2662 9.91201 5.2364C9.91201 5.20784 9.94326 5.19069 9.95576 5.16213C9.98409 5.09661 9.99892 5.02687 9.99951 4.95643C9.99892 4.88599 9.98409 4.81625 9.95576 4.75073C9.95576 4.72217 9.92451 4.70502 9.91201 4.67646C9.89559 4.64666 9.87679 4.61802 9.85576 4.59075L6.73076 1.16248C6.672 1.09799 6.59841 1.04612 6.51523 1.01056C6.43206 0.975012 6.34133 0.956652 6.24951 0.956788C6.10348 0.956527 5.96196 1.00302 5.84951 1.08821C5.78622 1.13617 5.73391 1.19508 5.69557 1.26156C5.65722 1.32804 5.6336 1.40078 5.62605 1.47562C5.6185 1.55045 5.62718 1.62592 5.65158 1.69768C5.67599 1.76945 5.71564 1.83611 5.76826 1.89385L8.03701 4.38505H0.624512C0.458751 4.38505 0.29978 4.44525 0.18257 4.55241C0.0653599 4.65956 -0.000488281 4.80489 -0.000488281 4.95643C-0.000488281 5.10797 0.0653599 5.2533 0.18257 5.36045C0.29978 5.46761 0.458751 5.52781 0.624512 5.52781Z' fill='%23333333'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center",
      },
      "#prime-mini__card-extra1:hover": {
        transform: "scale(1.05)",
      },
    },
    rootCssSelector: "body #root",
    stringPathSeparator: "///",
    userCustomizable: {
      vars: {
        templateFonts: {
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "12px",
            cssProperty: "--titlefontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
        },
        rootCssVars: {
          // the values for these is taken from the semantic
          color1: {
            title: "Color1",
            description: "bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                // prettier-ignore
                path: ".prime-mini__clickable-area .prime-mini__card///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title", // TODO missing x to close
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [],
          },
          color3: {
            title: "Color3",
            description: "background freccia",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "colore freccia cta",
            type: "string",
            default: "#333333",
            cssProperty: "--color1cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#prime-mini__card-extra1///backgroundImage",
              },
            ],
          },
          // color2cta: {
          //   title: "Color2Cta",
          //   description: "background or border di Cta",
          //   type: "string",
          //   default: "#F6CF53",
          //   cssProperty: "--color2cta",
          //   applyToSvg: [
          //     {
          //       changes: [{ element: "path", attribute: "fill" }],
          //       path: ".dropDownHeader///backgroundImage",
          //     },
          //   ],
          //   applyToRgb: [
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///backgroundColor",
          //     },
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///borderColor",
          //     },
          //   ],
          // },
        },
      },
    },
    windowInfo: {
      totalWidth: 160,
      totalHeight: 150,
    },
    allowedCustomization: {
      "semanticPreview.title": true,
      "semanticPreview.text": false,
      "semanticPreview.cta1Text": false,
    },
  },
  "prw-0ec81715-4300-4e4e-8e34-a95a8f39055d": {
    templateId: "prw-0ec81715-4300-4e4e-8e34-a95a8f39055d",
    templateName: "S2",
    tFamilyId: "fam-0a44232d-c114-4898-8108-3441516c93bf",
    previewStyle: {
      "@font-face": {
        fontFamily: "Lora",
        fontStyle: "normal",
        src: 'url(fonts/Lora/static/Lora-Regular.ttf) format("truetype")',
      },

      ".prime-mini": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        justifyContent: "flex-end",
      },

      "body #root": {
        width: "140px",
        // editable customVars
        "--color1": "#F6CF53",
        "--color2": "#333333",
        "--color3": "#FFFFFF", // temporarily applying this also to preview cta background
        "--color1cta": "#333333",
        // "--color2cta": "#F6CF53",
        "--titlefontsize": "12px",
        "--titlefontfamily": "Lora, serif",
        margin: "12px 15px 26px 2px",
      },
      ".prime-mini .prime-mini__clickable-area": {
        height: "45px",
        width: "140px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        position: "relative",
      },
      ".prime-mini__clickable-area .prime-mini__card": {
        position: "absolute",
        width: "140px",
        height: "45px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        top: 0, // marginRight: "11px", // marginBottom: "4px", // background: "#e6444e", // borderRadius: "18px", // width: "57px", // height: "57px", // marginRight: "11px", // marginBottom: "4px", // position: "relative", // display: "flex", // justifyContent: "center", // alignItems: "center",
      },

      ".prime-mini__card .content": {
        display: "flex",
        flexDirection: "column",
        padding: "0", // paddingBottom: "8px",
        zIndex: "10",
        minHeight: "45px",
        width: "90px",
        marginTop: "2px",
        marginLeft: "18px",
        marginRight: "12px",
      },

      ".prime-mini__card .title": {
        marginTop: "0px",
        fontFamily: "var(--titlefontfamily)",
        color: "var(--color2)",
        fontWeight: 700,
        fontSize: "var(--titlefontsize)",
        lineHeight: "150%",
      },
      ".prime-mini__card .title p": {
        marginBlockEnd: 0,
        marginTop: "9px",
      },
      ".prime-mini #prime-mini__close": {
        cursor: "pointer",
        position: "absolute",
        top: "-10px",
        right: "-10px",
        zIndex: 10,
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "#B9B9B9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ".prime-mini #prime-mini__close:hover": {
        /* transform: "scale(1.05)", */
      },
      "#closeBtnContent": {
        width: "6px",
        height: "6px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='11' height='11' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path fill-rule='evenodd' clip-rule='evenodd' d='M1.23497 11.6925L1.23502 11.6925L1.23506 11.6926C1.26725 11.7248 1.28335 11.7409 1.29748 11.754C1.68084 12.1089 2.27279 12.1089 2.65616 11.754C2.6703 11.7409 2.68641 11.7248 2.71862 11.6925L6.42694 7.98423L10.1355 11.6927C10.1677 11.725 10.1838 11.7411 10.1979 11.7542C10.5813 12.1091 11.1732 12.1091 11.5566 11.7542C11.5707 11.7411 11.5868 11.725 11.6191 11.6927C11.6513 11.6605 11.6674 11.6444 11.6805 11.6303C12.0354 11.2469 12.0354 10.655 11.6805 10.2716C11.6674 10.2575 11.6513 10.2414 11.6191 10.2091L7.91054 6.50062L11.6202 2.79093C11.6525 2.75871 11.6686 2.74261 11.6817 2.72847C12.0366 2.3451 12.0366 1.75315 11.6817 1.36979C11.6686 1.35565 11.6525 1.33954 11.6202 1.30732C11.588 1.27511 11.5719 1.259 11.5578 1.24591C11.1744 0.891011 10.5825 0.891011 10.1991 1.24591C10.185 1.259 10.1689 1.27511 10.1366 1.30732L6.42694 5.01702L2.71744 1.30752L2.71743 1.30751C2.68522 1.27531 2.66912 1.2592 2.65498 1.24611C2.27161 0.891209 1.67967 0.891209 1.2963 1.24611C1.28216 1.2592 1.26605 1.27531 1.23384 1.30752C1.20162 1.33974 1.18552 1.35584 1.17243 1.36998C0.817525 1.75335 0.817525 2.3453 1.17243 2.72866C1.18552 2.7428 1.20162 2.75891 1.23384 2.79113L4.94334 6.50062L1.23502 10.2089L1.23497 10.209C1.20279 10.2412 1.18669 10.2573 1.1736 10.2714C0.818704 10.6548 0.818704 11.2467 1.1736 11.6301C1.18669 11.6442 1.20279 11.6603 1.23497 11.6925Z' fill='%23fafafa'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      /* ".prime-mini #prime-mini__close:active": {
        transform: "scale(0.9) translateY(15%)",
      }, */
      ".prime-mini .prime-mini__clickable-area:hover": {
        transform: "scale(1.05)",
      },
      ".prime-mini .prime-mini__clickable-area:active": {
        transform: "rotate(0) scale(0.95)",
      }, // ".prime-mini .prime-mini__container": { // display: "flex", // flexDirection: "column", // alignItems: "center", // position: "relative", // },
      ".prime-mini__clickable-area .prime-mini__card:hover": {
        // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.12)",
        /* filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.12))", */
        /* transform: "scale(1.05)", */
      },
      ".prime-mini:hover #prime-mini__close": {
        visibility: "visible",
      },
      ".prime-mini.loading .content": {
        visibility: "hidden",
        pointerEvents: "none",
      },
      ".prime-mini.loading #prime-mini__close": {
        visibility: "hidden !important",
        pointerEvents: "none",
      },
      // prettier-ignore
      ".prime-mini.loading .prime-mini__card-hilogo,\n  .prime-mini.loading .prime-mini__card-flow-length":
        {
          display: "none !important",
        },
      ".prime-mini.loading .prime-mini__card-loader": {
        display: "block",
        top: "10px",
        left: "22px",
      },
      ".prime-mini__card-loader": {
        display: "none",
        fontSize: "10px",
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        background: [
          "var(--color3)",
          "linear-gradient(to right,\n    var(--color3) 10%,\n    var(--color1) 42%)",
        ],
        position: "relative",
        animation: "load3 1.4s infinite linear",
        transform: "translateZ(0)",
      },
      ".prime-mini__card-loader:before": {
        width: "50%",
        height: "50%",
        background: "var(--color3)",
        borderRadius: "100% 0 0 0",
        position: "absolute",
        top: "0",
        left: "0",
        content: '""',
      },
      ".prime-mini__card-loader:after": {
        background: "var(--color1)",
        width: "75%",
        height: "75%",
        borderRadius: "50%",
        content: '""',
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
      },
      "@-webkit-keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      },
      "@keyframes load3": {
        "0%": {
          WebkitTransform: "rotate(0deg)",
          transform: "rotate(0deg)",
        },
        "100%": {
          WebkitTransform: "rotate(360deg)",
          transform: "rotate(360deg)",
        },
      }, // "#extra1": { // position: "absolute", // marginLeft: "30px", // width: "26px", // height: "15px", // background: "#FFFFFF", // borderRadius: "6px 31px", // backgroundImage: // "url(https://files.habitinspiring.com/content-dashboard/qa/templates/1S/vector.svg )", // backgroundRepeat: "no-repeat", // backgroundSize: "auto", // backgroundPosition: "center", // },
      "#prime-mini__card-extra1": {
        position: "absolute",
        top: "17px",
        left: "109px",
        width: "12px",
        height: "11px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='12' height='11' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<path d='M0.624512 5.52781H8.03701L5.76826 8.01901C5.71573 8.07679 5.67617 8.14346 5.65182 8.21521C5.62748 8.28697 5.61883 8.36241 5.62637 8.43722C5.64161 8.58831 5.72188 8.72767 5.84951 8.82466C5.97715 8.92164 6.1417 8.9683 6.30696 8.95437C6.47223 8.94044 6.62468 8.86706 6.73076 8.75038L9.85576 5.32211C9.87679 5.29484 9.89559 5.2662 9.91201 5.2364C9.91201 5.20784 9.94326 5.19069 9.95576 5.16213C9.98409 5.09661 9.99892 5.02687 9.99951 4.95643C9.99892 4.88599 9.98409 4.81625 9.95576 4.75073C9.95576 4.72217 9.92451 4.70502 9.91201 4.67646C9.89559 4.64666 9.87679 4.61802 9.85576 4.59075L6.73076 1.16248C6.672 1.09799 6.59841 1.04612 6.51523 1.01056C6.43206 0.975012 6.34133 0.956652 6.24951 0.956788C6.10348 0.956527 5.96196 1.00302 5.84951 1.08821C5.78622 1.13617 5.73391 1.19508 5.69557 1.26156C5.65722 1.32804 5.6336 1.40078 5.62605 1.47562C5.6185 1.55045 5.62718 1.62592 5.65158 1.69768C5.67599 1.76945 5.71564 1.83611 5.76826 1.89385L8.03701 4.38505H0.624512C0.458751 4.38505 0.29978 4.44525 0.18257 4.55241C0.0653599 4.65956 -0.000488281 4.80489 -0.000488281 4.95643C-0.000488281 5.10797 0.0653599 5.2533 0.18257 5.36045C0.29978 5.46761 0.458751 5.52781 0.624512 5.52781Z' fill='%23333333'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center",
      },
      /* "#prime-mini__card-extra1:hover": {
        transform: "scale(1.05)",
      }, */
      "#prime-mini__card-extra2": {
        position: "absolute",
        top: "53px",
        left: "114px",
        width: "13px",
        height: "13px",
        backgroundImage:
          "url(\"data:image/svg+xml;utf8,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>\n<circle cx='7.14062' cy='6.50269' r='5.5' stroke='%23F6CF53' stroke-width='2'/>\n</svg>\")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "inline-block",
      },
      /* "#prime-mini__card-extra2:hover": {
        transform: "scale(1.05)",
      }, */
      "#prime-mini__card-extra3": {
        position: "absolute",
        top: "-10px",
        left: "75px",
        width: "71px",
        height: "71px",
        backgroundImage: "url(tmpTemplates/circle.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "inline-block",
        zIndex: "-1",
      },
      /* "#prime-mini__card-extra3:hover": {
        transform: "scale(1.05)",
      }, */
    },
    rootCssSelector: "body #root",
    stringPathSeparator: "///",
    userCustomizable: {
      vars: {
        templateFonts: {
          titlefontsize: {
            title: "Title font size",
            description: "string; ex. 16px, or 2em etc.",
            type: "string",
            default: "16px",
            cssProperty: "--titlefontsize",
          },
          titlefontfamily: {
            title: "Title font family",
            description: "Font name; ex. Lora",
            type: "string",
            default: "Lora, serif",
            cssProperty: "--titlefontfamily",
          },
        },
        rootCssVars: {
          // the values for these is taken from the semantic
          color1: {
            title: "Color1",
            description: "bolla gialla",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1",
            // objects so that can have other details on what to change in the svg
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                // prettier-ignore
                path: ".prime-mini__clickable-area .prime-mini__card///backgroundImage",
              },
            ],
          },
          color2: {
            title: "Color2",
            description: "title", // TODO missing x to close
            type: "string",
            default: "#333333",
            cssProperty: "--color2",
            applyToSvg: [],
          },
          color3: {
            title: "Color3",
            description: "background freccia",
            type: "string",
            default: "#FFFFFF",
            cssProperty: "--color3",
          },
          color1cta: {
            title: "Color1Cta",
            description: "colore freccia cta",
            type: "string",
            default: "#F6CF53",
            cssProperty: "--color1cta",
            applyToSvg: [
              {
                changes: [{ element: "path", attribute: "fill" }],
                path: "#prime-mini__card-extra1///backgroundImage",
              },
              {
                changes: [{ element: "circle", attribute: "stroke" }],
                path: "#prime-mini__card-extra2///backgroundImage",
              },
            ],
          },
          // color2cta: {
          //   title: "Color2Cta",
          //   description: "background or border di Cta",
          //   type: "string",
          //   default: "#F6CF53",
          //   cssProperty: "--color2cta",
          //   applyToSvg: [
          //     {
          //       changes: [{ element: "path", attribute: "fill" }],
          //       path: ".dropDownHeader///backgroundImage",
          //     },
          //   ],
          //   applyToRgb: [
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///backgroundColor",
          //     },
          //     {
          //       changes: [{ element: "fieldValueWithTransparency" }],
          //       path: ".listItem.selected///borderColor",
          //     },
          //   ],
          // },
        },
      },
    },
    windowInfo: {
      totalWidth: 190,
      totalHeight: 90,
    },
    allowedCustomization: {
      "semanticPreview.title": true,
      "semanticPreview.text": true,
      "semanticPreview.cta1Text": false,
    },
  },
};
