import React, { useCallback } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useRouteMatch } from "react-router-dom";

const Publish = ({ previewLink, title, buttonText }) => {
  const match = useRouteMatch();
  const { id } = match.params;

  const handlePreview = useCallback(() => {
    if (!id || id === "new") return;
    window.open(`${previewLink}/${id}?isPreview=true`, "_blank", "noreferrer");
  }, [previewLink, id]);

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              {buttonText}

              <IconButton
                disabled={!id || id === "new"}
                onClick={handlePreview}
              >
                <VisibilityIcon />
              </IconButton>
            </Typography>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default Publish;
