import React from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const CallDetection = () => {
  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Call Detection</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                Call Detection configurations
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            List of tools for which leaf checks whenever they use user`&apos;`s
            microphone and eventually prevent the display of new messages:
            <ul>
              <li>CiscoJabber.exe</li>
              <li>Chime.exe (Amazon Chime)</li>
              <li>Teams.exe</li>
              <li>msteams.exe (new Teams for work)</li>
              <li>ms-teams.exe</li>
              <li>Zoom.exe</li>
              <li>atmgr.exe (Cisco WebEx)</li>
              <li>brave.exe (browser)</li>
              <li>chrome.exe (browser)</li>
              <li>firefox.exe (browser)</li>
              <li>msedge.exe (browser)</li>
              <li>opera.exe (browser)</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Frequency of the check:
            <ul>
              <li>Periodically, every 60 seconds</li>
              <li>Puctually, as soon as a new message arrives</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallDetection;
