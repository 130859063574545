import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import OutlinedButton from "../OutlinedButton";
import Loader from "../shared/Loader";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../api/AppContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useModals } from "../../hooks/useModals";
import deepPurple from "@material-ui/core/colors/deepPurple";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const WelfareList = () => {
  const [isBenefitsLoading, setIsBenefitsLoading] = useState(true);
  const [isCopyBenefitLoading, setIsCopyBenefitLoading] = useState(false);

  const [groupedBenefits, setGroupedBenefits] = useState([]);
  const modals = useModals();

  const history = useHistory();
  const {
    fetchAllBenefits,
    benefits,
    fetchWelfareSelector,
    welfareSelectors,
    updateWelfareBenefit,
    deleteWelfareBenefit,
  } = useAppContext();

  const [expanded, setExpanded] = useLocalStorage(`WelfareList.Expanded`, {});

  const handleExpandClick = useCallback(
    (step) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [step]: !expanded[step],
      }));
    },
    [setExpanded]
  );

  useEffect(() => {
    if (!welfareSelectors.entities.length) {
      fetchWelfareSelector("entities");
    }
    fetchAllBenefits();
  }, []);

  useEffect(() => {
    if (benefits.length && welfareSelectors.entities.length) {
      const templateEntity = welfareSelectors.entities.find(
        (entity) => entity.displayname === "Template"
      );
      const groupedItems = benefits.reduce((result, item) => {
        // Find the corresponding legal entity name from array2
        const entity = welfareSelectors.entities.find(
          (entity) => entity.id === item.legalentity_id
        );

        result[templateEntity.id] = result[templateEntity.id] || {
          name: templateEntity.displayname,
          benefits: [],
        };
        if (entity) {
          // Combine legalentity_id and legalentity_name for the key
          const key = item.legalentity_id;

          // Initialize an object for the key if it doesn't exist
          result[key] = result[key] || {
            name: entity.displayname,
            benefits: [],
          };

          // Add the benefit to the array
          result[key].benefits.push(item);
        }
        return result;
      }, {});

      // Extract the "Template" item
      const templateItem = groupedItems[templateEntity.id];

      // Remove the "Template" item from the original map
      delete groupedItems[templateEntity.id];

      // Sort the remaining items by key in descending order
      const sortedItems = Object.values(groupedItems).sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Combine the "Template" item and the sorted items
      const groupedObjects = [templateItem, ...sortedItems];

      setGroupedBenefits(groupedObjects);
      setIsBenefitsLoading(false);
      setIsCopyBenefitLoading(false);
    }
  }, [benefits, welfareSelectors]);

  const handleCreate = useCallback(() => {
    history.push(`/welfares/new/start`, {
      isNew: true,
    });
  }, [history]);

  const handleEdit = useCallback(({ id }) => {
    history.push(`/welfares/${id}`);
    //window.location.href = `/welfares/${id}`;
  }, []);

  const handleDelete = useCallback(
    (benefit) => {
      modals.openConfirmation({
        onConfirm: async () => {
          await deleteWelfareBenefit({
            id: benefit.id,
            isDeleted: true,
          });
        },
        text: `You want to delete benefit "${benefit.text_default.name}"`,
      });
    },
    [deleteWelfareBenefit, modals]
  );

  const handlePublish = useCallback(
    async (benefit) => {
      const benefitObj = { id: benefit.id, isPublished: !benefit.ispublished };
      await updateWelfareBenefit(benefitObj);
    },
    [updateWelfareBenefit]
  );

  const buildBenefitObject = useCallback(
    ({ benefit, entityId, skipForUserIds = false }) => {
      return {
        isNew: true,
        cmp: "c7fff3f2-4ef9-4e3e-93ad-6d4fcf64c80a",
        pillars: benefit.pillars,
        category_id: benefit.category_id,
        subcategory_id: benefit.subcategory_id,
        image: benefit.image,
        previewImage: benefit.previewimage,
        analytics_name: benefit?.analytics_name,
        text_default: benefit.text_default,
        text_en: benefit.text_en,
        isPublished: false,
        userCanGiveFeedback: benefit.usercangivefeedback,
        booking_id: benefit?.booking_id,
        viaBookingSystem: benefit?.booking_id ? true : false,
        userCanSubscribe: benefit.usercansubscribe || false,
        openInWecare: benefit.openinwecare || false,
        freeComment: benefit.freecomment,
        questionAboutUsed: benefit.questionaboutused,
        keyInfo: {
          contacts: benefit.keyinfo?.contacts || [""],
          manager: benefit.keyinfo?.manager,
          supportContacts: benefit.keyinfo?.supportContacts || [""],
          sites: benefit.keyinfo?.sites || [""],
          usefullLinks: benefit.keyinfo?.usefullLinks || [""],
          deadline: benefit.keyinfo?.deadline,
          expirationDate: benefit.keyinfo?.expirationDate || null,
        },
        benefitquestions: benefit.benefitquestions || [],
        conditions: {
          entity: entityId,
          contractTypes: benefit.contracttypenames,
          employeeTypes: benefit.employeetypenames,
          employeeRoles: benefit.employeerolenames,
          officeTypes: benefit.officetypenames,
          officeLocations: benefit.officelocationnames,
          forUserIds: skipForUserIds ? [] : benefit.foruser_ids || [],
          notForUserIds: skipForUserIds ? [] : benefit.notforuser_ids || [],
        },
      };
    },
    []
  );

  const handleCopy = useCallback(
    (benefit) => {
      modals.openModal(modals.modalTypes.CopyWelfare, {
        onConfirm: async ({ entity }) => {
          const newBenefit = buildBenefitObject({
            benefit,
            entityId: entity,
            skipForUserIds: true,
          });
          await updateWelfareBenefit(newBenefit);
          setIsCopyBenefitLoading(true);
          setIsCopyBenefitLoading(false);
          //window.location.reload();
        },
      });
    },
    [modals, buildBenefitObject, updateWelfareBenefit]
  );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      {" "}
      {isBenefitsLoading || isCopyBenefitLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6">WECARE’S benefits</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Use this section to add and maintain welfares.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleCreate}
            >
              Add new welfare
            </Button>
          </Grid>
          <Grid item>
            <List dense>
              {groupedBenefits &&
                groupedBenefits.map(
                  (elem, i) =>
                    elem.benefits?.length > 0 && (
                      <Accordion
                        key={i}
                        expanded={Boolean(expanded[i])}
                        onChange={handleExpandClick(i)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid item style={{ flexGrow: 1 }}>
                              <Grid container direction="column" spacing={1}>
                                <Grid item>
                                  <Typography variant="h6">
                                    {elem.name} ({elem.benefits?.length})
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Paper style={{ width: "100%" }} variant="outlined">
                            <Box p={2}>
                              <Grid container direction="column" spacing={2}>
                                <Grid item>
                                  <List>
                                    {elem.benefits?.map((benefit, i) => (
                                      <>
                                        <ListItem
                                          key={i}
                                          style={{
                                            ...(!benefit.ispublished &&
                                              elem.name?.toLowerCase() !==
                                                "template" && {
                                                backgroundColor: deepPurple[50],
                                              }),
                                            ...(elem.benefits?.length &&
                                              i < elem.benefits?.length - 1 && {
                                                borderBottom: "1px solid gray",
                                              }),
                                          }}
                                        >
                                          <ListItemText
                                            primary={benefit.text_default?.name}
                                            secondary={
                                              benefit.text_default?.description
                                            }
                                          />
                                          <ListItemSecondaryAction
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                            }}
                                          >
                                            {elem.name?.toLowerCase() !==
                                              "template" && (
                                              <Tooltip
                                                title={
                                                  benefit.ispublished
                                                    ? "Published (click to unpublish)"
                                                    : "Unpublished (click to publish)"
                                                }
                                              >
                                                <span>
                                                  <IconButton
                                                    onClick={() =>
                                                      handlePublish(benefit)
                                                    }
                                                  >
                                                    {benefit.ispublished ? (
                                                      <VisibilityIcon />
                                                    ) : (
                                                      <VisibilityOffIcon />
                                                    )}
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            )}
                                            <Tooltip title="Copy">
                                              <span
                                                style={{
                                                  marginRight: "1rem",
                                                }}
                                              >
                                                <OutlinedButton
                                                  onClick={() =>
                                                    handleCopy(benefit)
                                                  }
                                                >
                                                  Copy
                                                </OutlinedButton>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                              <span>
                                                <OutlinedButton
                                                  onClick={() =>
                                                    handleEdit(benefit)
                                                  }
                                                >
                                                  Edit
                                                </OutlinedButton>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              <span>
                                                <IconButton
                                                  onClick={() =>
                                                    handleDelete(benefit)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    )
                )}
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default WelfareList;
