/* eslint-disable prettier/prettier */
import { Grid, TextField, Box, Button, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAppContext } from "../../api/AppContext";

const Tags = ({ formik }) => {
  const handleInputChange = (name, value, lang) => {
    if (lang) {
      formik.setFieldValue(name, {
        ...formik.values[name],
        [lang]: value,
      });
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Box
      alignContent={"center"}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
   <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
          <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              error={formik.touched.tagname?.en && Boolean(formik.errors.tagname?.en)}
              helperText={formik.touched.tagname?.en && formik.errors.tagname?.en}
              label="Name (EN)"
              margin="none"
              value={formik.values.tagname?.en}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("tagname", event.target.value, "en")
              }
            />

          </Grid>
          <Grid item md={6} xs={12}>
          <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              error={formik.touched.tagname?.it && Boolean(formik.errors.tagname?.it)}
              helperText={formik.touched.tagname?.it && formik.errors.tagname?.it}
              label="Name (IT)"
              margin="none"
              value={formik.values.tagname?.it}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("tagname", event.target.value, "it")
              }
            />
          </Grid>

          <Grid container item justifyContent="center" mr={2} mt={2} xs={12}>
          <Typography align="center" color="textSecondary">
              Color
            </Typography>
            <input
              style={{marginLeft:"0.5rem"}}
              type="color"
              value={formik.values.tagcolor}
              onChange={(event) =>
                handleInputChange("tagcolor", event.target.value)
              }
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

const TagsContainer = () => {
  const { updateTag } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [configurationEntity, setConfigurationEntity] = useState(null);
  const { rowData } = location.state || {};
  const basePath = useMemo(() => location.pathname.split('/')[1], [location.pathname]);

  useEffect(() => {
    if (rowData) {
      setConfigurationEntity({ ...rowData, id });
    }
  }, [location.state, id, rowData]);

  const validationSchema = Yup.object({
    tagcolor: Yup.string().required("Color is required"),
    tagname: Yup.object({
      en: Yup.string().required("English tagname is required"),
      it: Yup.string().required("Italian tagname is required")
    }),  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity || {
      isNew: true,
      tagcolor: "",
      tagname: { en: '', it: '' },
    },
    onSubmit: async (values) => {
      await updateTag({...values }, basePath);
      //console.log("values" + JSON.stringify(values));
      history.push(`/${basePath}/tags`);
    },
    validationSchema: validationSchema,
    validator: () => ({}),
  });

  if (!rowData && !formik.values.isNew) {
    return (
      <Box alignItems="center" display="flex" justifyContent="center" width="100%">
        <Typography variant="subtitle1">Tag is not found 😢</Typography>
      </Box>
    );
  }

  return <Tags formik={formik} />;
};

export default TagsContainer;
