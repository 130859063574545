import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const visibleStyle = { height: "100%", visibility: "visible" };
const hiddenStyle = { height: "0", visibility: "hidden" };

const IFrame = forwardRef(({ src, ...props }, ref) => {
  const [url] = useState(src);
  const [isLoading, setIsLoading] = useState(false);
  const iframe = useRef();

  useEffect(() => {
    (ref || iframe).current?.contentWindow.location.replace(src);
    setIsLoading(true);
  }, [src, ref]);

  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onError = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading && (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <CircularProgress />
        </Box>
      )}
      {url && (
        <div style={isLoading ? hiddenStyle : visibleStyle}>
          <iframe
            ref={ref || iframe}
            frameBorder="0"
            height="100%"
            name="_blank"
            src={url}
            title={props.title}
            width="100%"
            onError={onError}
            onLoad={onLoad}
            {...props}
          />
        </div>
      )}
    </>
  );
});

export default IFrame;
