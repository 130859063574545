import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import ConfirmableInput from "../shared/ConfirmableInput";
import Timestamps from "../Timestamps";
import { useModals } from "../../hooks/useModals";

const Integrations = () => {
  const {
    isIntegrationsLoading,
    fetchIntegrations,
    integrations,
    updateIntegration,
    deleteIntegration,
  } = useAppContext();
  const { type } = useParams();

  useEffect(() => {
    if (isIntegrationsLoading) {
      return;
    }

    const oauthflow = sessionStorage.getItem("oauthflow");

    if (!oauthflow) {
      return;
    }

    sessionStorage.removeItem("oauthflow");

    try {
      const { code } = JSON.parse(oauthflow);
      // make sure that redirectUri is the same as the one used in Survey.js (redirect_uri)
      updateIntegration({
        isNew: true,
        code,
        redirectUri: `${window.location.origin}/settings/integrations/typeform`,
        type,
      }).then();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [isIntegrationsLoading, type]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const modals = useModals();

  const [rename, setRename] = useState({});

  const handleDelete = useCallback(
    ({ id, name }) =>
      () => {
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Delete",
          onConfirm: async () => deleteIntegration({ id }),
          text: `You're about to permanently delete this integration "${name}".`,
          title: "Delete the integration?",
        });
      },
    [deleteIntegration, modals]
  );

  if (isIntegrationsLoading) {
    return <Loader />;
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Integrations</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                Integrations
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} style={{ flexDirection: "column" }}>
            {integrations.map((integration) => (
              <Grid key={integration.id} item>
                <Paper variant="outlined">
                  <Box p={2}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item style={{ flex: 1 }}>
                        <Grid
                          container
                          spacing={1}
                          style={{ flexDirection: "column" }}
                        >
                          <Grid item>
                            <Timestamps
                              createdAt={integration.createdAt}
                              createdBy={integration.createdBy}
                              updatedAt={integration.updatedAt}
                              updatedBy={integration.updatedBy}
                            />
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography variant="body2">
                                  Integration name:
                                </Typography>
                              </Grid>
                              <Grid item>
                                {rename[integration.id] ? (
                                  <ConfirmableInput
                                    autoFocus
                                    value={integration.name}
                                    variant="standard"
                                    onCancel={() => {
                                      setRename((rename) => ({
                                        ...rename,
                                        [integration.id]: false,
                                      }));
                                    }}
                                    onChange={async (name) => {
                                      await updateIntegration({
                                        id: integration.id,
                                        name,
                                      });
                                      setRename((rename) => ({
                                        ...rename,
                                        [integration.id]: false,
                                      }));
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    variant="body2"
                                    onClick={() =>
                                      setRename((rename) => ({
                                        ...rename,
                                        [integration.id]: true,
                                      }))
                                    }
                                  >
                                    {integration.name}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Typography variant="body2">Tool:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2">
                                  {integration.type}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Typography variant="body2">
                                  User for auth:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="body2">
                                  {integration.account.alias}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Delete integration">
                          <IconButton onClick={handleDelete(integration)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Integrations;
