import { useCallback, useState } from "react";

const useLoading = (action, { throwError = false } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const handler = useCallback(
    async (...params) => {
      setIsLoading(true);

      try {
        let result = null;
        if (typeof action === "function") {
          result = await action(...params);
        }

        setIsLoading(false);
        setIsSuccess(true);
        return result;
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setIsSuccess(false);

        if (throwError) {
          throw e;
        }
        throw e;
      } finally {
        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);
      }
    },
    [action]
  );

  return {
    handler,
    isLoading,
    isSuccess,
  };
};

export default useLoading;
