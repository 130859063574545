import * as yup from "yup";
import React, { useEffect } from "react";
import times from "lodash/times";
import differenceWith from "lodash/differenceWith";
import { useFormik } from "formik";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Contextual from "./Contextual";
import GeneralInfo from "./GeneralInfo";
import Loader from "../shared/Loader";
import Materials from "./Materials";
import StructureAndInteractions from "./StructureAndInteractions";
import Tips from "./Tips";
import Surveys from "./Surveys/Surveys";
import { useAppContext } from "../../api/AppContext";
import ScheduledSlots from "./ScheduledSlots";
import { getIntersectedIntervals } from "../utils";
import { useSnackbar } from "notistack";

const validationSchema = ({ days }) =>
  yup.object().shape({
    afterCallDelayNudges: yup.number().when("callBehaviourNudges", {
      is: "delay",
      then: yup
        .number()
        .integer()
        .min(0, "Must be greater than or equal to 0")
        .required("required"),
    }),
    afterCallDelayTips: yup.number().when("callBehaviourTips", {
      is: "delay",
      then: yup
        .number()
        .integer()
        .min(0, "Must be greater than or equal to 0")
        .required("required"),
    }),
    benefits: yup.string(),
    callBehaviourNudges: yup.string().nullable(),
    callBehaviourTips: yup.string().nullable(),
    description: yup.string().required("Is required"),
    durationInfo: yup.string(),
    icon: yup.string().nullable(),
    image: yup.string().nullable(),
    isAnimationEnabled: yup.boolean().nullable(),
    isSoundEnabled: yup.boolean().nullable(),
    locale: yup.string().required("Is required"),
    miniTipAutoProgress: yup.boolean().nullable(),
    miniTipAutoProgressDelay: yup.number().when("miniTipAutoProgress", {
      is: true,
      then: yup
        .number()
        .integer()
        .min(0, "Must be greater than or equal to 0")
        .required("required"),
    }),
    miniTipAutoProgressOnlyIfIgnored: yup.boolean().nullable(),
    miniTipAutoProgressOnlyIfIgnoredTimes: yup
      .number()
      .when("miniTipAutoProgressOnlyIfIgnored", {
        is: true,
        then: yup
          .number()
          .integer()
          .min(1, "Must be greater than or equal to 1")
          .required("required"),
      }),
    name: yup.string().required("Is required"),
    suggestions: yup.string(),
    tiers: yup.array().of(
      yup.object({
        from: yup
          .number()
          .integer()
          .min(1, "From must be greater than or equal to ${min}")
          .required()
          .when("to", {
            is: (to) => to !== undefined,
            then: yup
              .number()
              .max(yup.ref("to"), "From must be less than or equal to ${max}"),
          }),
        to: yup
          .number()
          .integer()
          .min(yup.ref("from"), "To must be greater than or equal to ${min}")
          .max(days, "To must be less than or equal to ${max}"),
        maxDailyOccurrences: yup.number().integer().min(1).required(),
        maxMonthlyOccurrences: yup.number().integer().nullable().min(1),
        maxWeeklyOccurrences: yup.number().integer().nullable().min(1),
        minOccurrencesInterval: yup.number().integer().min(0).required(),
        extendGlobalRulesToTips: yup.boolean().nullable(),
        dontShowNudges: yup.boolean().nullable(),
      })
    ),
    postJourneyTier: yup.object({
      maxDailyOccurrences: yup.number().integer().min(1).required(),
      maxMonthlyOccurrences: yup.number().integer().nullable().min(1),
      maxWeeklyOccurrences: yup.number().integer().nullable().min(1),
      minOccurrencesInterval: yup.number().integer().min(0).required(),
      extendGlobalRulesToTips: yup.boolean().nullable(),
      dontShowNudges: yup.boolean().nullable(),
    }),
  });

const Journey = ({ journey }) => {
  const {
    // user,
    updateJourney,
    journeys,
    linkJourneys,
    unlinkJourneys,
    linkedJourneys,
    journeySemanticsWithEmptyCTALink,
  } = useAppContext();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...journey,
      afterCallDelayNudges: journey?.afterCallDelayNudges || 60,
      afterCallDelayTips: journey?.afterCallDelayTips || 60,
      linkedJourneys: journey?.isNew ? [] : linkedJourneys,
    },
    onSubmit: async (values, { resetForm }) => {
      const intersectedIntervals = getIntersectedIntervals(
        (values.tiers || []).map(({ from, to }) => [from, to])
      );
      if (intersectedIntervals.length) {
        enqueueSnackbar("Tiers have intersected interval", {
          variant: "error",
        });
        return;
      }

      const initialLinkedJourneys = formik.initialValues.linkedJourneys || [];
      const linkedJourneys = values.linkedJourneys || [];

      const linkedJourneysToDelete = differenceWith(
        initialLinkedJourneys,
        linkedJourneys,
        (a, b) => a.journeyAId === b.journeyAId && a.journeyBId === b.journeyBId
      );

      const linkedJourneysToCreate = differenceWith(
        linkedJourneys,
        initialLinkedJourneys,
        (a, b) => a.journeyAId === b.journeyAId && a.journeyBId === b.journeyBId
      );

      await unlinkJourneys(linkedJourneysToDelete);
      await linkJourneys(linkedJourneysToCreate);

      await updateJourney(values, { optimisticUpdates: false });

      if (journey.locale !== values.locale) {
        history.replace({
          ...location,
          pathname: location.pathname.replace(journey.locale, values.locale),
          ...(values.isNew && {
            state: null,
          }),
        });
        await resetForm();
        return;
      }

      if (values.isNew) {
        history.replace({
          ...location,
          state: null,
        });
      }

      await resetForm();
    },
    validationSchema: validationSchema({ days: journey?.days?.length }),
    validator: () => ({}),
  });

  const tab = match.params.tab || "info";

  if (!journey) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Journey is not found 😢</Typography>
      </Box>
    );
  }

  const isDisabled = journey.isDisabled;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {isDisabled && (
        <Alert severity="info">
          Journey is not editable! Copy it to edit 💡
        </Alert>
      )}

      <Box
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        height="100%"
        p={4}
        width="100%"
      >
        {tab === "info" && (
          <GeneralInfo
            formik={formik}
            isDisabled={isDisabled}
            journeys={journeys}
            semanticsWithEmptyCTALink={journeySemanticsWithEmptyCTALink}
          />
        )}
        {tab === "structure-and-interactions" && (
          <StructureAndInteractions
            days={journey.days}
            formik={formik}
            isDisabled={isDisabled}
            journeyId={journey.id}
            locale={journey.locale}
            steps={journey.steps}
            tiers={journey.tiers}
          />
        )}
        {tab === "tips" && (
          <Tips
            days={journey.days}
            formik={formik}
            isDisabled={isDisabled}
            journeyId={journey.id}
            locale={journey.locale}
            steps={journey.steps}
          />
        )}
        {tab === "scheduled-slots" && (
          <ScheduledSlots
            days={journey.days}
            formik={formik}
            isDisabled={isDisabled}
            journeyId={journey.id}
            locale={journey.locale}
            steps={journey.steps}
          />
        )}
        {tab === "contextual" && (
          <Contextual
            isDisabled={isDisabled}
            journeyId={journey.id}
            locale={journey.locale}
          />
        )}
        {tab === "learning-materials" && (
          <Materials
            isDisabled={isDisabled}
            journeyId={journey.id}
            locale={journey.locale}
            steps={journey.steps}
          />
        )}
        {!isDisabled && tab === "surveys" && (
          <Surveys journeyId={journey.id} locale={journey.locale} />
        )}
      </Box>
    </Box>
  );
};

const CreateJourney = ({ match }) => {
  const { journeys, fetchJourneys, isJourneysLoading } = useAppContext();
  const { journeyId, locale } = match.params;

  useEffect(() => {
    if (!journeys.length) {
      fetchJourneys();
    }
  }, []);

  useEffect(() => {
    document.body.style.height = "calc(100vh - 96px)";

    return () => {
      document.body.style.height = "calc(100vh - 48px)";
    };
  });

  if (isJourneysLoading) {
    return <Loader />;
  }

  const journey = {
    benefits: "",
    days: times(5, (i) => ({
      day: i + 1,
      id: `day-${uuidv4()}`,
      name: `Untitled day`,
    })),
    description: "",
    durationInfo: "",
    icon: null,
    id: journeyId,
    image: null,
    isNew: true,
    isAnimationEnabled: true,
    isSoundEnabled: true,
    locale,
    maxDailyOccurrences: 6,
    minOccurrencesInterval: 60,
    miniTipAutoProgressDelay: 0,
    miniTipAutoProgress: true,
    name: "",
    steps: [
      {
        days: 5,
        description: "",
        id: `step-${uuidv4()}`,
        isPublished: true,
        name: "Your first step title here.",
      },
    ],
    tiers: [
      {
        from: 1,
        maxDailyOccurrences: 6,
        minOccurrencesInterval: 60,
        maxMonthlyOccurrences: undefined,
        maxWeeklyOccurrences: undefined,
        extendGlobalRulesToTips: false,
        dontShowNudges: false,
      },
    ],
    postJourneyTier: {
      maxDailyOccurrences: 6,
      minOccurrencesInterval: 60,
      maxMonthlyOccurrences: undefined,
      maxWeeklyOccurrences: undefined,
      extendGlobalRulesToTips: false,
      dontShowNudges: true,
    },

    suggestions: "",
  };
  return <Journey journey={journey} />;
};

const EditJourney = ({ match }) => {
  const {
    journeys,
    fetchJourneys,
    isJourneysLoading,
    fetchLinkedJourneys,
    isLinkedJourneysLoading,
    fetchJourneySemanticsWithEmptyCTALink,
    isJourneySemanticsWithEmptyCTALinkLoading,
  } = useAppContext();
  const { journeyId, locale } = match.params;

  useEffect(() => {
    document.body.style.height = "calc(100vh - 96px)";

    return () => {
      document.body.style.height = "calc(100vh - 48px)";
    };
  });

  useEffect(() => {
    if (!journeys.length) {
      fetchJourneys();
    }

    fetchLinkedJourneys(journeyId);
    fetchJourneySemanticsWithEmptyCTALink({ journeyId });
  }, []);

  if (
    isJourneysLoading ||
    !journeys.length ||
    isLinkedJourneysLoading ||
    isJourneySemanticsWithEmptyCTALinkLoading
  ) {
    return <Loader />;
  }

  return (
    <Journey
      journey={journeys.find(
        (journey) => journey.id === journeyId && journey.locale === locale
      )}
    />
  );
};

export { CreateJourney, EditJourney };
