const hiSurveyOutcomeTypeTitles = {
  "category-single": "Category (single)",
  "no-outcome": "No outcome",
  "self-evaluation": "Self evaluation",
};

const typeformSurveyOutcomeTypeTitles = {
  "category-single": "Category (single)",
  "scoring-single": "Scoring (single)",
  "scoring-category-single": "Scoring&Category (single)",
  "category-multiple": "Category (multiple)",
  "scoring-multiple": "Scoring (multiple)",
  "scoring-category-multiple": "Scoring&Category (multiple)",
  "no-outcome": "No outcome",
  "self-evaluation": "Self evaluation",
};

const surveyOutcomeTypeTitles = {
  ...hiSurveyOutcomeTypeTitles,
  ...typeformSurveyOutcomeTypeTitles,
};

export {
  surveyOutcomeTypeTitles,
  hiSurveyOutcomeTypeTitles,
  typeformSurveyOutcomeTypeTitles,
};
