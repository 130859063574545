import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import Sticky from "react-sticky-el";
import times from "lodash/times";
import sumBy from "lodash/sumBy";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import deepPurple from "@material-ui/core/colors/deepPurple";

import Loader from "../shared/Loader";
import TitleTypography from "../TitleTypography";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";
import AsyncButton from "../shared/AsyncButton";
import getData from "../../lib/getData";
import FlowPreview from "../FlowPreview";

const renderers = {
  paragraph: "span",
};

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const ListItem = withStyles({
  root: {
    paddingRight: 160,
  },
})(MuiListItem);

// the same is copied in Contextual.js; TODO move it to an utility file
const maxSemanticTextPreviewLength = 80;
const getSemanticTextPreview = function ({ title, text }) {
  const tmpTextPreview = (title || text) ?? "";
  const textPreview =
    tmpTextPreview.length > maxSemanticTextPreviewLength
      ? `${tmpTextPreview.slice(0, maxSemanticTextPreviewLength)}...`
      : tmpTextPreview;
  return textPreview;
};

const Tips = ({ journeyId, locale, steps, days, isDisabled }) => {
  const {
    semantics,
    fetchSemantics,
    fetchFlowRelations,
    isSemanticsLoading,
    uiConfigs,
    fetchUIConfigs,
    isUIConfigsLoading,
    deleteJourneySemanticsByFlow,
    user,
    exportSemantics,
    flowRelations,
  } = useAppContext();

  useEffect(() => {
    fetchSemantics({ journeyId, locale });
    fetchFlowRelations({ journeyId, locale });

    if (!Object.keys(uiConfigs).length) {
      fetchUIConfigs();
    }
  }, [journeyId, locale]);

  const {
    primesByDays,
    primesByFlowId,
    flowRelationsByFlowId,
    firstInFlowPrimes,
  } = useMemo(
    () => getData({ semantics, days, flowRelations }),
    [days, flowRelations, semantics]
  );

  const history = useHistory();

  const openAdd = useCallback(
    ({ journeyId, locale, interaction }) => {
      history.push(
        `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${
          interaction.id
        }`,
        {
          isNew: true,
          type: interaction.type,
          ...(interaction.dayId && {
            dayId: interaction.dayId,
          }),
          ...(Number.isFinite(interaction.step) && {
            step: interaction.step,
          }),
        }
      );
    },
    [history]
  );

  const openEdit = useCallback(
    ({ journeyId, locale, interaction }) => {
      history.push(
        `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${
          interaction.id
        }`
      );
    },
    [history]
  );

  const modals = useModals();

  const handleEdit = useCallback(
    ({ day, flowId }) =>
      () => {
        let interaction;
        if (day) {
          interaction = primesByDays[day]?.[0];
        }

        if (flowId) {
          interaction = primesByFlowId[flowId]?.[0];
        }

        if (!interaction) {
          return;
        }

        openEdit({ interaction, journeyId, locale });
      },
    [journeyId, locale, openEdit, primesByDays, primesByFlowId]
  );

  const openCopyFlow = useCallback(
    ({ journeyId, flowId }) => {
      modals.openModal(modals.modalTypes.CopyFlow, {
        flowId,
        journeyId,
      });
    },
    [modals]
  );

  const handleCopyFlow = useCallback(
    ({ journeyId, flowId }) =>
      () => {
        if (!flowId || !journeyId) {
          return;
        }
        openCopyFlow({ flowId, journeyId });
      },
    [openCopyFlow]
  );

  const handleAddInteraction = useCallback(() => {
    openAdd({
      interaction: {
        id: `sem-${uuidv4()}`,
        type: "PRIME",
      },
      journeyId,
      locale,
    });
  }, [journeyId, locale, openAdd]);

  const [activeSteps, setActiveSteps] = useLocalStorage(
    `${user.sub}.Journeys.${journeyId}-${locale}.Tips.ActiveSteps`,
    {}
  );

  const handleStepClick = useCallback(
    (step) => () => {
      setActiveSteps((activeSteps) => ({
        ...activeSteps,
        [step]: !activeSteps[step],
      }));
    },
    [setActiveSteps]
  );

  const [selectedFlowId, setSelectedFlowId] = useState(null);

  const handleListItemClick = useCallback(
    (flowId) => () => {
      setSelectedFlowId(flowId);
    },
    []
  );

  const handleFlowSemanticsDelete = useCallback(
    (flowId) => () => {
      modals.openConfirmation({
        closeCTATitle: "Go back",
        confirmCTATitle: "Delete",
        onConfirm: () => deleteJourneySemanticsByFlow({ flowId, journeyId }),
        text: `You're about to permanently delete a tip flow.`,
        title: "Delete the flow?",
      });
    },
    [deleteJourneySemanticsByFlow, journeyId, modals]
  );

  const handleExport = useCallback(
    () => exportSemantics({ journeyId, locale }),
    [exportSemantics, journeyId, locale]
  );

  const stepsWithPrimes = useMemo(
    () =>
      steps.map((step, stepNumber) => ({
        ...step,
        days: times(step.days, (i) => {
          const daysBefore = sumBy(steps.slice(0, stepNumber), "days");
          const day = daysBefore + i + 1;

          return {
            day,
            primes: primesByDays[day] ?? [],
          };
        }).filter(({ primes }) => primes),
      })),
    [primesByDays, steps]
  );

  const theme = useTheme();

  if (isSemanticsLoading || isUIConfigsLoading) {
    return <Loader inline />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="flex-end" spacing={4}>
          <Grid item>
            <AsyncButton
              color="primary"
              variant="contained"
              onClick={handleExport}
              {...{ "hi-web-id": "semantics-export-button" }}
            >
              Export all tips and nudges
            </AsyncButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TitleTypography variant="h6">Tips</TitleTypography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2"></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={isDisabled}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddInteraction}
            >
              Create a new tip
            </Button>
          </Grid>
          {[
            {
              name: "notAssociatedToADay",
              semantics: firstInFlowPrimes.filter(
                ({ flowId }) => !flowRelationsByFlowId[flowId]
              ),
              title: "Not associated to a day",
            },
            {
              name: "scheduled",
              semantics: flowRelations.filter(
                ({ type, flowId }) =>
                  type === "scheduledSlot" && primesByFlowId[flowId]
              ),
              title: "Scheduled",
            },
          ].map(({ name, title, semantics }) => (
            <Grid key={name} item xs={12}>
              <Accordion
                expanded={Boolean(activeSteps[name])}
                onChange={handleStepClick(name)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h6">{title}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    <Paper variant="outlined">
                      <Box p={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <List dense>
                              {semantics.length === 0 && (
                                <Box display="flex" justifyContent="center">
                                  <Typography variant="subtitle1">
                                    No tips 😢
                                  </Typography>
                                </Box>
                              )}
                              {semantics.map(({ flowId }, i) => (
                                <Fragment key={flowId}>
                                  <ListItem
                                    key={flowId}
                                    button
                                    selected={flowId === selectedFlowId}
                                    onClick={handleListItemClick(flowId)}
                                  >
                                    <ListItemText
                                      primary={
                                        <ReactMarkdown renderers={renderers}>
                                          {getSemanticTextPreview({
                                            text: primesByFlowId[flowId][0]
                                              ?.text,
                                            title:
                                              primesByFlowId[flowId][0]?.title,
                                          })}
                                        </ReactMarkdown>
                                      }
                                      secondary={`${
                                        primesByFlowId[flowId]?.length ?? 0
                                      } Tips in flow`}
                                    />
                                    <ListItemSecondaryAction>
                                      {!isDisabled && (
                                        <>
                                          {
                                            <>
                                              <Tooltip
                                                placement="top"
                                                title="Copy"
                                              >
                                                <IconButton
                                                  onClick={handleCopyFlow({
                                                    flowId,
                                                    journeyId,
                                                  })}
                                                >
                                                  <FileCopy />
                                                </IconButton>
                                              </Tooltip>
                                              <IconButton
                                                onClick={handleEdit({ flowId })}
                                              >
                                                <EditIcon />
                                              </IconButton>
                                              <IconButton
                                                onClick={handleFlowSemanticsDelete(
                                                  flowId
                                                )}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </>
                                          }
                                        </>
                                      )}
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  {i !== semantics.length - 1 && <Divider />}
                                </Fragment>
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <Grid item xs={12}>
            {stepsWithPrimes
              .filter(({ days }) => days.some(({ primes }) => primes.length))
              .map((step, stepNumber) => (
                <Accordion
                  key={stepNumber}
                  expanded={Boolean(activeSteps[stepNumber])}
                  style={{
                    ...(!step.isPublished && {
                      backgroundColor: deepPurple[50],
                    }),
                  }}
                  onChange={handleStepClick(stepNumber)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container item direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h6">{step.name}</Typography>
                      </Grid>
                      {step.description && (
                        <Grid item>
                          <Typography color="textSecondary">
                            {step.description}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box width="100%">
                      <Paper variant="outlined">
                        <Box p={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <List dense>
                                {step.days
                                  .filter(({ primes }) => primes.length)
                                  .map(({ day, primes }, i, days) => {
                                    const { flowId } = primes[0];

                                    return (
                                      <div key={day}>
                                        <ListItem
                                          key={flowId}
                                          button
                                          selected={flowId === selectedFlowId}
                                          onClick={handleListItemClick(flowId)}
                                        >
                                          <ListItemText
                                            primary={
                                              <ReactMarkdown
                                                renderers={renderers}
                                              >
                                                {primesByDays[day][0].title ??
                                                  ""}
                                              </ReactMarkdown>
                                            }
                                            secondary={`${day} Day - ${
                                              primesByDays[day].length ?? 0
                                            } Tips in flow`}
                                          />
                                          <ListItemSecondaryAction>
                                            {!isDisabled && (
                                              <>
                                                {
                                                  <>
                                                    <Tooltip
                                                      placement="top"
                                                      title="Copy"
                                                    >
                                                      <IconButton
                                                        onClick={handleCopyFlow(
                                                          {
                                                            flowId:
                                                              primesByDays[
                                                                day
                                                              ][0].flowId,
                                                            journeyId,
                                                          }
                                                        )}
                                                      >
                                                        <FileCopy />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <IconButton
                                                      onClick={handleEdit({
                                                        day,
                                                      })}
                                                    >
                                                      <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                      onClick={handleFlowSemanticsDelete(
                                                        primesByDays[day][0]
                                                          .flowId
                                                      )}
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </>
                                                }
                                              </>
                                            )}
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                        {i !== days.length - 1 && <Divider />}
                                      </div>
                                    );
                                  })}
                              </List>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2} xs={12} />
      <Grid item lg={4} xs={12}>
        <Sticky
          scrollElement=".scrollarea"
          stickyStyle={{ top: theme.spacing(4) + 92 }}
        >
          <FlowPreview
            interactions={primesByFlowId[selectedFlowId] ?? []}
            journeyId={journeyId}
            locale={locale}
            name="day"
            onEdit={(interaction) =>
              openEdit({ journeyId, locale, interaction })
            }
          />
        </Sticky>
      </Grid>
    </Grid>
  );
};

export default Tips;
