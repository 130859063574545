import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useModals } from "../../hooks/useModals";
import { Box, Grid, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

const LEGACY_TEMPLATEFAMILY_NAME = "Legacy";

const GridContainer = styled(Grid)`
  cursor: pointer;
  border: 0.5px dashed ${(props) => (props.error ? "#f44336" : "#0000008a")};
  border-radius: 4px;
  height: 245px;
  &:hover {
    border-color: ${(props) => (props.error ? "#f44336" : "#000000")};
  }
  position: relative;
  top: -21px;
  flex-wrap: nowrap;
`;

const Image = styled.img`
  max-height: ${(props) => (props.isSelectorSmall ? "55%" : "85%")};
  max-width: ${(props) => (props.isSelectorSmall ? "" : "85%")};
`;

const Label = styled(Typography)`
  color: ${(props) => (props.error ? "#f44336" : "#0000008a")};
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  background: #f8f8f8;
  position: relative;
  top: ${(props) => (props.isTitle ? "-10px" : "-20px")};
  left: 10px;
  padding: 0 7px 0 7px;
  z-index: 1;
`;

const PlaceholderContainer = styled(Grid)`
  align-items: center;
  direction: column;
  justify-content: center;
  margin-left: 8px !important;
`;

const PreviewTemplateContainer = ({
  props,
  handleTemplatesPreview,
  templateSelected,
}) => {
  const {
    form: { touched, errors },
    name: field,
  } = props;

  const previewTemplateContainerMemo = useMemo(() => {
    return (
      <>
        <Label
          error={errors[field] && touched[field]}
          isTitle={true}
          variant="caption"
        >
          Card Template
        </Label>
        <GridContainer
          container
          item
          alignItems="center"
          error={errors[field] && touched[field]}
          md={12}
          xl={10}
          onClick={() => handleTemplatesPreview()}
        >
          {templateSelected && (
            <>
              <Grid
                container
                item
                justifyContent="center"
                style={{
                  flexWrap: "nowrap",
                  height: "inherit",
                  justifyContent: "space-evenly",
                }}
                xs={12}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  xs={8}
                >
                  <Image
                    alt="Semantic template preview"
                    isSelectorSmall={templateSelected.isSelectorSmall}
                    src={`/${templateSelected.templatePreviewUrl}`}
                  />
                </Box>
                <PlaceholderContainer container item xs={4}>
                  <Typography variant="h6">
                    {`${templateSelected.templateName}`}
                    <br />
                    <b>Click to change</b>
                  </Typography>
                </PlaceholderContainer>
              </Grid>
            </>
          )}
        </GridContainer>
        {errors[field] && touched[field] ? (
          <Label
            error={errors[field] && touched[field]}
            isTitle={false}
            variant="caption"
          >
            {errors[field]}{" "}
          </Label>
        ) : null}
      </>
    );
  }, [errors, field, handleTemplatesPreview, templateSelected, touched]);

  return previewTemplateContainerMemo;
};

const SemanticTemplateSelect = (props) => {
  const modals = useModals();
  const [templateSelected, setTemplateSelected] = useState();

  useEffect(() => {
    const templateSelected = props.semantictemplatecatalogue.find(
      (elem) => elem.templateId === props.value
    );

    setTemplateSelected(templateSelected);
  }, [props.semantictemplatecatalogue, props.value]);

  const setTemplateSelectedCB = useCallback(
    (template) => {
      setTemplateSelected(template);
    },
    [setTemplateSelected]
  );

  const handleTemplatesPreview = useCallback(() => {
    modals.openModal(modals.modalTypes.SemanticTemplatePreview, {
      onConfirm: (templateSelected) => {
        setTemplateSelectedCB(templateSelected);
        props.form.setFieldValue(props.name, templateSelected.templateId);
      },
      templates: props.semantictemplatecatalogue,
    });
  }, [modals, props, setTemplateSelectedCB]);

  return (
    <>
      {props.templateFamily === LEGACY_TEMPLATEFAMILY_NAME ? (
        <TextField
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          label={props.label || "Semantic template select"}
          variant="outlined"
          {...props}
        >
          {props.semantictemplatecatalogue.map((obj) => {
            return (
              <MenuItem
                key={obj.templateId}
                disabled={obj.isActive ? false : true}
                value={obj.templateId}
              >
                {obj.templateName}
              </MenuItem>
            );
          })}
        </TextField>
      ) : (
        <PreviewTemplateContainer
          handleTemplatesPreview={handleTemplatesPreview}
          props={props}
          styles={{ position: "relative", top: "0px" }}
          templateSelected={templateSelected}
        />
      )}
    </>
  );
};

export default SemanticTemplateSelect;
