export const CHANNEL_CLIENT = { name: "Client", value: "client" };
export const CHANNEL_TEAMSAPP = { name: "Teams App", value: "teams-app" };
export const CHANNEL_EMBEDDED = { name: "Embedded", value: "embedded" };
export const CHANNEL_BROWSERPLUGIN = {
  name: "Browser Plugin",
  value: "browser-plugin",
};
export const DASHBOARD_ACCESS_ICON = { name: "From Icon", value: "icon" };
export const DASHBOARD_ACCESS_INSIDE_APP = {
  name: "From inside the app",
  value: "inside-app",
};
