import React from "react";
import { useFormikContext } from "formik";
import { FormControl, MenuItem, Select } from "@material-ui/core/";

const Tone = () => {
  const formik = useFormikContext();

  return (
    <FormControl>
      <Select
        displayEmpty
        required
        id="tone"
        inputProps={{ "aria-label": "Select tone" }}
        name="tone"
        value={formik.values.tone}
        onChange={formik.handleChange}
      >
        <MenuItem disabled value="">
          Select Tone
        </MenuItem>
        <MenuItem value="formal">Formal</MenuItem>
        <MenuItem value="friendly">Friendly</MenuItem>
        <MenuItem value="engaging">Engaging</MenuItem>
        <MenuItem value="alarming">Alarming</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Tone;
