// legacy semantics and nudges etc. do not have a template specified; this should ideally apply to them
export const LEGACY_TEMPLATEFAMILY_ID =
  "fam-6fae4cea-82c4-4f2f-89c8-a04973c7fbfa"; // semantic.templateFamilyId
export const LEGACY_SEMANTICTEMPLATE_ID =
  "crd-50ad24d4-c833-4aa9-9958-3a0cfc59c54f"; // semantic.semanticTemplateId
export const NO_PREVIEW_ID = "prw-b492645d-fd89-4209-9c60-b38399fdf830"; // semantic.semanticPreview.semanticPreviewTemplateId
// Number of bytes for an image upload; 524288 bytes = 0.5 MiB (mebibyte)
// 1,049e+6 bytes = 1 MiB (mebibyte)
export const MAX_IMAGE_UPLOAD_SIZE_BYTES = 1049000;
export const PREVIEW_LEGACY = "prw-f0c8d386-c174-43ae-abfc-a028c1b68ef0";
