import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// import OutlinedButton from "../OutlinedButton";

import { useHistory } from "react-router-dom";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Loader from "../../shared/Loader";
import { useModals } from "../../../hooks/useModals";
import { useAppContext } from "../../../api/AppContext";
import useLocalStorage from "../../../hooks/useLocalStorage";
import OutlinedButton from "../../OutlinedButton";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const WelfareNewsList = () => {
  const [isNewsLoading, setIsNewsLoading] = useState(true);
  const [isCopyNewsLoading, setIsCopyNewsLoading] = useState(false);

  const [groupedNews, setGroupedNews] = useState([]);
  const modals = useModals();

  const history = useHistory();
  const {
    fetchAllNews,
    news,
    fetchWelfareSelector,
    welfareSelectors,
    updateWelfareNews,
    deleteWelfareNews,
  } = useAppContext();

  const [expanded, setExpanded] = useLocalStorage(
    `WelfareNewsList.Expanded`,
    {}
  );

  const handleExpandClick = useCallback(
    (step) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [step]: !expanded[step],
      }));
    },
    [setExpanded]
  );

  useEffect(() => {
    if (!welfareSelectors.entities.length) {
      fetchWelfareSelector("entities");
    }
    fetchAllNews();
  }, []);

  useEffect(() => {
    if (news.length && welfareSelectors.entities.length) {
      const templateEntity = welfareSelectors.entities.find(
        (entity) => entity.displayname === "Template"
      );
      const groupedItems = news.reduce((result, item) => {
        // Find the corresponding legal entity name from array2
        const entity = welfareSelectors.entities.find(
          (entity) => entity.id === item.legalentity_id
        );

        result[templateEntity.id] = result[templateEntity.id] || {
          name: templateEntity.displayname,
          news: [],
        };
        if (entity) {
          // Combine legalentity_id and legalentity_name for the key
          const key = item.legalentity_id;

          // Initialize an object for the key if it doesn't exist
          result[key] = result[key] || {
            name: entity.displayname,
            news: [],
          };

          // Add the benefit to the array
          result[key].news.push(item);
        }
        return result;
      }, {});

      // Extract the "Template" item
      const templateItem = groupedItems[templateEntity.id];

      // Remove the "Template" item from the original map
      delete groupedItems[templateEntity.id];

      // Sort the remaining items by key in descending order
      const sortedItems = Object.values(groupedItems).sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Combine the "Template" item and the sorted items
      const groupedObjects = [templateItem, ...sortedItems];

      setGroupedNews(groupedObjects);
    }
    setIsNewsLoading(false);
    setIsCopyNewsLoading(false);
  }, [news, welfareSelectors]);

  const handleCreate = useCallback(() => {
    history.push(`/welfares/news/new/start`, {
      isNew: true,
    });
  }, [history]);

  const handleEdit = useCallback(({ id }) => {
    history.push(`/welfares/news/${id}`);
  }, []);

  const handleDelete = useCallback(
    (news) => {
      modals.openConfirmation({
        onConfirm: async () => {
          await deleteWelfareNews({
            id: news.id,
            isDeleted: true,
          });
        },
        text: `You want to delete news "${news.text_default.title}"`,
      });
    },
    [deleteWelfareNews, modals]
  );

  const handlePublish = useCallback(
    async (news) => {
      const newsObj = { id: news.id, isPublished: !news.ispublished };
      await updateWelfareNews(newsObj);
    },
    [updateWelfareNews]
  );

  const buildNewsObject = useCallback(
    ({ news, entityId, skipForUserIds = false }) => {
      return {
        isNew: true,
        cmp: "9aa46b2c-5fa6-4f0c-839d-35527dd19386",

        image: news.image,
        text_default: news.text_default,
        text_en: news.text_en,
        isPublished: false,
        startDate: new Date(news.startdate),
        expirationDate: new Date(news.expirationdate),
        conditions: {
          entity: entityId,
          contractTypes: news.contracttypenames,
          employeeTypes: news.employeetypenames,
          employeeRoles: news.employeerolenames,
          officeTypes: news.officetypenames,
          officeLocations: news.officelocationnames,
          forUserIds: skipForUserIds ? [] : news.foruser_ids || [],
          notForUserIds: skipForUserIds ? [] : news.notforuser_ids || [],
        },
      };
    },
    []
  );

  const handleCopy = useCallback(
    (news) => {
      modals.openModal(modals.modalTypes.CopyWelfare, {
        onConfirm: async ({ entity }) => {
          const newNews = buildNewsObject({
            news,
            entityId: entity,
            skipForUserIds: true,
          });
          await updateWelfareNews(newNews);
          setIsCopyNewsLoading(true);
          setIsCopyNewsLoading(false);
          //window.location.reload();
        },
      });
    },
    [modals, buildNewsObject, updateWelfareNews]
  );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      {" "}
      {isNewsLoading || isCopyNewsLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h6">News</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Use this section to configure each news - its information and
                  who can see it.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleCreate}
            >
              Add news
            </Button>
          </Grid>
          <Grid item>
            <List dense>
              {groupedNews &&
                groupedNews.map(
                  (elem, i) =>
                    elem.news?.length > 0 && (
                      <Accordion
                        key={i}
                        expanded={Boolean(expanded[i])}
                        onChange={handleExpandClick(i)}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={1}
                            style={{ width: "100%" }}
                          >
                            <Grid item style={{ flexGrow: 1 }}>
                              <Grid container direction="column" spacing={1}>
                                <Grid item>
                                  <Typography variant="h6">
                                    {elem.name} ({elem.news?.length})
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Paper style={{ width: "100%" }} variant="outlined">
                            <Box p={2}>
                              <Grid container direction="column" spacing={2}>
                                <Grid item>
                                  <List>
                                    {elem.news?.map((news, i) => (
                                      <>
                                        <ListItem
                                          key={i}
                                          style={{
                                            ...(!news.ispublished &&
                                              elem.name?.toLowerCase() !==
                                                "template" && {
                                                backgroundColor: deepPurple[50],
                                              }),
                                            ...(elem.news?.length &&
                                              i < elem.news?.length - 1 && {
                                                borderBottom: "1px solid gray",
                                              }),
                                          }}
                                        >
                                          <ListItemText
                                            primary={news.text_default?.title}
                                            secondary={
                                              news.text_default?.subtitle
                                            }
                                          />
                                          <ListItemSecondaryAction
                                            style={{
                                              alignItems: "center",
                                              display: "flex",
                                            }}
                                          >
                                            {elem.name?.toLowerCase() !==
                                              "template" && (
                                              <Tooltip
                                                title={
                                                  news.ispublished
                                                    ? "Published (click to unpublish)"
                                                    : "Unpublished (click to publish)"
                                                }
                                              >
                                                <span>
                                                  <IconButton
                                                    onClick={() =>
                                                      handlePublish(news)
                                                    }
                                                  >
                                                    {news.ispublished ? (
                                                      <VisibilityIcon />
                                                    ) : (
                                                      <VisibilityOffIcon />
                                                    )}
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            )}
                                            <Tooltip title="Copy">
                                              <span
                                                style={{
                                                  marginRight: "1rem",
                                                }}
                                              >
                                                <OutlinedButton
                                                  onClick={() =>
                                                    handleCopy(news)
                                                  }
                                                >
                                                  Copy
                                                </OutlinedButton>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                              <span>
                                                <OutlinedButton
                                                  onClick={() =>
                                                    handleEdit(news)
                                                  }
                                                >
                                                  Edit
                                                </OutlinedButton>
                                              </span>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                              <span>
                                                <IconButton
                                                  onClick={() =>
                                                    handleDelete(news)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      </>
                                    ))}
                                  </List>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </AccordionDetails>
                      </Accordion>
                    )
                )}
            </List>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default WelfareNewsList;
