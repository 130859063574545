import { Route, Switch } from "react-router-dom";
// import WelfareList from "./WelfareList";
import WelfareContainer from "./Welfare";
import WelfareList from "./WelfareList";
import WelfareSimulate from "./WelfareSimulate";
import WelfareNewsList from "./News/WelfareNewsList";
import NewsContainer from "./News/WelfareNews";
import WelfareBooking from "./WelfareBooking";

const Welfares = () => {
  return (
    <>
      <Switch>
        <Route exact component={WelfareList} path="/welfares" />
        <Route exact component={WelfareList} path="/welfares/list" />
        <Route exact component={WelfareNewsList} path="/welfares/news" />
        <Route exact component={WelfareNewsList} path="/welfares/news/list" />
        <Route
          exact
          component={NewsContainer}
          path="/welfares/news/:id/:tab?"
        />
        <Route
          exact
          component={() => <div>Categories</div>}
          path="/welfares/categories"
        />
        <Route
          exact
          component={() => <div>Ad properties</div>}
          path="/welfares/ad-properties"
        />
        <Route
          exact
          component={WelfareSimulate}
          path="/welfares/welfare-impersonate"
        />
        <Route exact component={WelfareBooking} path="/welfares/bookings" />
        <Route exact component={WelfareContainer} path="/welfares/:id/:tab?" />
      </Switch>
    </>
  );
};

export default Welfares;
