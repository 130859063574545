import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { getFieldProps, inputProps } from "../../utils";
import FileUpload from "../../shared/FileUpload";
import bytes from "bytes";
import { uploadFile } from "../../../api";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
// import { useField } from "formik";
// import MarkdownEditor from "../../shared/MarkdownEditor";
import MarkdownEditorV2 from "../../shared/MarkdownEditorV2";

const Placeholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-height: 150px;
  height: 150px;
  border: ${({ isDragActive }) => (isDragActive ? 3 : 2)}px #e0e0e0 dashed;

  font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  p,
  span {
    font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  }
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #bfbfbf;
  padding: 35px 73px;
`;

const ImagePlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: ${({ isDragActive }) => (isDragActive ? 3 : 2)}px #e0e0e0 dashed;
  font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  p,
  span {
    font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  }
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #bfbfbf;
  padding: 15px 25px;
`;

export const DocumentContainer = ({
  isLoading,
  document,
  containerStyles,
  styles,
  formik,
  fieldName,
  caption,
  buttonProps,
  onStartUpload,
  disabled,
  isUploading,
  handleRemove,
  onUpload,
  error,
}) => {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: onUpload,
  });

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        {isLoading ? (
          <Placeholder>
            <CircularProgress size={30} />
          </Placeholder>
        ) : document && document.url ? (
          <Box mt={2}>
            <Typography variant="subtitle2">
              <a
                href={isLoading ? document.body : document.url}
                rel="noreferrer noopener"
                target="_blank"
                {...(isLoading ? { download: document.name } : {})}
              >
                <Box alignItems="center" display="flex">
                  <Box mr={1}>{document.name}</Box>
                  <OpenInNewIcon fontSize="small" />
                </Box>
              </a>
            </Typography>
          </Box>
        ) : (
          <ImagePlaceholder {...getRootProps()} isDragActive={isDragActive}>
            {caption}
          </ImagePlaceholder>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={buttonProps.fullWidth ? 6 : undefined}>
            <Button
              color="primary"
              disabled={disabled || isUploading}
              endIcon={isUploading && <CircularProgress size={25} />}
              variant="contained"
              onClick={onStartUpload}
              {...buttonProps}
            />
            {error && (
              <div>
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={buttonProps.fullWidth ? 6 : undefined}>
            <Button
              disabled={disabled || !document}
              variant="contained"
              onClick={handleRemove}
              {...buttonProps}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const BuildMarkdownEditor = ({
  formik,
  placeholder,
  name,
  value,
  maxLength,
}) => {
  const onChange = useCallback(
    (newValue) => {
      // checking also here max length
      const valueToStore = maxLength ? newValue.slice(0, maxLength) : newValue;
      formik.setFieldValue(name, valueToStore);
      formik.setFieldTouched(name, true);
    },
    [formik, name, maxLength]
  );

  return (
    <Grid item md={6} xs={12}>
      <MarkdownEditorV2
        error={false}
        label={``}
        placeHolder={placeholder}
        {...getFieldProps(formik, {
          name,
        })}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
};
const Doubts = ({ formik }) => {
  const [isFileUploading, setIsFileUploading] = useState(null);

  const buildTextField = (formik, placeholder, name, md = 5, xs = 11) => {
    return (
      <Grid item md={md} xs={xs}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={placeholder}
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </Grid>
    );
  };

  const handleChangeImage = useCallback(async (args, formik, field) => {
    setIsFileUploading(field);

    const data = await uploadFile({
      ...args,
      customPathInfo: { cmp: formik.values?.cmp },
    });

    setIsFileUploading(null);
    formik.setFieldValue(field, { name: args.name, url: data.url });
    formik.setFieldTouched(field, true);
  }, []);

  const handleResetImage = useCallback(
    async (field) => {
      formik.setFieldValue(`${field}`, { name: null, url: null });
      formik.setFieldTouched(`${field}`, true);
    },
    [formik]
  );

  const buildDocumentUpload = useCallback(
    (formik, caption, name, formikValue) => {
      return (
        <Grid item md={5} xs={11}>
          <FileUpload
            accept=".pdf, .doc, .odt, .xlsx, .xls, .ods"
            maxSize={bytes("10 MB")}
            readAs="DataURL"
            onChange={(val) => handleChangeImage(val, formik, name)}
          >
            {({ upload, onUpload, error }) => {
              return (
                <>
                  <DocumentContainer
                    buttonProps={{ children: "Upload", fullWidth: true }}
                    caption={caption}
                    containerStyles={{}}
                    disabled={false}
                    document={formikValue}
                    error={error}
                    handleRemove={() => handleResetImage(name)}
                    isLoading={isFileUploading === name}
                    isUploading={isFileUploading === name}
                    styles={{}}
                    onStartUpload={upload}
                    onUpload={onUpload}
                  />
                </>
              );
            }}
          </FileUpload>
        </Grid>
      );
    },
    [handleChangeImage, isFileUploading]
  );

  const handleAddQuestion = useCallback(() => {
    const questions = formik.values.benefitquestions ?? [];
    const updatedValue = [
      ...questions,
      {
        idx: questions.length,
        text_default: {
          question: "",
          answer: "",
        },
        text_en: {
          question: "",
          answer: "",
        },
      },
    ];

    formik.setFieldValue("benefitquestions", updatedValue);
  }, [formik]);

  const handleDeleteQuestion = useCallback(
    (i) => () => {
      const newValue = [...(formik.values.benefitquestions || [])];
      newValue.splice(i, 1);
      formik.setFieldValue("benefitquestions", newValue);
    },
    [formik]
  );

  const handleAddDocument = useCallback(() => {
    const defaultDocuments = formik.values.text_default?.documents ?? [];
    const englishDocuments = formik.values.text_en?.documents ?? [];

    const updatedDefaultValues = [
      ...defaultDocuments,
      {
        name: null,
        url: null,
      },
    ];

    const updatedEnValues = [
      ...englishDocuments,
      {
        name: null,
        url: null,
      },
    ];
    formik.setFieldValue("text_default.documents", updatedDefaultValues);
    formik.setFieldValue("text_en.documents", updatedEnValues);
  }, [formik]);

  const handleDeleteDocument = useCallback(
    (i) => () => {
      const newDefaultValue = [...(formik.values.text_default.documents || [])];
      newDefaultValue.splice(i, 1);
      const newEnValue = [...(formik.values.text_en.documents || [])];
      newEnValue.splice(i, 1);
      formik.setFieldValue("text_default.documents", newDefaultValue);
      formik.setFieldValue("text_en.documents", newEnValue);
    },
    [formik]
  );

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          Here instead you can upload additional information to help with
          your/your employee&apos;s concerns
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Are there questions or concerns you are often asked? Fill out the
              <b> FAQs</b>
            </Typography>
          </Grid>
          {formik.values.benefitquestions?.map((elem, i) => {
            return (
              <React.Fragment key={i}>
                <Grid
                  item
                  style={{ marginTop: "0.75rem", marginBottom: "0.5rem" }}
                >
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "0.75rem" }}
                    variant="h6"
                  >
                    Write question:
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  {buildTextField(
                    formik,
                    "Default language",
                    `benefitquestions.[${i}].text_default.question`
                  )}

                  {buildTextField(
                    formik,
                    "English",
                    `benefitquestions.[${i}].text_en.question`
                  )}
                  <Grid item md={2} xs={1}>
                    <IconButton onClick={handleDeleteQuestion(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: "1.75rem" }}>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "0.75rem" }}
                    variant="h6"
                  >
                    Response:
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <BuildMarkdownEditor
                    formik={formik}
                    name={`benefitquestions.[${i}].text_default.answer`}
                    placeholder={"Default language"}
                    value={
                      formik.values.benefitquestions[i]?.text_default?.answer ??
                      ""
                    }
                  />
                  {/*<Grid item md={6} xs={12}>
                     <MarkdownEditor
                      error={false}
                      label={``}
                      placeholder={"Default language"}
                      {...getFieldProps(formik, {
                        name: `benefitquestions.[${i}].text_default.answer`,
                      })}
                      value={
                        formik.values.benefitquestions[i]?.text_default
                          ?.answer ?? ""
                      }
                    /> 
                  </Grid>*/}

                  <BuildMarkdownEditor
                    formik={formik}
                    name={`benefitquestions.[${i}].text_en.answer`}
                    placeholder={"English"}
                    value={
                      formik.values.benefitquestions[i]?.text_en?.answer ?? ""
                    }
                  />
                </Grid>
                {i < formik.values.benefitquestions?.length - 1 && (
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                )}
              </React.Fragment>
            );
          })}
          <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddQuestion}
            >
              Add question
            </Button>
          </Grid>
          <Grid item style={{ marginBottom: "0.5rem", marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Is there any supporting document? Upload it here!
            </Typography>
          </Grid>

          {formik.values.text_default?.documents?.map((_, i) => {
            return (
              <>
                <Grid key={i} container spacing={2}>
                  {buildDocumentUpload(
                    formik,
                    "Upload document(s) (.pdf, .word, .odt, .xlsx, .xls, .ods) supporting single benefit (Default language)",
                    `text_default.documents[${i}]`,
                    formik.values.text_default?.documents[i] ?? {
                      name: null,
                      url: null,
                    }
                  )}
                  {buildDocumentUpload(
                    formik,
                    "Upload document(s) (.pdf, .word, .odt, .xlsx, .xls, .ods) supporting single benefit (English)",
                    `text_en.documents[${i}]`,
                    formik.values.text_en?.documents[i] ?? {
                      name: null,
                      url: null,
                    }
                  )}
                  <Grid item md={2} xs={1}>
                    <IconButton onClick={handleDeleteDocument(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {formik.values.text_default?.documents &&
                  formik.values.text_default?.documents?.length &&
                  i < formik.values.text_default?.documents?.length - 1 && (
                    <Grid item md={11} xs={11}>
                      <Divider
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      />
                    </Grid>
                  )}
              </>
            );
          })}
          <Grid
            item
            style={{ marginBottom: "1rem", marginTop: "1.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddDocument}
            >
              Add document
            </Button>
          </Grid>

          <Grid item style={{ marginBottom: "0.5rem", marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Is there any video or videopills? Upload it here!
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {buildTextField(
              formik,
              " Upload single benefit support video (link) Default language",
              `text_default.video`,
              6,
              12
            )}
            {buildTextField(
              formik,
              " Upload single benefit support video (link) English",
              `text_en.video`,
              6,
              12
            )}
          </Grid>
          <Grid item style={{ marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Inside the <b>&quot;Do you need any support?&quot;</b> section, to
              whom can the employee go for any kind of support? Write the
              contact information as well!
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              name={`text_default.support`}
              placeholder={"Default language"}
              value={formik.values.text_default?.support}
            />
            <BuildMarkdownEditor
              formik={formik}
              name={`text_en.support`}
              placeholder={"English"}
              value={formik.values.text_en?.support}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default Doubts;
