import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import "./CompanyUserStyles.css";
import Company from "./Company";
import User from "./User";

const CallDetection = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      pb={4}
      pl={4}
      pr={4}
      pt={1}
      width="100%"
    >
      <Tabs
        centered
        selectionFollowsFocus
        aria-label="icon position tabs example"
        indicatorColor="primary"
        value={currentTabIndex}
        onChange={handleTabChange}
        // textColor="primary"
      >
        {/* label="Companies" */}
        <Tab icon={<BusinessIcon />} />
        {/* label="Users" */}
        <Tab icon={<PeopleIcon />} />
      </Tabs>

      {/* Companies Tab content */}
      {currentTabIndex === 0 && <Company />}

      {/* Users Tab content */}
      {currentTabIndex === 1 && <User />}
    </Box>
  );
};

export default CallDetection;
