import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const localeMap = {
  "en-GB": "English",
  "fr-FR": "French",
  "it-IT": "Italian",
};

const getLocaleMapCopy = function () {
  // returning a copy, to avoid changes at main obj by importers
  return { ...localeMap };
};

const LocaleSelect = (props) => (
  <TextField
    fullWidth
    select
    InputLabelProps={{ shrink: true }}
    label={props.label || "Locale"}
    variant="outlined"
    {...props}
  >
    {props.allowEmpty && <MenuItem value={null}>-</MenuItem>}
    {props.addOptions &&
      Object.keys(props.addOptions).map((k) => {
        return (
          <MenuItem key={k} value={k}>
            {props.addOptions[k]}
          </MenuItem>
        );
      })}
    {Object.keys(localeMap).map((k) => {
      return (
        <MenuItem key={k} value={k}>
          {localeMap[k]}
        </MenuItem>
      );
    })}
  </TextField>
);

export default LocaleSelect;
export { getLocaleMapCopy };
