import React from "react";
import { useFormikContext } from "formik";
import { Select, Grid, FormControl, MenuItem } from "@material-ui/core/";
const LanguageChoices = ({ language }) => {
  const formik = useFormikContext();

  const getLanguageOption = (languageCode) => {
    switch (languageCode) {
      case "en-GB":
        return "english";
      case "it-IT":
        return "italian";
      case "fr-FR":
        return "french";
      default:
        return "";
    }
  };
  const defaultLanguage = getLanguageOption(language);

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <FormControl style={{ maxWidth: "fit-content", width: "auto" }}>
          <Select
            displayEmpty
            required
            id="language"
            inputProps={{ "aria-label": "Select language" }}
            name="language"
            style={{ width: "100%" }}
            value={formik.values.language || defaultLanguage}
            onChange={formik.handleChange}
          >
            <MenuItem disabled value="">
              Select Language
            </MenuItem>
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="italian">Italian</MenuItem>
            <MenuItem value="french">French</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default LanguageChoices;
