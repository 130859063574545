import Img from "./Image";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FileUpload from "./FileUpload";
import ImageIcon from "@material-ui/icons/Image";
import { useDropzone } from "react-dropzone";
import { MAX_IMAGE_UPLOAD_SIZE_BYTES } from "./constConfig/config";

const defaultStyles = {
  borderRadius: "4px",
  height: "unset",
  maxHeight: "unset",
  maxWidth: "100%",
  width: 200,
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  > img {
    width: ${({ styles }) =>
      Number.isFinite(styles.width) ? `${styles.width}px` : styles.width};
    height: ${({ styles }) =>
      Number.isFinite(styles.height) ? `${styles.height}px` : styles.height};
    max-width: ${({ styles }) =>
      Number.isFinite(styles.maxWidth)
        ? `${styles.maxWidth}px`
        : styles.maxWidth};
    max-height: ${({ styles }) =>
      Number.isFinite(styles.maxHeight)
        ? `${styles.maxHeight}px`
        : styles.maxHeight};
    border-radius: ${({ styles }) =>
      Number.isFinite(styles.borderRadius)
        ? `${styles.borderRadius}px`
        : styles.borderRadius};
  }
`;

const Placeholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-height: 150px;
  height: 150px;
  border: ${({ isDragActive }) => (isDragActive ? 3 : 2)}px #e0e0e0 dashed;

  font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  p,
  span {
    font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  }
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #bfbfbf;
  padding: 35px 73px;
`;

const ImagePlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: ${({ isDragActive }) => (isDragActive ? 3 : 2)}px #e0e0e0 dashed;
  font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  p,
  span {
    font-weight: ${({ isDragActive }) => (isDragActive ? "bold" : "normal")};
  }
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #bfbfbf;
  padding: 15px 25px;
`;

const Container = ({
  isLoading,
  image,
  containerStyles,
  styles,
  caption,
  buttonProps,
  onStartUpload,
  disabled,
  isUploading,
  handleRemove,
  onUpload,
  error,
}) => {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: onUpload,
  });

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        {isLoading ? (
          <Placeholder>
            <CircularProgress size={30} />
          </Placeholder>
        ) : image ? (
          <Img
            container={(children) => (
              <div {...getRootProps()}>
                {isDragActive ? (
                  <Placeholder isDragActive={isDragActive}>
                    <Typography
                      color="textSecondary"
                      style={{ color: "#BFBFBF" }}
                      variant="body2"
                    >
                      Drop to upload
                    </Typography>
                  </Placeholder>
                ) : (
                  <ImageContainer style={containerStyles || {}} styles={styles}>
                    {children}
                  </ImageContainer>
                )}
              </div>
            )}
            loader={
              <Placeholder>
                <CircularProgress size={30} />
              </Placeholder>
            }
            src={[image]}
            unloader={
              <Placeholder {...getRootProps()} isDragActive={isDragActive}>
                <Typography variant="caption">
                  Image is broken or missed
                </Typography>
              </Placeholder>
            }
          />
        ) : (
          <ImagePlaceholder {...getRootProps()} isDragActive={isDragActive}>
            {caption}
          </ImagePlaceholder>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={buttonProps.fullWidth ? 6 : undefined}>
            <Button
              color="primary"
              disabled={disabled || isUploading}
              endIcon={isUploading && <CircularProgress size={25} />}
              variant="contained"
              onClick={onStartUpload}
              {...buttonProps}
            />
            {error && (
              <div>
                <Typography color="error" variant="caption">
                  {error}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={buttonProps.fullWidth ? 6 : undefined}>
            <Button
              disabled={disabled || !image}
              variant="contained"
              onClick={handleRemove}
              {...buttonProps}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ImageUpload = ({
  onChange,
  value,
  disabled,
  errorMessage,
  isLoading,
  maxSize = MAX_IMAGE_UPLOAD_SIZE_BYTES,
  caption = <ImageIcon />,
  buttonProps = { children: "Upload", fullWidth: true },
  onReset,
  containerStyles,
  ...props
}) => {
  const [error, setError] = useState(errorMessage);
  const [image, setImage] = useState(value);
  const [isUploading, setIsUploading] = useState(false);
  const styles = useMemo(
    () => ({
      ...defaultStyles,
      ...props.styles,
    }),
    [props.styles]
  );

  useEffect(() => {
    setImage(value);
  }, [value]);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const handleImageUpload = useCallback(
    async ({ body, type, name }) => {
      setImage(body);
      if (!onChange) {
        return;
      }

      try {
        setIsUploading(true);
        await onChange({ body, name, type });
      } finally {
        setIsUploading(false);
        setError(null);
      }
    },
    [onChange]
  );

  const handleRemove = useCallback(() => {
    setImage(null);
    if (!onChange) {
      return;
    }
    onChange({ body: null });
  }, [onChange, setImage]);

  return (
    <FileUpload
      accept=".png, .jpg, .jpeg" // or image/png, image/jpeg, but later on I am checking based on extension
      maxSize={maxSize}
      readAs="DataURL"
      onChange={handleImageUpload}
      onError={setError}
    >
      {({ upload, onUpload }) => (
        <Container
          buttonProps={buttonProps}
          caption={caption}
          containerStyles={containerStyles}
          disabled={disabled}
          error={error}
          handleRemove={handleRemove}
          image={image}
          isLoading={isLoading}
          isUploading={isUploading}
          styles={styles}
          onStartUpload={upload}
          onUpload={onUpload}
        />
      )}
    </FileUpload>
  );
};

export default memo(ImageUpload);
