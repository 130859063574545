import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { tmpFonts } from "../../api/tmpData/tmpFonts";

const FontFamilySelect = (props) => (
  <TextField
    fullWidth
    select
    InputLabelProps={{ shrink: true }}
    label={props.label || "Font Family Select"}
    variant="outlined"
    {...props}
  >
    {tmpFonts.map((el) => {
      return (
        <MenuItem key={el.fntId} value={el.withFallback}>
          {el.name}
        </MenuItem>
      );
    })}
  </TextField>
);

export default FontFamilySelect;
