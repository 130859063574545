import React, { useCallback, useMemo, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components/macro";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../shared/Dialog";
import useModal from "../../hooks/useModal";
import { Grid, Typography } from "@material-ui/core";

const CustomGrid = styled(Grid)`
  padding: 20px;
`;

const ImageContainer = styled(Grid)`
  justify-content: flex-end;
  width: 350px;
`;

const Image = styled.img`
  cursor: pointer;
  height: auto;
  max-width: ${(props) => (props.isSelectorSmall ? "240px" : "587px")};
  /* max-height: 260px; */
  margin-bottom: 24px;
`;

const ImageInfo = styled(Typography)`
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  background: #d9d9d9;
  padding: 5px 40px;
  margin-top: 36px;
`;

const DialogContentContainer = styled(DialogContent)`
  background: #f8f8f8;
  display: flex;
  height: 900px;
  justify-content: center;
  overflow-x: hidden;
`;

const DialogTitleContainer = styled(DialogTitle)`
  background: #f8f8f8;
`;

const SemanticTemplatesGalleryModal = ({ templates, onConfirm, onClose }) => {
  const [loading, setLoading] = useState(true);
  const counter = useRef(0);

  const templatesLoaded = useCallback(() => {
    counter.current += 1;
    if (counter.current >= templates.length) {
      setLoading(false);
    }
  }, [templates.length]);

  const { handleConfirm } = useModal({
    onClose,
    onConfirm: useCallback((template) => onConfirm(template), [onConfirm]),
  });

  const getImageContainer = useCallback(
    (template, size) => {
      return (
        <ImageContainer
          key={template.templateId}
          container
          item
          alignItems="center"
          direction="column"
          sm={template.modelSelectorColumns}
          xs={size}
        >
          <Image
            key={"img-" + template.templateId}
            alt="Semantic template preview"
            isSelectorSmall={template.isSelectorSmall}
            src={"/" + template.templatePreviewUrl}
            onClick={() => handleConfirm(template)}
            onLoad={templatesLoaded}
          />

          <Typography variant="h6">{template.templateName}</Typography>
        </ImageContainer>
      );
    },
    [handleConfirm, templatesLoaded]
  );

  const renderCustomGrid = useCallback(
    (idx) => {
      const slicedTemplates = templates.slice(idx, idx + 2);
      const content = slicedTemplates.map((template) =>
        getImageContainer(template, 6)
      );
      return content;
    },
    [templates, getImageContainer]
  );

  const templatesGrid = useMemo(() => {
    return (
      <Grid container spacing={5}>
        {templates.map((template, idx) => {
          if (template.templateName.includes("5A")) {
            return (
              <Box
                key={"box-" + idx}
                display="flex"
                justifyContent="center"
                p={"20px"}
              >
                <CustomGrid container item justifyContent="center">
                  {renderCustomGrid(idx)}
                  <ImageInfo>
                    In cards 5A and 5B you can write and display only the{" "}
                    <b>text.</b>
                  </ImageInfo>
                </CustomGrid>

                <CustomGrid container item justifyContent="flex-start">
                  {renderCustomGrid(idx + 2)}
                  <ImageInfo>
                    In cards 6A and 6B you can write and display only the{" "}
                    <b>title.</b>
                  </ImageInfo>
                </CustomGrid>
              </Box>
            );
          }
          if (
            !template.templateName.includes("5") &&
            !template.templateName.includes("6")
          ) {
            return getImageContainer(template, 12);
          }
          return <></>;
        })}
      </Grid>
    );
  }, [templates, renderCustomGrid, getImageContainer]);

  return (
    <Dialog fullWidth open maxWidth="lg">
      <DialogTitleContainer>
        <Box display="flex" justifyContent="space-between">
          Card Template Gallery
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitleContainer>
      <DialogContentContainer>
        <CircularProgress
          size={25}
          style={{
            display: loading ? "block" : "none",
            marginBottom: "auto",
            marginTop: "auto",
          }}
        />

        <div style={{ display: loading ? "none" : "block" }}>
          {templatesGrid}
        </div>
      </DialogContentContainer>
    </Dialog>
  );
};

export default SemanticTemplatesGalleryModal;
