/* eslint-disable prettier/prettier */
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import useConfirmLeave from "../hooks/useConfirmLeave";
import useModal from "../hooks/useModal";
import { getFieldProps, inputProps } from "./utils";
import { useModals } from "../hooks/useModals";
import { useAppContext } from "../api/AppContext";

const editSchema = yup.object().shape({
  maxperuserperyear: yup.string().required(),
  name: yup.string().required(),
  url: yup.string().required(),
});
const EditWelfareBookingModal = ({
  bookingRecord,
  onClose,
  onConfirm,
  isNew = false,
}) => {
  const { handleConfirm } = useModal({ onClose, onConfirm });
  const [isAngeliniUsersLoading, setIsAngeliniUsersLoading] = useState(false);
  const modals = useModals();
  const { fetchAngeliniUsers } = useAppContext();

  const formik = useFormik({
    initialValues: isNew
      ? {
        added_user_ids: [],
        bookingId : "",
        customaddreduce : {},
        custommaxperuser: {},
        isfromcontent: false,
        maxSessionsCheckbox: false,
        maxperuserperyear: "",
        name: "",
        url: "",
        }
      : bookingRecord,
      onSubmit: (values) => {
        delete values.maxSessionsCheckbox;
        handleConfirm(values);
      },    validationSchema: editSchema,
    validator: () => ({}),
  });

  const handleMaxSessionsChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && Number.isInteger(Number(value))) {
      
      formik.setFieldValue("maxperuserperyear", value);
    }

    if (value) {
      formik.setFieldValue("maxSessionsCheckbox", false);
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      formik.setFieldValue("maxperuserperyear", "unlimited");
    } else {
      formik.setFieldValue("maxperuserperyear", "");
    }
  };

  const { onClose: handleClose2 } = useConfirmLeave({
    formik,
    onClose,
  });

  const handleClick = useCallback(async () => {
    const dataObject = {};

    //if (dataObject.extendedSessionsUsers?.length || dataObject.extendedSessionsUsers?.length || dataObject.reducedSessionsUsers?.length) {
      setIsAngeliniUsersLoading(true);
      await fetchAngeliniUsers(dataObject);
      setIsAngeliniUsersLoading(false);
      
    //}
    modals.openModal2(modals.modalTypes.SpecificUsersBooking, {
      //bookingId: item?.id , 
      bookingRecord: bookingRecord,
      customaddreduce :  {},
      custommaxperuser:  {},
      maxperuserperyear: bookingRecord?.maxperuserperyear,
      onConfirm: async ({ added_user_ids, custommaxperuser, customaddreduce }) => {
        formik.setFieldValue(
          "added_user_ids",
          added_user_ids.map((user) => ({ id: user.id, mail: user.mail }))
        );
        formik.setFieldValue("custommaxperuser", custommaxperuser);
        formik.setFieldValue("customaddreduce", customaddreduce);
        formik.setFieldValue("isfromcontent", true);
      },
    });
    
  }, [fetchAngeliniUsers, modals, bookingRecord, formik]);

  return (
    <Dialog fullWidth open maxWidth="md" onClose={handleClose2}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {isNew ? "Add booking" : "Edit booking"}
          <IconButton size="small" onClick={handleClose2}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Booking name"
              margin="none"
              placeholder="Booking name..."
              variant="outlined"
              {...getFieldProps(formik, { name: "name" })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Embeddable URL"
              margin="none"
              placeholder="Embeddable URL..."
              variant="outlined"
              {...getFieldProps(formik, { name: "url" })}
            />
          </Grid>
          <Grid item xs={12}>
            <Box alignItems="center" display="flex" justifyContent="center">
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Maximum sessions per user per year"
                margin="none"
                placeholder="Maximum sessions per user per year"
                style={{ width: "17.5rem" }}
                type="number"
                value={
                  formik.values.maxperuserperyear === "unlimited"
                    ? ""
                    : formik.values.maxperuserperyear
                }
                variant="outlined"
                onChange={handleMaxSessionsChange}
              />
              <Box mx={2}>
                <Typography>or</Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.maxperuserperyear === "unlimited"}
                    color="primary"
                    onChange={handleCheckboxChange}
                  />
                }
                label="Unlimited"
              />
            </Box>
          </Grid>
          {!isNew && (
            <Grid item style={{ alignItems: "center",  display: "flex", justifyContent:"center", width:"100%" }}>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Is there a specific case where you need to change the
              limit for a specific person ?
              {isAngeliniUsersLoading ? (
                <CircularProgress size={25} style={{ marginLeft: "1rem" }} />
              ) : (
                <Button color="primary" onClick={handleClick}>
                  Click here
                </Button>
              )}
            </Typography>
            </Grid>
        )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!formik.dirty || formik.isSubmitting}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          variant="outlined"
          onClick={handleClose2}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWelfareBookingModal;
