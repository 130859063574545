import React, { memo, useCallback, useMemo, useState } from "react";

const Image = ({ src, alt, loader, unloader, container, style }) => {
  const [error, setError] = useState(!src);
  const [isLoading, setIsLoading] = useState(Boolean(src));
  const handleError = useCallback((e) => {
    setIsLoading(false);
    setError(true);
  }, []);

  const handleLoad = useCallback((e) => {
    setIsLoading(false);
    setError(false);
  }, []);

  const image = useMemo(
    () => (
      <img
        alt={alt}
        src={src}
        style={style}
        onError={handleError}
        onLoad={handleLoad}
        {...((isLoading || error) && { style: { display: "none", ...style } })}
      />
    ),
    [src, alt, handleError, handleLoad, style, isLoading, error]
  );

  return (
    <>
      {isLoading && loader}
      {error && unloader}
      {typeof container === "function" ? container(image) : image}
    </>
  );
};

export default memo(Image);
