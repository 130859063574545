import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { getFieldProps, inputProps } from "../../utils";
import MultiSelect from "../../shared/MultiSelect";
import Loader from "../../shared/Loader";
import { useModals } from "../../../hooks/useModals";
import { useAppContext } from "../../../api/AppContext";

const Requirements = ({ formik, welfareSelectors }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAngeliniUsersLoading, setIsAngeliniUsersLoading] = useState(false);
  const modals = useModals();
  const { fetchAngeliniUsers } = useAppContext();

  useEffect(() => {
    if (!formik.values.isNew) {
      setIsLoading(false);
      return;
    }

    const selectedContractTypes = welfareSelectors.contractType.map(
      (selector) => selector.id
    );

    const selectedOfficeTypes = welfareSelectors.officeType.map(
      (selector) => selector.id
    );

    const selectedEmployeeTypes = welfareSelectors.employeeType.map(
      (selector) => selector.id
    );

    const selectedEmployeeRoles = welfareSelectors.employeeRole.map(
      (selector) => selector.id
    );

    const selectedOfficeLocations = welfareSelectors.officeLocation.map(
      (selector) => selector.id
    );
    if (
      selectedContractTypes &&
      selectedOfficeTypes &&
      selectedEmployeeTypes &&
      selectedEmployeeRoles &&
      selectedOfficeLocations
    ) {
      formik.setFieldValue("conditions.contractTypes", selectedContractTypes);
      formik.setFieldTouched("conditions.contractTypes", true);
      formik.setFieldValue("conditions.officeTypes", selectedOfficeTypes);
      formik.setFieldTouched("conditions.officeTypes", true);
      formik.setFieldValue("conditions.employeeTypes", selectedEmployeeTypes);
      formik.setFieldTouched("conditions.employeeTypes", true);
      formik.setFieldValue("conditions.employeeRoles", selectedEmployeeRoles);
      formik.setFieldTouched("conditions.employeeRoles", true);
      formik.setFieldValue(
        "conditions.officeLocations",
        selectedOfficeLocations
      );
      formik.setFieldTouched("conditions.officeLocations", true);

      setIsLoading(false);
    }
  }, [welfareSelectors]);

  const handleChange = useCallback(
    (value, field) => {
      formik.setFieldValue(field, value);
      formik.setFieldTouched(field, true);
    },
    [formik]
  );

  const multiselector = (formik, name, options, onChange, label) => {
    const items = options.map((option) => ({
      id: option.id,
      name: option.displayname,
    }));

    return (
      <MultiSelect
        required
        {...getFieldProps(formik, {
          name,
        })}
        items={items}
        label={label}
        onChange={(value) => onChange(value, name)}
      />
    );
  };

  const handleClick = useCallback(async () => {
    const dataObject = {};
    let results;
    if (
      formik.values.conditions.forUserIds &&
      formik.values.conditions.forUserIds.length > 0
    ) {
      dataObject.enabledUsers = formik.values.conditions.forUserIds;
    }

    if (
      formik.values.conditions.notForUserIds &&
      formik.values.conditions.notForUserIds.length > 0
    ) {
      dataObject.disabledUsers = formik.values.conditions.notForUserIds;
    }

    if (dataObject.enabledUsers?.length || dataObject.disabledUsers?.length) {
      setIsAngeliniUsersLoading(true);
      results = await fetchAngeliniUsers(dataObject);
      setIsAngeliniUsersLoading(false);
    }
    modals.openModal(modals.modalTypes.SpecificUsers, {
      disabledUsers: results?.disabledUsers || [],
      enabledUsers: results?.enabledUsers || [],
      onConfirm: async ({ enabledUsers, disabledUsers }) => {
        formik.setFieldValue(
          "conditions.forUserIds",
          enabledUsers.map((user) => user.id)
        );
        formik.setFieldValue(
          "conditions.notForUserIds",
          disabledUsers.map((user) => user.id)
        );
      },
    });
  }, [formik, modals, fetchAngeliniUsers]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          In this section you will need to select who is eligible and view the
          benefit
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: "1rem" }} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              required
              select
              InputLabelProps={{ shrink: true }}
              InputProps={inputProps}
              label="Entity"
              margin="none"
              variant="outlined"
              {...getFieldProps(formik, { name: "conditions.entity" })}
            >
              {welfareSelectors.entities.map((elem, idx) => (
                <MenuItem key={idx} value={elem.id}>
                  {elem.displayname}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            {multiselector(
              formik,
              "conditions.officeTypes",
              welfareSelectors.officeType ?? [],
              handleChange,
              "Office field"
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {multiselector(
              formik,
              "conditions.contractTypes",
              welfareSelectors.contractType ?? [],
              handleChange,
              "Contract type"
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {multiselector(
              formik,
              "conditions.employeeTypes",
              welfareSelectors.employeeType ?? [],
              handleChange,
              "Employee type"
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {multiselector(
              formik,
              "conditions.employeeRoles",
              welfareSelectors.employeeRole ?? [],
              handleChange,
              "Role"
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            {multiselector(
              formik,
              "conditions.officeLocations",
              welfareSelectors.officeLocation ?? [],
              handleChange,
              "Office location"
            )}
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: "1rem" }}>
          <Typography
            color="textSecondary"
            style={{ display: "flex", alignItems: "center" }}
            variant="body2"
          >
            Is there a specific case where you need to enable or disable the
            visual a specific person ?
            {isAngeliniUsersLoading ? (
              <CircularProgress size={25} style={{ marginLeft: "1rem" }} />
            ) : (
              <Button color="primary" onClick={handleClick}>
                {" "}
                Click here
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Requirements;
