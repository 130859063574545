import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import useModal from "../hooks/useModal";
import { useAppContext } from "../api/AppContext";
import {
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { useTheme } from "@material-ui/core/styles";
import { DebounceInput } from "react-debounce-input";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

const CustomTitle = styled(DialogTitle)`
  padding-bottom: 0px;
`;

const CustomDialogContent = styled(DialogContent)`
  padding-top: 0px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

const CustomDropdown = ({ options, onSelect, loading, values }) => {
  return (
    <Paper
      style={{
        height: "200px",
        maxHeight: "200px",
        overflowY: "auto",
        position: "absolute",
        width: "100%",
        zIndex: 1,
      }}
    >
      {loading ? (
        <LoaderContainer>
          <CircularProgress size={30} />
        </LoaderContainer>
      ) : options.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.id}
            selected={values.some((item) => item.id === option.id)}
            onClick={() => onSelect(option)}
          >
            {option.mail ?? `${option.surname}, ${option.givenname}`}
          </MenuItem>
        ))
      ) : (
        <LoaderContainer>
          Oops.. There&apos;s no result for your search :(
        </LoaderContainer>
      )}
    </Paper>
  );
};

const Search = ({
  onChange,
  value,
  isDisabled,
  isLoading,
  options,
  onSelect,
  selectedValues,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClear = useCallback(() => {
    onChange({
      target: {
        value: "",
      },
    });
  }, [onChange]);

  useEffect(() => {
    const listener = (e) => {
      if ((e.key === "f" || e.key === "F") && e.ctrlKey && e.shiftKey) {
        e.preventDefault();
        ref.current?.focus();
      }
    };
    window.addEventListener("keypress", listener);
    return () => {
      window.removeEventListener("keypress", listener);
    };
  }, []);

  const handleSelectOption = (selectedOption) => {
    onSelect(selectedOption);
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative" }}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            inputComponent: DebounceInput,
            inputProps: {
              debounceTimeout: 500,
            },
          }}
          disabled={isDisabled}
          inputRef={ref}
          margin="none"
          placeholder="Browse all users..."
          size="small"
          style={{
            ...theme.typography.body2,
            width: "100%",
          }}
          value={value}
          variant="outlined"
          onChange={onChange}
          onClick={handleToggleDropdown}
        />
        {value && showDropdown && (
          <CustomDropdown
            loading={isLoading}
            options={options}
            values={selectedValues}
            onSelect={handleSelectOption}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

const validationSchema = yup.object().shape({
  disabledUsers: yup.array(),
  enabledUsers: yup.array(),
});

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: 100%;
  }
`;

const SpecificUsersModal = ({
  onConfirm,
  onClose,
  enabledUsers,
  disabledUsers,
}) => {
  const { isAngeliniUsersLoading, fetchAngeliniUsers } = useAppContext();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [angeliniUsers, setAngeliniUsers] = useState([]);

  const buildUserContainer = (user) => {
    return (
      <Grid
        item
        md={10}
        style={{
          padding: "0.75rem",
          display: "flex",
          alignItems: "start",
          flexDirection: "column",
          justifyContent: "center",
        }}
        xs={11}
      >
        {`${user.surname || ""}${user.surname && user.givenname ? ", " : ""}${
          user.givenname || ""
        }`}
        <div>{user.mail}</div>
      </Grid>
    );
  };

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const { handleConfirm } = useModal({
    onClose,
    onConfirm,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      disabledUsers: disabledUsers || [],
      enabledUsers: enabledUsers || [],
    },
    onSubmit: async (values) => {
      await handleConfirm(values);
    },
    validationSchema,
    validator: () => ({}),
  });

  const handleDeleteUser = useCallback(
    (i, values, field) => () => {
      const newValue = [...(values || [])];
      newValue.splice(i, 1);
      formik.setFieldValue(field, newValue);
    },
    [formik]
  );

  const handleOnSelect = useCallback(
    (item) => {
      let userAlreadyOnArray = 0;
      if (currentTabIndex === 0) {
        const enabledUsersCopy = formik.values.enabledUsers || [];

        userAlreadyOnArray = enabledUsersCopy.findIndex(
          (elem) => elem.id === item.id
        );
        if (!enabledUsersCopy.length || userAlreadyOnArray === -1) {
          formik.setFieldValue("enabledUsers", [...enabledUsersCopy, item]);
        }
      }
      if (currentTabIndex === 1) {
        const disabledUsersCopy = formik.values.disabledUsers || [];
        userAlreadyOnArray = disabledUsersCopy.findIndex(
          (elem) => elem.id === item.id
        );
        if (!disabledUsersCopy.length || userAlreadyOnArray === -1) {
          formik.setFieldValue("disabledUsers", [...disabledUsersCopy, item]);
        }
      }
    },
    [currentTabIndex, formik]
  );

  const handleSearch = useCallback(
    (e) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );

  const cancelSearch = () => {
    setSearchText("");
    setAngeliniUsers([]);
  };

  useEffect(() => {
    async function fetchData() {
      const dataObject = { searchtext: searchText };

      const users = await fetchAngeliniUsers(dataObject);
      setAngeliniUsers(users);
    }
    if (searchText && searchText.length > 3) fetchData();
  }, [fetchAngeliniUsers, searchText]);

  return (
    <CustomDialog fullWidth open maxWidth="sm" style={{ height: "100%" }}>
      <CustomTitle>
        <Box display="flex" justifyContent="space-between">
          Select the person(s) to whom to enable/disable viewing of the news you
          are uploading
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </CustomTitle>
      <CustomDialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item style={{ width: "100%" }} xs={12}>
              <Search
                isDisabled={false}
                isLoading={isAngeliniUsersLoading}
                options={
                  currentTabIndex === 0
                    ? angeliniUsers.filter(
                        (angeliniUser) =>
                          !formik.values.disabledUsers.some(
                            (disabledUser) =>
                              angeliniUser.id === disabledUser.id
                          )
                      )
                    : angeliniUsers.filter(
                        (angeliniUser) =>
                          !formik.values.enabledUsers.some(
                            (enabledUser) => angeliniUser.id === enabledUser.id
                          )
                      )
                }
                selectedValues={
                  currentTabIndex === 0
                    ? formik.values.enabledUsers
                    : formik.values.disabledUsers
                }
                value={searchText}
                onCancelSearch={cancelSearch}
                onChange={handleSearch}
                onSelect={handleOnSelect}
              />
            </Grid>
            <Grid item style={{ paddingTop: "0px" }} xs={12}>
              <Box
                boxSizing="border-box"
                display="flex"
                flexDirection="column"
                height="100%"
                pb={4}
                pl={4}
                pr={4}
                pt={0}
                width="100%"
              >
                <Tabs
                  centered
                  selectionFollowsFocus
                  indicatorColor="primary"
                  value={currentTabIndex}
                  onChange={handleTabChange}
                >
                  <Tab
                    label={`Enable users (${formik.values.enabledUsers.length})`}
                  />
                  <Tab
                    label={`Disable users (${formik.values.disabledUsers.length})`}
                  />
                </Tabs>

                {currentTabIndex === 0 && (
                  <>
                    {formik.values.enabledUsers.map((user, idx) => (
                      <Grid key={idx} container spacing={2}>
                        {buildUserContainer(user)}
                        <Grid item md={2} xs={1}>
                          <IconButton
                            onClick={handleDeleteUser(
                              idx,
                              formik.values.enabledUsers,
                              "enabledUsers"
                            )}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}

                {currentTabIndex === 1 && (
                  <>
                    {formik.values.disabledUsers.map((user, idx) => (
                      <Grid key={idx} container spacing={2}>
                        {buildUserContainer(user)}
                        <Grid item md={2} xs={1}>
                          <IconButton
                            onClick={handleDeleteUser(
                              idx,
                              formik.values.disabledUsers,
                              "disabledUsers"
                            )}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </CustomDialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Confirm
        </Button>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default SpecificUsersModal;
