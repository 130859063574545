import React from "react";
import format from "date-fns/format";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import keyBy from "lodash/keyBy";
// import { useAppContext } from "../api/AppContext";

const Timestamps = ({
  createdAt,
  updatedAt,
  createdBy,
  updatedBy,
  copiedFromJourneyId,
  locale,
  journeys,
  copiedFromEntity,
  copiedFromEntityId,
}) => {
  const journeysById = keyBy(journeys, "id");
  // const { user } = useAppContext();
  return (
    <>
      {createdAt && (
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="caption">
            Created at{" "}
            {format(new Date(createdAt * 1000), "dd MMMM yyyy HH:mm:ss")}{" "}
            {createdBy &&
              ` by ${createdBy.firstName} ${createdBy.lastName} (${createdBy.email})`}
            {copiedFromJourneyId && journeysById[copiedFromJourneyId] && (
              <>
                {copiedFromJourneyId && ` copied from `}
                {copiedFromJourneyId && (
                  <a
                    href={`/journeys/${copiedFromJourneyId}/${locale}${
                      copiedFromEntity && copiedFromEntityId
                        ? `/${copiedFromEntity}s/${copiedFromEntityId}`
                        : ""
                    }`}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {journeysById[copiedFromJourneyId]?.internalName ||
                      journeysById[copiedFromJourneyId]?.name ||
                      `source ${copiedFromEntity}`}
                  </a>
                )}
              </>
            )}
            {copiedFromJourneyId && journeysById[copiedFromJourneyId] && (
              <>
                <i>Originally copied from another journey</i>
              </>
            )}
          </Typography>
        </Grid>
      )}
      {updatedAt && (
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="caption">
            Last updated at{" "}
            {format(new Date(updatedAt * 1000), "dd MMMM yyyy HH:mm:ss")}
            {updatedBy &&
              ` by ${updatedBy.firstName} ${updatedBy.lastName} (${updatedBy.email})`}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Timestamps;
