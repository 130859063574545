import DateFnsUtils from "@date-io/date-fns";
import { ErrorBoundary } from "react-error-boundary";
import React, { useEffect, useState, useRef, useCallback } from "react"; // useCallback,
// import { Amplify, Auth } from "aws-amplify";
// import { Authenticator } from "@aws-amplify/ui-react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider as StyledProvider } from "styled-components";

import grey from "@material-ui/core/colors/grey";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import Header from "./components/Header";
import Loader from "./components/shared/Loader";
import MainApp from "./components/MainApp";
import getHostedUiLink from "./amplify-config-prep";
import { AppContextProvider, useAppContext } from "./api/AppContext";
import { ModalsProvider } from "./hooks/useModals";
import { listMyInfo } from "./api";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { DebounceInput } from "react-debounce-input";
// import "@aws-amplify/ui-react/styles.css"; // this applies to the whole app; better to avoid
import "./customAmplifyStyles.css";
import "./customUserPoolSelector.css";
import { getCookie } from "./lib/cookieUtils";

// import { useAuthenticator } from "@aws-amplify/ui-react";

const theme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      editor: {
        border: `1px solid ${grey[400]}`,
        borderRadius: 5,
        marginTop: 5,
        minHeight: "calc(1rem + 20px)",
        padding: "10.5px 14px",
      },
      placeHolder: {
        marginTop: 15,
        padding: "10.5px 14px",
      },
    },
  },
  palette: {
    primary: {
      main: "#6C2BDD",
    },
  },
  typography: {
    body2: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "0.875rem",
    },
    small: {
      fontSize: 10,
    },
  },
});

// const showAdminsSignIn =
//   window.location.pathname === "/da-login" ||
//   window.location.host.startsWith("admins.");

// const userpooloptions = ["userPool0", "userPool1"];

const App = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParamCompany = urlParams.get("compid");

  //== START Special support for localhost
  const expUtcTokenCookie = urlParams.get("expUtcTokenCookie");
  if (expUtcTokenCookie) document.cookie = expUtcTokenCookie;
  const hostname = window.location.hostname;
  const isLocalhost = Boolean(
    hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      hostname === "[::1]" ||
      // for static html from local folder
      hostname === "" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
  const useLocalhostMode =
    isLocalhost && process.env.REACT_APP_DASHBOARD_STAGE !== "prod";
  //== END Special support for localhost

  // const urlParamIsAuthenticated = urlParams.get("isAuthenticated") === "true";
  // console.log("urlParamCompany => ", urlParamCompany);
  const go2loginBtnRef = useRef();

  const [selectedupool, setSelectedUPool] = useState(urlParamCompany || null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [upoolConfigFromBe, setUpoolConfigFromBe] = useState(null);
  const handleChangeUPool = (event) => {
    setSelectedUPool(event.target.value);
  };
  // const [authState, setAuthState] = useState();
  const { setUser, user, authRefresh } = useAppContext();

  const fetchUPoolConfigBaseUrl =
    process.env[
      `REACT_APP_service_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_endpoints_get_pool_config`
    ];

  // in case, coming back to the page through the login flow, you need again the pool config
  // useEffect(() => {
  //   const finalUpoolConfig =
  //     JSON.parse(window.localStorage.getItem("finalUpoolConfig")) || null;
  // }, []);

  // useEffect(() => {
  //   if (upoolConfigFromBe && upoolConfigFromBe.identityPoolId) {
  //     const finalUpoolConfig = getConfig(upoolConfigFromBe);
  //     window.localStorage.setItem(
  //       "finalUpoolConfig",
  //       JSON.stringify(finalUpoolConfig)
  //     );
  //     Amplify.configure(finalUpoolConfig);
  //     // Note: this .configure allows Auth.federatedSignIn to work corretly;
  //     // after going to the login page, the redirectSignIn is used (coming back to this page)
  //     // and the proper configuration needs to be reapplied (will be fetched from localstorage)
  //   }
  // }, [upoolConfigFromBe]);

  useEffect(() => {
    let isCancelled = false;

    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const response = await fetch(
        fetchUPoolConfigBaseUrl.replace("{id}", selectedupool)
      );

      // convert the data to json
      let getPoolConfigReply = null;
      try {
        getPoolConfigReply = await response.json();
      } catch (e) {
        console.log("Err ", e);
      }

      // set state with the result if `isSubscribed` is true
      if (
        !isCancelled &&
        getPoolConfigReply &&
        getPoolConfigReply.identityPoolId
      ) {
        setUpoolConfigFromBe(getPoolConfigReply);
        go2loginBtnRef.current.focus();
        // window.localStorage.setItem(
        //   "upoolConfigKey",
        //   getPoolConfigReply.compid
        // );
      } else {
        setUpoolConfigFromBe(null); // so that also the button will be disabled
      }
    };

    // call the function
    if (selectedupool) {
      fetchData()
        // make sure to catch any error
        .catch(console.error);
    }

    // cancel any future `setUpoolConfigFromBe`
    return () => {
      isCancelled = true;
    };
  }, [fetchUPoolConfigBaseUrl, selectedupool]);

  // const { user: authenticatedUser, authStatus } = useAuthenticator(
  //   (context) => [context.authStatus, context.user]
  // );

  // do it once on application load
  useEffect(() => {
    // let refreshInterval = null;

    // epoch time in ms, of token expiration
    // on login, this token is set, along with the http-only ones
    const expUtcToken = getCookie("expUtcToken");
    if (expUtcToken?.trim() && Number(expUtcToken) - new Date().getTime()) {
      setIsAuthenticated(true);

      // Note: Number(expUtcToken) is already set at be as: expiration less some seconds (to allow for network calls to happen)
      // Note: user may come back to the page after some time (token already there; need to schedule next refresh)
      // Note: in AppContext.js, the refresh reply is checked to schedule the next refresh, each time
      setTimeout(function () {
        authRefresh();
      }, Number(expUtcToken) - new Date().getTime());

      // Currently: just set a refresh every 5 minutes; irrespective of expUtcToken
      // refreshInterval = setInterval(() => authRefresh(), 5 * 60 * 1000);
    } else {
      setIsAuthenticated(false);
    }

    // if (urlParamIsAuthenticated) {
    //   window.localStorage.setItem("isAuthenticated", "true");
    //   setIsAuthenticated(true);
    // } else {
    //   setIsAuthenticated(
    //     window.localStorage.getItem("isAuthenticated") === "true"
    //   );
    // }
    // return () => clearInterval(refreshInterval);
  }, [authRefresh, setIsAuthenticated]); // urlParamIsAuthenticated

  useEffect(() => {
    if (isAuthenticated) {
      listMyInfo().then((userInfo) => {
        setUser((user) => {
          return { ...user, ...userInfo };
        });
      });
    }
  }, [isAuthenticated, setUser]);

  // for Hi-web
  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    if (window.HiInitialized === true) {
      return;
    }

    if (window.Hi === undefined || window.Hi === null) {
      return;
    }

    if (!user.companies?.includes("5706867a-c563-4ca5-b803-c1883c38b640")) {
      return; // make Hi-web available just to Digital Attitude users
    }

    window.HiConfig = {
      customerId: "dac6b5d10df",
      showBadge: false,
      showNotification: true,
      userHash: user.sub,
      userId: user.sub,
    };
    window.Hi.init();
    window.HiInitialized = true;
  }, [isAuthenticated, user]);

  const handleGoToLoginClick = useCallback(() => {
    if (upoolConfigFromBe) {
      window.location.href = getHostedUiLink(
        upoolConfigFromBe,
        useLocalhostMode
      );
    }
  }, [upoolConfigFromBe, useLocalhostMode]);

  useEffect(() => {
    if (urlParamCompany && upoolConfigFromBe) {
      // se era nell'url, auto-click
      handleGoToLoginClick();
    }
  }, [handleGoToLoginClick, urlParamCompany, upoolConfigFromBe]);

  // note: authState can be: unauthenticated, authenticated, configuring
  return (
    <>
      {!user && !isAuthenticated && (
        <div id="selectorWrapper">
          <form>
            <TextField
              autoFocus
              // fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: DebounceInput,
                inputProps: {
                  debounceTimeout: 500,
                },
              }}
              label="Name"
              margin="none"
              placeholder="Company identifier"
              value={selectedupool}
              variant="outlined"
              onChange={handleChangeUPool}
            />
            {/* <select value={selectedupool} onChange={handleChangeUPool}>
              {userpooloptions.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select> */}
          </form>
          <Button
            ref={go2loginBtnRef}
            color="primary"
            disabled={!upoolConfigFromBe}
            variant="contained"
            onClick={handleGoToLoginClick}
          >
            Go to login
          </Button>
        </div>
      )}

      {isAuthenticated && !user && <Loader slot="loading" />}
      {isAuthenticated && user && (
        <>
          <Header />
          <Switch>
            <MainApp />
          </Switch>
        </>
      )}
    </>
  );
};

const AppWithContext = (props) => (
  <ErrorBoundary
    FallbackComponent={() => (
      <Box
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
        width="100vw"
      >
        <Typography variant="subtitle1">
          Something went wrong 😢 Please, reload page
        </Typography>
      </Box>
    )}
  >
    <ThemeProvider theme={theme}>
      <StyledProvider theme={theme}>
        <Router>
          <SnackbarProvider
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            maxSnack={3}
          >
            <AppContextProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ModalsProvider>
                  <App {...props} />
                </ModalsProvider>
              </MuiPickersUtilsProvider>
            </AppContextProvider>
          </SnackbarProvider>
        </Router>
      </StyledProvider>
    </ThemeProvider>
  </ErrorBoundary>
);

export default AppWithContext;
