import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

// import GetAppIcon from "@material-ui/icons/Edit";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import Timestamps from "../Timestamps";

import { getFieldProps, inputProps, multilineInputProps } from "../utils";
import SaveButtons from "../SaveButtons";

const UserPage = ({ formik }) => {
  // journeys, .. next to formik, in params..
  // const handleCurrentJourneyChange = useCallback(
  //   (value) => {
  //     formik.setFieldValue("currentJourney", value);
  //     formik.setFieldTouched("currentJourney", true);
  //   },
  //   [formik]
  // );

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Timestamps
            createdAt={formik.values.createdAt}
            createdBy={formik.values.createdBy}
            updatedAt={formik.values.updatedAt}
            updatedBy={formik.values.updatedBy}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            User for access to content dashboard
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Email"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "email" })}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="First Name"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "firstname" })}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Last Name"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "lastname" })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
                InputProps={multilineInputProps}
                label="Notes"
                margin="none"
                minRows={5}
                // placeholder=""
                variant="outlined"
                {...getFieldProps(formik, {
                  name: "notes",
                })}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SaveButtons formik={formik} />
        </Grid>
      </Grid>
    </Box>
  );
};

const editSchema = yup.object({
  email: yup.string().required().max(50),
  firstname: yup.string().max(50),
  id: yup.string().when("isNew", {
    is: true,
    otherwise: (schema) => schema.required(),
    then: (schema) => schema,
  }),
  lastname: yup.string().max(50),
  notes: yup.string().max(500),
});

const UserPageContainer = () => {
  const {
    fetchUser,
    cpuser,
    isUserLoading,
    updateUser,
    // isJourneysLoading,
    // fetchJourneys,
    // journeys,
  } = useAppContext();

  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { state } = useLocation();

  const userEntity = state?.isNew
    ? {
        email: "",
        firstname: "",
        isNew: true,
        lastname: "",
        notes: "",
      }
    : cpuser;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userEntity,
    onSubmit: async (values) => {
      console.log("values ", JSON.stringify(values));

      const newOrUpdatedUser = await updateUser({
        ...values,
      });

      if (!values.isNew) {
        return;
      }

      history.replace({
        ...location,
        pathname: location.pathname.replace("new", newOrUpdatedUser.id),
        state: null,
      });
    },
    validationSchema: editSchema,
    validator: () => ({}),
  });

  useEffect(() => {
    if (!state?.isNew) {
      fetchUser({ id });
    }
    // fetchJourneys();
  }, [fetchUser, id, state?.isNew]);

  if (isUserLoading) {
    //|| isJourneysLoading
    return <Loader />;
  }

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">User is not found 😢</Typography>
      </Box>
    );
  }

  return <UserPage formik={formik} />; // journeys={journeys}
};

export default UserPageContainer;
