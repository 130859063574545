import * as yup from "yup";
import React from "react";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import { withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";

import Dialog from "./shared/Dialog";
import DialogActions from "./DialogActions";
import OutlinedButton from "./OutlinedButton";
import useModal from "../hooks/useModal";
import { useAppContext } from "../api/AppContext";
import { getFieldProps, inputProps } from "./utils";
import { CircularProgress, MenuItem } from "@material-ui/core";
import Loader from "./shared/Loader";

const validationSchema = yup.object().shape({
  entity: yup.string().required(),
});

// const Autocomplete = withStyles({
//   option: {
//     display: "block",
//     padding: 0,
//     paddingLeft: "0 !important",
//   },
// })(MuiAutocomplete);

const CopyWelfareModal = ({ onConfirm, onClose } = {}) => {
  const { welfareSelectors, isWelfareSelectorsLoading } = useAppContext();

  const { handleConfirm } = useModal({
    onClose,
    onConfirm,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      entity: "",
    },
    onSubmit: async (values) => {
      await handleConfirm(values);
    },
    validationSchema,
    validator: () => ({}),
  });

  if (isWelfareSelectorsLoading) {
    return <Loader />;
  }
  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Select entity
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                select
                InputLabelProps={{ shrink: true }}
                InputProps={inputProps}
                label="Country"
                margin="none"
                variant="outlined"
                {...getFieldProps(formik, { name: "entity" })}
              >
                {welfareSelectors.entities.map((elem, idx) => (
                  <MenuItem key={idx} value={elem.id}>
                    {elem.displayname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.dirty}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Confirm
        </Button>
        <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyWelfareModal;
