/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import { useAppContext } from "../../api/AppContext";
import Loader from "../shared/Loader";
import { useModals } from "../../hooks/useModals";
import useLocalStorage from "../../hooks/useLocalStorage";
import Timestamps from "../Timestamps";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

const A = styled.a`
  text-decoration: none;
`;

/* const tipGroups = 
  [
    {
      id:"123",
      name:"test 1",
      primes:[
        { 
          id:"321321",
          title:"test 2 tip"
        }
      ],
      nudges:[
        {
          id:"321321",
          title:"test 2 nudge"
        }
      ]
    },
    {
      
      id:"321",
      name:"test 2",
      primes:[
        { 
          id:"321321",
          title:"test 2 tip"
        }
      ],
      nudges:[
        {
          id:"321321",
          title:"test 2 nudge"
        }
      ]
    }
  ]
 */
const TipGroups = () => {
  const {
    fetchTipGroups,
    tipGroups,
    isTipGroupsLoading,
    updateTipGroup,
    deleteTipGroup,
    isJourneysLoading,
    fetchJourneys,
    journeys,
    user,
  } = useAppContext();

  const modals = useModals();

  const [expanded, setExpanded] = useLocalStorage(
    `${user.sub}.TipGrouping.Expanded`,
    {},
  );

  const handleExpandClick = useCallback(
    (step) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [step]: !expanded[step],
      }));
    },
    [setExpanded],
  );

  useEffect(() => {
    if (!journeys.length) {
      fetchJourneys();
    }
    fetchTipGroups({ all: true });
  }, []);

    const handleCreate = useCallback(() => {
    modals.openModal(modals.modalTypes.EditTipGroup, {
      isNew: true,
      onConfirm: (tipGroup) =>
        updateTipGroup({
          isNew: true,
          ...tipGroup,
        }),
    });
  }, [modals, updateTipGroup]);

  const handleEdit = useCallback(
    (tipGroup) => (e) => {
      e.stopPropagation();
      modals.openModal(modals.modalTypes.EditTipGroup, {
        item: tipGroup,
        onConfirm: (tipGroup) => updateTipGroup(tipGroup),
      });
    },
    [modals, updateTipGroup]
  );

  const { enqueueSnackbar } = useSnackbar();
  
  const handleEditSemantic = useCallback(
    ({ journeyId, id, type }) =>
      () => {
        const journey = journeys.find(({ id }) => id === journeyId);

        if (!journey) {
          enqueueSnackbar("Journey doesn't exist", { variant: "error" });
          return;
        }
        window.open(
          `/journeys/${journeyId}/${
            journey.locale
          }/${type.toLowerCase()}s/${id}`,
          "_blank"
        );
      },
    [enqueueSnackbar, journeys]
  );

  const handleDelete = useCallback(
      ({ id, name, primes, nudges }) =>
        (e) => {
        e.stopPropagation();

        if (primes?.length || nudges?.length) {
          enqueueSnackbar(
            "This tip group is used in the tips and nudges. Substitute it and then you can delete this Tip Group.",
            {
              variant: "error",
            }
          );
          setExpanded((expanded) => ({
            ...expanded,
            [id]: true,
          }));
          return;
        }

        modals.openConfirmation({
          onConfirm: () => deleteTipGroup({ id }),
          text: `You want to delete tip group "${name}"`,
        });
      },
    [deleteTipGroup, enqueueSnackbar, modals, setExpanded]
  );
  
  if (isTipGroupsLoading || isJourneysLoading) {
    return <Loader />;
  }

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Tip Groups</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                Use this section to add and maintain tip groups that will be
                available across journeys.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={handleCreate}
          >
            Add new tip group
          </Button>
        </Grid>
        <Grid item>
          {tipGroups.map((tipGroup, i) => (
            <Accordion
              key={i}
              expanded={Boolean(expanded[i])}
              onChange={handleExpandClick(i)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  style={{ width: "100%" }}
                >
                  <Grid item style={{ flexGrow: 1 }}>
                    <Grid item>
                      <Typography variant="h6">{tipGroup.name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="textSecondary" variant="body2">
                          Used in {tipGroup.primes?.length || 0} tips and{" "}
                          {tipGroup.nudges?.length || 0} nudges
                        </Typography>
                      </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Tooltip title="Edit">
                          <IconButton onClick={handleEdit(tipGroup)}>
                            <EditIcon />
                          </IconButton> 
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Delete">
                          <IconButton onClick={handleDelete(tipGroup)}>
                            <DeleteIcon />
                          </IconButton> 
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{ width: "100%" }} variant="outlined">
                  <Box p={2}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Grid item>
                          <Timestamps
                            createdAt={tipGroup.createdAt}
                            createdBy={tipGroup.createdBy}
                            updatedAt={tipGroup.updatedAt}
                            updatedBy={tipGroup.updatedBy}
                          />
                        </Grid>
                        <List subheader={<ListSubheader>Tips</ListSubheader>}>
                          {tipGroup.primes?.map(
                            ({ id, title, text, journeyId, journey, type }) => (
                              <ListItem key={id}>
                                <ListItemText
                                  primary={
                                    <>
                                      {journey && (
                                        <>
                                          <A
                                            href={`/journeys/${journey.id}/${journey.locale}`}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            (
                                            {journey.internalName ||
                                              journey.name}
                                            )
                                          </A>{" "}
                                        </>
                                      )}
                                      {title}
                                    </>
                                  }
                                  secondary={text}
                                />
                                 <ListItemSecondaryAction>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={handleEditSemantic({
                                        id,
                                        journeyId,
                                        type,
                                      })}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ),
                          )}
                        </List>
                      </Grid>
                      <Grid item>
                        <List subheader={<ListSubheader>Nudges</ListSubheader>}>
                          {tipGroup.nudges?.map(
                            ({ id, title, text, journeyId, type, journey }) => (
                              <ListItem key={id}>
                                <ListItemText
                                  primary={
                                    <>
                                      {journey && (
                                        <>
                                          <A
                                            href={`/journeys/${journey.id}/${journey.locale}`}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            (
                                            {journey.internalName ||
                                              journey.name}
                                            )
                                          </A>{" "}
                                        </>
                                      )}
                                      {title}
                                    </>
                                  }
                                  secondary={text}
                                />
                                <ListItemSecondaryAction>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={handleEditSemantic({
                                        id,
                                        journeyId,
                                        type,
                                      })}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          )}
                        </List> 
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TipGroups;
