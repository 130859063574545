import React, { useCallback } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { getFieldProps, inputProps } from "../../utils";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFormikContext } from "formik";

const KeyPoints = () => {
  const formik = useFormikContext();

  const buildTextField = (
    formik,
    placeholder,
    name,
    label,
    required = false,
    md = 6,
    xs = 12
  ) => {
    return (
      <Grid item md={md} xs={xs}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={label}
          margin="none"
          placeholder={placeholder}
          required={required}
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </Grid>
    );
  };

  const handleAddKeyPoint = useCallback(() => {
    const defaultKeyPoints = formik.values.text_default?.keyPoints ?? [];
    const englishKeyPoints = formik.values.text_en?.keyPoints ?? [];

    const updatedDefaultValues = [...defaultKeyPoints, ""];
    const updatedEnValues = [...englishKeyPoints, ""];

    formik.setFieldValue("text_default.keyPoints", updatedDefaultValues);
    formik.setFieldValue("text_en.keyPoints", updatedEnValues);
  }, [formik]);

  const handleDeleteKeyPoint = useCallback(
    (i) => () => {
      const newDefaultValue = [...(formik.values.text_default.keyPoints || [])];
      newDefaultValue.splice(i, 1);
      const newEnValue = [...(formik.values.text_en.keyPoints || [])];
      newEnValue.splice(i, 1);
      formik.setFieldValue("text_default.keyPoints", newDefaultValue);
      formik.setFieldValue("text_en.keyPoints", newEnValue);
    },
    [formik]
  );
  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          We are almost at the end! Summarize the key information of the news!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Write down the key points of the news, to summarize the
              information so even those with limited time know what it is about!
            </Typography>
          </Grid>
          {formik.values.text_default?.keyPoints?.map((_, i) => {
            return (
              <React.Fragment key={i}>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: "1rem", marginTop: "1rem" }}
                >
                  {buildTextField(
                    formik,
                    "Key point",
                    `text_default.keyPoints[${i}]`,
                    "Default language",
                    true,
                    5,
                    11
                  )}

                  {buildTextField(
                    formik,
                    "Key point",
                    `text_en.keyPoints[${i}]`,
                    "English",
                    false,
                    5,
                    11
                  )}

                  {formik.values.text_default.keyPoints?.length > 1 && (
                    <Grid item md={2} xs={1}>
                      <IconButton onClick={handleDeleteKeyPoint(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddKeyPoint}
            >
              Add key point
            </Button>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default KeyPoints;
