import React, { useCallback, useState } from "react";

import Alert from "@material-ui/lab/Alert";

const AlertWithShowMore = ({ children, ...props }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const showMore = useCallback(() => {
    setIsShowMore((isShowMore) => !isShowMore);
  }, []);

  return <Alert {...props}>{children({ isShowMore, showMore })}</Alert>;
};

export default AlertWithShowMore;
