import React, { useCallback, useMemo } from "react";
import Highlighter from "react-highlight-words";
import keyBy from "lodash/keyBy";

import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useAppContext } from "../../api/AppContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

const filter = createFilterOptions();

const UserStatePathSelect = ({
  value,
  pathValue,
  onChange,
  helperText,
  error,
  isLoading,
  userStatePaths,
  ...props
}) => {
  const { updateUserStatePath } = useAppContext();
  const paths = useMemo(
    () =>
      [
        {
          value: "currentJourney",
          title: "User's Journey",
        },
        {
          value: "locale",
          title: "User's preferred language",
        },
        ...userStatePaths.map(({ name, displayName }) => ({
          title: displayName,
          value: name,
        })),
      ].sort(function (a, b) {
        const x = a.title.toLowerCase();
        const y = b.title.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      }),
    [userStatePaths]
  );
  const pathsByValue = useMemo(() => keyBy(paths, "value"), [paths]);

  const handleChange = useCallback(
    async (e, value, reason) => {
      if (!["select-option"].includes(reason)) {
        return;
      }

      if (value.isNew) {
        const newUserStatePath = await updateUserStatePath({
          displayName: value.value,
          isNew: true,
        });
        onChange?.(newUserStatePath.value);
      } else {
        onChange?.(value.value);
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableClearable
      fullWidth
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        const isExisting = options.some((option) => inputValue === option.path);
        if (isExisting || !inputValue) {
          return filtered;
        }

        return [
          ...filtered,
          {
            value: inputValue,
            title: `Create new "${inputValue}"`,
            isNew: true,
          },
        ];
      }}
      getOptionLabel={(option) => option.title}
      loading={isLoading}
      options={paths}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isLoading ? (
              <InputAdornment position="end">
                {isLoading && <CircularProgress size={25} />}
              </InputAdornment>
            ) : (
              params.InputProps?.startAdornment
            ),
          }}
          error={error}
          helperText={helperText}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={option.title}
            >
              {option.title}
            </Highlighter>
          }
        />
      )}
      value={pathsByValue[value] ?? null}
      onChange={handleChange}
    />
  );
};

export default UserStatePathSelect;
