import React, { useCallback } from "react";
import cloneDeep from "lodash/cloneDeep";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { getFieldProps, inputProps } from "../utils";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import useLocalStorage from "../../hooks/useLocalStorage";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    "&.Mui-expanded": {
      marginBottom: "1rem",
      marginTop: 0,
    },
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "1rem",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: "4rem",
  },
  content: {
    "&.Mui-expanded": {
      margin: 0,
    },
    wordBreak: "break-all",
  },
})(MuiAccordionSummary);

const SubTitle = withStyles({
  root: {
    fontWeight: "bold",
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
})(Typography);

const Title = withStyles({
  root: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
})(Typography);

const MessagesRules = ({
  formik,
  isDisabled,
  globalCheckboxLabel = "",
  nudgeCheckboxLabel = "",
  days,
  defaultMaxDailyOccurrences = 6,
  defaultMinOccurrencesInterval = 1,
  defaultMaxWeeklyOccurrences,
  defaultMaxMonthlyOccurrences,
  maxDailyOccurrencesLabel = "",
  maxMonthlyOccurrencesLabel = "",
  maxOccurrencesHelperText = "",
  maxWeeklyOccurrencesLabel = "",
  minOccurrencesIntervalLabel = "",
  minOccurrencesIntervalHelperText = "",
  size = "medium",
  expandedKey,
  isGeneralInfoMessages,
}) => {
  const handleAdd = useCallback(() => {
    formik.setFieldValue("tiers", [
      ...formik.values.tiers.slice(0, -1),
      {
        ...formik.values.tiers.slice(-1)[0],
        to: days,
      },
      {
        from: formik.values.tiers.slice(-1)[0].to || days,
        maxDailyOccurrences: defaultMaxDailyOccurrences,
        maxMonthlyOccurrences: defaultMaxMonthlyOccurrences,
        maxWeeklyOccurrences: defaultMaxWeeklyOccurrences,
        minOccurrencesInterval: defaultMinOccurrencesInterval,
      },
    ]);
  }, [
    days,
    defaultMaxDailyOccurrences,
    defaultMinOccurrencesInterval,
    defaultMaxMonthlyOccurrences,
    defaultMaxWeeklyOccurrences,
    formik,
  ]);

  const handleDelete = useCallback(
    (i) => () => {
      const newValue = [...(formik.values.tiers || [])];

      if (i === 0) {
        return;
      }

      newValue[i - 1].to = newValue[i + 1]?.from || undefined;
      newValue.splice(i, 1);

      formik.setFieldValue("tiers", newValue);
    },
    [formik]
  );

  const handleToChange = useCallback(
    (i) => (e) => {
      const value = parseInt(e.target.value) || 0;

      const newValue = cloneDeep(formik.values.tiers);
      if (!newValue[i + 1]) {
        return;
      }

      newValue[i + 1].from = Math.min(value + 1, [i + 1].to || days);
      newValue[i].to = Math.min(value, ([i + 1].to || days) - 1);

      formik.setFieldValue("tiers", newValue);
      formik.setFieldTouched(`tiers.${i + 1}.from`);
      formik.setFieldTouched(`tiers.${i}.to`);
    },
    [formik]
  );

  const handleFromChange = useCallback(
    (i) => (e) => {
      const value = parseInt(e.target.value) || 0;
      const newValue = cloneDeep(formik.values.tiers);
      if (!newValue[i - 1]) {
        return;
      }

      newValue[i - 1].to = Math.max(value - 1, newValue[i - 1].from);
      newValue[i].from = Math.max(value, newValue[i - 1].to + 1);

      formik.setFieldValue("tiers", newValue);
      formik.setFieldTouched(`tiers.${i - 1}.to`);
      formik.setFieldTouched(`tiers.${i}.from`);
    },
    [formik]
  );

  return (
    <Grid container spacing={2}>
      {isGeneralInfoMessages ? (
        <GeneralInfoMessages
          days={days}
          expandedKey={expandedKey}
          formik={formik}
          globalCheckboxLabel={globalCheckboxLabel}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleFromChange={handleFromChange}
          handleToChange={handleToChange}
          isDisabled={isDisabled}
          maxDailyOccurrencesLabel={maxDailyOccurrencesLabel}
          maxMonthlyOccurrencesLabel={maxMonthlyOccurrencesLabel}
          maxOccurrencesHelperText={maxOccurrencesHelperText}
          maxWeeklyOccurrencesLabel={maxWeeklyOccurrencesLabel}
          minOccurrencesIntervalHelperText={minOccurrencesIntervalHelperText}
          minOccurrencesIntervalLabel={minOccurrencesIntervalLabel}
          nudgeCheckboxLabel={nudgeCheckboxLabel}
          size={size}
        />
      ) : (
        <NudgeMessages
          days={days}
          formik={formik}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleFromChange={handleFromChange}
          handleToChange={handleToChange}
          isDisabled={isDisabled}
          maxDailyOccurrencesLabel={maxDailyOccurrencesLabel}
          maxOccurrencesHelperText={maxOccurrencesHelperText}
          minOccurrencesIntervalHelperText={minOccurrencesIntervalHelperText}
          minOccurrencesIntervalLabel={minOccurrencesIntervalLabel}
          size={size}
        />
      )}
    </Grid>
  );
};

const GeneralInfoMessages = ({
  formik,
  isDisabled,
  globalCheckboxLabel = "",
  nudgeCheckboxLabel = "",
  days,
  maxDailyOccurrencesLabel = "",
  maxMonthlyOccurrencesLabel = "",
  maxOccurrencesHelperText = "",
  maxWeeklyOccurrencesLabel = "",
  minOccurrencesIntervalLabel = "",
  minOccurrencesIntervalHelperText = "",
  size = "medium",
  expandedKey,
  handleFromChange,
  handleToChange,
  handleDelete,
  handleAdd,
}) => {
  const transformTimeToHours = (value) => {
    value = parseFloat(value) || 0;
    if (Number.isInteger(value)) {
      value = Math.round(value / 60, 1);
    }
    return value;
  };

  const handleMinOccurrencesIntervalChange = useCallback(
    (key) => (e) => {
      let value = parseFloat(e.target.value) || 0;
      if (Number.isInteger(value)) {
        value = value * 60;
      }
      formik.setFieldValue(`${key}.minOccurrencesInterval`, value);
      formik.setFieldTouched(`${key}.minOccurrencesInterval`);
    },
    [formik]
  );

  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = useCallback(
    (key, fieldName) => (e) => {
      let value = null;
      if (e.target.value) {
        value = parseInt(e.target.value) || 0;
        formik.setFieldValue(`${key}.${fieldName}`, value);
        formik.setFieldTouched(`${key}.${fieldName}`);
      } else {
        formik.setFieldValue(`${key}.${fieldName}`, undefined);
        formik.setFieldTouched(`${key}.${fieldName}`);
      }
    },
    [formik]
  );

  const [expanded, setExpanded] = useLocalStorage(expandedKey, {});

  const handleExpandedChange = useCallback(
    (id) => () => {
      setExpanded((expanded) => ({
        ...expanded,
        [id]: !expanded[id],
      }));
    },
    [setExpanded]
  );

  const buildTierDetailsFields = (
    maxDailyOccurrences,
    minOccurrencesInterval,
    key
  ) => {
    return (
      <AccordionDetails>
        <Grid key={key} item xs={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <SubTitle>How many messages in this tier? </SubTitle>
            </Grid>
            <Grid container spacing={2} style={{ marginLeft: "0rem" }}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...inputProps,
                    inputProps: {
                      ...inputProps.inputProps,
                      min: 1,
                    },
                  }}
                  label={maxDailyOccurrencesLabel}
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: `${key}.maxDailyOccurrences`,
                  })}
                  size={size}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...inputProps,
                    inputProps: {
                      ...inputProps.inputProps,
                      min: 1,
                    },
                  }}
                  label={maxWeeklyOccurrencesLabel}
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: `${key}.maxWeeklyOccurrences`,
                  })}
                  size={size}
                  onChange={handleChange(key, "maxWeeklyOccurrences")}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...inputProps,
                    inputProps: {
                      ...inputProps.inputProps,
                      min: 1,
                    },
                  }}
                  label={maxMonthlyOccurrencesLabel}
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: `${key}.maxMonthlyOccurrences`,
                  })}
                  size={size}
                  onChange={handleChange(key, "maxMonthlyOccurrences")}
                />
              </Grid>
            </Grid>
            <Grid item spacing={1}>
              <Typography color="textSecondary" variant="body2">
                {maxOccurrencesHelperText.replace(
                  "${value}",
                  maxDailyOccurrences
                )}{" "}
              </Typography>
            </Grid>
            <Grid item>
              <SubTitle>How much time between messages? </SubTitle>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "0rem", alignItems: "center" }}
            >
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...inputProps,
                    inputProps: {
                      ...inputProps.inputProps,
                      min: 0,
                    },
                  }}
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: `${key}.minOccurrencesInterval`,
                  })}
                  size={size}
                  value={transformTimeToHours(
                    getFieldProps(formik, {
                      disabled: isDisabled,
                      name: `${key}.minOccurrencesInterval`,
                    }).value
                  )}
                  onChange={handleMinOccurrencesIntervalChange(key)}
                />
              </Grid>
              <Grid item>
                <SubTitle>{minOccurrencesIntervalLabel}</SubTitle>
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {minOccurrencesIntervalHelperText.replace(
                  "${value}",
                  transformTimeToHours(minOccurrencesInterval)
                )}{" "}
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, flexGrow: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps(formik, {
                      name: `${key}.extendGlobalRulesToTips`,
                    })}
                    checked={
                      getFieldProps(formik, {
                        name: `${key}.extendGlobalRulesToTips`,
                      }).value
                    }
                    color="primary"
                  />
                }
                label={
                  key === "postJourneyTier"
                    ? "Extend to scheduled tips"
                    : globalCheckboxLabel
                }
              />
            </Grid>
            <Grid item style={{ flex: 1, flexGrow: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps(formik, {
                      name: `${key}.dontShowNudges`,
                    })}
                    checked={
                      getFieldProps(formik, {
                        name: `${key}.dontShowNudges`,
                      }).value
                    }
                    color="primary"
                  />
                }
                label={nudgeCheckboxLabel}
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    );
  };

  return (
    <>
      {formik.values.tiers?.map(
        ({ from, to, maxDailyOccurrences, minOccurrencesInterval }, i) => (
          <>
            <Accordion
              key={i}
              TransitionProps={{ unmountOnExit: true }}
              expanded={Boolean(expanded[i])}
              onChange={handleExpandedChange(i)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  style={{ flexWrap: "nowrap", width: "100%" }}
                >
                  <Grid
                    item
                    style={{ alignSelf: "center", flexGrow: 1 }}
                    xs={3}
                  >
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      style={
                        expanded[i]
                          ? { marginTop: "1rem", marginBottom: "1rem" }
                          : {}
                      }
                    >
                      <Grid item>
                        <Title>Tier {i + 1}:</Title>
                      </Grid>
                      <Grid item>
                        {expanded[i] ? (
                          <Grid key={i} item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item style={{ flex: 1 }}>
                                <TextField
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    inputProps: {
                                      max: to || days,
                                      min:
                                        (formik.values.tiers[i - 1]?.from ||
                                          0) + 1,
                                    },
                                  }}
                                  label="From day"
                                  type="number"
                                  variant="outlined"
                                  {...getFieldProps(formik, {
                                    disabled: isDisabled || i === 0,
                                    name: `tiers.${i}.from`,
                                  })}
                                  size={size}
                                  onChange={handleFromChange(i)}
                                  onClick={handleInputClick}
                                />
                              </Grid>
                              <Grid item style={{ flex: 1 }}>
                                <TextField
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    inputProps: {
                                      max:
                                        (formik.values.tiers[i + 1]?.to ||
                                          days) - 1,
                                      min: from || 0,
                                    },
                                  }}
                                  label="To day"
                                  type="number"
                                  variant="outlined"
                                  {...getFieldProps(formik, {
                                    disabled:
                                      isDisabled ||
                                      i === formik.values.tiers.length - 1,
                                    name: `tiers.${i}.to`,
                                    defaultValue: days,
                                  })}
                                  size={size}
                                  onChange={handleToChange(i)}
                                  onClick={handleInputClick}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography color="textSecondary" variant="body2">
                            from day {from} to day {to || days}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "center",
                    }}
                    xs={1}
                  >
                    {i !== 0 ? (
                      <IconButton onClick={handleDelete(i)}>
                        <DeleteIcon
                          fontSize={size === "medium" ? "default" : size}
                        />
                      </IconButton>
                    ) : (
                      <Box minWidth={size === "medium" ? 48 : 30} />
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              {buildTierDetailsFields(
                maxDailyOccurrences,
                minOccurrencesInterval,
                `tiers.${i}`
              )}
            </Accordion>
          </>
        )
      )}
      <Grid item xs={12}>
        <Button
          color="primary"
          disabled={isDisabled}
          size={size}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleAdd}
        >
          Add tier
        </Button>
      </Grid>
      {[formik.values.postJourneyTier].map(
        ({ maxDailyOccurrences, minOccurrencesInterval }, i) => (
          <>
            <Accordion
              key={i}
              TransitionProps={{ unmountOnExit: true }}
              expanded={Boolean(expanded["last"])}
              style={{ marginTop: "1rem" }}
              onChange={handleExpandedChange("last")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  style={{ flexWrap: "nowrap", width: "100%" }}
                >
                  <Grid
                    item
                    style={{ alignSelf: "center", flexGrow: 1 }}
                    xs={3}
                  >
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Title>Post tier:</Title>
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary" variant="body2">
                          Once the journey is finished
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              {buildTierDetailsFields(
                maxDailyOccurrences,
                minOccurrencesInterval,
                `postJourneyTier`
              )}
            </Accordion>
          </>
        )
      )}
    </>
  );
};

const NudgeMessages = ({
  formik,
  isDisabled,
  days,
  maxDailyOccurrencesLabel = "",
  maxOccurrencesHelperText = "",
  minOccurrencesIntervalLabel = "",
  minOccurrencesIntervalHelperText = "",
  size = "medium",
  handleFromChange,
  handleToChange,
  handleDelete,
  handleAdd,
}) => {
  const buildExtraConditions = (
    maxDailyOccurrences,
    minOccurrencesInterval,
    key
  ) => {
    return (
      <>
        <Grid item style={{ flex: 1, flexGrow: 3 }}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...inputProps,
              inputProps: {
                ...inputProps.inputProps,
                min: 0,
              },
            }}
            label={minOccurrencesIntervalLabel}
            type="number"
            variant="outlined"
            {...getFieldProps(formik, {
              disabled: isDisabled,
              helperText: minOccurrencesIntervalHelperText.replace(
                "${value}",
                minOccurrencesInterval
              ),
              name: `${key}.minOccurrencesInterval`,
            })}
            size={size}
          />
        </Grid>
        <Grid item style={{ flex: 1, flexGrow: 3 }}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...inputProps,
              inputProps: {
                ...inputProps.inputProps,
                max: 100,
                min: 1,
              },
            }}
            label={maxDailyOccurrencesLabel}
            type="number"
            variant="outlined"
            {...getFieldProps(formik, {
              disabled: isDisabled,
              helperText: maxOccurrencesHelperText.replace(
                "${value}",
                maxDailyOccurrences
              ),
              name: `${key}.maxDailyOccurrences`,
            })}
            size={size}
          />
        </Grid>
      </>
    );
  };
  return (
    <>
      {formik.values.tiers?.map(
        ({ from, to, maxDailyOccurrences, minOccurrencesInterval }, i) => (
          <Grid key={i} item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  style={{ lineHeight: size === "medium" ? "56px" : "30px" }}
                  variant="subtitle1"
                >
                  Tier {i + 1}:
                </Typography>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      max: to || days,
                      min: (formik.values.tiers[i - 1]?.from || 0) + 1,
                    },
                  }}
                  label="From day"
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled || i === 0,
                    name: `tiers.${i}.from`,
                  })}
                  size={size}
                  onChange={handleFromChange(i)}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      max: (formik.values.tiers[i + 1]?.to || days) - 1,
                      min: from || 0,
                    },
                  }}
                  label="To day"
                  type="number"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled:
                      isDisabled || i === formik.values.tiers.length - 1,
                    name: `tiers.${i}.to`,
                    defaultValue: days,
                  })}
                  size={size}
                  onChange={handleToChange(i)}
                />
              </Grid>
              <>
                {buildExtraConditions(
                  maxDailyOccurrences,
                  minOccurrencesInterval,
                  `tiers.${i}`
                )}
              </>

              <Grid item>
                {i !== 0 ? (
                  <IconButton onClick={handleDelete(i)}>
                    <DeleteIcon
                      fontSize={size === "medium" ? "default" : size}
                    />
                  </IconButton>
                ) : (
                  <Box minWidth={size === "medium" ? 48 : 30} />
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      )}
      <Grid item xs={12}>
        <Button
          color="primary"
          disabled={isDisabled}
          size={size}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={handleAdd}
        >
          Add tier
        </Button>
      </Grid>
      {[formik.values.postJourneyTier]?.map(
        ({ maxDailyOccurrences, minOccurrencesInterval }, i) => (
          <Grid key={i} item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  style={{ lineHeight: size === "medium" ? "56px" : "30px" }}
                  variant="subtitle1"
                >
                  Post tier:
                </Typography>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Typography color="textSecondary" variant="body2">
                  Once the journey is finished
                </Typography>
              </Grid>
              <>
                {buildExtraConditions(
                  maxDailyOccurrences,
                  minOccurrencesInterval,
                  `postJourneyTier`
                )}
              </>
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default MessagesRules;
