export const tmpFonts = [
  {
    description: "Reset template default",
    fntId: "",
    name: "Template default",
    suggestedPairing: ["fnt-f23ee053-eb2e-4003-adac-77c170d40b7e"],
    withFallback: "",
  },
  {
    description: "Barlow",
    fntId: "fnt-ce52e406-3852-45cf-bdbc-54352304674d",
    name: "Barlow",
    withFallback: "Barlow, sans-serif",
  },
  {
    description: "Lora",
    fntId: "fnt-66fe34b1-27f9-403c-925d-2ddd736d7e62",
    name: "Lora",
    suggestedPairing: ["fnt-f23ee053-eb2e-4003-adac-77c170d40b7e"],
    withFallback: "Lora, serif",
  },
  {
    description: "Merriweather",
    fntId: "fnt-d740a8b9-aed1-4b10-b6e9-71ffed762f5c",
    name: "Merriweather",
    withFallback: "Merriweather, serif",
  },
  {
    description: "Open Sans",
    fntId: "fnt-f23ee053-eb2e-4003-adac-77c170d40b7e",
    name: "Open Sans",
    withFallback: '"Open Sans", sans-serif',
  },
  {
    description: "Source Sans 3 (Pro)",
    fntId: "d6cac77d-37dd-47fc-a40e-fd6e6816d179",
    name: "Source Sans 3 (Pro)",
    withFallback: '"Source Sans 3", sans-serif',
  },
  {
    description: "Playfair Display",
    fntId: "e9ea62ea-d76e-403d-b7cd-caca2de64ce1",
    name: "Playfair Display",
    withFallback: '"Playfair Display", serif',
  },
  {
    description: "Titillium Web",
    fntId: "6ed25113-f33c-4fab-8909-02c0a4ef7792",
    name: "Titillium Web",
    withFallback: '"Titillium Web", sans-serif',
  },
  {
    description: "Passion One",
    fntId: "fnt-9a62bd38-1827-49a2-8f70-6a65ffe474f3",
    name: "Passion One",
    withFallback: '"Passion One", cursive',
  },
  {
    description: "Segoe UI",
    fntId: "fnt-e1c0233f-b32e-4af7-bbc9-c1a8b6e1f37d",
    name: "Segoe UI",
    withFallback: '"Segoe UI", Helvetica, Arial, sans-serif',
  },
  {
    description: "Maven Pro (Similar to DaxlinePro)",
    fntId: "fnt-c3b2f8f0-47ff-4df4-a268-8332934b08d9",
    name: "Maven Pro (Similar to DaxlinePro)",
    withFallback: '"Maven Pro", sans-serif',
  },
  {
    description: "Liberation Sans (Similar to Arial)",
    fntId: "fnt-b1942457-3934-46f0-afe4-f62e3b600efa",
    name: "Liberation Sans (Similar to Arial)",
    withFallback: '"Liberation Sans", sans-serif',
  },
  {
    description: "Noto Serif (Similar to Mercury)",
    fntId: "fnt-220f82dd-4b10-4aad-bb8d-842465360368",
    name: "Noto Serif (Similar to Mercury)",
    withFallback: '"Noto Serif", serif, sans-serif',
  },
  {
    description: "Roboto (Similar to Helvetica neue)",
    fntId: "fnt-9468b9c2-13a7-41e8-8103-e5f7bf2bb52e",
    name: "Roboto (Similar to Helvetica neue)",
    withFallback: '"Roboto", sans-serif',
  },
  {
    description: "Lato (Similar to Univers)",
    fntId: "fnt-0d020494-8ed5-4f04-ba96-06715ed24e5f",
    name: "Lato (Similar to Univers)",
    withFallback: '"Lato", sans-serif',
  },
];
