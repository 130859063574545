import React, { useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import Highlighter from "react-highlight-words";

import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import keyBy from "lodash/keyBy";

const renderers = {
  paragraph: "span",
};

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.id} ${option.name}`, // ${option.name} ${option.internalName} ${option.id}
});

const StimuliSelect = ({
  stimuli,
  value,
  onChange,
  multiple,
  helperText,
  error,
  disableClearable,
  ...props
}) => {
  const stimuliById = useMemo(() => keyBy(stimuli, "id"), [stimuli]);
  const handleChange = useCallback(
    (e, value, reason) => {
      if (!["select-option", "remove-option", "clear"].includes(reason)) {
        return;
      }

      onChange?.(multiple ? value.map(({ id }) => id) : value?.id ?? null);
    },
    [multiple, onChange]
  );

  return (
    <Autocomplete
      fullWidth
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        return `${option.name}`;
      }} // internalName || option.name
      multiple={multiple}
      options={stimuli}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputLabelProps={{ shrink: true, ...params.InputLabelProps }}
          error={error}
          helperText={helperText}
          variant="outlined"
        />
      )}
      renderOption={(option, { inputValue }) => (
        <ListItemText
          primary={
            <Highlighter
              searchWords={[inputValue || ""]}
              textToHighlight={`${option.name}`}
            >
              <ReactMarkdown renderers={renderers}>
                `${option.name}`
              </ReactMarkdown>
            </Highlighter>
          }
        />
      )}
      value={
        (multiple
          ? (value || []).map((id) => stimuliById[id]).filter(Boolean)
          : stimuliById[value]) ?? null
      }
      onChange={handleChange}
    />
  );
};

export default StimuliSelect;
