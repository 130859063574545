import * as yup from "yup";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "../shared/Dialog";
import OutlinedButton from "../OutlinedButton";
import DialogActions from "../DialogActions";
import { getFieldProps, inputProps } from "../utils";
import useModal from "../../hooks/useModal";
import { contentTypesConfig } from "./contentTypes";

const SwitchTypography = withStyles({
  root: {
    fontSize: "0.8125rem",
  },
})(Typography);

const positions = {
  "bottom-center": "Bottom Center",
  "bottom-left": "Bottom Left",
  "bottom-right": "Bottom Right",
  "center-left": "Center Left",
  "center-right": "Center Right",
  notification_area: "Notification Area",
  screen_center: "Screen Center",
  "top-center": "Top Center",
  "top-left": "Top Left",
  "top-right": "Top Right",
};

const browserTriggerValidationSchema = yup.object().shape({
  isAnd: yup.boolean(),
  name: yup.string().required(),
  title: yup.string(),
  uiConfig: yup.object().shape({
    anchor: yup.mixed().oneOf(Object.keys(positions)),
    maxDailyOccurrences: yup
      .number()
      .min(0, "Must be greater than or equal to 0"),
    minOccurrencesInterval: yup
      .number()
      .min(0, "Must be greater than or equal to 0"),
    timeout: yup.number().min(0, "Must be greater than or equal to 0"),
  }),
  windowClassName: yup
    .string()
    .url("URL must be a valid URL")
    .required("URL is a required field"),
  windowName: yup.string(),
});

const appTriggerValidationSchema = yup.object().shape({
  config: yup.mixed().test("config", "Invalid config", (value, testContext) => {
    if (!testContext.parent?.isNew && !value) {
      return true;
    }

    try {
      const json = atob(value);
      const triggerConfig = JSON.parse(json);

      return Boolean(
        triggerConfig.elementName?.length &&
          (triggerConfig.elementNameSubstring?.length ||
            triggerConfig.windowName?.length)
      );
    } catch (e) {
      return false;
    }
  }),
  isAnd: yup.boolean(),
  name: yup.string().required(),
  title: yup.string(),
  uiConfig: yup.object().shape({
    anchor: yup.mixed().oneOf(Object.keys(positions)),
    maxDailyOccurrences: yup
      .number()
      .min(0, "Must be greater than or equal to 0"),
    minOccurrencesInterval: yup
      .number()
      .min(0, "Must be greater than or equal to 0"),
    timeout: yup.number().min(0, "Must be greater than or equal to 0"),
  }),
});

const parseConfig = (config) => {
  try {
    const json = atob(config);
    const triggerConfig = JSON.parse(json);

    if (
      (!triggerConfig.elementName?.length &&
        !triggerConfig.elementNameSubstring?.length) ||
      !triggerConfig.windowName?.length
    ) {
      return null;
    }

    return triggerConfig;
    // eslint-disable-next-line no-empty
  } catch (e) {
    return null;
  }
};

const CreateContextualTriggerModal = ({
  onClose,
  onConfirm,
  contentType,
  trigger,
}) => {
  const [type, setType] = useState(trigger?.type || "browser");
  const [tab, setTab] = useState("general");

  const handleTabChange = useCallback((_, value) => {
    if (!value) {
      return;
    }
    setTab(value);
  }, []);

  const handleIsBrowserChange = useCallback((e) => {
    setType(e.target.checked ? "app" : "browser");
  }, []);

  const { handleConfirm, isLoading } = useModal({ onClose, onConfirm });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: trigger || {
      contentType,
      description: "-",
      isAnd: false,
      isNew: true,
      name: "",
      uiConfig: {
        anchor: "screen_center",
        maxDailyOccurrences: 1,
        minOccurrencesInterval: 0,
        timeout: 30,
        ...contentTypesConfig[contentType]?.uiConfig,
      },
      windowClassName: "",
      windowName: "",
      ...(type === "browser" && {
        elementClassName: "elementClassName",
        elementControlType: 0,
        elementName: "elementName",
      }),
      ...(type === "app" && {
        config: "",
      }),
    },
    onSubmit: async (values, props) => {
      if (!formik.dirty) {
        return;
      }
      const { config, ...restValues } = values;
      return handleConfirm({
        type,
        ...restValues,
        ...(type === "app" && parseConfig(config)),
      });
    },
    validationSchema:
      type === "browser"
        ? browserTriggerValidationSchema
        : appTriggerValidationSchema,
    validator: () => ({}),
  });

  useEffect(() => {
    if (type !== "app") {
      return;
    }
    navigator.clipboard.readText().then((clipboard) => {
      const parserConfig = parseConfig(clipboard);

      if (!parserConfig) {
        return;
      }

      formik.setFieldValue("config", clipboard);
      formik.setFieldTouched("config", true);
    });
  }, [type]);

  return (
    <Dialog fullWidth open maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          Provide content information
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <ToggleButtonGroup
                    exclusive
                    size="small"
                    value={tab}
                    onChange={handleTabChange}
                  >
                    <ToggleButton selected={tab === "general"} value="general">
                      General
                    </ToggleButton>
                    <ToggleButton
                      selected={tab === "advanced"}
                      value="advanced"
                    >
                      Advanced
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    component="label"
                    spacing={1}
                  >
                    <Grid item>
                      <SwitchTypography
                        color={type === "browser" ? "primary" : "default"}
                        variant="button"
                      >
                        Browser
                      </SwitchTypography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={type === "app"}
                        color="primary"
                        onChange={handleIsBrowserChange}
                      />
                    </Grid>
                    <Grid item>
                      <SwitchTypography
                        color={type === "app" ? "primary" : "default"}
                        variant="button"
                      >
                        App
                      </SwitchTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {tab === "general" && (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={inputProps}
                    label="Name"
                    margin="none"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "name",
                    })}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    label="Screen position"
                    margin="none"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "uiConfig.anchor",
                    })}
                  >
                    {Object.entries(positions).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                        {value ===
                          contentTypesConfig[formik.values.contentType]
                            ?.uiConfig?.anchor && " (recommended)"}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {type === "app" && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps}
                      label="Config from Triggers Creation App"
                      margin="none"
                      type="password"
                      variant="outlined"
                      {...getFieldProps(formik, { name: "config" })}
                    />
                  </Grid>
                )}
                {type === "browser" && (
                  <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={inputProps}
                          label="Starting point URL"
                          margin="none"
                          variant="outlined"
                          {...getFieldProps(formik, {
                            helperText: "Use * for wildcard",
                            name: "windowClassName",
                          })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          component="label"
                          spacing={1}
                        >
                          <Grid item>OR</Grid>
                          <Grid item>
                            <Switch
                              checked={formik.values.isAnd}
                              color="primary"
                              name="isAnd"
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          <Grid item>AND</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={inputProps}
                          label="Page title"
                          margin="none"
                          variant="outlined"
                          {...getFieldProps(formik, {
                            helperText: "Use * for wildcard",
                            name: "windowName",
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...inputProps,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    label="Max occurrences per day"
                    margin="none"
                    type="number"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "uiConfig.maxDailyOccurrences",
                    })}
                  />
                </Grid>
              </>
            )}
            {tab === "advanced" && (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...inputProps,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    label="Minimum interval between occurrences (minutes)"
                    margin="none"
                    type="number"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "uiConfig.minOccurrencesInterval",
                    })}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...inputProps,
                      inputProps: {
                        min: 0,
                      },
                    }}
                    label="Close after timeout? (seconds)"
                    margin="none"
                    type="number"
                    variant="outlined"
                    {...getFieldProps(formik, {
                      name: "uiConfig.timeout",
                    })}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isLoading || !formik.dirty}
          endIcon={isLoading && <CircularProgress size={25} />}
          variant="contained"
          onClick={formik.handleSubmit}
        >
          {formik.values.isNew ? "Next" : "Save"}
        </Button>
        <OutlinedButton color="primary" onClick={onClose}>
          Cancel
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContextualTriggerModal;
