import ImageUploadBase from "./shared/ImageUpload";
import { uploadFile } from "../api";
import { useCallback } from "react";

const ImageUpload = ({ onChange, customPathInfo, ...props }) => {
  const handleUpload = useCallback(
    async (args) => {
      if (!args.body) {
        if (typeof onChange === "function") {
          onChange({ url: "" });
        }
        return;
      }
      const { url } = await uploadFile({
        ...args,
        customPathInfo,
      });
      if (typeof onChange === "function") {
        onChange({ url });
      }
    },
    [onChange, customPathInfo]
  );
  return <ImageUploadBase {...props} onChange={handleUpload} />;
};

export default ImageUpload;
