import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const TitleTypography = withStyles({
  root: {
    fontSize: "1.5rem",
  },
})(Typography);

export default TitleTypography;
