import React, { Fragment } from "react";
import { useFormik } from "formik";

import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import MuiChip from "@material-ui/core/Chip";

import Dialog from "../shared/Dialog";
import OutlinedButton from "../OutlinedButton";
import DialogActions from "../DialogActions";

const components = [
  {
    description:
      "Use this feature to onboard users on a new technology, feature, page or upcoming project.",
    name: "Onboarding",
    value: "onboarding",
  },
  {
    description:
      "Use this component to promote certain features, functionalities or best behaviors. This component can be combined with an additional link, video, pdf, document for interested users.",
    name: "Feature Promotion",
    value: "feature_promotion",
  },
  {
    description:
      "Use this component to guide users in unintuitive and complex situations, or when users are not performing things often.",
    name: "Step-By-Step Guide",
    value: "step_guide",
  },
  {
    description: "",
    label: "Advanced",
    name: "Custom",
    value: "custom",
  },
];

const Chip = withStyles({
  label: {
    fontSize: "0.625rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
})(MuiChip);

const CreateContextualContentModal = ({ onClose, onConfirm }) => {
  const formik = useFormik({
    initialValues: {
      contentType: "onboarding",
    },
    onSubmit: onConfirm,
  });

  return (
    <Dialog fullWidth open maxWidth="md">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          What would you like to create?
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup
              name="contentType"
              value={formik.values.contentType}
              onChange={formik.handleChange}
            >
              {components.map(({ description, name, value, label }) => (
                <Fragment key={name}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={
                      <Box pb={2} pt={2}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Typography color="textPrimary" variant="h6">
                              {name}
                            </Typography>
                          </Grid>
                          {label && (
                            <Grid item>
                              <Chip
                                color="secondary"
                                label={label}
                                size="small"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Typography color="textSecondary" variant="body2">
                          {description}
                        </Typography>
                      </Box>
                    }
                    value={value}
                  />
                  <Divider />
                </Fragment>
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          Next
        </Button>
        <OutlinedButton color="primary" onClick={onClose}>
          Cancel
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContextualContentModal;
