import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const Accordion = withStyles({
  root: {
    "&:before": {
      height: 0,
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
})(MuiAccordionSummary);

export { AccordionSummary, Accordion };
