// import AWS from "aws-sdk";

const getHostedUiLink = function (config, useLocalhostMode) {
  // ?compid=${config.compid} not needed, as already included
  const redirectUri = useLocalhostMode
    ? `${config.oauth.redirectSignIn}&islocal=true`
    : config.oauth.redirectSignIn;

  return `https://${config.oauth.domain}/oauth2/authorize?client_id=${
    config.userPoolWebClientId
  }&response_type=${config.oauth.responseType}&scope=${config.oauth.scope.join(
    "+"
  )}&redirect_uri=${encodeURIComponent(redirectUri)}`;
};

// in case you want to get amplify config:
// const getConfig = function (config) {
//   AWS.config.update({ region: config.region });

//   return {
//     Auth: {
//       clientMetadata: { source: "content-dashboard" },
//       identityPoolId: config.identityPoolId, // or process.env[`REACT_APP_service_account_${process.env.REACT_APP_DASHBOARD_NAME.toLowerCase()}_dashboard_identity_pool_id`]
//       oauth: {
//         domain: config.oauth.domain,
//         redirectSignIn:
//           typeof window !== "undefined"
//             ? `${window.location.origin}/`
//             : config.oauth.redirectSignIn,
//         redirectSignOut:
//           typeof window !== "undefined"
//             ? `${window.location.origin}/`
//             : config.oauth.redirectSignOut,
//         responseType: config.oauth.responseType,
//         scope: config.oauth.scope,
//       },
//       region: AWS.config.region,
//       userPoolId: config.userPoolId,
//       userPoolWebClientId: config.userPoolWebClientId,
//     },
//   };
// };

// export default getConfig;

export default getHostedUiLink;
