import React, { useCallback } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import Dialog from "./shared/Dialog";
import useModal from "../hooks/useModal";

const CopyJourneyModal = ({ journey, onConfirm, onClose }) => {
  const { isLoading, handleConfirm } = useModal({
    onClose,
    onConfirm: useCallback(() => onConfirm(journey), [journey, onConfirm]),
  });

  return (
    <Dialog fullWidth open maxWidth="xs">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {`Copy "${journey.internalName || journey.name}" Journey?`}
          <div>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          All materials and interactions will be copied to the new Journey
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={25} />}
          variant="contained"
          onClick={handleConfirm}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyJourneyModal;
