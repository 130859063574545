import React from "react";
import ReactDOM from "react-dom";
// import { Authenticator } from "@aws-amplify/ui-react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Amplify shows sign in if code available in url. Code will be available in url after Typeform auth, so we need to skip showing sign in.
const searchParams = new URLSearchParams(window.location.search);

if (
  searchParams.has("code") &&
  searchParams.has("state") &&
  localStorage.getItem("HI::typeform:state") === searchParams.get("state")
) {
  window.sessionStorage.setItem(
    "oauthflow",
    JSON.stringify(
      Object.fromEntries(new URLSearchParams(window.location.search))
    )
  );
  localStorage.removeItem("HI::typeform:state");

  setTimeout(() => {
    window.location.href = window.location.pathname;
  }, 1000);
}
//

ReactDOM.render(
  <React.StrictMode>
    {/* <Authenticator.Provider> */}
    <App />
    {/* </Authenticator.Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
